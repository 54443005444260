import { forwardRef, useImperativeHandle, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { useOutletContext } from "react-router-dom";
import { toTitleCase } from "../../../../utils/helper.utils";
import IntegrationService from "../../../../services/integrationservice";
import { toast } from "react-toastify";
import { FieldTypeById } from "../../integrations-constants";
import SelectInput from "../../../../shared/select/select-input.component";

const getImageUrl = (imageName) => {
  try {
    return require(`../../../../../../assets/img/${imageName}`);
  } catch (error) {
    console.error(`Image not found: ${imageName}`);
    return null;
  }
};

const FieldMappingModal = forwardRef(function FieldMappingModal(
  {
    fieldMapping,
    crmFieldOptions,
    handleUpdate,
    fieldValidationObj,
    selectedObjectIds,
  },
  ref
) {
  const { integration, configuration } = useOutletContext();
  const integrationId = integration.integrationId;
  const integrationName = toTitleCase(configuration.name);

  const [show, setShow] = useState();
  const [currentFieldMappings, setCurrentFieldMappings] = useState({
    ...fieldMapping,
  });

  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [inCompatibleFields, setInCompatibleFields] = useState([]);

  const crmImageUrl = getImageUrl(
    configuration.name.toLowerCase() + "-mini.svg"
  );

  useImperativeHandle(ref, () => {
    return {
      show() {
        setShow(true);
      },
      hide() {
        setShow(false);
      },
      isShown() {
        return show;
      },
    };
  });

  function handleClose() {
    setShow(false);
  }

  function handleFieldSelection(currentFieldIndex, selectedField) {
    const crmFields = Array.isArray(selectedField)
      ? [...selectedField]
      : [{ ...selectedField }];

    if (
      fieldValidationObj?.multipleSelection &&
      crmFields.length > fieldValidationObj?.maxSelection
    ) {
      toast.error(
        `Maximum ${fieldValidationObj?.maxSelection} values can be selected.`
      );
      crmFields.pop();
    }

    currentFieldMappings.crmFields[currentFieldIndex] = {
      ...currentFieldMappings.crmFields[currentFieldIndex],
      crmFieldName: crmFields.length ? crmFields.map((f) => f.text) : [],
      crmFieldId: crmFields.length ? crmFields.map((f) => f.id) : [],
      crmFieldType: crmFields.length
        ? crmFields.map((f) => f.additional?.type)
        : null,
    };

    setCurrentFieldMappings({ ...currentFieldMappings });
    showWarningsIfAny();
  }

  function showWarningsIfAny() {
    const validationObj = fieldValidationObj?.validation;

    const isTypeValid = (type) => {
      if (
        validationObj?.notsupporteddatatypes &&
        validationObj?.notsupporteddatatypes?.includes(type)
      ) {
        return false;
      } else if (
        validationObj?.supporteddatatypes &&
        !validationObj?.supporteddatatypes?.includes(type)
      ) {
        return false;
      }

      return true;
    };

    if (validationObj) {
      const _inCompatibleFields = [];

      currentFieldMappings.crmFields.filter(x => selectedObjectIds.includes(x.objectTypeId)).forEach((field) => {
        const invalidPrefix = [7, 8, 9].includes(
          currentFieldMappings.pvlFieldId
        )
          ? Array.isArray(field.crmFieldName)
            ? field?.crmFieldName?.findLast((t) => !t.startsWith("sc_"))
            : !field.crmFieldName?.startsWith("sc_")
          : false;

        const objectTypeName = configuration.defaultConfig.types.find(
          (d) => d.typeId === field.objectTypeId
        )?.displayName;

        if (invalidPrefix) {
          const foundIndex = _inCompatibleFields.findIndex(
            (f) => f.id === "INVALID_FIELD_NAME"
          );

          const message = `The sureConnect field name
                    <strong className="text-dark">
                      ${currentFieldMappings.pvlFieldName}
                    </strong>
                    isn't compatible with the ${integrationName.toLowerCase()}
                    ${objectTypeName ? objectTypeName.toLowerCase() + " " : ""}
                    field name
                    <strong className="text-dark">${field.crmFieldName}</strong>.
                    `;

          if (foundIndex > -1) {
            _inCompatibleFields[foundIndex].messages.push(message);
          } else {
            _inCompatibleFields.push({
              id: "INVALID_FIELD_NAME",
              title: "Invalid Field Name",
              messages: [message],
              note: `This crm field name should start with <strong>"sc_"</strong>. Please make sure
                    these mappings have compatible field names`,
            });
          }
        } else {
          const invalidFieldType = Array.isArray(field?.crmFieldType)
            ? field?.crmFieldType?.findLast((t) => !isTypeValid(t))
            : isTypeValid(field?.crmFieldType)
              ? null
              : field?.crmFieldType;

          const message = `The sureConnect field type
              <strong className="text-dark">
                ${FieldTypeById[currentFieldMappings.pvlFieldType]}
              </strong>
              isn't compatible with the ${integrationName.toLowerCase()}
              ${objectTypeName ? objectTypeName.toLowerCase() + " " : ""}
              field type
              <strong className="text-dark">${invalidFieldType}</strong>.
              `;

          if (invalidFieldType) {
            const foundIndex = _inCompatibleFields.findIndex(
              (f) => f.id === "FIELD_TYPE_MISMATCH"
            );

            if (foundIndex > -1) {
              _inCompatibleFields[foundIndex].messages.push(message);
            } else {
              _inCompatibleFields.push({
                id: "FIELD_TYPE_MISMATCH",
                title: "Field Type Mismatch!",
                messages: [message],
                note: `This could cause errors during syncing. Please make sure
                    these mappings have compatible field types`,
              });
            }
          }
        }
      });

      setInCompatibleFields([..._inCompatibleFields]);
    }
  }

  function handleSave() {
    if (inCompatibleFields.length) {
      toast.error("Please fix the warnings to proceed.");
      return;
    }

    setIsButtonLoading(true);

    const payload = [];

    for (const f of currentFieldMappings.crmFields) {
      if (!selectedObjectIds.includes(f.objectTypeId)) {
        continue;
      }

      if (!f.crmFieldId?.length) {
        toast.error("Please select the values.");
        setIsButtonLoading(false);
        return;
      }

      if (Array.isArray(f.crmFieldId) ? f.crmFieldId.length : f.crmFieldId) {
        payload.push({
          fieldMappingId: f.fieldMappingId,
          pvlFieldID: currentFieldMappings.pvlFieldId,
          pvlFieldType: currentFieldMappings.pvlFieldType,
          crmFieldName: Array.isArray(f.crmFieldName)
            ? f.crmFieldName?.join(",")
            : f.crmFieldName ?? null,
          crmFieldId: Array.isArray(f.crmFieldId)
            ? f.crmFieldId?.join(",")
            : f.crmFieldId ?? null,
          crmFieldType: Array.isArray(f.crmFieldType)
            ? f.crmFieldType?.at(f.crmFieldType.length - 1)
            : f.crmFieldType ?? null,
          syncDirection: currentFieldMappings.syncDirection,
          integrationId: integrationId,
          integrationType: configuration.crmConfigurationId,
          objectType: f.objectTypeId,
        });
      }
    }

    IntegrationService.UpdateFieldMapping(payload)
      .then((result) => {
        toast.success("Field mapping updated successfully.");
        handleClose();

        if (handleUpdate) {
          handleUpdate(currentFieldMappings);
        }
      })
      .catch((error) => {
        if (handleUpdate) {
          handleUpdate();
        }
        toast.error("Failed to update, please try again.");
      })
      .finally(() => {
        setIsButtonLoading(false);
      });
  }

  return (
    <Modal
      show={show}
      onHide={handleClose}
      className="modal-no-border modal-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title className="modal-title">
          <span className="round-modal-icon grey">
            <i className="fa-solid fa-left-right"></i>
          </span>{" "}
          Edit Mapping
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>Map CRM field for the following SureConnect field</div>
        <div className="summary-wrap mt-3 p-20px">
          <div className="dark-text lato-bold position-relative d-flex align-items-center gap-2">
            {" "}
              <img src={getImageUrl("sc-phone.svg")} alt={"SureConnect"} />
            <span>
              SureConnect Field
            </span>
          </div>
          <div className="lato-medium dark-text mt-3">
            "{fieldMapping.pvlFieldName}"
          </div>
          <div className="font-12 grey-text">
            Field Type:
            <span className="lato-medium dark-text">
              {" "}
              {FieldTypeById[fieldMapping.pvlFieldType]}
            </span>
          </div>
          {/* <div className="font-12 grey-text">
            Sample data:<span className="lato-medium dark-text"> Frank</span>
          </div> */}
        </div>
        <div className="summary-wrap mt-3 p-20px">
          <div className="dark-text lato-bold position-relative d-flex align-items-center gap-2">            
              {" "}
              <img src={crmImageUrl} alt={integrationName} />            
            <span>
              {integrationName} Fields
            </span>
          </div>
          <div className="row mt-3">
            {currentFieldMappings.crmFields.map((field, index) => (
              <div
                key={index}
                className="col"
                style={{
                  display: selectedObjectIds.includes(field.objectTypeId)
                    ? "block"
                    : "none",
                }}
              >
                <div className="lato-bold dark-text mb-2">
                  {toTitleCase(field.objectTypeName)} Field
                </div>
                <div className="mb-2">
                  <SelectInput
                    aria-label="Field Select"
                    className="w-100"
                    multiple={fieldValidationObj?.multipleSelection ?? false}
                    defaultValue={field.crmFieldId}
                    data={crmFieldOptions[field.objectTypeId]}
                    disabled={
                      !crmFieldOptions[field.objectTypeId] ||
                      !selectedObjectIds.includes(field.objectTypeId)
                    }
                    allowSearch={true}
                    onSelectionChanges={(selectedField) =>
                      handleFieldSelection(index, selectedField)
                    }
                  />
                </div>
                <div className="font-12 grey-text">
                  Field Type:
                  <span className="lato-medium dark-text">
                    {" "}
                    {field.crmFieldType?.at(field.crmFieldType.length - 1) ??
                      ""}
                  </span>
                </div>
                {/* <div className="font-12 grey-text">
                  Sample data:
                  <span className="lato-medium dark-text"> Frank</span>
                </div> */}
              </div>
            ))}
          </div>
        </div>
        {inCompatibleFields.length
          ? inCompatibleFields.map((d) => (
              <div className="warning-box mt-4">
                <i className="fa-solid fa-warning orange-color"></i>
                <span className="lato-bold dark-text ms-2">{d.title}</span>
                <div className="font-12 grey-text mt-2">
                  <ul>
                    {d.messages.map((message) => (
                      <li dangerouslySetInnerHTML={{ __html: message }}></li>
                    ))}
                  </ul>
                  <div dangerouslySetInnerHTML={{ __html: d.note }}></div>
                </div>
              </div>
            ))
          : undefined}
      </Modal.Body>
      <Modal.Footer className="justify-content-end">
        <Button
          className="btn btn-cancel mr-15px"
          variant="secondary"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          className="btn blue-btn-primary"
          variant="primary"
          onClick={handleSave}
          disabled={isButtonLoading || !selectedObjectIds.length}
        >
          {isButtonLoading ? (
            <Spinner
              style={{ width: "15px", height: "15px" }}
              animation="border"
              role="status"
            >
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          ) : (
            <span>Save</span>
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
});

export default FieldMappingModal;
