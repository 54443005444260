import {React, useEffect, useState} from 'react';
import './login.component.scss';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import { useGoogleLogin } from '@react-oauth/google';
import { Link } from 'react-router-dom';
import { userSignup,inhouseSignup,emailVerification,regenerateVerifyEmail, verifyRecaptcha, getDetailsFromGoogle, getDetailsFromOffice365 } from '../../apis/pvlapis';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { msalInstance } from '../../configs/azureConfig';
import { VerifiedEmail } from '../emailvalidate/verified.emailvalidate';
import { VerificationFailed } from '../emailvalidate/failed.emailvalidate';
import { VerifySent } from '../emailvalidate/verifyemail.emailvalidate';
import ReCAPTCHA from "react-google-recaptcha";
import { async } from 'q';
import axios from 'axios';
import { Mixpanel } from '../../configs/mixPanel';
import { Crisp } from 'crisp-sdk-web';

export default function Signup({isFromInvitation = false, invitationToken='',redirectUrl=''}) {
  const navigate = useNavigate();
  const { token } = useParams();
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [invalidUserName, setInvalidUserName] = useState(null);
  const [invalidUserEmail, setInvalidUserEmail] = useState(null);
  const [userPassword, setUserPassword] = useState("");
  const [invalidUserPassword, setInvalidUserPassword] = useState(null);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage,setErrorMessage] = useState(null);
  const [errorDescription,seterrorDescription] = useState("Something went wrong. Please sign up again.");
  const [showSignupForm, setShowSignupForm] = useState(false);
  const [showEmailSent, setShowEmailSent] = useState(false);
  const [showEmailVerified, setShowEmailVerified] = useState(false);
  const [showLinkExpired, setShowLinkExpired] = useState(false);
  const [verificationMessage, setVerificationMessage] = useState("");
  const [verificationDescription, setVerificationDescription] = useState("");
  const [verifiedUserEmail, setVerifiedUserEmail] = useState(null);
  const [verifiedUserId, setVerifiedUserId] = useState(null);
  const [verificationToken, setVerificationToken] = useState(null);
  const [disableSignupButton,setDisableSignupButton] = useState(true);
  const [invalidRecaptcha, setInvalidRecaptcha] = useState(null);
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
  const [recaptcha, setRecaptcha] = useState(null);
  const [ user, setUser ] = useState(null);
  const [ source, setSource ] = useState(null);
  const [signupSuccess, setSignupSuccess] = useState(false);

  const handleEmailChange = (e) => {
    setUserEmail(e.target.value);
    setInvalidUserEmail(null);
  };

  const handleTermsConditionsChange = (event) => {
    const { value, checked } = event.target;
    if(checked){
      setDisableSignupButton(false);
    }
    else{
      setDisableSignupButton(true);
    }
  }

  const handlePasswordChange = (e) => {
    const enteredPassword = e.target.value;
    setUserPassword(enteredPassword);
    const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,15}$/;
    if (!passwordRegex.test(enteredPassword)) {
      setInvalidUserPassword("Password length should be minimum of 8 characters and maximum of 15 characters. Should have at least one special character, one number, one lowercase, and one uppercase letter.");
    }
    else{
      setInvalidUserPassword(null);
    }
  };
  const handleUserNameChange = (e) => {
    setUserName(e.target.value);
    setInvalidUserName(null);
  }
  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };
  const sendToHubspot = async() => {
    try{
      const formId  = 'df05744b-c8c6-429e-9957-6d06a8e20daa';
      const portalId = '24914847';
      const config = { // important!
        headers: {
          'Content-Type': 'application/json',
        },
      }
      const response = await axios.post(`https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`,
      {
        portalId,
        formId,
        fields: [
          {
            name: 'firstname',
            value: userName,
          },
          {
            name: 'email',
            value: userEmail,
          }
        ],
      },
      config
    );


    }
    catch(err){
      // console.log("ERROR SENDING DATA TO HUBSPOT",err)
    }
  }
  //Signup using Inhouse 
  const signupInhouse = async(e) => {
    Mixpanel.track("Signup button clicked");
    e.preventDefault();
    let checks_passed = true;
    //Check email validity
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(userEmail)) {
      setInvalidUserEmail("Enter a valid email address.");
      setUserPassword('');
      checks_passed = false;
    }
    //Check password validity
    if(userName.trim().length === 0){
      setInvalidUserName("Enter your user name");
      setUserPassword('');
      checks_passed = false;
    }
    //Check length os username
    if(userName.trim().length > 50){
      setInvalidUserName("User name is too big");
      setUserPassword('');
      checks_passed = false;
    }
    //Check password validity
    const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,15}$/;
    if (!passwordRegex.test(userPassword)) {
      setInvalidUserPassword("Password length should be minimum of 8 characters and maximum of 15 characters. Should have at least one special character, one number, one lowercase, and one uppercase letter.");
      setUserPassword('');
      checks_passed = false;
    }
    //Check for captch value
    if(!recaptchaValue){
      setInvalidRecaptcha("Captcha is required");
      return
    }
    //Check captch validity
    if(!await verifyRecaptcha(recaptchaValue)){
      setRecaptchaValue(null);
      recaptcha.reset();
      setInvalidRecaptcha("Invalid Captcha");
      checks_passed=false;
    }
    if(!checks_passed){
      return;
    }
    try{
      setDisableSignupButton(true);
      const requestForm = {
        "userFullName": userName.trim(),
        "userEmail": userEmail.trim(),
        "password": userPassword.trim(),
      };
      
      // Add the invitation property only if the invitationToken has a length greater than 0
      if (invitationToken.trim().length > 0 && !showErrorMessage) {
        requestForm.invitation = { token: invitationToken };
      }
      
      setSource("inhouse");
      setSignupSuccess(true);
      const response = await inhouseSignup(requestForm);
      // console.log(response);
      if (response.data.statusCode === 200){
        setShowSignupForm(false);
        setShowErrorMessage(false);
        // console.log("Access token",response.data.result.tokens.accessToken);
        setShowSignupForm(false);
        setShowErrorMessage(false);
        if(invitationToken.length>0){
          toast.success("You have successfully signed up. Login to continue.",{position: toast.POSITION.BOTTOM_LEFT});
          navigate(redirectUrl,{state:{signUpSuccessfull:true}});
        }
        else{
          setVerificationMessage("Verify your Email!");
          setVerificationDescription("A verification link has been sent to your email ID");
          toast.success("Signup Successful. Please Verify Your Email.",{position: toast.POSITION.BOTTOM_LEFT});
          setShowEmailSent(true);
        }
        sendSignUpDataToCrisp(requestForm);
      }
      else if(response.data.statusCode === 462 && invitationToken.length > 0){
        // toast.info("Token Expired for login.",{position: toast.POSITION.BOTTOM_LEFT});
        setShowSignupForm(true);
        setShowLinkExpired(true);
        setShowErrorMessage(true);
        setErrorMessage("Invitation Expired");
        seterrorDescription("Your invitation has expired. Ask account owner for a new invitation.");
      }
      else if(response.data.statusCode === 455 && invitationToken.length > 0){
        // toast.info("Your Token to join the account is invalid",{position: toast.POSITION.BOTTOM_LEFT});
        setShowErrorMessage(true);
        setShowSignupForm(true);
        setErrorMessage("Invalid Invitation");
        seterrorDescription("Your invitation link is invalid. Please get in touch with support team.");
      }
      else if(response.data.statusCode === 459){
        toast.warning("Please try Signup with a valid Business Email.",{position: toast.POSITION.BOTTOM_LEFT});
      }
      else if(response.data.statusCode === 452){
        toast.info("Account already present. Please Login!",{position: toast.POSITION.BOTTOM_LEFT});
      }
      else if(response.data.statusCode === 460){
        toast.info("User already registered. Please verify your email to Login.",{position: toast.POSITION.BOTTOM_LEFT});
        setShowSignupForm(false);
        setShowErrorMessage(false);
        setShowEmailSent(true);
        setVerificationMessage("Email Already Present");
        setVerificationDescription("You have already signed up. A verification link is already sent to your email ID")
      }
      else if(response.data.statusCode === 463){
        toast.info("This email already exists. Please Login!",{position: toast.POSITION.BOTTOM_LEFT});
      }
      else if(response.data.statusCode === 402){
        toast.error("User is not allowed to join the acount!",{position: toast.POSITION.BOTTOM_LEFT});
      }
      else{
        toast.error("Something went wrong. Please try again.",{position: toast.POSITION.BOTTOM_LEFT});
      }
    }
    catch(error){
      // console.log("Caught Error", error);
      // toast.error("Something went wrong. Please try again after some time.",{position: toast.POSITION.BOTTOM_LEFT})
    }
    finally{
      setDisableSignupButton(false);
      recaptcha.reset();
    }
  }
  

  //Signup using Google 
  const signupGoogle =  useGoogleLogin({
        onSuccess: (codeResponse) => {
          Mixpanel.track("Signup button clicked");
          setUser(codeResponse);
          setSource("google");
        },
        onError: (error) => toast.error("Please try again!",{position: toast.POSITION.BOTTOM_LEFT})
    });
    //Signup using Office365
    const signupOffice = async () => {
      Mixpanel.track("Signup button clicked");
      const loginRequest = {
          scopes:  ["openid", "profile", "User.Read", "email"]  // Add additional scopes as needed
      };
  
      try {
          // Attempt login using a popup window
          const response = await msalInstance.loginPopup(loginRequest);

          setUser({access_token: response.accessToken});
          setSource("office365");

      } catch (err) {
        toast.error("Please try again!",{position: toast.POSITION.BOTTOM_LEFT});
      }
  };

  //Call signup function
  const signupUser = async() => {
    try{
      if(source === 'google'){
        const response = await getDetailsFromGoogle(user.access_token);
        if(response.status !== 200){
          toast.error("Something went wrong. Please try again after some time.",{position: toast.POSITION.BOTTOM_LEFT});
          return;
        }
        setUserName(response.data.name);
        setUserEmail(response.data.email);
        sendSignUpDataToCrisp({'userEmail': response.data.email, 'userFullName': response.data.name})
      }
      else if(source === 'office365')
      {
        const response = await getDetailsFromOffice365(user.access_token)
        if(response.status !== 200){
          toast.error("Something went wrong. Please try again after some time.",{position: toast.POSITION.BOTTOM_LEFT});
          return;
        }
        setUserName(response.data.displayName);
        setUserEmail(response.data.mail);
        sendSignUpDataToCrisp({'userEmail': response.data.email, 'userFullName': response.data.name})
      }
      setSignupSuccess(true);
      await userSignup(user.access_token, source, navigate,showErrorMessage?'':invitationToken, showErrorMessage?'':redirectUrl);
    }
    catch (error) {

      // toast.error("Please try again!",{position: toast.POSITION.BOTTOM_LEFT});
    }
  }
  const verifyEmail = async() => {
    try{
      setVerificationToken(token);
        if(invitationToken.length<=0){
          const response = await emailVerification(token);
        
          
        // Show the verified page if success
        // console.log(response);
        if(response.data.statusCode === 200){
          setShowSignupForm(false);
          setShowEmailVerified(true);
          setVerificationMessage("Email Verified Successfully!");
          setVerificationDescription("Your email has been verified and your account is now active. You may login to your account.");
        }
        else if(response.data.statusCode === 463){
          setShowSignupForm(false);
          setShowEmailVerified(true);
          setVerificationMessage("Email already Verified!");
          setVerificationDescription("Your email has already been verified and your account is active. You may login to your account.");
        }
        // Show token expired if status=462
        else if(response.data.statusCode === 462){
          // console.log(response.data.statusCode);
          setShowSignupForm(false);
          setShowLinkExpired(true);
          setVerificationMessage("Verification link expired!");
          setVerificationDescription("The verification link emailed to you has expired. Please use the button below to get a new link.");
        }
        // Show tinvalid token if status=455
        else if(response.data.statusCode === 455){
          setShowErrorMessage(true);
          setShowSignupForm(true);
          setErrorMessage("Invalid Access Token!");
        }
        //show error message if wrong
        else{
          setShowErrorMessage(true);
          setErrorMessage("Invalid Request!");
          setShowSignupForm(true);
        }
      }
      else{
        setShowSignupForm(true);
      }
    }
    catch(err){
      // console.log("Vrification Error",err);
      //Show signup form if error
      // console.log(err);
      setErrorMessage("Could Not Process!");
      setShowErrorMessage(true);
      setShowSignupForm(true);
      toast.error("Something went wrong. Please try again after some time.",{position: toast.POSITION.BOTTOM_LEFT});
    }
  }

  const handleResendLink = async() => {
    // console.log("RESENDING");
    try{
      const payload = {
        "token": verificationToken,
        "userEmail": userEmail
      }
      const response = await regenerateVerifyEmail(payload);
      if (response === 200){
        setShowSignupForm(false);
        setShowLinkExpired(false);
        setVerificationMessage("Verify your Email!")
        setVerificationDescription("A verification email has been resent to your email ID")
        setShowEmailSent(true);
        toast.success("Email sent again!",{position: toast.POSITION.BOTTOM_LEFT});
      }
      else if(response === 463){
        setShowSignupForm(false);
        setShowLinkExpired(false);
        setVerificationMessage("Email already Verified!");
        setVerificationDescription("Your email has already been verified and your account is active. You may login to your account.");
        setShowEmailVerified(true);
        toast.warning("Email already verified!",{position: toast.POSITION.BOTTOM_LEFT});
      }
      else if(response.data.statusCode === 455){
        setShowLinkExpired(false);
        setShowSignupForm(true);
        setErrorMessage("Invalid Access Token!");
        setShowErrorMessage(true);
      }
      else{
        setShowSignupForm(false);
        setVerificationMessage("Resend email Failed!");
        setVerificationDescription("Could not resed the verification email. Please try again or contact the support team if problem persists.");
        toast.error("Failed to resend!",{position: toast.POSITION.BOTTOM_LEFT});
      }
    }
    catch(err){
      toast.error("Please try again!",{position: toast.POSITION.BOTTOM_LEFT});
    }
  }

  const sendSignUpDataToCrisp = async(inputData) => {
    Crisp.user.setEmail(inputData.userEmail);
    Crisp.user.setNickname(inputData.userFullName);
    Crisp.session.setSegments(['Registered', "SureConnect.ai"]);
    Crisp.load();
    Crisp.chat.hide();
    Crisp.session.pushEvent('sc-registered-user');
    
    //send signed up user to Tolt eferral
    if (window.tolt_referral) {
      window.tolt.signup(inputData.userEmail);
    }
  }
  
    useEffect(
      () => {
        
          if (user) {
            // console.log("SIGNUP USER USE EFFECT");
            signupUser();
          }
      },
      [ user ]
  );

  useEffect(
    () => {
      
        if (signupSuccess) {
          // console.log("SIGNUP USER USE EFFECT");
          sendToHubspot();
        }
    },
    [ signupSuccess ]
);
    useEffect(
      () => {
        if(token ){
          verifyEmail();
        }
        else{
          setShowSignupForm(true);
        }
      },
      [token]
    );
  return (
    <div className="login-wrap">
      <div className="left">
        <div className="inside-wrap">
          <div>
            <img src={require("../../../assets/img/sc-logo-signup.svg").default} className='logo-img' alt="logo" />
          </div>

           {/* Error Msg */}
           {
            showErrorMessage && (

          <div className="error_message">
            <img src={require("../../../assets/img/alert-circle.svg").default} alt="logo" className="me-2"/>
            <div className="message-block">
              <div className="font-14  poppins-medium mb-1">
                {errorMessage}
              </div>
              <div className=" font-14  lato-regular">
              {errorDescription}
              </div>
            </div>
            <button type="button" className="btn btn-cancel pt-0 ms-auto">X</button>
          </div>
            )
           }
           {
            invitationToken.length>0 && !showErrorMessage && (

          <div className="join_account_message">
            <div className="message-block">
              <div className="font-14  poppins-medium mb-1">
                Welcome to Sure connect.
              </div>
              <div className=" font-14  lato-regular">
              Please signup to join the account.
              </div>
            </div>
          </div>
            )
           }
           
          {/* Error Msg End */}

          <div className="form-wrap">
              {/* Verify your Email! */}
              {showEmailSent &&!isFromInvitation && (
                <VerifySent messDes={verificationDescription} messHead={verificationMessage} userEmail={userEmail} onResend={handleResendLink}/>
              )}
              {/* Verify your Email! End */}

              {/* Verification Success and Already Verified Use Same */}
              { showEmailVerified && !isFromInvitation && (
                <VerifiedEmail messHead={verificationMessage} messDescription={verificationDescription}/>
              )}
              {/* Verification Success End */}


              {/* Verification - Link Expired  */}
              {showLinkExpired && !isFromInvitation &&(
                <VerificationFailed onResent={handleResendLink} errorHead={verificationMessage} errorDescription={verificationDescription} />
              )}
              {/* Verification - Link Expired  End */}
              {showSignupForm && (
              <div className="sign-up-block">
                <div className="text-center font-5 poppins-medium mb-4">
                🇺🇸🇨🇦 Validates +1 country code numbers only.
                </div>
                <div className="text-center font-20 dark-text poppins-medium mb-4">
                  Sign Up
                </div>
                <form>
                <input
                    type="text"
                    value={userName}
                    className={`form-control ${invalidUserName ? 'is-invalid' : ''}`}
                    placeholder="John Smith"
                    onChange={handleUserNameChange}
                    required
                  />
                  {invalidUserName && (
                    <div className=" font-14  lato-regular text_error_message">
                            {invalidUserName}
                      </div>
                  )}
                  <input
                    type="email"
                    value={userEmail}
                    className={`form-control ${invalidUserEmail ? 'is-invalid' : ''}`}
                    placeholder="john@example.com"
                    onChange={handleEmailChange}
                    required
                  />
                  {invalidUserEmail && (
                    <div className=" font-14  lato-regular text_error_message">
                            {invalidUserEmail}
                      </div>
                  )}
                  <input
                    type="password"
                    value={userPassword}
                    className={`form-control ${invalidUserPassword ? 'is-invalid' : ''}`}
                    placeholder="••••••••"
                    onChange={handlePasswordChange}
                    required
                  />
                  {invalidUserPassword && (
                    <div className=" font-14  lato-regular text_error_message text_error_message">
                            {invalidUserPassword}
                      </div>
                  )}
                  <div className='mt-3'>
                    <ReCAPTCHA
                      ref={ref => setRecaptcha(ref)}
                      sitekey={recaptchaSiteKey}
                      onChange={handleRecaptchaChange}
                    />
                  </div>
                  {
                    invalidRecaptcha && (
                      <div className=" font-14  lato-regular text_error_message text_error_message">
                                {invalidRecaptcha}
                      </div>
                    )
                  }
                <div className="font-14 poppins-regular text-left mt-4">
                <label className="CheckboxContainer mb-1 mt-1 d-block">
                        <input
                            type="checkbox"
                            name="Status"
                            value="true"
                            onChange={handleTermsConditionsChange}
                            className=""
                        />
                        <span className="CheckedMark"></span>
                        <span className="ms-4 ps-1 light-text lato-medium">I have read and agree to the</span>
                        <a href="https://sureconnect.ai/terms-conditions" target="_blank" rel="noopener noreferrer" className="primary-blue-text poppins-medium ms-1 cursor-pointer">
                    Terms and Conditions
                  </a>
                    </label>
                </div>
                    <button className="btn blue-btn-primary w-100 my-3 btn-lg" disabled={disableSignupButton} onClick={signupInhouse}>Signup</button>
                  <div className="text-center">OR</div>
                  
                  <div className="social-login-btn text-center">
                    <img
                      src={require("../../../assets/img/google-icon.svg").default}
                      alt="google-icon"
                      className="me-2"
                    />
                    <span className="poppins-medium font-14 default-text" onClick={() => signupGoogle()}>
                      Signup with Google
                    </span>
                  </div>
                  <div className="social-login-btn text-center">
                    <img
                      src={require("../../../assets/img/office-365-icon.svg").default}
                      alt="office-365-icon"
                      className="me-2"
                    />
                    <span className="poppins-medium font-14 default-text" onClick={() => signupOffice()}>
                      Signup with Office365
                    </span>
                  </div>
                </form>
                <div className="font-14 poppins-regular text-center mt-4">
                <span className="ms-4 ps-1 light-text lato-medium">By continuing, you are agreeing to the</span>
                        <a href="https://sureconnect.ai/terms-conditions" target="_blank" rel="noopener noreferrer" className="primary-blue-text poppins-medium ms-1 cursor-pointer">
                    Terms and Conditions
                  </a>
                </div>
                <div className="font-14 poppins-regular text-center mt-4">
                  Already have an account?
                  <Link to="/">
                  <span className="primary-blue-text poppins-medium ms-1 cursor-pointer">
                    Log in
                  </span>
                  </Link>
                </div>
              </div>
              )}
          </div>
        </div>
      </div>
      <div className="right">
      <div className="inside-wrap">
        {/* <img src={require("../../../assets/img/rhs-img.png")} alt="rhs-image" /> */}
          <iframe className='testi-video mb-4' width="95%" height="334" src="https://www.youtube.com/embed/CtcG24EeJHo?si=C9GoMhrvQq-lIGFq" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
          <img src={require("../../../assets/img/Signup.png")} alt="rhs-image" className='testi-video-text'  />
        </div>
      </div>
      < ToastContainer / >
    </div>
  );
}
