import React, { useState } from "react";
import '../list.component.scss'
import { Tooltip } from "react-tooltip";
import 'react-tooltip/dist/react-tooltip.css'
import defaultProfilePic from '../../../../../assets/img/user-dummy.svg'
import { Mixpanel } from "../../../../configs/mixPanel";

const ListCard = ({project, onDownload}) => {
    const [openDetails, setOpenDetails] = useState(false);

    const toggleOpenDetails = () => {
      setOpenDetails(!openDetails);
    }

    const openDownloadModal = (projectId) => {
      // console.log("Download here");
      // console.log(projectId,project);
      Mixpanel.track("External Download Results button clicked");
      //Call the handleOpenDownload function with projectid
      onDownload(project);
    }
    const calculateDaysAgo = (date) => {
      //Used to calculate the days between the current time and the uploaded date.
      //Format the given date
        const givendate = new Date(date);
        //Get current date
        const presentdate = new Date()
        const oneday = 24 * 60 * 60 * 1000;
        //Calculate the difference in days
        const diffdays = Math.floor((presentdate - givendate) / oneday);
        //If difference is less than 1, call it today
        if (diffdays < 1){
            return "Today"
        }
        //If difference is 1, call it yesterday
        else if (diffdays === 1) {
            return "Yesterday"
        } else {
            return `${diffdays} days ago`
        }
        
    }
    const dateformatter = (date) => {
      //Used to format the date in UTC
      //Format the date part of the complete date
      const formatedDate = new Date(date).toLocaleDateString(undefined, {
        day: "numeric",
        month: "short",
        year: "numeric",
      });
      //Format the time part of the complete date
      const formattedTime = new Date(date).toLocaleTimeString(undefined, {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
      //Get month, day, year of from the formatted date.
      const [month, day, year] = formatedDate.split(" ");
      const formattedDay = day.includes(",") ? day.replace(",", "") : day; 
      const outputDate = `${formattedDay} ${month}, ${year}`;
      //Combine date and time
      return `${outputDate} ${formattedTime} UTC`;
    }
    //Get the original projectname
    const originalProjectName = project.projectName;
    let projectName = project.projectName
    //Slice the project name if the size is more than 20 characters
    if(projectName.length > 20){
      projectName = projectName.slice(0,20) + "..."
    }
    const projectStatus = project.projectExecutionStatus
    const projectStats = project.projectStats[0]
    const totalRecords = projectStats.totalProspects
    const completedRecords = projectStats.completedProspects
    const inprogressRecords = projectStats.inProgressProspects
    const answerRecords = projectStats.answerProspects
    // const wrongRecords = projectStats.wrongProspects
    // const unreachableRecords = projectStats.unreachableProspects
    const otherRecords = projectStats.otherProspects
    const voicemailRecords = projectStats.voicemailProspects
    const gatekeeperRecords = projectStats.gatekeeperProspects
    const userDetails = JSON.parse(localStorage.getItem('PVLUserDetails'));
    const projectSource = project.projectSource;
    let userName = project.userFullName;
    // userName = userDetails.userfullname;
    
    let userPic = defaultProfilePic
    if (project.displayPicture){
      // console.log(project.displayPicture);
      //Picture of the user
      userPic = project.displayPicture;
    }
    // else if(userDetails.displaypicture !== null){
    //   userPic = userDetails.displaypicture;
    // }
    const createdDate = project.projectCreatedDate
    //Format the created date
    const formattedCreatedDate = dateformatter(createdDate);
    let completedDate = "";
    if(project.projectCompletedDate){
      //Format the completed date
      completedDate = dateformatter(project.projectCompletedDate);
    }
    
    const projectId = project.projectId
    //Mapping of the project execution status
    const statusMap = {
      1: { text: 'In Progress', tooltip: 'Wait for Results',style: 'inProgress' },
      2: { text: 'Stopped', tooltip: 'Validation Stopped',style:'stopped' },
      4: { text: 'Completed', tooltip: 'Results Available',style: 'completed' },
      5: { text: 'Failed', tooltip: 'Validation Failed',style:'failed' },
      6: { text: 'Waiting', tooltip: 'Waiting for Approval',style:'waiting' },
      7: { text: 'Rejected', tooltip: 'Rejected by Admin. Contact support if this is wrong.',style:'rejected' },
    };
    //Get the mapping of the project execution status
    const projectStatusDetails = statusMap[projectStatus] || {
      text: 'In Progress',
      tooltip: 'Wait for Results',
      style: 'inProgress'
    };
    return (
        <>
        <tr className="text-start main-tr-row">
          <td className="lato-bold dark-text project-name">
            <div className="d-flex align-items-center">
            {
              projectSource === 1 ? (
                <div className='rounded-icon grey me-2'>
                  <i className='fa-regular fa-display-code'></i>
                </div>
              ):(
                <div className='rounded-icon green me-2'>
                  <i className='fa-regular fa-file-csv'></i>
                </div>
              )
            }
              <span data-tooltip-content={originalProjectName} data-tooltip-id="listcomponent">{projectName}</span>
            </div>
          </td>
          <td className="grey-text ps-5">{totalRecords}</td>
          <td>
            <span className={`status ${projectStatusDetails.style}`} data-tooltip-content={projectStatusDetails.tooltip} data-tooltip-id="listcomponent">{projectStatusDetails.text}</span>
          </td>
          <td>
            <div className="d-inline-flex align-items-center w-20rem">
              <div className="progress w-20rem">
                <div
                  className="progress-bar valid"
                  data-tooltip-content={`Likely Answer contacts ${answerRecords}`}
                  data-tooltip-id="listcomponent"
                  role="progressbar"
                  aria-label="Segment one"
                  style={{width: `${(answerRecords/totalRecords)*100}%`}}
                  aria-valuenow="15"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
                <div
                  className="progress-bar unverifiable"
                  data-tooltip-content={`Likely Voicemail contacts ${voicemailRecords}`}
                  data-tooltip-id="listcomponent"
                  role="progressbar"
                  aria-label="Segment two"
                  style={{width: `${(voicemailRecords/totalRecords)*100}%`}}
                  aria-valuenow="15"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
                <div
                  className="progress-bar  gatekeeper"
                  data-tooltip-content={`Likely Gatekeeper contacts ${gatekeeperRecords}`}
                  data-tooltip-id="listcomponent"
                  role="progressbar"
                  aria-label="Segment three"
                  style={{width: `${(gatekeeperRecords/totalRecords)*100}%`}}
                  aria-valuenow="20"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
                <div
                  className="progress-bar duplicates"
                  data-tooltip-content={`Other contacts ${otherRecords}`}
                  data-tooltip-id="listcomponent"
                  role="progressbar"
                  aria-label="Segment four"
                  style={{width: `${(otherRecords/totalRecords)*100}%`}}
                  aria-valuenow="20"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
                {/* <div
                  className="progress-bar invalid"
                  data-tooltip-content={`Likely Wrong contacts ${wrongRecords}`}
                  data-tooltip-id="listcomponent"
                  role="progressbar"
                  aria-label="Segment five"
                  style={{width: `${(wrongRecords/totalRecords)*100}%`}}
                  aria-valuenow="30"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div> */}
              </div>
              {openDetails && (
              <div className="accordian-icon ms-2 cursor-pointer" data-tooltip-content="Close Details" data-tooltip-id="listcomponent">
                <i className="fa-regular fa-angle-up" onClick={toggleOpenDetails}></i>
              </div>
              )}
              {!openDetails && (
              <div className="accordian-icon ms-2 cursor-pointer">
                <i className="fa-regular fa-angle-down" data-tooltip-content="Expand Details" data-tooltip-id="listcomponent" onClick={toggleOpenDetails}></i>
              </div>
              )}
            </div>
          </td>
          <td className="text-center">
            <span>
              <img
                src={userPic}
                width="24"
                height="24"
                className="rounded-circle"
                data-tooltip-content={`Added by ${userName}`} data-tooltip-id="listcomponent"
              />
            </span>
          </td>
          <td>
          <span className="grey-text" data-tooltip-content={`Added on ${formattedCreatedDate}`} data-tooltip-id="listcomponent">
            {calculateDaysAgo(createdDate)}
          </span>
          </td>
          <td>
            <span className="cursor-pointer" data-tooltip-content="Download Results" data-tooltip-id="listcomponent" onClick={() => openDownloadModal(projectId)}
              ><img src={require("../../../../../assets/img/CTA.svg").default}
            /></span>
          </td>
          {/* <td className="text-end w-15rem">
            <span>
              <div className="dropdown">
                <span
                  className="grey-text"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="fa-regular fa-ellipsis-v"></i>
                </span>
                <ul className="dropdown-menu">
                  <li><a className="dropdown-item" href="#">Action</a></li>
                  <li><a className="dropdown-item" href="#">Another action</a></li>
                  <li>
                    <a className="dropdown-item" href="#">Something else here</a>
                  </li>
                </ul>
              </div>
            </span>
          </td> */}
          <td>{/* It just for making some space*/}</td>
        </tr>
        {openDetails && (
        <tr className="inside-tr">
          <td colSpan={8} className="p-0">
            <table className="table">
              <tr>
              <td className="text-center">
            <div className="lato-bold dark-text">{totalRecords}</div>
            <div className="font-12 grey-text">Total Contacts</div>
          </td>
          <td className="text-center">
            <div className="lato-bold dark-text">{completedRecords}</div>
            <div className="font-12 grey-text">Completed</div>
          </td>
          <td className="text-center">
            <div className="lato-bold dark-text">{inprogressRecords}</div>
            <div className="font-12 grey-text">In Progress</div>
          </td>
          <td className="text-center">
            <div>
              <span className="lato-bold dark-text">{answerRecords}</span
              ><span className="font-12 light-grey-text ms-1">({((answerRecords/totalRecords)*100).toFixed(0)}%)</span>
            </div>
            <div className="d-inline-flex align-items-center">
              <span className="dot-icon valid"></span>
              <span className="font-12 grey-text ms-1"data-tooltip-content="Likely Answer have 15-35% connect rate" data-tooltip-id="listcomponent">Likely Answer</span>
            </div>
          </td>
          <td className="text-center">
            <div>
              <span className="lato-bold dark-text">{voicemailRecords}</span
              ><span className="font-12 light-grey-text ms-1">({((voicemailRecords/totalRecords)*100).toFixed(0)}%)</span>
            </div>
            <div className="d-inline-flex align-items-center">
              <span className="dot-icon unverifiable"></span>
              <span className="font-12 grey-text ms-1"data-tooltip-content="Likely Voicemail have 2-10% connect rate" data-tooltip-id="listcomponent">Likely Voicemail</span>
            </div>
          </td>
          <td className="text-center">
            <div>
              <span className="lato-bold dark-text">{gatekeeperRecords}</span
              ><span className="font-12 light-grey-text ms-1">({((gatekeeperRecords/totalRecords)*100).toFixed(0)}%)</span>
            </div>
            <div className="d-inline-flex align-items-center">
              <span className="dot-icon gatekeeper"></span>
              <span className="font-12 grey-text ms-1"data-tooltip-content="Likely Gatekeeper have 0-2% connect rate" data-tooltip-id="listcomponent">Likely Gatekeeper</span>
            </div>
          </td>
          <td className="text-center">
            <div>
              <span className="lato-bold dark-text">{otherRecords}</span
              ><span className="font-12 light-grey-text ms-1">({((otherRecords/totalRecords)*100).toFixed(0)}%)</span>
            </div>
            <div className="d-inline-flex align-items-center">
              <span className="dot-icon duplicates"></span>
              <span className="font-12 grey-text ms-1"data-tooltip-content="Others have 0-1% connect rate" data-tooltip-id="listcomponent">Others</span>
            </div>
          </td>
          {/* <td className="text-center">
            <div>
              <span className="lato-bold dark-text">{wrongRecords}</span
              ><span className="font-12 light-grey-text ms-1">({((wrongRecords/totalRecords)*100).toFixed(0)}%)</span>
            </div>
            <div className="d-inline-flex align-items-center">
              <span className="dot-icon invalid"></span>
              <span className="font-12 grey-text ms-1">Likely Wrong</span>
            </div>
          </td> */}
          <td className="text-end">
            <div className="lato-bold dark-text font-12">Completed on</div>
            <div className="font-12 grey-text">{completedDate}</div>
          </td>
              </tr>
            </table>
          </td>
        </tr>
        )}
      <Tooltip id = "listcomponent"/>
      </>
    );
}

export default ListCard;