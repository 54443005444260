import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './billing.component.scss'
import { NavLink } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import poweredByStripe from '../../../../assets/img/powered_by_stripe.svg'
import CardComponent from "./cards.component";
import { getUserCards, deleteCard, changeDefaultCard, GetPermission } from "../../../apis/pvlapis";
import { async } from "q";
import LoadingAnimation from "../lottie-files/loading.lotte";
import EmptyCard from "../list/emptylist.component";
import Sidebar from "../side-bar.component";
const BillingComponent = () => {
    let poweredByStripeIcon = poweredByStripe;
    const [creditCards, setCreditCards] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const getCards = async() => {
        //used to get the credit cards of the user. Not used anymore
        try{
            const response = await getUserCards();
            // console.log(response);
            if(response.data.statusCode === 200){
                setCreditCards(response.data.result['cards']);
            }
            else{
                toast.error("Something went wrong. Please try again.",{position: toast.POSITION.BOTTOM_LEFT});
            }

        }
        catch(err){
            // console.log(err);
            toast.error("Something went wrong. Please try again after some time.",{position: toast.POSITION.BOTTOM_LEFT});
        }
        finally{
            setIsLoading(false);
        }
    }

    const handleDeleteUserCard = async(cardid) => {
        //Used to delete a credit card of a user. Not used anymore
        try{
            const payload = {
                "paymentid": cardid
            }
            const response = await deleteCard(payload);
            if(response.data.statusCode === 200){
                toast.success("Card deleted successfully!",{position: toast.POSITION.BOTTOM_LEFT});
            }
            else{
                toast.error("Something went wrong. Please try again.",{position: toast.POSITION.BOTTOM_LEFT});
            }

        }
        catch{
            toast.error("Something went wrong. Please try again after some time.",{position: toast.POSITION.BOTTOM_LEFT});

        }
        finally{
            getCards();
        }
    }

    const handleDefaultCardChange = async(cardid) => {
        //Used to change the default credit card. Not used anymore
        try{
            const payload = {
                "paymentid": cardid
            }
            const response = await changeDefaultCard(payload);
            if(response.data.statusCode === 200){
                toast.success("Default card changed successfully!",{position: toast.POSITION.BOTTOM_LEFT});
            }
            else{
                toast.error("Something went wrong. Please try again.",{position: toast.POSITION.BOTTOM_LEFT});
            }
        }
        catch{
            toast.error("Something went wrong. Please try again after some time.",{position: toast.POSITION.BOTTOM_LEFT});

        }
        finally{
            getCards();
        }
    }
    useEffect(() => {
        setIsLoading(true);
        getCards();
      },[]);
      const [showUsersTab, setShowUsersTab] = useState(null);
    const [showApiTab, setShowApiTab] = useState(null);
    const [showInvoiceTab, setShowInvoiceTab] = useState(null);
    const [showSubscriptionTab, setShowSubscriptionTab] = useState(null);
    const handleGetPermission = async() =>{
        setIsLoading(true);
  
        try {
            const response = await GetPermission();
            if(response.statusCode !==200 || response?.result === null){
                return;
            }
            const buycreditsObject = response?.result?.find(obj => obj?.component === "buycredits");
            const settingsviewcredithistoryandinvoice = response?.result?.find(obj => obj?.component === "settingsviewcredithistoryandinvoice");
            const settingsviewapi = response?.result?.find(obj => obj?.component === "settingsviewapi");
            const inviteuserstoaccount = response?.result?.find(obj => obj?.component === "inviteuserstoaccount");
  
            setShowUsersTab(inviteuserstoaccount?.permission);
            setShowApiTab(settingsviewapi?.permission);
            setShowInvoiceTab(settingsviewcredithistoryandinvoice?.permission);
            setShowSubscriptionTab(buycreditsObject?.permission);
        } catch (error) {
            toast.error("Something went wrong. Please try again after some time.",{position:toast.POSITION.BOTTOM_LEFT})
        }
        finally{
            setIsLoading(false);
        }
        
    }
    useEffect(() => {
        handleGetPermission();
    }, []);
    return (
        <Container fluid className="main-page-wrap">
            <Row>
                <Col>
                    <div className="d-flex align-items-center justify-content-between page-header py-3">
                        <span className="font-18 dark-text poppins-semibold">Settings</span>
                    </div>
                </Col>
            </Row>
            <Row className="mt-4">
                <Col sm={3}>
      
                    <Sidebar/>

                </Col>
                <Col sm={9}>
                    <div className="profile-card mb-4">
                        <div className="card-title mb-4">Credit Cards</div>

                        <Row>
                            <Col sm={7}>
                                {creditCards && creditCards.length>0? (creditCards.map((card) => (
                                    <CardComponent key={card.id} card={card} onDelete={handleDeleteUserCard} onDefault = {handleDefaultCardChange}/>
                                    ))) : (!isLoading &&(
                                    <div>
                                        <EmptyCard messHead={"No Cards Found!"} MessDescription={"Purchase credits today to start seeing your cards."} styling={"d-none"}/>
                                    </div>
                                    ))}
                                {
                                    isLoading && (
                                    <div >
                                        <LoadingAnimation/>
                                        <span>
                                        Loading data, please wait...
                                        </span>
                                    </div>
                                    )
                                }
                                <button className="btn blue-btn-primary small-btn d-none" data-tooltip-id="listtips" data-tooltip-content="Add a new Credit Card">
                                    <i className="fa-regular fa-plus me-2" ></i>Add Card
                                </button>
                            </Col>
                            <Col sm={5}>
                                <div className="secureDate">
                                    <div className="top">
                                        <div className="dark-text lato-bold"><i className="fa-solid fa-shield-check me-2 green-txt-clr"></i>Your Credit Card Data is Secure</div>
                                        <div className="font-12 light-grey-text">We never store any cardholder information on our servers. All the payments are encrypted and securely processed by Stripe.</div>
                                    </div>
                                    <div className="bottom">
                                        <img src={poweredByStripeIcon} alt="" width={120} />
                                    </div>
                                </div>
                            </Col>
                        </Row>

                    </div>
                </Col>
            </Row>
            < ToastContainer />
        </Container>
    );
}

export default BillingComponent;