import { useEffect, useRef, useState } from "react";
import { useOutletContext } from "react-router-dom";
import IntegrationService from "../../../../services/integrationservice";
import { toast } from "react-toastify";
import ConfirmationModal from "../../../../shared/confirmation-modal.component";
import CustomDropdown from "../../../../shared/custom-dropdown.component";
import LoadingAnimation from "../../../lottie-files/loading.lotte";

export default function ConfigureUserMapping() {
  const [isLoading, setIsLoading] = useState(false);
  const { goToNextStep, goBack, integration, configuration, currentStepIndex } =
    useOutletContext();
  const [userMappings, setUserMappings] = useState([]);
  const [crmUsers, setCrmUsers] = useState([]);

  const confirmationModalRef = useRef();
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [deletingUserIndex, setDeletingUserIndex] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    getUserMappings();
    getCrmUsers();
  }, []);

  async function getUserMappings() {
    try {
      const response = await IntegrationService.UserMappings(
        integration.integrationId
      );
      setUserMappings(response);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error(error?.message ?? "Failed to get user mappings.");
    }
  }

  async function getCrmUsers() {
    try {
      const response = await IntegrationService.CrmUsers(
        integration.integrationId,
        configuration.name
      );

      if (response?.result) {
        setCrmUsers(
          response.result.map((user) => ({
            id: user.id,
            text: user.name,
            additional: user,
          }))
        );
      } else {
        setCrmUsers([]);
      }
    } catch (error) {
      toast.error(error?.message ?? "Failed to get crm users.");
    }
  }

  async function handleUserSelection(userIndex, selectedUser) {
    const tempUserMappings = structuredClone(userMappings);

    tempUserMappings[userIndex].crmUserId = selectedUser.id;
    tempUserMappings[userIndex].crmUserName = selectedUser.text;

    try {
      const payload = {
        userId: tempUserMappings[userIndex].userId,
        crmUserId: selectedUser.id,
        crmUserName: selectedUser.text,
        integrationId: integration.integrationId,
        integrationType: integration.integrationType,
      };

      const response = await IntegrationService.UpdateUserMapping(payload);

      setUserMappings([...tempUserMappings]);
      if (response.success) {
        toast.success(response?.message ?? "User mapping updated successfully");
      }
      else{
        setUserMappings([...userMappings]);
        toast.error(response?.message ?? "Failed to update user mapping.");
      }
    } catch (error) {
      setUserMappings([...userMappings]);
      toast.error(error?.message ?? "Failed to update user mapping.");
    }
  }

  async function handleDeleteMapping(userIndex, showModal) {
    if (showModal) {
      setDeletingUserIndex(userIndex);
      confirmationModalRef.current.show();
      return;
    }

    setIsButtonLoading(true);

    try {
      const response = await IntegrationService.DeleteUserMapping(
        integration.integrationId,
        userMappings[userIndex].userId
      );
      setIsButtonLoading(false);
      confirmationModalRef.current.hide();

      const tempUserMappings = structuredClone(userMappings);

      tempUserMappings[userIndex].crmUserId = null;
      tempUserMappings[userIndex].crmUserName = null;
      setUserMappings(tempUserMappings);

      toast.success(response?.message ?? "User mapping deleted successfully");
    } catch (error) {
      setIsButtonLoading(false);
      toast.error(error?.message ?? "Failed to un-map user mapping.");
    }
  }

  function RenderDeleteOption({ onDelete = () => {} }) {
    return (
      <div
        className="p-2 cursor-pointer font-14"
        style={{ background: "#F08080", color: "white" }}
        onClick={onDelete}
      >
        <i class="fa-solid fa-trash me-1 font-12"></i> Remove mapping
      </div>
    );
  }

  return isLoading ? (
    <div className="loading-animation">
      <LoadingAnimation />
      <span>Loading data, please wait...</span>
    </div>
  ) : (
    <div id="MapUsers">
      <div className="mt-4 mb-3">
        <span>
          <i className="fa-regular fa-left-right six-c-7"></i>
        </span>
        <span className="lato-bold dark-text ms-2">
          Map SureConnect users with CRM users
        </span>
        <div className="lato-medium">
          Leads/Contacts of the mapped users will only be fetched for phone
          number verification
        </div>
      </div>
      <div
        className="table-responsive mb-5 colored-table td-transparent"
        style={{ maxWidth: "700px" }}
      >
        <table
          className="table table-bordered"
          style={{ tableLayout: "fixed" }}
        >
          <thead>
            <tr>
              <th>SureConnect User</th>
              <th>CRM User</th>
            </tr>
          </thead>
          <tbody>
            {userMappings.map((user, userIndex) => (
              <tr key={user.userId}>
                <td>
                  <div className="lato-medium font-14 dark-text">
                    {user.userName}{" "}
                  </div>
                  <div className="font-12 grey-text">{user.userEmail}</div>
                </td>
                <td>
                  <CustomDropdown
                    data={crmUsers}
                    value={user.crmUserId}
                    onSelectItem={(selectedItem) =>
                      handleUserSelection(userIndex, selectedItem)
                    }
                    renderDefaultItem={
                      user.crmUserId
                        ? () =>
                            RenderDeleteOption({
                              onDelete: () =>
                                handleDeleteMapping(userIndex, true),
                            })
                        : null
                    }
                  >
                    {user.crmUserId ? (
                      <>
                        <div className="lato-medium font-14 dark-text">
                          {user.crmUserName}
                          <span className="ms-2">
                            <i class="fa-solid fa-caret-down light-grey-text"></i>
                          </span>
                        </div>
                        <div className="font-12 grey-text">
                          {user.crmUserId}
                        </div>
                      </>
                    ) : (
                      <div className="d-flex align-items-center gap-1">
                        <i className="font-14">No User Mapped</i>
                        <i class="fa-solid fa-caret-down light-grey-text ms-1"></i>
                      </div>
                    )}
                  </CustomDropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="d-flex align-items-center gap-2">
        {currentStepIndex > 0 ? (
          <button
            type="button"
            className="btn blue-btn-primary font-12 small-btn go-back-btn"
            onClick={goBack}
          >
            <i class="fa-solid fa-arrow-left me-2"></i>Back
          </button>
        ) : undefined}
        <button
          type="button"
          className="btn blue-btn-primary font-12 small-btn"
          onClick={goToNextStep}
        >
          Next
        </button>
      </div>
      <ConfirmationModal
        ref={confirmationModalRef}
        title="Confirm Delete"
        iconClass="far fa-trash-alt"
        message="Are you sure you want to delete the mapping ?"
        confirmButtonText="Delete"
        disabled={isButtonLoading}
        onConfirm={() => handleDeleteMapping(deletingUserIndex, false)}
      />
    </div>
  );
}
