import React, { useState } from "react";
import { Tooltip } from "react-tooltip";
import 'react-tooltip/dist/react-tooltip.css'
import './results.component.scss'

const ResultsComponent = ({file,results,onDelete}) => {

  let totalRecords = null;
  let passRecords = null;
  let failRecords = null;
  let requiredCredits = null;
  let failRecordsStats = {};
  if (results){
   totalRecords = results.result.report.totalRows;
   passRecords = results.result.report.passCount;
   failRecords = results.result.report.failCount; 
   failRecordsStats = results.result.evidenceCount;
   requiredCredits = results.result.creditsRequired;
  }
  const statsArray = Object.entries(failRecordsStats);
  //sort the stats by count
  statsArray.sort((a, b) => b[1] - a[1]);
  //keep the first five stats
  const slicedStats = statsArray.slice(0, 5);
  let fileName = null;
  let fileSize = null;
  if(file){
    fileName = file.name;
    if(fileName.length > 30){
      fileName = fileName.slice(0,30) + "..."
    }
  if (file.size > 1024*1024){
    const number = (file.size/(1024*1024)).toFixed(2)
    fileSize = `${number} MB`;
  }
  else if (file.size > 1024){
    const number = (file.size/1024).toFixed(2)
    fileSize = `${number} KB`;
  }
  else{
    fileSize = `${file.size} Bytes`;
  }
  }

  const renderFailRecordsStats = () => {
    return `
      <div>
        <p class="lato-bold dark-text font-12"><i class="fa-solid fa-triangle-exclamation" style="color: #E18F61;"></i> Failed - ${failRecords}</p>
        
          ${slicedStats.map(([key, value]) => `<li class='lato-medium font-12' key=${key}>${key} - ${value}</li>`).join('')}
        <p>Download Validated CSV for more details.</p>
      </div>
    `;
  };
  const creditsToolTipHTML = `<p>It costs 1 credit per valid phone number. <br>There could be more than 1 valid number per passed contact.</p>`
  const failRecordsStatsHtml = renderFailRecordsStats();
  const handleFileDelete = () => {
    onDelete();
  };
    return(
        <div>
          <div className='uploaded-wrap'>
          <div className='header d-flex justify-content-between align-items-center'>
              <div className='d-flex align-items-center'>
                <i className="fa-solid fa-file-csv green-text font-18"></i>
                <span className='ms-2 font-14 dark-text lato-medium'>{fileName}</span>
                <i className="fa-solid fa-circle font-4 mx-2 light-grey-text"></i>
                <span className='font-14 light-grey-text'>CSV File</span>
                <span className='ms-3 default-text font-10'>{fileSize}</span>
              </div>
              <div>
                <i className='fa-regular fa-trash-alt font-16 pink-text cursor-pointer' onClick={handleFileDelete}></i>
                {/* <i className='fa-regular fa-trash-alt font-16 pink-text cursor-pointer' data-bs-toggle="modal" data-bs-target="#uploadModal"></i> */}
              </div>
            </div>
            <div className="body">
              <div className='d-flex justify-content-center align-items-center analysis text-center py-5'>
                <div>
                  <div className='lato-bold dark-text font-16' data-tooltip-id="results" data-tooltip-content="Total number of contacts uploaded.">{totalRecords}</div>
                  <div className='light-grey-text font-12 mt-1'>Total Contacts</div>
                </div>
                <div className='v-line'></div>
                <div>
                  <div className='lato-bold dark-text font-16' data-tooltip-id="results" data-tooltip-content="Number of passed contacts.">{passRecords}</div>
                  <div className='light-grey-text font-12 mt-1'>Pass</div>
                </div>
                <div className='v-line'></div>
                <div>
                  <div className='lato-bold dark-text font-16'  data-tooltip-id="results" data-tooltip-content="Number of failed contacts."> {failRecords}</div>
                  <div className='light-grey-text font-12 mt-1'>Fail <span data-tooltip-id="fail-stats-tooltip" data-tooltip-html={failRecordsStatsHtml}><i className="fa-solid fa-circle-info cursor-pointer" style={{"color": "#6C79A2"}}></i></span>
                  </div>
                </div>
                <div className='v-line'></div>
                <div data-tooltip-id="results" data-tooltip-html={creditsToolTipHTML}>
                  <div className='lato-bold dark-text font-16'> <img src={require("../../../assets/img/luckycoins.svg").default} className="me-1" />{requiredCredits}</div>
                  <div className='light-grey-text font-12 mt-1'>Credits needed</div>
                </div>
              </div>
              {/* Analysis.. End */}
            </div>
          </div>
          <Tooltip id = "results"/>
          <Tooltip id="fail-stats-tooltip" place="right" className="custom-tooltip" />
        </div>
    );
}

export default ResultsComponent;