import {React, useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './login.component.scss';
import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DefaultForgotPassword from './forget-default.component';
import ForgotEmailSent from './mailsent.forgotpassword';
import { verifyForgotPassword,sendForgotPasswordLink, changeForgotPassword } from '../../apis/pvlapis';
import { useParams } from 'react-router-dom';

export default function ResetPassword({}) {
  
  const [showEmailSent, setShowEmailSent] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showResetSuccessful, setShowResetSuccessful] = useState(false);
  const [showPasswordForm, setShowPasswordForm] = useState(false);
  const [showDefaultPage, setShowDefaultPage] = useState(false);
  const [errorMessage,setErrorMessage] = useState(null);
  const [errorDescription, setErrorDescription] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [userId, setUserId] = useState(null);
  const [userPassword,setUserPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [accessToken, setAccessToken] = useState(null);
  const [disableSendButton, setDisableSendButton] = useState(false);
  const [invalidUserPassword,setInvalidUserPassword] = useState(null);
  const [invalidConfirmPassword, setInvalidConfirmPassword] = useState(null);
  // Get the token from the link
  const { token } = useParams();

  const handleCloseErrorMessage = () => {
    setShowErrorMessage(false);
  }

  const handleSendVerificationLink = async(email) => {

    // console.log('handle send verification',email);
    if(email){
      setUserEmail(email);
      setDisableSendButton(true);
      try{
        //Call the forgot password email
        const response = await sendForgotPasswordLink(email);
        //Sending email successful
        if(response.data.statusCode === 200){
          // show success message
          setShowErrorMessage(false);
          setShowDefaultPage(false);
          setShowEmailSent(true);
          setUserEmail(email);
        }
        //Account deactivated
        else if(response.data.statusCode === 454){
          setShowErrorMessage(true);
          setErrorMessage("Account deactivated!");
          setErrorDescription("Your account is deactivated. Contact support.");
          // toast.error(`Your account is no longer active. Contact support for help.`,{position: toast.POSITION.BOTTOM_LEFT});
        }
        //Account not found
        else if(response.data.statusCode === 456){
          setShowErrorMessage(true);
          setErrorMessage("EmailID not found!");
          setErrorDescription("The emailID is not registered.");
        }
        //Account not activated
        else if(response.data.statusCode === 460){
          setShowErrorMessage(true);
          setErrorMessage("Account not activated!");
          setErrorDescription("Check your inbox for verification email to activate your account.");
        }
        else{
          toast.error(`Something went wrong with sending email.`,{position: toast.POSITION.BOTTOM_LEFT});
        }
        setDisableSendButton(false);
      }
      catch(error){
        toast.error(`Something went wrong. Please try again after some time.`,{position: toast.POSITION.BOTTOM_LEFT});
      }

    }
    else{
      toast.error("Please enter your email address.",{position: toast.POSITION.BOTTOM_LEFT});
    }
    
  };

  const handlePasswordChange = (e) => {
    const enteredPassword = e.target.value;
    setUserPassword(enteredPassword);
    //Check validity of the entered password
    const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,15}$/;
    if (!passwordRegex.test(enteredPassword)) {
      setInvalidUserPassword("Password length should be minimum of 8 characters and maximum of 15 characters. Should have at least one special character, one number, one lowercase, and one uppercase letter.");
    }
    else{
      setInvalidUserPassword(null);
    }
  };
  const handleConfirmPassword = (e) => {
    const enteredConfirmPassword = e.target.value;
    setConfirmPassword(enteredConfirmPassword);
    //Check if confirm password is same as password
    if(userPassword !== enteredConfirmPassword){
      setInvalidConfirmPassword('Password should match');
    }
    else{
      setInvalidConfirmPassword(null);
    }

  }
  const handleResetPassword = async(e) => {
    e.preventDefault();
    let checks_failed = false;
    //Check validity of password
    const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,15}$/;
    if (!passwordRegex.test(userPassword)) {
      setInvalidUserPassword("Password length should be minimum of 8 characters and maximum of 15 characters. Should have at least one special character, one number, one lowercase, and one uppercase letter.");
      setUserPassword('');
      setConfirmPassword("");
      checks_failed=true;
    }
    if(userPassword !== confirmPassword){
      setInvalidConfirmPassword("Password should match");
      setUserPassword('');
      setConfirmPassword("");
      checks_failed=true;
    }
    if(checks_failed){
      return;
    }
    try{
      const response = await changeForgotPassword(userPassword,accessToken);
      setUserPassword("");
      setConfirmPassword("");
      if(response.data.statusCode === 200){
        setShowPasswordForm(false);
        setShowResetSuccessful(true);
      }
      else{
        setErrorMessage("Reset Password Failed");
        setErrorDescription("Could not change your password");
        setShowErrorMessage(true);
        setShowDefaultPage(false);
      }     
    }
    catch(error){
      setErrorMessage("Reset Password Failed");
      setErrorDescription("Could not change your password");
      setShowErrorMessage(true);
      setShowDefaultPage(false);
      // console.log("Caught Error", error);
    }

  }

  const verifyEmail = async() => {
    // console.log("Verifying Link",token);
    try{
      const response = await verifyForgotPassword(token);
      if(response.data.statusCode === 200){
        setShowDefaultPage(false);
        setShowErrorMessage(false);
        setShowPasswordForm(true);
        setAccessToken(response.data.result["accessToken"]);
      }
      else if(response.data.statusCode === 462){
        setShowDefaultPage(true);
        setErrorMessage("Link Expired!");
        setErrorDescription("Request a new password reset link.")
        setShowErrorMessage(true);
      }
      else if(response.data.statusCode === 455){
        setShowDefaultPage(true);
        setErrorMessage("Invalid Token!");
        setErrorDescription("Request a new password reset link.")
        setShowErrorMessage(true);
      }
      else{
        setShowDefaultPage(true);
        setErrorMessage("Error Occured");
        setErrorDescription("Request a new password reset link.")
        setShowErrorMessage(true);
      }
    }
    catch(error){
      // console.log(error);
      setShowDefaultPage(true);
      setErrorMessage("Cannot process request");
      setErrorDescription("Something is not right. Wait for some time before requesting a reset link.")
      setShowErrorMessage(true);
    } 
  }

  useEffect(() => {
    if(token){
      verifyEmail();
    }
    else{
      setShowDefaultPage(true);
    }
}, [token]);

  return (
    <div className="login-wrap">
      <div className="left">
        <div className="inside-wrap">
          <div>
            <img src={require("../../../assets/img/sc-logo-signup.svg").default} alt="logo" />
          </div>
          {/* Error Msg */}
          {
            showErrorMessage && (
              <div className="error_message">
                <img src={require("../../../assets/img/alert-circle.svg").default} alt="logo" className="me-2"/>
                <div className="message-block">
                  <div className="font-14  poppins-medium mb-1">
                      {errorMessage}
                  </div>
                  <div className=" font-14  lato-regular">
                    {errorDescription}
                  </div>
                </div>
                <button type="button" className="btn btn-cancel pt-0 ms-auto" onClick={handleCloseErrorMessage}>X</button>
              </div>
            )
          }
          {/* Error Msg End */}
          <div className="form-wrap mh-327px">
            {/*  */}
            {
              showDefaultPage && (
                <DefaultForgotPassword onSubmit={handleSendVerificationLink} disableButton={disableSendButton} />
              )
            }
            {/*  */}
            {
              showEmailSent && (
                <ForgotEmailSent onResend={handleSendVerificationLink} userEmail={userEmail} />
              )
            }
            {
              showPasswordForm && (
                <div className="forget-pwd-reset w-100">

                  <div className="text-center font-20 dark-text poppins-medium mb-4 pb-3">
                  Create New Password
                  </div>
                  <form>
                    <input
                      type="password"
                      className={`form-control mb3 ${invalidUserPassword ? 'is-invalid' : ''}`}
                      placeholder="Enter new password"
                      value={userPassword}
                      onChange={handlePasswordChange}
                      required
                    />
                    {invalidUserPassword && (
                      <div className=" font-14  lato-regular text_error_message">
                        {invalidUserPassword}
                      </div>
                    )}
                    <input
                      type="password"
                      className={`form-control mb3 ${invalidConfirmPassword ? 'is-invalid' : ''}`}
                      value={confirmPassword}
                      placeholder="Confirm new password"
                      onChange={handleConfirmPassword}
                      required
                    />
                    {invalidConfirmPassword && (
                      <div className=" font-14  lato-regular text_error_message">
                        {invalidConfirmPassword}
                      </div>
                    )}
                    <br></br>
                    {/* <Link to="/home"> */}
                      <button className="btn blue-btn-primary w-100 mb-3" onClick={handleResetPassword}>Reset Password</button>
                    {/* </Link> */}
                  </form>
                  <div className="font-14 poppins-regular text-center mt-3">
                    <Link to="/">
                    <span className="primary-blue-text poppins-medium ms-1 cursor-pointer">
                    Login 
                    </span>
                    </Link>
                    &nbsp;or
                    <Link to="/signup">
                    <span className="primary-blue-text poppins-medium ms-1 cursor-pointer">
                    Sign up
                    </span>
                    </Link>
                  </div>
                </div>
              )
            }
            {/*  */}
            {
              showResetSuccessful && (
                <div className="reset-sucess w-100">

                  <div className="text-center mb-3">
                  <img src={require("../../../assets/img/Success.svg").default} alt="Success" />
                  </div>
                  
                  <div className="text-center font-18 dark-text poppins-semibold mb-2">
                  Password Reset Successful!
                  </div>
                  <p className="text-center font-14 grey-text mw-388 pt-1 poppins-regular">Your password has been reset. Please continue to login with your new password.</p>
                  <div className="text-center mt-3">
                  <Link to="/">
                    <button className="btn blue-btn-primary w-100 mb-3">Continue to Login</button>
                  </Link>
                  </div>
              </div>
              )
            }
          </div>
        </div>
      </div>
      <div className="right">
      <div className="inside-wrap">
        {/* <img src={require("../../../assets/img/rhs-img.png")} alt="rhs-image" /> */}
          <iframe className='testi-video mb-4' width="95%" height="334" src="https://www.youtube.com/embed/CtcG24EeJHo?si=C9GoMhrvQq-lIGFq" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
          <img src={require("../../../assets/img/Signup.png")} alt="rhs-image" className='testi-video-text'  />
        </div>
      </div>
      < ToastContainer / >
    </div>
  );
}
