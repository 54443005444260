import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import IntegrationService from "../../../../services/integrationservice";
import { pollIntervalTypes } from "../../integrations-constants";
import { useOutletContext } from "react-router-dom";
import SelectInput from "../../../../shared/select/select-input.component";
import {
  IntegrationInboundFilter,
  filterGroupObj,
} from "./integration-inbound-filter.component";
import { Spinner } from "react-bootstrap";
import LoadingAnimation from "../../../lottie-files/loading.lotte";

export default function ConfigureSettings() {
  const {
    integration,
    configuration,
    goToNextStep,
    goBack,
    currentStepIndex,
    setIntegration,
  } = useOutletContext();
  const [isLoading, setIsLoading] = useState(false);
  const [syncSettings, setSyncSettings] = useState({});
  const [pollInterval, setPollInterval] = useState(0);
  const [pollIntervalType, setPollIntervalType] = useState(1);
  const [inboundFilters, setInboundFilters] = useState([]);

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const inboundFilterRef = useRef();
  const pollIntervalInputRef = useRef();

  async function fetchSyncSettings() {
    setIsLoading(true);
    try {
      let settings = await IntegrationService.SyncSettings(
        integration.integrationId
      );

      settings = settings.sort((s1, s2) => s1.objectType - s2.objectType);

      setSyncSettings(settings);
      setPollInterval(settings[0].pollInterval);
      setPollIntervalType(settings[0].pollIntervalType);

      const _inboundFilters = [];
      settings
        .forEach((s) => {
          _inboundFilters.push({
            inboundSyncEnabled: s.inboundSyncEnabled,
            objectId: s.objectType,
            objectName: configuration.defaultConfig.types.find(
              (t) => t.typeId === s.objectType
            ).displayName,
            filter:
              s.inboundFilter && s.inboundFilter !== "{}"
                ? JSON.parse(s.inboundFilter)
                : { ...filterGroupObj },
          });
        });

      setInboundFilters(_inboundFilters);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error(
        error?.message ?? "Failed to fetch settings, please try again."
      );
    }
  }

  useEffect(() => {
    fetchSyncSettings();
  }, []);

  function changePollIntervalType(value) {
    setPollIntervalType(value);
  }

  async function saveSettings() {
    setIsButtonLoading(true);
    setPollInterval(pollIntervalInputRef.current.value);

    try {
      const filterValues = inboundFilterRef.current.getFilters();

      const payload = syncSettings
        .map((setting) => {
          const _inboundFilter = filterValues.find(
            (obj) => obj.objectId === setting.objectType
          ).filter;

          return {
            ...setting,
            pollInterval: pollIntervalInputRef.current.value,
            pollIntervalType: pollIntervalType,
            inboundFilter: _inboundFilter.rules.length
              ? JSON.stringify(_inboundFilter)
              : JSON.stringify({}),
            integrationId: integration.integrationId,
          };
        });

      await IntegrationService.UpdateSyncSetting(payload);

      toast.success("Settings saved successfully.");

      if (!integration.isSetupCompleted) {
        setIntegration({ ...integration, isSetupCompleted: true });
      }

      setIsButtonLoading(false);
      goToNextStep();
    } catch (error) {
      setIsButtonLoading(false);
      toast.error(
        error?.message ?? "Failed to save settings, please try again."
      );
    }
  }

  return isLoading ? (
    <div className="loading-animation">
      <LoadingAnimation />
      <span>Loading data, please wait...</span>
    </div>
  ) : (
    <div id="SyncSettings">
      {configuration.uiConfig.uiproperties?.syncsettings
        ?.enablepollingfrequency ? (
        <>
          <div className="mt-4 mb-3">
            <span>
              <i className="fa-regular fa-rotate six-c-7"></i>
            </span>
            <span className="lato-bold dark-text ms-2">
              Set Polling Frequency
            </span>
            <div className="lato-medium mt-2">
              <strong>Note:</strong> We suggest to keep polling frequency at
              least 10 minutes to conserve your API usage
            </div>
          </div>
          <div className="d-flex gap-10">
            <input
              ref={pollIntervalInputRef}
              type="number"
              className="form-control w-10rem"
              placeholder="10"
              defaultValue={pollInterval}
            />
            <SelectInput
              className="w-15rem"
              defaultValue={pollIntervalType}
              data={pollIntervalTypes}
              onValueChanges={(value) => changePollIntervalType(value)}
            />
          </div>
        </>
      ) : undefined}
      {configuration.uiConfig.uiproperties?.syncsettings
        ?.enableinboundfilters ? (
        <IntegrationInboundFilter
          ref={inboundFilterRef}
          filters={inboundFilters}
        />
      ) : undefined}
      <div className="d-flex align-items-center gap-2">
        {currentStepIndex > 0 ? (
          <button
            type="button"
            className="btn blue-btn-primary font-12 small-btn go-back-btn"
            onClick={goBack}
          >
            <i class="fa-solid fa-arrow-left me-2"></i>Back
          </button>
        ) : undefined}
        <button
          type="button"
          className="btn blue-btn-primary font-12 small-btn"
          onClick={saveSettings}
          disabled={isButtonLoading}
        >
          {isButtonLoading ? (
            <Spinner
              style={{ width: "15px", height: "15px" }}
              animation="border"
              role="status"
            >
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          ) : (
            <span>Save</span>
          )}
        </button>
      </div>
    </div>
  );
}
