import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { createCheckout, getTierDetails, updateSubscription } from '../../../apis/pvlapis';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Mixpanel } from '../../../configs/mixPanel';
import './buycredits.component.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus,faPlus,faCheckCircle, faAngleRight } from '@fortawesome/free-solid-svg-icons';

import { Crisp } from 'crisp-sdk-web';

const BuySubscriptionOrCredits = ({ onClose, checkoutType, isEditSubscription, subscriptionId, subscriptionStatus, 
  subscriptionType = 3, subscribedUser = 1,subscriptionEndDate, formatDate }) => {
  const [selectedTier, setSelectedTier] = useState("tier1");
  const tiers = getTierDetails();
  const tierDetails = tiers["tierJSON"];
  const tierArray = tiers["tierArray"];
  const lastTierDetails = tierArray[tierArray.length-1][1];

  // Mapping subscription type enum to name 
  const subscriptionTypeToName = {
    0: "month",
    1: "quarter",
    2: "halfyear",
    3: "year",
  }
  /**
   * Returns the subscription plan name from the given subscription type ID.
   *
   * @param {number} id - The subscription type ID.
   * @return {string} The subscription plan name.
   */
  function getSubscriptionTypeNameFromId(id) {
    return subscriptionTypeToName[id];
  }
  /**
   * Returns the subscription type ID from the given subscription plan name.
   *
   * @param {string} name - The subscription plan name.
   * @return {number} The subscription type ID.
   */
  function getSubscriptionTypeIdFromName(name) {
    return Object.keys(subscriptionTypeToName).find(key => subscriptionTypeToName[key] === name);
  }
  const subscriptionPlanName = getSubscriptionTypeNameFromId(subscriptionType);
  const creditDetails = tierArray[0][1];
  const sortedCreditDetailsArray = Object.values(creditDetails).sort((a, b) => a.upto - b.upto);
  const payAsyouGoObj = sortedCreditDetailsArray.reduce((acc, item, index) => {
      acc[`tier${index + 1}`] = item;
      return acc;
  }, {});

  const [creditsAmount, setCreditsAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [showLicenseIncrementModal, setShowLicenseIncrementModal] = useState(false)
  const subscriptionDetails = tiers["tierJSON"].subscription;
  const [isIncrement, setIsIncrement] = useState(null);
  const [show, setShow] = useState(false);
  const [numOfUsers,setNumOfUsers] = useState(subscribedUser);
  // if(isEditSubscription){
  //   setNumOfUsers(subscribedUser+4);
  // }

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [selectedPlan, setSelectedPlan] = useState(subscriptionType === 0?'Monthly':'Yearly'); // Default to 'Yearly'
  const [selectedSubscriptionDetails,setSelectedSubscriptionDetails] = useState(subscriptionDetails[selectedPlan==='Yearly'?'year':'month']);

  const handleAddUser = () => {
      setNumOfUsers(prevCount => prevCount + 1);
  };
  
  const handleRemoveUser = () => {
      setNumOfUsers(prevCount => (prevCount > 1 ? prevCount - 1 : 1));
  };
  const handleSelectPlan = (plan) => {
    setSelectedPlan(plan);
    const choosenPlan = plan==='Yearly'?'year':'month';
    setSelectedSubscriptionDetails(subscriptionDetails[choosenPlan]);
  };

  // Initialize the slider value with a default of 500
  const dividedFactor = 1357.14286
  const [sliderValue, setSliderValue] = useState(500);
  const [creditsValue, setCreditsValue] = useState(500);
  const [dollarValue, setDollarValue] = useState(300);
  
  const [disablePurchaseButton, setDisablePurchaseButton] = useState(false);
  const stripeKey = process.env.REACT_APP_PVL_STRIPE_SECRET_KEY;
  
  // Fixed credit amounts
  const fixedCreditAmounts = [500, 1000, 1500, 2000, 2500, 3000, 5000, 10000];

  useEffect(() => {    
    for (const key in payAsyouGoObj) {
      const value = payAsyouGoObj[key];
      if(value.from <= creditsValue &&  creditsValue <= value.upto){
        setSelectedTier(key)
        setDollarValue(Math.floor(value.cost * creditsValue));
        break;
      }
    }
  }, [creditsValue]);

  // Define a function to handle slider changes
  const handleSliderChange = (event) => {
    const newValue = parseInt(event.target.value, 10);
    let finalValue = newValue;
    let factor = ((newValue - 500)/dividedFactor) ;
    if(factor < 0.5){
      finalValue = 0;
      setCreditsValue(500);
    }
    if (factor > 0.5) {
      finalValue = dividedFactor*1;
      setCreditsValue(1000);
    }
    if (factor > 1.5) {
      finalValue = dividedFactor*2;
      setCreditsValue(1500);
    }
    if (factor > 2.5) {
      finalValue = dividedFactor*3;
      setCreditsValue(2000);
    }
    if (factor > 3.5) {
      finalValue = dividedFactor*4;
      setCreditsValue(2500);
    }
    if (factor > 4.5) {
      finalValue = dividedFactor*5;
      setCreditsValue(3000);
    }
    if (factor > 5.5) {
      finalValue = dividedFactor*6;
      setCreditsValue(5000);
    }
    if (factor > 6.5) {
      finalValue = dividedFactor*7;
      setCreditsValue(10000);
    }
    setSliderValue(finalValue+500);
  };
  const handleFixedAmountClick = (amount) => {
    setCreditsValue(amount);
    const index = fixedCreditAmounts.indexOf(amount)
    setSliderValue(dividedFactor * index +500);
  };

  const handleCloseModal = () => {
    onClose();
    sessionStorage.setItem('isPVLSubscribed',true);
  }

  const sendBuyCreditsDatatoCrisp = async() => {
    Crisp.session.setSegments(['CreditsBought']);
  }

  const handlePurchaseCreditsOrSubscription = async () => {
    setShowLicenseIncrementModal(false);
    // define vars
    const plan = selectedPlan==='Yearly'?'year':'month';
    // if edit subscription is not true i.e. this is fresh buy of subscription or add-ons
    if (!isEditSubscription || ![1, 4].includes(subscriptionStatus))
    {
      Mixpanel.track("Buy Credits button clicked");
      const tolt_referral = window.tolt_referral? window.tolt_referral:'';
      // create subscription payload
      let payload = {'checkouttype': 'subscription', 
            'checkoutdetails': {
              'plan': plan, 'quantity': numOfUsers, 'tolt_referral': tolt_referral
            }
      } 
      // if checkout type is pay as you go (or add on)
      if(checkoutType==='payasyougo'){
        payload = {'checkouttype': 'payasyougo', 
          'checkoutdetails': {
            'credits': creditsValue, 'tolt_referral': tolt_referral          
          }
        }
      }  
      try {
        setIsLoading(true);
        setDisablePurchaseButton(true);
        // Create checkout
        const response = await createCheckout(payload);
        if (response.data.statusCode === 200) {
          toast.success("Please wait...", { position: toast.POSITION.BOTTOM_LEFT });
          const sessionid = response.data.result["sessionid"];
          await handleCheckout(sessionid);
          sendBuyCreditsDatatoCrisp();
        }
        else {
          toast.error("Something went wrong. Please try again.", { position: toast.POSITION.BOTTOM_LEFT });
        }
      }
      catch (err) {

        toast.error("Something went wrong. Please try again after some time.", { position: toast.POSITION.BOTTOM_LEFT });
      }
      finally {
        setDisablePurchaseButton(false);
        setIsLoading(false);
      }
    }
    // if edit subscription is true i.e. this is edit of subscription
    else{
      // var
      let flagplanchange = subscriptionPlanName !== plan;
      // check if plan type has changed, return error
      if (flagplanchange) {
        toast.error("Cannot change plan. Please cancel existing subscription to proceed.", { position: toast.POSITION.BOTTOM_LEFT });
        return;
      }
      // check if there is no change in subscription
      if (numOfUsers===subscribedUser && !flagplanchange) {
        toast.error("No change in subscription. Please try again.", { position: toast.POSITION.BOTTOM_LEFT });
        return;
      }
      // check if numOfUsers!=0
      if (numOfUsers===0) {
        toast.error("Invalid number of users. Please try again.", { position: toast.POSITION.BOTTOM_LEFT });
        return;
      }
      // identify the type of update
      let updatetype = (numOfUsers>subscribedUser) ? 'increment' : 'decrement';
      // updatetype = (flagplanchange) ? 'planchange' : updatetype;
      // check if there is no change in quantity
      // create the payload for update subscription
      try {
        setIsLoading(true);
        setDisablePurchaseButton(true);
        let payload = {
          "updatetype": updatetype, 
          "updatemetadata": {
              "newtype": subscriptionType,
              "newquantity": numOfUsers
          }
        }
        const response = await updateSubscription(payload);
        if (response.data.statusCode === 200) {
            setTimeout( () => { 
              setDisablePurchaseButton(false);
              setIsLoading(false);
              window.location.reload();
            }, 2000); // 2-second delay
            
        }
        else{
            toast.error("Something went wrong. Please contact support.",{position:toast.POSITION.BOTTOM_LEFT})
            // setTimeout(() => {
            //     window.location.reload()
            // }, 2000); // 2-second delay
        }
      }        
      catch  (error) {
          toast.error("Something went wrong. Please try again after some time.",{position:toast.POSITION.BOTTOM_LEFT})
      }
      finally{
        // setDisablePurchaseButton(false);
        // setIsLoading(false);
      }
  }
  }
  const handleCloseLicenseIncrementModal = async()=>{
    setShowLicenseIncrementModal(false);
  }

  const handleShowLicenseIncrementModal = async()=>{
    if(isEditSubscription){
      if(numOfUsers>subscribedUser){
        setIsIncrement(true);
        setShowLicenseIncrementModal(true);
      }
      else if(numOfUsers<subscribedUser){
        setIsIncrement(false);
        setShowLicenseIncrementModal(true);
      }
      else{
        setIsIncrement(null);
        toast.error("No change in subscription. Please try again.",{position:toast.POSITION.BOTTOM_LEFT});
        // show toaster
      }
      
    }
    else{
      await handlePurchaseCreditsOrSubscription();
    }
    
  }

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleCheckout = async (sessionId) => {
    //Create an instance of stripe using stripe key
    const stripe = window.Stripe(stripeKey); // Replace with your actual Stripe Publishable API key
    //Get the sessionid
    const sessionid = sessionId;
    //Redirect to the stripe checkout page using sessionid
    setDisablePurchaseButton(false);
    setIsLoading(false);
    const result = await stripe.redirectToCheckout({
      sessionId: sessionid,
    });
    if (result.error) {
      // Handle any errors that might occur during the redirect to Checkout Session
      console.error(result.error);
    }
  };

  return (
    <>
      {checkoutType === 'payasyougo'&&<Modal
        show={true}
        onHide={handleCloseModal}
        size="lg"
        // backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered className='modal-no-border'
      >
        <Modal.Header closeButton>
          <Modal.Title><span className="round-modal-icon grey">
            <i className="fa-regular fa-coins"></i>
          </span>{" "}
            Buy Credits</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id="addcredits" className="pt-3 collapse show">
            <div className="credit_tab_header">
              <p className="font-14 lato-regular">Purchased credits will expire with the Subscription! To purchase credits in larger quantity, <a href="mailto:support@sureconnect.ai" className='blue-link' >Contact Support</a></p>
              <div className='d-flex justify-content-between align-items-center'>
                <h5 className="font-16 lato-bold default-text">
                  Select Credits
                </h5>
                <div className="range_count">
                  {/* Display the selected credit amount */}
                  <output id="output" htmlFor="rangeInput">${dollarValue}</output>
                </div>
              </div>

            </div>
            <div className="credit_tab_body">
              <div className="track-range">
                <div className="range-slider">
                  {/* Slider input */}
                  <input
                    type="range"
                    min="500"
                    max="10000"
                    value={sliderValue}
                    onChange={handleSliderChange}
                    className="range-slider__range"
                  />
                </div>
                {/* Fixed credit amounts */}
                <div className="d-flex align-items-center justify-content-between py-3">
                {fixedCreditAmounts.map((amount) => (
                  <div
                    key={amount}
                    className={`fixed-amount ${amount === creditsValue ? 'selected' : ''}`}
                    onClick={() => handleFixedAmountClick(amount)}
                  >
                    {amount}
                  </div>
                ))}
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-lg-12">
                  <div className="summary-wrap">
                    <h5>Your Summary</h5>
                    <div className="d-flex align-items-center justify-content-between mb-2">
                        <div className="inter-font-medium">Credits selected</div>
                        <div className='dark-text inter-font-bold'>{creditsValue}</div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between mb-3">
                        <div className="inter-font-medium">Price per credit</div>
                        <div className='dark-text inter-font-bold'>${payAsyouGoObj[selectedTier].cost}</div>
                    </div>
                    <div className="total d-flex align-items-center justify-content-between pt-3">
                      <div className="poppins-semibold dark-text font-16">Total</div>
                      <div className="amount lato-bold">${dollarValue}</div>
                    </div>
                    {/* <button className="btn btn-default w-100 mt-3">
                        Purchase
                    </button> */}
                  </div>
                </div>
                {/* commented out the credit rates section */}
                {/* <div className="col-lg-5 ps-4">
                  <div className="lato-bold dark-text font-14 mb-3">Credit Rates</div>
                  <div className="d-flex align-items-center justify-content-between mb-2 font-12">
                    <div className="inter-font-medium">No. of Credits</div>
                    <div className="inter-font-medium">Price/credit</div>
                  </div>
                  {
                    Object.entries(payAsyouGoObj).map(([tier,details]) => (
                      <div className={`d-flex justify-content-between align-items-center font-14 cus-h-p ${selectedTier === tier ? "active-bg": ''}`}>
                        <div className="inter-font-medium">up to {details.upto}</div>
                        <div className="dark-text inter-font-bold">${details.cost}</div>
                      </div>
                    ))
                  }
                  <hr />
                  <div className="d-flex justify-content-between align-items-center font-14 cus-h-p">
                    <div className="inter-font-medium">above {lastTierDetails.upto}</div>
                    <div className="dark-font inter-font-bold blue-font">
                      <a href="mailto:support@sureconnect.ai" className='blue-link' >Contact Support</a>
                    </div>
                  </div>
                  <div className="font-14 mt-2">
                    <i className="fa-regular fa-info-circle me-1"></i>1 credit
                    verifies 1 phone number
                  </div>
                </div> */}
              </div>
            </div>
            <div className="font-14 mt-2">
              <i className="fa-regular fa-info-circle me-1"></i>1 credit
              verifies 1 phone number
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="text-end w-100">
            <Button className="btn btn-cancel mr-15px" variant="secondary" onClick={handleCloseModal}>
              Cancel
            </Button>
            {/* <Button className="btn blue-btn-primary font-12" variant="primary" disabled={disablePurchaseButton} onClick={handlePurchaseCreditsOrSubscription}>
              Buy Credits
            </Button> */}
            <button className="btn blue-btn-primary" disabled={disablePurchaseButton} onClick={handlePurchaseCreditsOrSubscription}>
              Purchase
              {isLoading &&
              <div className="spinner-border position-absolute spinner" role="status" >
                <span className="visually-hidden">Loading...</span>
              </div>}
            </button>
          </div>
        </Modal.Footer>

      </Modal>}

      {checkoutType==='subscription'&&<Modal show={true} onHide={handleCloseModal} centered className='modal-no-border subscribeModal'>
        <Modal.Header closeButton>
          <Modal.Title><span className="round-modal-icon grey">
            <i className="fa-regular fa-rocket-launch"></i>
          </span>{" "}
            {isEditSubscription?'Edit your subscription':'Subscribe to SureConnect'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">{isEditSubscription?<span>Increasing users will be immediate. Any change to decrease users will take effect starting <span style={{ fontWeight: 'bold' }} >{formatDate(subscriptionEndDate)}</span>.</span>:<span>Subscribe to a monthly/yearly plan in order to continue using SureConnect.</span>}</div>
          <div className="row">
            <div className="col-lg-7">
              <div className="pro-wrap">
                <div className="header">
                  <div className="d-flex">
                    <div>
                    <img src={require("../../../../assets/img/proIcon.svg").default} />
                    </div>
                    <div className="ms-2">
                      <div><img src={require("../../../../assets/img/proText.svg").default} /></div>
                      <div className='font-12 mt-1'><span className="lato-bold dark-text">{selectedSubscriptionDetails.credits}</span> Credits/mo/user</div>
                    </div>
                  </div>
                </div>
                {/*  */}
                {<div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <div className="lato-bold me-3 dark-text">Select no. of Users</div>
                    <div className="d-flex align-items-center gap-10">
                      <span className="widget-icon small" onClick={handleRemoveUser} style={{ cursor: 'pointer' }}>
                        <FontAwesomeIcon icon={faMinus} />
                      </span>
                      <span className="lato-bold">{numOfUsers}</span>
                      <span className="widget-icon small" onClick={handleAddUser} style={{ cursor: 'pointer' }}>
                      <FontAwesomeIcon icon={faPlus} />
                      </span>
                    </div>
                  </div>
                  <div>
                  <span><img src={require("../../../../assets/img/luckycoins.svg").default} /></span><span className="poppins-medium dark-text ms-1">{selectedPlan==='Yearly' ?selectedSubscriptionDetails.credits*12*numOfUsers:selectedSubscriptionDetails.credits*numOfUsers}</span> Credits
                  </div>
                </div>}
                {/*  */}
                {/* <div className="row my-3">
                  <div className="col">
                    <div className="duration-card">
                      <span><i className="fa-solid fa-check-circle"></i></span>
                      <div className="font-12 lato-bold">Monthly</div>
                      <div className="font-24 poppins-bold dark-text">$149</div>
                      <div className="font-12">/mo /user, billed monthly</div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="duration-card active">
                      <span><i className="fa-solid fa-check-circle"></i></span>
                      <div className="font-12 lato-bold">Yearly <span className="discount-tag">SAVE 30%</span></div>
                      <div className="font-24 poppins-bold dark-text">$99</div>
                      <div className="font-12">/mo /user, billed yearly</div>
                    </div>
                  </div>
                </div> */}
                <div className="row my-3">
                  <div className="col">
                    <div 
                      className={`duration-card ${selectedPlan === 'Monthly' ? 'active' : ''}`}
                      onClick={!isEditSubscription ? () => handleSelectPlan('Monthly') : null}
                      // style={isEditSubscription ? { pointerEvents: 'none', opacity: 0.6 } : {}}
                      style={isEditSubscription && selectedPlan !== 'Monthly' ? { pointerEvents: 'none', opacity: 0.6 } : { }}
                    >
                      {selectedPlan === 'Monthly' && <span className='check-circle'><FontAwesomeIcon icon={faCheckCircle}  style={{ color: '#7D63E9' }}/></span>}
                      <div className="font-12 lato-bold">Monthly</div>
                      <div className="font-24 poppins-bold dark-text">${subscriptionDetails.month.cost}</div>
                      <div className="font-12">/mo/user, billed monthly</div>
                    </div>
                  </div>
                  <div className="col">
                    <div 
                      className={`duration-card ${selectedPlan === 'Yearly' ? 'active' : ''}`}
                      onClick={!isEditSubscription ? () => handleSelectPlan('Yearly') : null}
                      // style={isEditSubscription ? { pointerEvents: 'none', opacity: 0.6 } : {}}
                      style={isEditSubscription && selectedPlan !== 'Yearly' ? { pointerEvents: 'none', opacity: 0.6 } : { }}

                    >
                      {selectedPlan === 'Yearly' && <span className='check-circle'><FontAwesomeIcon icon={faCheckCircle}  style={{ color: '#7D63E9' }}/></span>}
                      <div className="font-12 lato-bold">Yearly <span className="discount-tag">SAVE 30%</span></div>
                      <div className="font-24 poppins-bold dark-text">${subscriptionDetails.year.cost}</div>
                      <div className="font-12">/mo/user, billed yearly</div>
                    </div>
                  </div>
                </div>
                {/*  */}
                <div><a href="https://sureconnect.ai/pricing" target="_blank" className="blue-link poppins-medium font-12">View plan details <FontAwesomeIcon icon={faAngleRight} /></a></div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="summary-wrap">
                <h5>Summary</h5>
                <div className="d-flex align-items-center justify-content-between mb-2">
                  <div className="lato-medium">SureConnect PRO<br />{selectedPlan==='Yearly' &&<span> (${selectedSubscriptionDetails.cost}/ month * 12)</span>}</div>
                  <div className='dark-text lato-bold'>$ {selectedPlan==='Yearly'? selectedSubscriptionDetails.cost*12:selectedSubscriptionDetails.cost}</div>
                </div>
                {/* <div className="d-flex align-items-center justify-content-between mb-2">
                  <div className="lato-medium">No. of Users</div>
                  <div className='dark-text lato-bold'>{numOfUsers} </div>
                </div> */}
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <div className="lato-medium">Credits {selectedPlan==='Yearly' ? <span>/ year</span>:<span>/ month</span>}</div>
                  <div className='dark-text lato-bold'><span>{selectedPlan==='Yearly' ?selectedSubscriptionDetails.credits*12*numOfUsers:selectedSubscriptionDetails.credits*numOfUsers}</span></div>
                </div>
                <div className="total d-flex align-items-center justify-content-between pt-3">
                  <div className="poppins-semibold dark-text font-16">Total</div>
                  <div className="amount lato-bold">$<span>{selectedPlan==='Yearly'?selectedSubscriptionDetails.cost*12*numOfUsers:selectedSubscriptionDetails.cost*numOfUsers}</span>{/* ${dollarValue} */}</div>
                </div>
                {selectedPlan==='Yearly' &&<div className="text-end font-12 mt-1">You just saved <span className="dark-text lato-bold">${subscriptionDetails.month.cost*12*numOfUsers-subscriptionDetails.year.cost*12*numOfUsers}</span> 🎉</div>}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="text-end w-100">
            <Button className="btn btn-cancel mr-15px" variant="secondary" onClick={handleCloseModal}>
              Cancel
            </Button>
            <Button className="btn blue-btn-primary font-12 position-relative custom-button" variant="primary" disabled={disablePurchaseButton} onClick={handleShowLicenseIncrementModal}>
              {isEditSubscription ? 'Save Subscription' : 'Subscribe & Pay'}
              {isLoading&&<div className="spinner-border position-absolute spinner" role="status" >
                          <span className="visually-hidden">Loading...</span>
                      </div>}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>}

      <Modal show={showLicenseIncrementModal} onHide={handleCloseLicenseIncrementModal} className='modal-no-border'>
        <Modal.Header closeButton>
          <Modal.Title>
              <span className="round-modal-icon orange">
                  <i className="fa-regular fa-circle-exclamation"></i>
              </span>{" "}
              {isIncrement === false ? 'Update License Seat?':'Confirm Payment'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div>{isIncrement === false?'Decrease in the subscription user quantity will be effective from the next billing cycle. Do you want to proceed?'
            :'You will be charged immediately for the pro-rated amount using your default payment method. Do you want to proceed?'}</div>
        </Modal.Body>

        <Modal.Footer className="no-shadow pt-0">
            <div className="text-end w-100">
            <Button  className="btn btn-cancel mr-15px" variant="secondary"  onClick={handleCloseLicenseIncrementModal}>
                  Cancel
                </Button>
                <Button className="btn blue-btn-primary small-btn font-12 position-relative" variant="primary" disabled={disablePurchaseButton} onClick={handlePurchaseCreditsOrSubscription}>
                    Yes, Proceed
                </Button>
            </div>
        </Modal.Footer>
    </Modal>
      
      <ToastContainer />
    </>
  );
};

export default BuySubscriptionOrCredits;