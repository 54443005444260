import React from "react";
import './help.component.scss'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Accordion from 'react-bootstrap/Accordion';


const HelpComponent = () => {
    return (
        <Container fluid className="main-page-wrap">
            <Row>
                <Col>
                    <div className="gradient-bg">
                        <div className="mx-w-750 m-auto mt-50">
                            <div className="font-36 poppins-medium text-center fw-bold dark-text">
                                Frequently Asked Questions
                            </div>
                            <div className="font-16 text-center default-text mt-1">
                                Quick answers to questions you may have. Did not find what<br />you’re looking for? <a href="mailto:support@sureconnect.ai" className="primary-blue-text">Get in touch with us</a>.
                            </div>

                            {/* Search Start */}

                            <div className="search-box my-4 d-none">
                                <input className="form-control search-input" placeholder="Search your questions here..." />
                                <i className="fa-regular fa-search search-icon"></i>
                            </div>

                            {/* Search End */}

                            <div className="faq mt-50">
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>What is SureConnect?</Accordion.Header>
                                        <Accordion.Body>
                                            The SureConnect service allows you to verify a lead's phone number to increase the connect-ratio by categorizing them into outcome classes like "Likely Answer", "Likely Wrong", "Likely Voicemail", etc. 
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>How does the phone number validation process work?</Accordion.Header>
                                        <Accordion.Body>
                                        Sureconnect leverages AI algorithms to streamline lead validation by connecting to different data sources and analyzing historical data points.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>What types of phone numbers can be validated?</Accordion.Header>
                                        <Accordion.Body>
                                        Sureconnect can validate US/Canada phone numbers.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>How accurate is the phone number validator?</Accordion.Header>
                                        <Accordion.Body>
                                            Our customers have reported connect rates between 10-30% when calling "Likely Answer" phone numbers.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4">
                                        <Accordion.Header>Is the phone number validator secure?</Accordion.Header>
                                        <Accordion.Body>
                                        Yes, we take data security seriously. Sureconnect follows industry best practices to ensure the security of your data. We use encryption to protect your information and adhere to strict data privacy standards.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="5">
                                        <Accordion.Header>Can the phone number validator be integrated with other software?</Accordion.Header>
                                        <Accordion.Body>
                                            Yes. You can use SureConnect Public APIs to integrate with any 3rd party tool. We also provide guides on how to use SureConnect with Clay and Zapier.
                                        </Accordion.Body>
                                    </Accordion.Item>                                    
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default HelpComponent;