import axios from "axios";
import Cookies from "universal-cookie";
import { regenerateTokens, sendLoginDataToCrisp, userLogout } from "./pvlapis";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { setAccessTokenCookie, setRefreshToken, setUserDetails } from "./tokenhandler";
import { Mixpanel } from "../configs/mixPanel";

const cookies = new Cookies();
const apiKey = process.env.REACT_APP_PVL_API_KEY;
const baseURL = process.env.REACT_APP_PVL_APP_URL+'/pvl';
const apiClient = axios.create({
  baseURL: baseURL,
  timeout: 10000,
  headers: { "Content-Type": "application/json",
        'Accept': 'application/json',
        'Authorization': apiKey,
        'X-API-Key': apiKey
   },
});

apiClient.interceptors.request.use(
  function (config) {
    config.headers.Authorization = `Bearer ${cookies.get("accessToken")}`;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

apiClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (
      error.response &&
      (error.response.status === 457 || error.response.status === 401 )&&
      !originalRequest._retry
    ) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers["Authorization"] = `Bearer ${token}`;
            return axios(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }
      originalRequest._retry = true;
      isRefreshing = true;
      try {
        const status = await regenerateTokens();
        if (status === 200) {
          const token = cookies.get("accessToken");
          originalRequest.headers["Authorization"] = `Bearer ${token}`;
          processQueue(null, token);
          return axios(originalRequest);
        } else {
          await userLogout();
        }
      } catch (err) {
        processQueue(err, null);
        return Promise.reject(err);
      } finally {
        isRefreshing = false;
      }
    }
    return Promise.reject(error);
  }
);

const handleResponse = (response) => response.data;
const handleError = (error) => {
  toast.error("Something went wrong in team. Please try again after some time.",{position:toast.POSITION.BOTTOM_LEFT})
};


const TeamService = {
  GetPermission: async () => {
    try {
      const response = await apiClient.post("/get_user_account_permission_details");
      return handleResponse(response);
    } catch (error) {
      handleError(error);
    }
  },
  SendInvitation: async ({inviteeEmail,roleType, firstName, lastName}) => {
    try {
      const userDetails = JSON.parse(localStorage.getItem('PVLUserDetails'));
      const inviterEmail = userDetails?.useremail;
      if(inviteeEmail.length<1){
        handleError();
        return;
      }
      const payload = {
        inviteeEmail: inviteeEmail,
        inviterEmail: inviterEmail,
        roleType: roleType,
        firstName: firstName,
        lastName: lastName
      };
      const response = await apiClient.post("/send_join_account_invitation",payload);
      return handleResponse(response);
    } catch (error) {
      handleError(error);
    }
  },
  VerifyAndAcceptInvitation: async (token) => {
    try {
      const refreshToken = cookies.get("refreshToken");
      const accessToken = cookies.get("accessToken");

      const payload = {
        token: token,
        accessToken:accessToken,
        refreshToken:refreshToken
      };
      if (typeof token === 'undefined' || (payload?.token && payload.token.length < 1)) {
        return;
      }
      const response = await apiClient.post("/verify_and_accept_account_invitation",payload);
      return handleResponse(response);
    } catch (error) {
      handleError(error);
    }
  },
  GetAccountMemberDetails: async() =>{
    try {
      const payload = {
        pageIndex:  1,
        pageDataCount:50,
        search:'',
        sortByValue:  0,
        sortByDirection: 1,
        filters: null
      }
      const response = await apiClient.post("/get_account_members_details",payload);
      return handleResponse(response);
    } catch (error) {
      handleError(error);
    }
  },
  UpdateAccountMemberStatus: async() =>{
    try {
      const payload = {
        pageIndex:  1,
        pageDataCount:50,
        search:'',
        sortByValue:  0,
        sortByDirection: 1,
        filters: null
      }
      const response = await apiClient.post("/get_account_members_details",payload);
      return handleResponse(response);
    } catch (error) {
      handleError(error);
    }
  },
  SwitchAccountForUser: async(navigate,accountId) => {
    try {
      
      const payload ={accountId:accountId}
      const response = await apiClient.post("/switch_account_for_user",payload);
      if (response.data.statusCode ===200){
        toast.success("You have switched the account.",{position:toast.POSITION.BOTTOM_LEFT})
        setAccessTokenCookie(response.data.result.tokens.accessToken);
        setRefreshToken(response.data.result.tokens.refreshToken);
        const userDetails = response.data.result.userDetails
        const userDetailsString = JSON.stringify(userDetails);
        setUserDetails(userDetailsString);
        Mixpanel.identify(userDetails.userid);
        // Mixpanel.track("Login Successful",{"status": "Success"});
        sendLoginDataToCrisp(userDetails);
        window.location.reload();
      }
      else if (response.statusCode ===500){
        toast.error("Unable to switch account.",{position:toast.POSITION.BOTTOM_LEFT})
      }
    } catch (error) {
      handleError(error);
    }
  },
  GetAccountsForUser: async() => {
    try {
      const response = await apiClient.post("/get_active_memberships_for_user");
      if (response.data.statusCode ===200){
        return response;
      }
      else if (response.data.statusCode ===500){
        toast.error("Unable to get accounts.",{position:toast.POSITION.BOTTOM_LEFT})
      }
    } catch (error) {
      handleError(error);
    }
  },
  CreateUserAccount: async(navigate,userId) => {
    try {
      const payload  = {userId:userId};
      const response = await apiClient.post("/create_account_for_user",payload);
      if (response.data.statusCode ===200){
        return navigate('/'); // so that user can login again.
      }
      else if (response.data.statusCode ===500){
        toast.error("Unable to get accounts.",{position:toast.POSITION.BOTTOM_LEFT})
      }
    } catch (error) {
      handleError(error);
    }
  },
  UpdateOrUserRoleOrRemoveMembership: async(changeType, userId, changeToRoleEnum) =>{
    try {
      const payload = {
        changeType: changeType,
        UserIdToChange: userId,
        changeToRoleEnum: changeToRoleEnum,
        
      };
      
      const response = await apiClient.post("/change_user_role_or_remove_membership",payload);

      if(response.data.statusCode === 200){
        return response;
      }

    } catch (error) {
      handleError(error);
    }
  }
};
export default TeamService;