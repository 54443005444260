import React from "react";

//This will return the default page of the website. If the user tries to access a page that is not present, we will show this page.
//This page is still under development.
function NoPage(){
    return (
        <div>
            <h1>404</h1>
            <p>Page not found</p>
        </div>
    );
}

export default NoPage;
