import React, { useState } from "react";
import UploadComponent from "../../uploads/upload.component";

const UploadModal = ({ onFileChange }) => {
    const handleFileChange = (file) => {
        onFileChange(file)
    }
  return (
    <>
        <div className="form-steps mb-3">
            <div className='active'>Upload CSV</div>
            <div><i className='fa-solid fa-chevron-right'></i></div>
            <div>Map Fields</div>
            <div><i className='fa-solid fa-chevron-right'></i></div>
            <div>Review</div>
        </div>
        <UploadComponent onFileSelect={handleFileChange} />
    </>
  );
};

export default UploadModal;