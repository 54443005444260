import React from "react";
import './credits-history.component.scss'
import { useRef } from "react";
import axios from "axios";
import qs from 'qs'
import { useEffect,useState } from "react";
import { Tooltip } from "react-tooltip";
import 'react-tooltip/dist/react-tooltip.css'
import LoadingAnimation from "../lottie-files/loading.lotte";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UsageCard from "./usagecard.component";
import { userLogout, getBalanceHistoryFunction } from "../../../apis/pvlapis";
import { getAccessTokenCookie } from "../../../apis/tokenhandler";
import BalanceFilters from "./filters/balancefilter.component";
import EmptyCard from "../list/emptylist.component";
const UsageComponent = () => {
  const [callBalanceHistory, setCallBalanceHistory] = useState(true);
  const [usageHistory, setBalanceHistory] = useState([]);
  //to store the selected file
  //to store if the csv file is valid or not
  const [dataAvailable, setDataAvailable] = useState(false);
  //Have a loader variable when loading the page for the first time to represent loading state
  const [isLoading, setIsLoading] = useState(true);


  //States to handle the race conditions during filtering to store the queueddrequests and stop calling when a response is yet to be received
  //State to check if an api request to fetch balance history details is already made and waiting for response
  const [responsePending, setResponsePending] = useState(false);
  //State to add a request to queue to be called later
  const [queuedRequests,setQueuedRequests] = useState(false);
  //State to check the queued results and fetch the lists if there are queued requests
  const [checkQueuedRequests, setCheckQueuedRequests] = useState(false);

  //State variables for List page settings
  const [nextPage, setNextPage] = useState(false);
  const [previousPage, setPreviousPage] = useState(false);
  const [pageDataCount, setpageDataCount] = useState(50);
  const [searchChange, setSearchChange] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [pageIndex, setPageIndex] = useState(1);
  const [sortByValue, setSortByValue] = useState(0);
  const [sortByDirection, setSortByDirection] = useState(2);
  const [pageFilters, setPageFilters] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [lowerIndex, setLowerIndex] = useState(0);
  const [upperIndex, setUpperIndex] = useState(0);
  //Filters
  const [showDropdown, setShowDropdown] = useState(false);
  // const [selectedFilter, setSelectedFilter] = useState('');
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [totalCreditsLowerLimit, setTotalCreditsLowerLimit] = useState(null);
  const [totalCreditsUpperLimit, setTotalCreditsUpperLimit] = useState(null);
  const [statusSelection, setStatusSelection] = useState([]);
  const dropdownRef = useRef(null);
  

  //Create a mapping for transaction execution status
  const transactionStatusMapping = {
    "0": "Completed",
    "1": "Failed",
    "2": "In Progress",
  }
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const getBalanceHistory = async () => {
    //Check if we are waiting for a response
    if(responsePending){
      //Make the queued requests true to call later
      setQueuedRequests(true);
      return;
    }
    const payload = {
      "pageIndex": pageIndex,
      "pageDataCount": pageDataCount,
      "search": searchTerm,
      "sortByValue": sortByValue,
      "sortByDirection": sortByDirection,
      "filters": pageFilters,
      "transactionType": 1
    };
    try {
      setResponsePending(true);
      setIsLoading(true);
      setNextPage(false);
      setPreviousPage(false);
      const response = await getBalanceHistoryFunction(payload);
      // console.log("USAGE",response.data);
      if(response.data.statusCode === 200){
        setBalanceHistory(response.data.result.data);
        setPreviousPage(response.data.result.pagination["previous"]);
        setNextPage(response.data.result.pagination["next"]);
        setTotalRecords(response.data.result.totalRecords);
        if(response.data.result.totalRecords === 0){
          setDataAvailable(false);
        }
        else{
          setDataAvailable(true);
        }
            setUpperIndex((pageIndex-1)*pageDataCount+response.data.result.data.length);
            if(response.data.result.data.length === 0){
              setLowerIndex((pageIndex-1)*pageDataCount);
        }
            else{
              setLowerIndex((pageIndex-1)*pageDataCount+1);
        }

      }
    else{
      await userLogout();
    }

    } 
    catch (error) {
      // console.log(error);
          toast.error("Something went wrong. Please try again after some time.",{position:toast.POSITION.BOTTOM_LEFT})
        }
    finally{
      setIsLoading(false);
      //Set the response pending to false
      setResponsePending(false);
      //Check if there are any pending requests
      setCheckQueuedRequests(!checkQueuedRequests);
    }


  };

  // useEffect(() => {
  //   console.log("List Use Effect called");
  // }, []);
  useEffect(() => {
    // console.log(pageFilters);
    getBalanceHistory();
  }, [callBalanceHistory]);
  useEffect(() => {
    // console.log(pageFilters);
    //Check if some requests are queued
    if(queuedRequests){
      //Set the queued requests to false and call the getBalanceHistory
      setQueuedRequests(false);
      getBalanceHistory();
    }
  }, [checkQueuedRequests]);


  //All filtering, sorting, searching handlers
  const handleNextPage = () => {

      if(nextPage){
        setPageIndex(pageIndex+1);
        setCallBalanceHistory(!callBalanceHistory);
    }
  }
  const handlePreviousPage = () => {
      if(previousPage){
        setPageIndex(pageIndex-1);
        setCallBalanceHistory(!callBalanceHistory);
    }
  }
  const handleSorting = (field) => {

      if(sortByDirection === 1){
      setSortByDirection(2);
    }
      else{
      setSortByDirection(1);
    }
      if(field!==-1){

      setSortByValue(field);
    }
    setPageIndex(1);
    setCallBalanceHistory(!callBalanceHistory);
  }

  const handleSearchKeyDown = (event) => {
    if (event.key === 'Enter') {
      setSearchTerm(searchChange);
      setSearchChange("");
      setPageIndex(1);
      setCallBalanceHistory(!callBalanceHistory);
    }
  };

  const handleSearchChange = (event) => {
    setSearchChange(event.target.value);
    setSearchTerm(event.target.value);
    setPageIndex(1);
    setCallBalanceHistory(!callBalanceHistory);
  };

  
    const handleTotalCreditsLowerLimitChange = (lower) => {
      setTotalCreditsLowerLimit(lower);
    };
  
    const handleTotalCreditsUpperLimitChange = (upper) => {
      setTotalCreditsUpperLimit(upper);
    };
  
    const handleStatusSelectionChange = (selectedOptions) => {
      setStatusSelection(selectedOptions);
    };

    const handleFilteredUsers = (users) => {
      setFilteredUsers(users);
    }
  
    const handleApplyFilter = (selectedFilter) => {
      setPageIndex(1);
      // Call the handleFilters function with the selected filter option and its corresponding value
      let fieldType = 1
      let fieldValue = []
      if(selectedFilter === 'transactionCredits'){
      fieldType = 0;
        if(totalCreditsLowerLimit === null || totalCreditsUpperLimit === null){
          return;
        }
        if(parseInt(totalCreditsLowerLimit, 10)>parseInt(totalCreditsUpperLimit, 10)){
          return;
        }
      }
      else if(selectedFilter === 'userName'){
        fieldValue = filteredUsers;
      }
      else if(selectedFilter === "transactionStatus"){
      fieldValue = statusSelection;
    }
    else{
      return;
    }
    // console.log(statusSelection);
    const newFilter = {
      "fieldName": selectedFilter,
      "fieldType": fieldType,
      "fieldMinValue": totalCreditsLowerLimit,
      "fieldMaxValue": totalCreditsUpperLimit,
      "fieldValue": fieldValue
    };
    const index = pageFilters.findIndex((f) => f.fieldName === newFilter.fieldName);
    // console.log("Index",index);
    if (index !== -1) {
      // If filter with same fieldname already exists, replace its fieldvalue
      const updatedFilters = [...pageFilters];
        if (selectedFilter === "transactionStatus" && fieldValue.length ===0){
        updatedFilters.splice(index, 1);
      }
        else if(selectedFilter === "transactionCredits" && (totalCreditsLowerLimit ===null || totalCreditsLowerLimit.length ===0 || totalCreditsUpperLimit === null || totalCreditsUpperLimit.length === 0)){
        updatedFilters.splice(index, 1);
      }
        else{
        updatedFilters[index] = newFilter;
      }
      setPageFilters(updatedFilters);
    }

    else {
      // Add new filter to the pageFilters array
        if(newFilter.fieldName === "transactionStatus" && newFilter.fieldValue.length === 0){
        return;
      }
        else if(selectedFilter === "transactionCredits" && (totalCreditsLowerLimit ===null || totalCreditsLowerLimit.length ===0 || totalCreditsUpperLimit === null || totalCreditsUpperLimit.length === 0)){
        return;
      }
        else{
        setPageFilters([...pageFilters, newFilter]);
      }
    }
    setCallBalanceHistory(!callBalanceHistory);
    // setSelectedFilter(null);
    // Close the dropdown after applying the filter
    // setShowDropdown(false);
  };

  const handleClearFilters = () => {
    setShowDropdown(false);
    setPageFilters([]);
    setSearchTerm("");
    setSearchChange("");
    setTotalCreditsLowerLimit(null);
    setTotalCreditsUpperLimit(null);
    setStatusSelection([]);
    setPageIndex(1);
    setCallBalanceHistory(!callBalanceHistory);
  }

  const handleClearTransactionStatus = (statusId) => {
    setPageIndex(1);
    let updatedList = []
    //close the dropdown
    setShowDropdown(false);
    //Get the index of the filter
    const index = pageFilters.findIndex((f) => f.fieldName === "transactionStatus");
    if(index !== -1){
      if(statusId){
        //Modify the status selection
        const modifiedStatusSelection = statusSelection.filter(id => id !== statusId);
        setStatusSelection(modifiedStatusSelection);
          //If the modified status list is length zero remove the index from page filters
          if(modifiedStatusSelection.length ===0){
            updatedList = [...pageFilters];
              updatedList.splice(index, 1);
          }
          //Else modify the filter at the index
          else{
            updatedList = pageFilters.map(filter => {
              if (filter.fieldName === "transactionStatus") {
                  return { ...filter, fieldValue: modifiedStatusSelection };
              }
              else{
                return filter;
              }
            });
          }
        }
      else{
        setStatusSelection([]);
        updatedList = [...pageFilters];
        updatedList.splice(index, 1);
      }
      setPageFilters(updatedList);
      setCallBalanceHistory(!callBalanceHistory);
  }
  }


  const handleClearTotalRecords = () => {
    setPageIndex(1);
    setShowDropdown(false);
    setTotalCreditsLowerLimit(null);
    setTotalCreditsUpperLimit(null);
    const index = pageFilters.findIndex((f) => f.fieldName === "transactionCredits");
    // console.log(index,pageFilters);
      if(index !== -1){
      let updatedList = [...pageFilters];
      updatedList.splice(index, 1);
      setPageFilters(updatedList);
      setCallBalanceHistory(!callBalanceHistory);
    }
  }
  const handleClearSearchTerm = () => {
    setPageIndex(1);
    setShowDropdown(false);
    setSearchTerm("");
    setSearchChange("");
    setCallBalanceHistory(!callBalanceHistory);
  }
  return (
    <div className="list-bg p-0">
      <div
        className="d-flex align-items-center justify-content-between action-items-bar my-3"
      >
        <div className="d-flex">
          <div className="form">
            <i className="fa-regular fa-search"></i>
            <input
              type="text"
              className="form-control form-input"
              placeholder="Search"
              value={searchChange}
              onChange={handleSearchChange}
              onKeyDown={handleSearchKeyDown}
              data-tooltip-content="Search by user name" 
              data-tooltip-id="listtips"
            />
          </div>
          <div className="list-widget-relative"ref={dropdownRef}>
            <div className="widget-icon ms-2 cursor-pointer" data-tooltip-content="Add filters" data-tooltip-id="listtips" onClick={() => setShowDropdown(!showDropdown)}>
              <i className={`${pageFilters.length > 0 ? "fa-solid" : "fa-regular"} fa-filter`} style={pageFilters.length > 0 ? {color: "#5C3CE4"} : {}}></i>
            </div>
            {showDropdown && (
              <div className="dropdown">
                <ul style={{ listStyleType: 'none', padding: 0, textAlign: 'left' }}>
                  <li><BalanceFilters onLowerChange={handleTotalCreditsLowerLimitChange}
                    onUpperChange={handleTotalCreditsUpperLimitChange} onStatusChange={handleStatusSelectionChange} applyFilter={handleApplyFilter}
                    lower={totalCreditsLowerLimit} upper={totalCreditsUpperLimit} status={statusSelection}
                    onTotalClear={handleClearTotalRecords} onStatusClear={handleClearTransactionStatus} transactionType={1} hidePaymentStyle={'d-none'}
                    type={[]}/></li>
                </ul>
              </div>
            )}
          </div>
        </div>
        <div className="d-flex align-items-center">
          <div className="widget-icon cursor-pointer" onClick={() => handleSorting(-1)}>
        {sortByDirection === 2? (<i className="fa-regular fa-arrow-down-wide-short" data-tooltip-content="Sort by Ascending" data-tooltip-id="listtips"></i>)
        :(<i className="fa-regular fa-arrow-up-wide-short" data-tooltip-content="Sort by Descending" data-tooltip-id="listtips"></i>)}
          </div>
          <div className="widget-icon ms-2 cursor-pointer" data-tooltip-content="Refresh" data-tooltip-id="listtips" onClick={getBalanceHistory}>
            <i className="fa-regular fa-arrow-rotate-right"></i>
          </div>
          <div className="mx-2 grey-text font-14 d-flex align-items-center pagination">
            <span>{lowerIndex} - {upperIndex} of {totalRecords}</span>
            <div className={`widget-icon ms-2 w-auto text-center ${!previousPage ? 'disabled' : 'cursor-pointer'}`} onClick={!previousPage ? null : handlePreviousPage}>
              <div className="prev-btn" data-tooltip-content="Previous Page" data-tooltip-id="listtips">
                <i className="far fa-chevron-left" ></i>
              </div>
            </div>
            <div className={`widget-icon ms-2 w-auto text-center ${!nextPage ? 'disabled' : 'cursor-pointer'}`} onClick={!nextPage ? null : handleNextPage}>
              <div className="next-btn" data-tooltip-content="Next Page" data-tooltip-id="listtips">
                <i className="far fa-chevron-right"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Table --> */}
  {(pageFilters.length>0 || searchTerm) && (
    <p className="search-response">
      {searchTerm && (<span className="filter-tag"> Search By <i>{searchTerm}</i><i className="fa-regular fa-times ms-1 cursor-pointer" onClick={handleClearSearchTerm}></i></span>)}
    {pageFilters.map((filter, index) => (
      <span key={index}>
        {filter.fieldName === "userName" && (
          <span className="filter-tag"> Uploaded By&nbsp; {" "}
          {filter.fieldValue.map((value, valueIndex) => (
                  <span key={valueIndex}>
                    {value}
                  </span>
                ))}
          </span>)
        }

        {filter.fieldName === "transactionStatus" && (
          <span className="filter-tag">Status is{" "}
            {filter.fieldValue.map((value, valueIndex) => (
              <span key={valueIndex}>
                <span className="dark-text lato-bold applied-filter-clr">{transactionStatusMapping[value]}&nbsp;</span>
                <span><i className="fa-regular fa-times ms-1 cursor-pointer" onClick={() => handleClearTransactionStatus(value)}></i></span>
                &nbsp;
              </span>
            ))}
          </span>)}

        {filter.fieldName === "transactionCredits" && (<span className="filter-tag"> Contacts between <i>{filter.fieldMinValue}</i> To <i>{filter.fieldMaxValue}</i><i className="fa-regular fa-times ms-1 cursor-pointer" onClick={handleClearTotalRecords}></i></span>)}
            </span>
        ))}
          <span className="clear-all primary-blue-text poppins-medium" onClick={handleClearFilters}> Clear All</span>
        </p>
      )}
      {!isLoading && dataAvailable && (
      <div className="table-responsive table-list-cusotom table-fixed-height">
        <table className="table pb-0 mb-0">
          <thead>
            <tr className="text-start">
              <td className="text-center"><span data-tooltip-id="listtips" data-tooltip-content="Transaction initiated by">User</span></td>
              <td className="text-center" onClick={() => handleSorting(2)}><span data-tooltip-id="listtips" data-tooltip-content="Credits Consumption Reason">List Name</span>{sortByValue === 2 && sortByDirection === 1 && <span>&uarr;</span>}
                {sortByValue === 2 && sortByDirection === 2 && <span>&darr;</span>}</td>
              {/* <td className="w-25rem">List Name<span>&uarr;</span></td> */}
              <td className="text-center" onClick={() => handleSorting(1)}><span data-tooltip-id="listtips" data-tooltip-content="Total credits consumed in the trasaction">Credits Used</span>{sortByValue === 1 && sortByDirection === 1 && <span>&uarr;</span>}
                {sortByValue === 1 && sortByDirection === 2 && <span>&darr;</span>}</td>
              <td className="text-center min-w-140" onClick={() => handleSorting(0)}><span data-tooltip-id="listtips" data-tooltip-content="Date of transaction">Date & Time</span> {sortByValue === 0 && sortByDirection === 1 && <span>&uarr;</span>}
                {sortByValue === 0 && sortByDirection === 2 && <span>&darr;</span>}</td>
              <td></td>
              {/* <td className="w-15rem"></td> */}
              <td>{/* It just for making some space*/}</td>
            </tr>
          </thead>

          <tbody>
      {usageHistory && usageHistory.length>0? (usageHistory.map((usage) => (
              <UsageCard key={usage.transactionId} usage={usage} />
            ))) : (
              <tr></tr>
            )}
          </tbody>

        </table>
      </div>
      )}
      {
        isLoading && (
          <div className="loading-animation">
            <LoadingAnimation/>
            <span>
              Loading data, please wait...
            </span>
          </div>
        )
      }
      {
        !dataAvailable && !isLoading &&(
      <EmptyCard messHead={"No transactions found!"} MessDescription={"Upload and verify your list of phone numbers today."} styling={"d-none"} />
      )
    }
      < ToastContainer />
      < Tooltip id="listtips" />
    </div>

  )
};

export default UsageComponent;