import { useContext, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { IntegrationContext } from "../../integrations-constants";
import { toTitleCase } from "../../../../utils/helper.utils";

const IntegrationIndexTabs = [
  {
    title: "Summary",
    id: "summary",
    route: "summary",
    validationKey: "enablesummarytab",
  },
  {
    title: "Errors & Warnings",
    id: "logs",
    route: "logs",
    validationKey: "enablelogstab",
  },
  {
    title: "Sync History",
    id: "synchistory",
    route: "synchistory",
    validationKey: "enablesynchistorytab",
  },
  {
    title: "API Usage",
    id: "apiusage",
    route: "apiusage",
    validationKey: "enableapiusagetab",
  },
];

export default function IntegrationIndex() {
  const { integration, configuration } = useContext(IntegrationContext);
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const [breadCrumbsOptions, setBreadCrumbsOptions] = useState(null);

  function navigateToTab(route) {
    navigate(`/integrations/${configuration.name}/${route}`, {
      state: location.state,
    });
  }

  function goToIntegrationsPage() {
    navigate("/integrations");
  }

  function checkIfActive(route) {
    return params["*"] === route;
  }

  function createBreadcrumb(options) {
    setBreadCrumbsOptions(options);
  }

  return (
    <div className="mb-4">
      <div className="d-flex align-items-center">
        <button className="btn return-back-btn" onClick={goToIntegrationsPage}>
          <i className="fa-solid fa-arrow-left me-2"></i>Back
        </button>
        <div className="d-flex align-items-center ms-3">
          <span className="poppins-medium dark-text font-16">
            {toTitleCase(configuration.name)}
          </span>
          {breadCrumbsOptions && breadCrumbsOptions?.items?.length
            ? breadCrumbsOptions.items.map((item, index) => (
                <>
                  {" "}
                  <span>
                    <i className="fa-solid fa-angle-right mx-2"></i>
                  </span>
                  <span
                    className={`poppins-medium ${!!item.route ? "primary-blue-text cursor-pointer" : "grey-text"}`}
                    onClick={() =>
                      breadCrumbsOptions.handleClick({ item, index })
                    }
                  >
                    {item.name}
                  </span>
                </>
              ))
            : undefined}
        </div>
      </div>
      {!breadCrumbsOptions && !breadCrumbsOptions?.items?.length ? (
        <div
          id="integration-nav-tabs"
          className="justify-content-start gap-40 my-3 minus-left-right-24"
        >
          {IntegrationIndexTabs.filter(
            (item) =>
              configuration.uiConfig.uiproperties.integration[
                item.validationKey
              ]
          ).map((item) => (
            <div
              key={item.route}
              className={`cursor-pointer ${checkIfActive(item.route) ? "active" : undefined}`}
              onClick={() => navigateToTab(item.route)}
            >
              {item.title}
            </div>
          ))}
        </div>
      ) : undefined}
      <Outlet context={{ integration, configuration, createBreadcrumb }} />
    </div>
  );
}
