import {React, useEffect, useState} from 'react';
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setAccessTokenCookie, getAccessTokenCookie, setCurrentCredits, getUserDetails,setUserDetails,removeUserDetails, setRefreshToken, getRefreshToken, removeAccessTokenCookie, removeRefreshToken} from './tokenhandler';
import { async } from 'q';
import { Mixpanel } from '../configs/mixPanel';
import { Crisp } from 'crisp-sdk-web';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const loginUrl = process.env.REACT_APP_PVL_APP_URL+"/pvl/user_login";
const getDownloadUrl = process.env.REACT_APP_PVL_APP_URL+"/pvl/get_project_data";
const signupUrl = process.env.REACT_APP_PVL_APP_URL+"/pvl/user_signup";
const getProjectsUrl = process.env.REACT_APP_PVL_APP_URL+"/pvl/get_user_account_projects_details";
const validateUrl = process.env.REACT_APP_PVL_APP_URL+"/pvl/upload_and_validate_prospect_data";
const executeUrl = process.env.REACT_APP_PVL_APP_URL+"/pvl/request_project_execution";
const regenerateTokenUrl = process.env.REACT_APP_PVL_APP_URL+"/pvl/regenerate_access_tokens";
const apiKey = process.env.REACT_APP_PVL_API_KEY;
const changeProfileUrl = process.env.REACT_APP_PVL_APP_URL+"/pvl/change_profile";
const changePasswordUrl = process.env.REACT_APP_PVL_APP_URL+"/pvl/in_house_change_password";
const verificationUrl = process.env.REACT_APP_PVL_APP_URL+"/pvl/invitation_link_verification";
const inhouseSignupUrl = process.env.REACT_APP_PVL_APP_URL+"/pvl/in_house_user_signup";
const inhouseLoginUrl = process.env.REACT_APP_PVL_APP_URL+"/pvl/in_house_user_login";
const sendForgotPasswordLinkUrl = process.env.REACT_APP_PVL_APP_URL+"/pvl/forgot_password";
const verifyForgotPasswordUrl = process.env.REACT_APP_PVL_APP_URL+"/pvl/email_verfication_forgot_password";
const resetPasswordUrl = process.env.REACT_APP_PVL_APP_URL+"/pvl/reset_password";
const regenerateVerifyEmailUrl = process.env.REACT_APP_PVL_APP_URL+"/pvl/regenerating_verification_link";
const accountInvitationUrl = process.env.REACT_APP_PVL_APP_URL+"/pvl/send_account_invitation";
const acceptInvitationUrl = process.env.REACT_APP_PVL_APP_URL+"/pvl/accepted_account_invitation";
const captchaValidationUrl = process.env.REACT_APP_PVL_APP_URL+"/pvl/validate_recaptcha";

const createCheckoutUrl = process.env.REACT_APP_PVL_APP_URL+"/pvl/create_stripe_checkout";
const getPaymentStatusUrl = process.env.REACT_APP_PVL_APP_URL+"/pvl/check_payment_status";
//  Subscription APIs
// const getCurrentCreditUrl = process.env.REACT_APP_PVL_APP_URL+"/pvl/get_account_credit_balance";
const getCurrentCreditUrl = process.env.REACT_APP_PVL_APP_URL+"/pvl/get_account_credit_subscription_details";
const updateSubscriptionUrl = process.env.REACT_APP_PVL_APP_URL+"/pvl/perform_subscription_update";

// End of Subscription Apis
const getInvoicesListUrl = process.env.REACT_APP_PVL_APP_URL+"/pvl/get_invoice_data";
const getBalanceHistoryUrl = process.env.REACT_APP_PVL_APP_URL+"/pvl/get_account_balance_history";
const getUserCardsUrl = process.env.REACT_APP_PVL_APP_URL+"/pvl/get_user_cards";
const deleteCardUrl = process.env.REACT_APP_PVL_APP_URL+"/pvl/delete_credit_card";
const customerAddressUrl = process.env.REACT_APP_PVL_APP_URL+"/pvl/get_customer_address";
const editAddressUrl = process.env.REACT_APP_PVL_APP_URL+"/pvl/edit_invoice_address";
const changeDeafultCardUrl = process.env.REACT_APP_PVL_APP_URL+"/pvl/change_default_card";
const generateApiKeyUrl = process.env.REACT_APP_PVL_APP_URL+"/pvl/generate_api_key";
const getApiKeyUrl = process.env.REACT_APP_PVL_APP_URL+"/pvl/get_api_key";
const fetchEventsUrl = process.env.REACT_APP_PVL_APP_URL+"/pvl/fetch_api_webhook_events_for_user";
const resendWebhookUrl = process.env.REACT_APP_PVL_APP_URL+"/pvl/replay_events_for_user";

// Team Management URLs
const getAccountMembersDetailsUrl = process.env.REACT_APP_PVL_APP_URL+"/pvl/get_active_memberships_for_user";
const getUserAccountDetailsUrl = process.env.REACT_APP_PVL_APP_URL+"/pvl/get_account_members_details";
const sendJoinAccountUrl = process.env.REACT_APP_PVL_APP_URL+"/pvl/send_join_account_invitation";
const verifyAndAcceptUrl = process.env.REACT_APP_PVL_APP_URL+"/pvl/verify_and_accept_account_invitation";
const getAccountPermissionUrl = process.env.REACT_APP_PVL_APP_URL+"/pvl/get_user_account_permission_details";
const switchAccountUserUrl = process.env.REACT_APP_PVL_APP_URL+"/pvl/switch_account_for_user";
const createAccountUserUrl = process.env.REACT_APP_PVL_APP_URL+"/pvl/create_account_for_user";
const changeUserRoleUrl = process.env.REACT_APP_PVL_APP_URL+"/pvl/change_user_role_or_remove_membership";
const updateAccountDetailUrl = process.env.REACT_APP_PVL_APP_URL +"/pvl/update_account_information";

const billingTierDetails = process.env.REACT_APP_PVL_BILLING_TIER_DETAILS;


const googleUserDetailsUrl = "https://www.googleapis.com/oauth2/v1/userinfo?access_token=";
const office365UserDetailsUrl = "https://graph.microsoft.com/v1.0/me"

export async function userLogout(){
  // console.log("LOGOUT CALLED");
  // Mixpanel.track("Logout Successful");
  Mixpanel.reset();
  removeAccessTokenCookie();
	removeRefreshToken();
  removeUserDetails();
}

export async function sendLoginDataToCrisp(userDetails){
  Crisp.session.reset();
  Crisp.user.setEmail(userDetails.useremail);
  Crisp.user.setNickname(userDetails.userfullname);
  Crisp.session.setSegments(["Login", "SureConnect.ai"]);
  Crisp.load();
  Crisp.chat.hide();
}

export async function userLogin(access_token, source, navigate,redirectUrl='',showJoinedModel=false){
    try{
        await userLogout();
        const config = {
  
          headers: { 
            'Accept': 'application/json',
            'Authorization': apiKey,
            'X-API-Key': apiKey
          }
        };
        const payload = {
          serviceType: source,
          accessToken: access_token,
          flag_via_invitation: false,
          invitation:null
        };
        const response = await axios.post(loginUrl, payload,config);
        if (response.data.statusCode === 200){
          setAccessTokenCookie(response.data.result.tokens.accessToken);
          setRefreshToken(response.data.result.tokens.refreshToken);
          const userDetails = response.data.result.userDetails
          const userDetailsString = JSON.stringify(userDetails);
          setUserDetails(userDetailsString);
          Mixpanel.identify(userDetails.userid);
          // Mixpanel.track("Login Successful",{"status": "Success"});
          sendLoginDataToCrisp(userDetails)
          if(redirectUrl.length>0){
            navigate(redirectUrl)
          }
          else{
            navigate('/home',{state:{showJoinedModel:showJoinedModel}});
          }
          
        }
        else if (response.data.statusCode === 425){
          // No active membership accounts found for user, please create a new one"
          return response;
          // navigate("/signup");
        }
        else if (response.data.statusCode === 456){
          // console.log("User not exist");
          toast.error("Sign up before trying to log in.",{position: toast.POSITION.BOTTOM_LEFT});
          // navigate("/signup");
        }
        else{
          toast.error("Problem logging in.",{position: toast.POSITION.BOTTOM_LEFT});
        }
      }
      catch (error) {
        // console.log("error");
        // console.log(error);
        toast.error("Something went wrong. Please try again after some time.",{position: toast.POSITION.BOTTOM_LEFT});
      }
}

export async function userSignup(access_token, source, navigate,invitationToken, redirectUrl){
  try{
      await userLogout();
      const config = {

        headers: { 
          'Accept': 'application/json',
          'Authorization': apiKey,
          'X-API-Key': apiKey
        }
      };
      const payload = {
        serviceType: source,
        accessToken: access_token,
        invitation:{token:invitationToken}
      };
      // console.log(payload);
      const response = await axios.post(signupUrl, payload,config);
      // console.log(response.data);
      if (response.data.statusCode === 200){
        // console.log("Access token",response.data.result.tokens.accessToken);
        setAccessTokenCookie(response.data.result.tokens.accessToken);
        setRefreshToken(response.data.result.tokens.refreshToken);
        const userDetails = response.data.result.userDetails
        const userDetailsString = JSON.stringify(userDetails);
        setUserDetails(userDetailsString);
        Mixpanel.identify(userDetails.userid);
        Mixpanel.people.set({
          "$name": userDetails.userfullname,
          "$email": userDetails.useremail,
          "Account": userDetails.accountid
        })
        if(redirectUrl.length>0){
          navigate(redirectUrl)
        }
        else{
          navigate('/home');
        }
      }
      else if(response.data.statusCode === 459){
        toast.warning("Please try Signup with a valid Business Email.",{position: toast.POSITION.BOTTOM_LEFT});
      }
      else if(response.data.statusCode === 452){
        toast.info("Account already present. Please Login!",{position: toast.POSITION.BOTTOM_LEFT});
        // navigate("/");
      }
      else if(response.data.statusCode === 402){
        toast.error("User is not allowed to join the account!",{position: toast.POSITION.BOTTOM_LEFT});
        // navigate("/");
      }
      else{
        toast.error("Something went wrong. Please try again.",{position: toast.POSITION.BOTTOM_LEFT});
      }
      return response;
    }
    catch (error) {
      // console.log("error");
      // console.log(error);
      toast.error("There is a problem with Sign up. Please try again after some time.",{position: toast.POSITION.BOTTOM_LEFT});
    }
}

export async function inhouseSignup(data, redirectUrl){
  try{
      const config = {

        headers: { 
          'Accept': 'application/json',
          'Authorization': apiKey,
          'X-API-Key': apiKey
        }
      };
      // console.log(payload);
      const response = await axios.post(inhouseSignupUrl, data,config);
      // console.log(response);
      return response;
    }
    catch (error) {
      // console.log("error");
      // console.log(error);
      toast.error("Something went wrong. Please try again after some time.",{position: toast.POSITION.BOTTOM_LEFT});
    }
}

export async function inhouseLogin(data, navigate){
    try{
        await userLogout();
        const config = {
  
          headers: { 
            'Accept': 'application/json',
            'Authorization': apiKey,
            'X-API-Key': apiKey
          }
        };
        // console.log(payload);
        const response = await axios.post(inhouseLoginUrl, data,config);
        // console.log(response);
        return response;
      }
      catch (error) {
        // console.log("error");
        // console.log(error);
        toast.error("Something went wrong. Please try again after some time.",{position: toast.POSITION.BOTTOM_LEFT});
      }
}

export async function emailVerification(token){
  try{
    const config = {
  
      headers: { 
        'Accept': 'application/json',
        'Authorization': apiKey,
        'X-API-Key': apiKey
      }
    };
    const payload = {
      "token": token
    }
    // console.log(payload);
    const response = await axios.post(verificationUrl, payload,config);
    return response;
  }
  catch(error){
    // console.log(error);
    return 800;
  }
}

export async function regenerateVerifyEmail(payload){
  const config = {
    headers: { 
      'Accept': 'application/json',
      'X-API-Key': apiKey
    }
  };
  const response = await axios.post(regenerateVerifyEmailUrl,payload,config);
  return response.data.statusCode;
}

export async function sendAccountInvitation(payload){
  const access_token = getAccessTokenCookie();
  const config = {
    headers: { 
      'Accept': 'application/json',
      'Authorization': `Bearer ${access_token}`,
      'X-API-Key': apiKey
    }
  };
  const response = await axios.post(accountInvitationUrl,payload,config);
  // console.log(response);
  return response;
}

export async function acceptAccountInviation(payload){
  await userLogout();
  const config = {
    headers: { 
      'Accept': 'application/json',
      'X-API-Key': apiKey
    }
  };
  const response = await axios.post(acceptInvitationUrl,payload,config);
  // console.log(response);
  return response;
}


export async function regenerateTokens(){
    const refreshToken = getRefreshToken();
    removeAccessTokenCookie();
    try{
      const config = {
        headers: { 
          'Accept': 'application/json',
          'Authorization': `Bearer ${refreshToken}`,
          'X-API-Key': apiKey
        }
      };
      const response = await axios.post(regenerateTokenUrl,null,config);
      // console.log(response.data);
      if (response.data.statusCode === 200){
        // console.log(response.data.result.accessToken);
        setAccessTokenCookie(response.data.result.accessToken);
        // setRefreshToken(response.data.result.tokens.refreshToken);
        return 200;
      }
      else{
        return 400;
      }
    }
    catch(error){
      // console.log(error);
      return 400;
    }
    
  }

export async function profileChanges(changes,navigate){
  const accessToken = getAccessTokenCookie();
  const refreshToken = getRefreshToken();
  // getCurrentCredits();
  try{
    const config = {

      headers: { 
        'Accept': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
        'X-API-Key': apiKey
      }
    };
    // console.log(payload);
    const response = await axios.post(changeProfileUrl, changes,config);
    // console.log(response.data);
    if (response.data.statusCode === 200){
      // console.log("Access token",response.data.result.tokens.accessToken);;
      // setUserDetails(response.data.result.userDetails);
      const userDetailsString = JSON.stringify(response.data.result.userDetails);
      setUserDetails(userDetailsString);
      // console.log(getUserDetails())
    }
    else if(response.data.statusCode === 457){
      const status = await regenerateTokens();
      if(status === 200){
        await profileChanges(changes,navigate);
      }
      else{
        await userLogout();
        navigate('/');
      }
    }
  }
  catch(error){
    // console.log(error);
    toast.error("Something went wrong. Please try again after some time.",{position: toast.POSITION.BOTTOM_LEFT});
  }
  
}

export async function sendForgotPasswordLink(email){
  try{
    const payload = {
      "userEmail": email
    }
    const config = {
      headers: {
        'X-API-Key': apiKey
      }
    };
    const response = await axios.post(sendForgotPasswordLinkUrl,payload,config);
    return response;
  }
  catch(error){
    // console.log(error);
    // toast.error("There is a problem proceesing your request. Please try again after some time.",{position: toast.POSITION.BOTTOM_LEFT});
  }
}


export async function verifyForgotPassword(token){
  try{
    const payload = {
      "token": token
    };
    const config = {
      headers: {
        'X-API-Key': apiKey
      }
    };
    const response = await axios.post(verifyForgotPasswordUrl,payload,config);
    return response;

  }
  catch(error){
    // console.log(error);
    toast.error("Something went wrong. Please try again after some time.",{position: toast.POSITION.BOTTOM_LEFT});
  }
}
export async function changeForgotPassword(password,token){
  try{
    const payload = {
      "newPassword": password
    };
    const config = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'X-API-Key': apiKey
      }
    };
    const response = await axios.post(resetPasswordUrl,payload,config);
    // console.log(response);
    return response;
  }
  catch(error){
    // console.log(error);
    toast.error("Something went wrong. Please try again after some time.",{position: toast.POSITION.BOTTOM_LEFT});
  }
}


export async function passwordChange(changes,navigate){
  const accessToken = getAccessTokenCookie();
  const refreshToken = getRefreshToken();
  try{
    const config = {

      headers: { 
        'Accept': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
        'X-API-Key': apiKey
      }
    };
    // console.log(payload);
    const response = await axios.post(changePasswordUrl, changes,config);
    // console.log(response.data);
    if (response.data.statusCode === 200){
      toast.success("Password Changed Successfully!",{position: toast.POSITION.BOTTOM_LEFT});
    }
    else if (response.data.statusCode === 461){
      toast.error("Current password is incorrect!",{position: toast.POSITION.BOTTOM_LEFT});
    }
    else if (response.data.statusCode === 455){
      await userLogout();
      navigate("/");
    }
    else if(response.data.statusCode === 457){
      const status = await regenerateTokens();
      if(status === 200){
        await passwordChange(changes,navigate);
      }
      else{
        await userLogout();
        navigate("/");
      }
    }
    else{
      toast.error("Could not process request.",{position: toast.POSITION.BOTTOM_LEFT});
    }
  }
  catch(error){
    // console.log(error);
    toast.error("Something went wrong. Please try again after some time.",{position: toast.POSITION.BOTTOM_LEFT});
  }
  
}

export async function verifyRecaptcha (recaptchaValue) {
  try{
    const config = {
      headers: { 
        'Accept': 'application/json',
        'X-API-Key': apiKey
      }
    };
    const payload = {
      "code": recaptchaValue
    }
    // console.log(payload);
    const response = await axios.post(captchaValidationUrl, payload,config);
    // console.log(response.data);
    if (response.data.statusCode === 200){
      return true;
    }
    else{
      return false;
    }
  }
  catch(error){
  }
};

export async function createCheckout(payload){
  const accessToken = getAccessTokenCookie();
  try{
    let response;
    const config = {
      headers: { 
        'Accept': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
        'X-API-Key': apiKey
      }
    };
    // console.log(payload);
    response = await axios.post(createCheckoutUrl, payload,config);
    // console.log(response.data);
    if(response.data.statusCode === 457){
      const status = await regenerateTokens();
      if(status === 200){
        response = await createCheckout(payload);
      }
      else{
        await userLogout();
      }
    }
    else if(response.data.statusCode === 455){
      await userLogout();
    }
    else if(response.data.statusCode === 453){
      await createCheckout(payload);
    }
    return response;
  }
  catch(error){
    // console.log(error);
  }
  
}

export async function getPaymentStatus(payload){
  const accessToken = getAccessTokenCookie();
  // getCurrentCredits();
  try{
    let response;
    const config = {
      headers: { 
        'Accept': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
        'X-API-Key': apiKey
      }
    };
    // console.log(payload);
    response = await axios.post(getPaymentStatusUrl, payload,config);
    // console.log(response.data);
    if(response.data.statusCode === 457){
      const status = await regenerateTokens();
      if(status === 200){
        response = await getPaymentStatus(payload);
      }
      else{
        await userLogout();
      }
    }
    else if(response.data.statusCode === 455){
      await userLogout();
    }
    else if(response.data.statusCode === 453){
      await getPaymentStatus(payload);
    }
    return response;
  }
  catch(error){
    // console.log(error);
  }
}

export async function getCurrentCredits(){
  const accessToken = getAccessTokenCookie();
  try{
    let response;
    const config = {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
        'X-API-Key': apiKey
      }
    };
    // console.log(payload);
    response = await axios.get(getCurrentCreditUrl,config);
    // console.log(response.data);
    if(response.data.statusCode === 457){
      const status = await regenerateTokens();
      if(status === 200){
        response =  await getCurrentCredits();
      }
      else{
        await userLogout();
      }
    }
    else if(response.data.statusCode === 455){
      await userLogout();
    }
    return response;
  }
  catch(error){
    // console.log(error);
  }
}

export async function getInvoicesList(payload){
  const accessToken = getAccessTokenCookie();
  try{
    let response;
    const config = {
      headers: { 
        'Accept': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
        'X-API-Key': apiKey
      }
    };
    // console.log(payload);
    response = await axios.get(getInvoicesListUrl,config);
    // console.log(response.data);
    if(response.data.statusCode === 457){
      const status = await regenerateTokens();
      if(status === 200){

        response = await getInvoicesList(payload);
      }
      else{
        await userLogout();
      }
    }
    else if(response.data.statusCode === 455){
      await userLogout();
    }
    return response;
  }
  catch(error){
    // console.log(error);
  }
  
}

export async function getBalanceHistoryFunction(payload){
  const accessToken = getAccessTokenCookie();
  try{
    let response;
    const config = {
      headers: { 
        'Accept': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
        'X-API-Key': apiKey
      }
    };
    // console.log(payload);
    response = await axios.post(getBalanceHistoryUrl,payload,config);
    // console.log(response.data);
    if(response.data.statusCode === 457){
      const status = await regenerateTokens();
      if(status === 200){
        response = await getBalanceHistoryFunction(payload);
      }
      else{
        await userLogout();
      }
    }
    else if(response.data.statusCode === 455){
      await userLogout();
    }
    else if (response.data.statusCode === 453){
      await getBalanceHistoryFunction();
    }

    return response;

  }
  catch(error){
    // console.log(error);
  }
  
}

export async function getUserCards(){
  const accessToken = getAccessTokenCookie();
  try{
    let response;
    const config = {
      headers: { 
        'Accept': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
        'X-API-Key': apiKey
      }
    };
    // console.log(payload);
    response = await axios.get(getUserCardsUrl,config);
    // console.log(response.data);
    if(response.data.statusCode === 457){
      const status = await regenerateTokens();
      if(status === 200){
        response = await getUserCards();
      }
      else{
        await userLogout();
      }
    }
    else if(response.data.statusCode === 455){
      await userLogout();
    }
    else if (response.data.statusCode === 453){
      await getUserCards();
    }
    return response;
  }
  catch(error){
    // console.log(error);
  }
  
}

export async function deleteCard(payload){
  const accessToken = getAccessTokenCookie();
  try{
    let response;
    const config = {
      headers: { 
        'Accept': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
        'X-API-Key': apiKey
      }
    };
    // console.log(payload);
    response = await axios.post(deleteCardUrl,payload,config);
    // console.log(response.data);
    if(response.data.statusCode === 457){
      const status = await regenerateTokens();
      if(status === 200){
        response = await deleteCard(payload);
      }
      else{
        await userLogout();
      }
    }
    else if(response.data.statusCode === 455){
      await userLogout();
    }
    else if (response.data.statusCode === 453){
      await deleteCard(payload);
    }
    return response;
  }
  catch(error){
    // console.log(error);
  }
  
}

export async function changeDefaultCard(payload){
  const accessToken = getAccessTokenCookie();
  try{
    let response;
    const config = {
      headers: { 
        'Accept': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
        'X-API-Key': apiKey
      }
    };
    // console.log(payload);
    response = await axios.post(changeDeafultCardUrl,payload,config);
    // console.log(response.data);
    if(response.data.statusCode === 457){
      const status = await regenerateTokens();
      if(status === 200){
        response = await changeDefaultCard(payload);
      }
      else{
        await userLogout();
      }
    }
    else if(response.data.statusCode === 455){
      await userLogout();
    }
    else if (response.data.statusCode === 453){
      await changeDefaultCard(payload);
    }

    return response;

  }
  catch(error){
    // console.log(error);
  }
  
}

export async function editAddress(payload){
  const accessToken = getAccessTokenCookie();
  try{
    let response;
    const config = {
      headers: { 
        'Accept': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
        'X-API-Key': apiKey
      }
    };
    // console.log(payload);
    response = await axios.post(editAddressUrl,payload,config);
    // console.log(response.data);
    if(response.data.statusCode === 457){
      const status = await regenerateTokens();
      if(status === 200){
        response = await editAddress(payload);
      }
      else{
        await userLogout();
      }
    }
    else if(response.data.statusCode === 455){
      await userLogout();
    }
    else if (response.data.statusCode === 453){
      await editAddress(payload);
    }

    return response;

  }
  catch(error){
    // console.log(error);
  }
  
}

export async function getCustomerAddress(){
  const accessToken = getAccessTokenCookie();
  try{
    let response;
    const config = {
      headers: { 
        'Accept': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
        'X-API-Key': apiKey
      }
    };
    // console.log(payload);
    response = await axios.get(customerAddressUrl,config);
    // console.log(response.data);
    if(response.data.statusCode === 457){
      const status = await regenerateTokens();
      if(status === 200){
        response = await getCustomerAddress();
      }
      else{
        await userLogout();
      }
    }
    else if(response.data.statusCode === 455){
      await userLogout();
    }
    else if (response.data.statusCode === 453){
      await getCustomerAddress();
    }
    return response;
  }
  catch(error){
    // console.log(error);
  }
  
}
export async function getDetailsFromGoogle (access_token) {
  try{
    const googleUrl = googleUserDetailsUrl + access_token;
    const config = {
      headers: { 
        'Accept': 'application/json',
        "Authorization": `Bearer ${access_token}`
      }
    };
    // console.log(payload);
    const response = await axios.get(googleUrl, config);
    return response;
  }
  catch(error){
  }
};

export async function getDetailsFromOffice365 (access_token) {
  try{
    const config = {
      headers:{
        "Authorization": `Bearer ${access_token}`
      }
    };
    // console.log(payload);
    const response = await axios.get(office365UserDetailsUrl, config);
    return response;
  }
  catch(error){
  }
};

export async function downloadValidatedCSV(projectId){
  if(!projectId){
    return;
  }
  const accessToken = getAccessTokenCookie();
  try{
    let response;
    const requiredData = ["validatedCSV"];
    const config = {
      headers: { 
        'Accept': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
        'X-API-Key': apiKey
      },
      params: {
        'projectId':projectId,
        'requiredData': requiredData.toString()
      }
    };
    // console.log(payload);
    response = await axios.get(getDownloadUrl, config);
    // console.log(response.data);
    if(response.data.statusCode === 200){

      const data = response.data.result
      for(const key in data){

        if (data.hasOwnProperty(key)) {
          window.open(data[key], '_blank');
        }
      }
    }
    else if(response.data.statusCode === 457){
      const status = await regenerateTokens();
      if(status === 200){
        response = await downloadValidatedCSV();
      }
      else{
        await userLogout();
      }
    }
    else if(response.data.statusCode === 455){
      await userLogout();
    }
    else if (response.data.statusCode === 453){
      await downloadValidatedCSV();
    }
    else{
      toast.error("nnSomething went wrong. Please try again.",{position: toast.POSITION.BOTTOM_LEFT});
    }
  }
  catch(error){
    toast.error("xxSomething went wrong. Please try again after some time.",{position: toast.POSITION.BOTTOM_LEFT});
  }
  
}

export async function generateApiKey(){
  const accessToken = getAccessTokenCookie();
  try{
    let response;
    const config = {
      headers: { 
        'Accept': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
        'X-API-Key': apiKey
      }
    };
    // console.log(payload);
    response = await axios.get(generateApiKeyUrl,config);
    // console.log(response.data);
    if(response.data.statusCode === 457){
      const status = await regenerateTokens();
      if(status === 200){
        response = await generateApiKey();
      }
      else{
        await userLogout();
      }
    }
    else if(response.data.statusCode === 455){
      await userLogout();
    }
    else if (response.data.statusCode === 453){
      await generateApiKey();
    }
    return response;
  }
  catch(error){
    // console.log(error);
  }
}

export async function getApiKey(){
  const accessToken = getAccessTokenCookie();
  try{
    let response;
    const config = {
      headers: { 
        'Accept': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
        'X-API-Key': apiKey
      }
    };
    // console.log(payload);
    response = await axios.get(getApiKeyUrl,config);
    // console.log(response.data);
    if(response.data.statusCode === 457){
      const status = await regenerateTokens();
      if(status === 200){
        response = await getApiKey();
      }
      else{
        await userLogout();
      }
    }
    else if(response.data.statusCode === 455){
      await userLogout();
    }
    else if (response.data.statusCode === 453){
      await getApiKey();
    }
    return response;
  }
  catch(error){
    // console.log(error);
  }
}

export async function fetchApiWebhookEvents(payload){
  const accessToken = getAccessTokenCookie();
  try{
    let response;
    const config = {
      headers: { 
        'Accept': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
        'X-API-Key': apiKey
      }
    };
    // console.log(payload);
    response = await axios.post(fetchEventsUrl,payload,config);
    // console.log(response.data);
    if(response.data.statusCode === 457){
      const status = await regenerateTokens();
      if(status === 200){
        response = await fetchApiWebhookEvents();
      }
      else{
        await userLogout();
      }
    }
    else if(response.data.statusCode === 455){
      await userLogout();
    }
    else if (response.data.statusCode === 453){
      await fetchApiWebhookEvents();
    }
    return response;
  }
  catch(error){
    // console.log(error);
  }
}


export async function resendWebhook(payload){
  const accessToken = getAccessTokenCookie();
  try{
    let response;
    const config = {
      headers: { 
        'Accept': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
        'X-API-Key': apiKey
      }
    };
    // console.log(payload);
    response = await axios.post(resendWebhookUrl,payload,config);
    // console.log(response.data);
    if(response.data.statusCode === 457){
      const status = await regenerateTokens();
      if(status === 200){
        response = await resendWebhook();
      }
      else{
        await userLogout();
      }
    }
    else if(response.data.statusCode === 455){
      await userLogout();
    }
    else if (response.data.statusCode === 453){
      await resendWebhook();
    }
    return response;
  }
  catch(error){
    // console.log(error);
  }
}

export function getTierDetails(){
  let tiers = {};
  //parse the string as json
  tiers = JSON.parse(billingTierDetails);
  //convert the json to array and sort the array based on the name
  const keyValueArray = Object.entries(tiers).sort((a, b) => a[1].from - b[1].from);
  //convert the array back to json
  const tierDetails = Object.fromEntries(keyValueArray);
  //return the tier details
  return {
    "tierJSON":tierDetails,
    "tierArray":keyValueArray
  }
}


export async function updateSubscription(payload){
  const accessToken = getAccessTokenCookie();
  // getCurrentCredits();


//MC: export async function getAccountUsers(){
 // Mc: const accessToken = getAccessTokenCookie();

  try{
    let response;
    const config = {
      headers: { 
        'Accept': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
        'X-API-Key': apiKey
      }
    };
    // console.log(payload);

    response = await axios.put(updateSubscriptionUrl, payload,config);

    // MC: response = await axios.get(fetchUsersUrl,config);

    // console.log(response.data);
    if(response.data.statusCode === 457){
      const status = await regenerateTokens();
      if(status === 200){

        response = await updateSubscription(payload);

        // MC: response = await getUserCards();

      }
      else{
        await userLogout();
      }
    }
    else if(response.data.statusCode === 455){
      await userLogout();
    }

    else if (response.data.statusCode === 453){
      await getUserCards();
    }
    return response;
  }
  catch(error){
    // console.log(error);
  }
  
}


// export async function getUserAccountPermissionDetails (){
//   const accessToken = getAccessTokenCookie();
//   try{
//     let response;
//     const config = {
//       headers: { 
//         'Accept': 'application/json',
//         'Authorization': `Bearer ${accessToken}`,
//         'X-API-Key': apiKey
//       }
//     };
//     // console.log(payload);
//     response = await axios.get(fetchUsersUrl,config);
//     if(response.data.statusCode === 457){
//       const status = await regenerateTokens();
//       // on successfull generation of token
//       if(status === 200){
//         response = await getUserAccountPermissionDetails();
//       }
//       else{
//         await userLogout();
//       }
//     }
//     else if(response.data.statusCode === 455){
//       await userLogout();
//     }
//     else if (response.data.statusCode === 453){
//       await getUserCards();
//     }
//     return response;
//   }
//   catch(error){
//     // console.log(error);
//   }
// }


  export async function GetPermission (){
    const accessToken = getAccessTokenCookie();
    try{
      let response;
      const config = {
        headers: { 
          'Accept': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
          'X-API-Key': apiKey
        }
      };
      // console.log(payload);
      response = await axios.post(getAccountPermissionUrl,{},config);
      if(response.data.statusCode === 457){
        const status = await regenerateTokens();
        // on successfull generation of token
        if(status === 200){
          // return the response as GetPermssion function is returning response.data
          response = await GetPermission();
          return response;
        }
        else{
          await userLogout();
        }
      }
      else if(response.data.statusCode === 455){
        await userLogout();
      }
      else if (response.data.statusCode === 453){
        await getUserCards();
      }
      return response.data;
    }
    catch(error){
      // console.log(error);
    }
  }
  const isAnyFieldValueEmpty = (filters) => {
    return filters.some(filter => !filter.fieldValue || filter.fieldValue.length === 0);
  };
    export async function GetAccountMembersDetails ( {pageIndex = 1, pageDataCount = 50, search='',
      sortByValue = 0, sortByDirection = 1, filters = null}){
      const accessToken = getAccessTokenCookie();
      try{
        let response;
        const config = {
          headers: { 
            'Accept': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
            'X-API-Key': apiKey
          }
        };
        
        const payload = {
          pageIndex:  pageIndex,
          pageDataCount:pageDataCount,
          search:search,
          sortByValue:  sortByValue,
          sortByDirection: sortByDirection,
          filters: filters.pageFilters
        }
        if (isAnyFieldValueEmpty(payload.filters)) {
          return -1;
        }
        
        response = await axios.post(getUserAccountDetailsUrl,payload,config);
        if(response.data.statusCode === 457){
          const status = await regenerateTokens();
          // on successfull generation of token
          if(status === 200){
            response = await GetAccountMembersDetails({pageIndex:pageIndex, pageDataCount:pageDataCount, search:search,
              sortByValue: sortByValue, sortByDirection: sortByDirection, filters:filters.pageFilters});
          }
          else{
            await userLogout();
          }
        }
        else if(response.data.statusCode === 455){
          await userLogout();
        }
        else if (response.data.statusCode === 453){
          await getUserCards();
        }
        return response.data;
      }
      catch(error){
        // console.log(error);
      }
    }

    export async function GetUserConnectedAccountDetails (){
      const accessToken = getAccessTokenCookie();
      try{
        let response;
        const config = {
          headers: { 
            'Accept': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
            'X-API-Key': apiKey
          }
        };
        // console.log(payload);
        
        response = await axios.post(getAccountMembersDetailsUrl,{},config);
        if(response.data.statusCode === 457){
          const status = await regenerateTokens();
          // on successfull generation of token
          if(status === 200){
            response = await GetUserConnectedAccountDetails();
          }
          else{
            await userLogout();
          }
        }
        else if(response.data.statusCode === 455){
          await userLogout();
        }
        else if (response.data.statusCode === 453){
          await getUserCards();
        }
        return response;
      }
      catch(error){
        // console.log(error);
      }
    }

      export async function SendJoinAccountInvitation ({inviteeEmail,roleType, firstName, lastName}){
        const accessToken = getAccessTokenCookie();
        try{
          let response;
          const config = {
            headers: { 
              'Accept': 'application/json',
              'Authorization': `Bearer ${accessToken}`,
              'X-API-Key': apiKey
            }
          };
          const userDetails = JSON.parse(localStorage.getItem('PVLUserDetails'));
        const inviterEmail = userDetails?.useremail;
        if(inviteeEmail.length<1){
          
          return;
        }
        
      const payload = {
        inviteeEmail: inviteeEmail,
        inviterEmail: inviterEmail,
        roleType: roleType,
        firstName: firstName,
        lastName: lastName
      };
          response = await axios.post(sendJoinAccountUrl,payload,config);
          if(response.data.statusCode === 457){
            const status = await regenerateTokens();
            // on successfull generation of token
            if(status === 200){
              response = await SendJoinAccountInvitation({inviteeEmail: inviteeEmail, roleType: roleType, 
                firstName:firstName, lastName:lastName});
            }
            else{
              await userLogout();
            }
          }
          else if(response.data.statusCode === 455){
            await userLogout();
          }
          else if (response.data.statusCode === 453){
            await getUserCards();
          }
          return response.data;
        }
        catch(error){
          // console.log(error);
        }
      }
        export async function verifyAndAcceptInvitation (token, tokenTologinAccount){
          try{
            let response;           
            // console.log(payload);
            const refreshToken = cookies.get("refreshToken");
            const accessToken = tokenTologinAccount?.length > 0 ? tokenTologinAccount : cookies.get("accessToken");
            const config = {
              headers: { 
                'Accept': 'application/json',
                'Authorization': `Bearer ${accessToken}`,
                'X-API-Key': apiKey
              }
            };
            const payload = {
              token: token,
              accessToken:accessToken,
              refreshToken:refreshToken
            };
            if (typeof token === 'undefined' || (payload?.token && payload.token.length < 1)) {
              return;
            }
            response = await axios.post(verifyAndAcceptUrl,payload,config);
            if(response.data.statusCode === 457){
              const status = await regenerateTokens();
              // on successfull generation of token
              if(status === 200){
                response = await verifyAndAcceptInvitation();
              }
              else{
                await userLogout();
              }
            }
            else if(response.data.statusCode === 455){
              await userLogout();
            }
            else if (response.data.statusCode === 453){
              await getUserCards();
            }
            return response.data;
          }
          catch(error){
            console.log(error);
          }
}

export async function SwitchAccountUser (navigate,accountId){
  const accessToken = getAccessTokenCookie();
  try{
    let response;
    const config = {
      headers: { 
        'Accept': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
        'X-API-Key': apiKey
      }
    };
    const payload ={accountId:accountId}
    // console.log(payload);
    response = await axios.post(switchAccountUserUrl,payload,config);
    if (response.data.statusCode ===200){
      toast.success("You have switched the account.",{position:toast.POSITION.BOTTOM_LEFT})
      setAccessTokenCookie(response.data.result.tokens.accessToken);
      setRefreshToken(response.data.result.tokens.refreshToken);
      const userDetails = response.data.result.userDetails
      const userDetailsString = JSON.stringify(userDetails);
      setUserDetails(userDetailsString);
      Mixpanel.identify(userDetails.userid);
      // Mixpanel.track("Login Successful",{"status": "Success"});
      sendLoginDataToCrisp(userDetails);
      setTimeout( () => {
        window.location.reload()
    }, 500);
    }
    else if (response.statusCode ===500){
      toast.error("Unable to switch account.",{position:toast.POSITION.BOTTOM_LEFT})
    }
    if(response.data.statusCode === 457){
      const status = await regenerateTokens();
      // on successfull generation of token
      if(status === 200){
        response = await SwitchAccountUser();
      }
      else{
        await userLogout();
      }
    }
    else if(response.data.statusCode === 455){
      await userLogout();
    }
    else if (response.data.statusCode === 453){
      await getUserCards();
    }
    return response;
  }
  catch(error){
    // console.log(error);
  }
}

export async function CreateAccountUser (navigate,userId){
  const accessToken = getAccessTokenCookie();
  try{
    let response;
    const config = {
      headers: { 
        'Accept': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
        'X-API-Key': apiKey
      }
    };
    // console.log(payload);
    const payload  = {userId:userId};
    response = await axios.post(createAccountUserUrl,payload,config);
    if(response.data.statusCode === 457){
      const status = await regenerateTokens();
      // on successfull generation of token
      if(status === 200){
        response = await CreateAccountUser();
      }
      else{
        await userLogout();
      }
    }
    else if(response.data.statusCode === 455){
      await userLogout();
    }
    else if (response.data.statusCode === 453){
      await getUserCards();
    }
    return response;
  }
  catch(error){
  }
}

export async function ChangeUserRole (changeType, userId, changeToRoleEnum,changeToStatusEnum){
  const accessToken = getAccessTokenCookie();
  try{
    let response;
    const config = {
      headers: { 
        'Accept': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
        'X-API-Key': apiKey
      }
    };
    const payload = {
      changeType: changeType,
      UserIdToChange: userId,
      changeToRoleEnum: changeToRoleEnum,
      changeToStatusEnum: changeToStatusEnum
    };
    response = await axios.post(changeUserRoleUrl,payload,config);
    if(response.data.statusCode === 457){
      const status = await regenerateTokens();
      // on successfull generation of token
      if(status === 200){
        response = await ChangeUserRole();
      }
      else{
        await userLogout();
      }
    }
    else if(response.data.statusCode === 455){
      await userLogout();
    }
    else if (response.data.statusCode === 453){
      await getUserCards();
    }
    return response.data;
  }
  catch(error){
    // console.log(error);
  }

}

export async function UpdateAccontInfo ({accountName, website}){
  const accessToken = getAccessTokenCookie();
  try{
    let response;
    const config = {
      headers: { 
        'Accept': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
        'X-API-Key': apiKey
      }
    };
    const payload = {
      AccountName: accountName,
      AccountWebsiteName: website,
    };
    response = await axios.post(updateAccountDetailUrl,payload,config);
    if(response.data.statusCode === 457){
      const status = await regenerateTokens();
      // on successfull generation of token
      if(status === 200){
        response = await UpdateAccontInfo(accountName, website);
      }
      else{
        await userLogout();
      }
    }
    else if(response.data.statusCode === 455){
      await userLogout();
    }
    else if (response.data.statusCode === 453){
      await getUserCards();
    }
    return response.data;
  }
  catch(error){
    
  }

}