import React from 'react';
import './login.component.scss';
import 'react-toastify/dist/ReactToastify.css';


export default function ForgotEmailSent({userEmail,onResend}) {

  const handleResendForgotPasswordLink = async() => {
    onResend(userEmail);
  }

  return(
    <div className="forget-link-sent">

      <div className="text-center mb-3">
        <img src={require("../../../assets/img/Success.svg").default} alt="Success" />
      </div>
        
      <div className="text-center font-18 dark-text poppins-semibold mb-2">
        Check Your Email.
      </div>
      <p className="text-center font-14 grey-text mw-388 pt-1 poppins-regular">We have sent a password reset link to your email ID &nbsp; 
      <span className=" poppins-medium">{userEmail}</span></p>
      <div className="font-14 poppins-regular text-center mt-3">
        Didn’t receive the email?
          <span className="primary-blue-text poppins-medium ms-1 cursor-pointer" onClick={handleResendForgotPasswordLink}>
          Try Again
          </span>
        </div>
      </div>
  )
}


