import React from 'react';
import { Link } from 'react-router-dom';
import './emailverification.component.scss';


const VerifiedEmail = ({messHead,messDescription}) => {

  return(
    <div className="verify-sucess">

              <div className="text-center mb-3">
                <img src={require("../../../assets/img/Success.svg").default} alt="Success" />
                </div> 
                <div className="text-center font-18 dark-text poppins-semibold mb-2">
                {messHead}
                </div>
                <p className="text-center font-14 grey-text mw-388 pt-1 poppins-regular">{messDescription}</p>
                <div className="text-center mt-3">
                <Link to="/">
                  <button className="btn blue-btn-primary w-100 mb-3">Continue to Login</button>
                </Link>
                </div>
    </div>
  );
};

export { VerifiedEmail };



