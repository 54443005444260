import React from 'react';
import { Link } from 'react-router-dom';
import './paymentsuccess.scss'
import Modal from 'react-bootstrap/Modal';


const PaymentChecking = () => {
  return(
    <>
    <Modal
            show={true}
            size="sg"
            aria-labelledby="contained-modal-title-vcenter"
            // centered
        >
            <Modal.Body>
            <div className="verify-sucess d-flex justify-content-center align-items-center">
              <div className='text-center'>
                <div className="spinner-3 mb-5 m-auto"></div>
                <div className='light-grey-text lato-regular'>Checking payment confirmation, please wait...</div>
              </div>
            </div>
            </Modal.Body>
    </Modal>

            
    </>

  );
};

export { PaymentChecking };