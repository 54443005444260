import { useRef, useState } from "react";
import FieldMappingModal from "./field-mapping.modal";
import IntegrationService from "../../../../services/integrationservice";
import { toast } from "react-toastify";
import ConfirmationModal from "../../../../shared/confirmation-modal.component";
import { Tooltip } from "react-tooltip";
import { MappingStatus } from "../../integrations-constants";

function getClassBySyncDirection(direction) {
  if (direction === 1) {
    return "fa-solid fa-arrow-right-long";
  } else if (direction === 2) {
    return "fa-solid fa-arrow-left-long";
  } else {
    return "fa-sharp fa-solid fa-right-left";
  }
}

export default function FieldMappingRow({
  fieldMapping,
  crmFieldOptions,
  handleFieldMappingUpdate,
  fieldValidationObj,
  selectedObjectIds,
  hideSyncColumn = false,
}) {
  const [isDeleteButtonLoading, setIsDeleteButtonLoading] = useState(false);
  const filteredCrmFields = fieldMapping.crmFields.filter((f) =>
    selectedObjectIds.includes(f.objectTypeId)
  );

  const fieldMappingModalRef = useRef();
  const confirmationModalRef = useRef();

  const handleEdit = () => {
    fieldMappingModalRef.current.show();
  };

  async function handleDelete(showModal = true) {
    const mappingIds = filteredCrmFields
      .filter((f) => f.fieldMappingId)
      .map((f) => f.fieldMappingId);

    if (!mappingIds.length) {
      return;
    }

    if (showModal) {
      confirmationModalRef.current.show();
      return;
    }

    setIsDeleteButtonLoading(true);

    try {
      const response = await IntegrationService.DeleteMapping(mappingIds);

      fieldMapping.crmFields.forEach((f) => {
        f.crmFieldName = null;
        f.crmFieldId = null;
        f.crmFieldType = null;
      });
      handleFieldMappingUpdate();
      setIsDeleteButtonLoading(false);
      confirmationModalRef.current.hide();
      toast.success(response?.message ?? "Field mapping deleted successfully.");
    } catch (error) {
      setIsDeleteButtonLoading(false);
      toast.error(
        error?.message ?? "Failed to delete mapping, please try again."
      );
    }
  }

  function handleUpdate(value = null) {
    if (value) {
      handleFieldMappingUpdate();
    }
  }

  return (
    <>
      <FieldMappingModal
        ref={fieldMappingModalRef}
        fieldMapping={fieldMapping}
        crmFieldOptions={crmFieldOptions}
        handleUpdate={handleUpdate}
        fieldValidationObj={fieldValidationObj}
        selectedObjectIds={selectedObjectIds}
      />
      <tr>
        <td>{fieldMapping.pvlFieldName}</td>
        {hideSyncColumn ? undefined : (
          <td className="text-center">
            <span className="sync-icon">
              <i
                className={getClassBySyncDirection(fieldMapping.syncDirection)}
              ></i>
            </span>
          </td>
        )}
        {filteredCrmFields.map((f) => (
          <td
            className={
              f.mappingStatus === MappingStatus.Disabled ? "disable-cell" : ""
            }
          >
            <Tooltip id="fieldmapping" />
            {!f.crmFieldName ||
            !Array.isArray(f.crmFieldName) ||
            f.crmFieldName?.length <= 1 ? (
              <div className="d-flex justify-content-between align-items-center">
                {f.crmFieldName || "-"}
                {f.mappingStatus === MappingStatus.Disabled && (
                  <i
                    class="fa-solid fa-triangle-exclamation text_error_message"
                    data-tooltip-content="CRM field disabled"
                    data-tooltip-id="fieldmapping"
                  ></i>
                )}
              </div>
            ) : (
              <div className="d-flex justify-content-between align-items-center">
                <span className="d-flex align-items-center gap-2 flex-wrap py-2">
                  {f.crmFieldName?.map((f) => (
                    <span key={f} className="ph-loc">
                      {f}
                    </span>
                  ))}
                </span>
                {f.mappingStatus === MappingStatus.Disabled && (
                  <i
                    class="fa-solid fa-triangle-exclamation text_error_message"
                    data-tooltip-content="CRM field disabled"
                    data-tooltip-id="fieldmapping"
                  ></i>
                )}
              </div>
            )}
          </td>
        ))}
        <td className="text-end">
          {!fieldValidationObj?.canBeDeleted ? undefined : (
            <i
              className="fa-regular fa-trash-alt pink-text cursor-pointer"
              onClick={handleDelete}
            ></i>
          )}
          {!fieldValidationObj?.canBeEdited ? undefined : (
            <i
              className="fa-regular fa-pen six-c-7 cursor-pointer ms-3"
              onClick={handleEdit}
            ></i>
          )}
        </td>
      </tr>
      <ConfirmationModal
        ref={confirmationModalRef}
        title="Confirm Delete"
        iconClass="far fa-trash-alt"
        message="Are you sure you want to delete the mapping ?"
        confirmButtonText="Delete"
        disabled={isDeleteButtonLoading}
        onConfirm={() => handleDelete(false)}
      />
    </>
  );
}
