import { useEffect, useState } from "react";
import FieldMappingRow from "./field-mapping-row.component";
import IntegrationService from "../../../../services/integrationservice";
import { toast } from "react-toastify";
import { transformFieldMappings } from "../../integrations-constants";
import { toTitleCase } from "../../../../utils/helper.utils";
import { useOutletContext } from "react-router-dom";
import LoadingAnimation from "../../../lottie-files/loading.lotte";

export default function ConfigureActivity() {
  const [isLoading, setIsLoading] = useState(false);
  const [fieldMappings, setFieldMappings] = useState([]);
  const [crmFieldOptions, setCrmFieldOptions] = useState({}); // It will be a object with key = "ObjectTypeId" and value = "Field[]"

  const {
    integration,
    configuration,
    setIntegration,
    goToNextStep,
    goBack,
    currentStepIndex,
  } = useOutletContext();

  const [recordActivity, setRecordActivity] = useState(
    JSON.parse(integration.activityConfig)?.activityenabled ?? false
  );

  const [fieldValidationObj, setFieldValidationObj] = useState(null);
  const selectedObjectIds = [
    configuration.defaultConfig.activityDetails.objectType,
  ];

  async function fetchFieldMappings() {
    try {
      const fMappings = await IntegrationService.FieldMappings(
        integration.integrationId,
        configuration.crmConfigurationId,
        configuration.defaultConfig.activityDetails.objectType
      );

      const transformedMappings = transformFieldMappings(fMappings);
      if (!fieldValidationObj) {
        prepareFieldValidationObj([...transformedMappings]);
      }
      setFieldMappings([...transformedMappings]);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error("Failed to fetch integrations");
    }
  }

  function getCrmFieldsByObjectType() {
    const crmObjectName =
      configuration.defaultConfig.typeMappings.fieldmappingsapi.find(
        (f) => f.pvlName === configuration.defaultConfig.activityDetails.name
      )?.crmName;

    const configObj = configuration.uiConfig.uiproperties.configureactivity;

    const options = { ...crmFieldOptions };
    IntegrationService.CrmFields(
      crmObjectName,
      integration.integrationId,
      configuration.name
    ).then((result) => {
      options[configuration.defaultConfig.activityDetails.objectType] = result
        .filter(
          (item) =>
            !configObj.fieldmappings?.typestobeexcluded?.includes(item.type) ??
            true
        )
        .map((item) => ({
          id: item.id,
          text: item.name,
          additional: item,
        }));
      setCrmFieldOptions(options);
    });
  }

  useEffect(() => {
    setIsLoading(true);
    fetchFieldMappings();
    getCrmFieldsByObjectType();
  }, []);

  function handleFieldMappingUpdate() {
    fetchFieldMappings();
  }

  async function updateActivityRecordStatus(value) {
    try {
      await IntegrationService.toggleRecordActivity(
        integration.integrationId,
        value
      );

      setRecordActivity(value);
      setIntegration({
        ...integration,
        activityConfig: JSON.stringify({ activityenabled: value }),
      });
    } catch (error) {
      setRecordActivity(!value);
      toast.error(
        error?.message ?? "Failed to delete mapping, please try again."
      );
    }
  }

  function prepareFieldValidationObj(fMappings) {
    const _validationObj = {};

    const configObj =
      configuration.uiConfig.uiproperties.configureactivity.fieldmappings;

    if (configObj) {
      fMappings.forEach((f) => {
        const multipleSelectionObj = configObj?.pvlfieldswithmultipletrue?.find(
          (m) => +m.fieldid === +f.pvlFieldId
        );

        _validationObj[f.pvlFieldId] = {
          canBeDeleted:
            !configObj?.pvlidscannotbedeleted?.includes(f.pvlFieldId) ?? true,
          canBeEdited:
            !configObj?.pvlidscannotbeedited?.includes(f.pvlFieldId) ?? true,
          validation:
            configObj?.validations.find(
              (v) => v.pvlfieldtypeid === f.pvlFieldId
            ) ?? null,
          multipleSelection: !!multipleSelectionObj,
          maxSelection: multipleSelectionObj?.maxselect ?? null,
          minSelection: multipleSelectionObj?.minselect ?? null,
        };
      });
    }

    setFieldValidationObj(_validationObj);
  }

  return isLoading ? (
    <div className="loading-animation">
      <LoadingAnimation />
      <span>Loading data, please wait...</span>
    </div>
  ) : (
    <div id="ConfigureActivity">
      {configuration.uiConfig.uiproperties.configureactivity
        .configureactivity ? (
        <div className="lato-medium mt-4 d-flex align-items-center">
          <div className="switch-wrapper d-inline-flex align-items-center me-2">
            <label className="switch mb-0">
              <input
                type="checkbox"
                id={`integration-${configuration.crmConfigurationId}-check`}
                checked={recordActivity}
                onChange={() => updateActivityRecordStatus(!recordActivity)}
              />
              <span className="slider round"></span>
            </label>
          </div>
          Record Activity in {toTitleCase(configuration.name)} after completing
          phone verification
        </div>
      ) : undefined}

      <div className="mt-4 mb-3 ms-5">
        <span>
          <i className="fa-regular fa-left-right six-c-7"></i>
        </span>
        <span className="lato-bold dark-text ms-2">
          Map CRM activity fields with SureConnect fields
        </span>
        <div className="lato-medium">
          See how activity will be recorded after phone number verification in
          CRM.
        </div>
      </div>
      <div
        className="table-responsive mb-5 ms-5 colored-table td-transparent w-100"
        style={{ maxWidth: "600px" }}
      >
        <table className="table table-bordered">
          <thead>
            <tr>
              <th
                style={{ minWidth: "108px", width: "100%", maxWidth: "250px" }}
              >
                SureConnect Field
              </th>
              {/* <th className="text-center">Sync</th> */}
              <th
                style={{
                  minWidth: "150px",
                  width: "100%",
                }}
              >
                CRM Field
              </th>
              <th
                style={{ minWidth: "100px", width: "100%", maxWidth: "100px" }}
              ></th>
            </tr>
          </thead>
          <tbody>
            {fieldMappings.map((mapping, index) => (
              <FieldMappingRow
                key={mapping.pvlFieldId}
                fieldMapping={mapping}
                crmFieldOptions={crmFieldOptions}
                handleFieldMappingUpdate={handleFieldMappingUpdate}
                fieldValidationObj={fieldValidationObj[mapping.pvlFieldId]}
                selectedObjectIds={selectedObjectIds}
                hideSyncColumn={true}
              />
            ))}
          </tbody>
        </table>
      </div>
      <div className="d-flex align-items-center gap-2 ms-5">
        {currentStepIndex > 0 ? (
          <button
            type="button"
            className="btn blue-btn-primary font-12 small-btn go-back-btn"
            onClick={goBack}
          >
            <i class="fa-solid fa-arrow-left me-2"></i>Back
          </button>
        ) : undefined}
        <button
          type="button"
          className="btn blue-btn-primary font-12 small-btn"
          onClick={goToNextStep}
        >
          Next
        </button>
      </div>
    </div>
  );
}
