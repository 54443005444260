import React from "react";
import { useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import './subscription.component.scss'
import { NavLink } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { getCurrentCredits, GetPermission, getTierDetails, updateSubscription } from "../../../apis/pvlapis";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faCircleExclamation, faCircleInfo, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import BuySubscriptionOrCredits from "../billing/buycredits.component";
import { Tooltip } from 'react-tooltip';
import LoadingAnimation from "../lottie-files/loading.lotte";
import Sidebar from "../side-bar.component";
import PermissionDenied from "../../shared/permission-denied.component";

const SubscriptionComponent = () => {

    // variable declarations 
    const [creditsAmount, setCreditsAmount] = useState(0);
    const [isSubscribed,setIsSubscribed] = useState(false);
    const [subscriptionStatus, setSubscriptionStatus] = useState(0);
    const [subscriptionId,setSubscriptionId] = useState("");
    const [isEditSubscription, setIsEditSubscription] = useState(false);
    const [openSubscriptionModal, setOpenSubscriptionModal] = useState(false);
    const [subscriptionStartDate, setSubscriptionStartDate] = useState(null);
    const [subscriptionEndDate, setSubscriptionEndDate] = useState(null);
    const [numberOfUser, setNumberOfUser] = useState(1);
    const [selectedPlan, setSelectedPlan] = useState('year');
    const [subscriptionType , setSubscriptionType] = useState(3);
    const [selectedReason, setSelectedReason] = useState('');
    const [textareaValue, setTextareaValue] = useState('');
    const [show, setShow] = useState(false);
    const [showCancellationReason, setShowCancellationReason] = useState(false);
    const [showCancellationConfirmation, setShowCancellationConfirmation] = useState(false);
    const [showRevertScheduleCancellation, setShowRevertScheduleCancellation] = useState(false);
    const [confirmText, setConfirmText] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [isConfirmationLoading, setIsConfirmationLoading] = useState(false);
    const [disablePurchaseButton, setDisablePurchaseButton] = useState(false);
    const [scheduleChangeRevertType, setScheduleChangeRevertType] = useState('cancel_cancellation');
    const [isPermissionLoading, setIsPermissionLoading] = useState(false);
    const [showSubscriptionTab, setShowSubscriptionTab] = useState(null);


    const tiers = getTierDetails();
    const subscriptionPlan = tiers.tierJSON.subscription;
    // console.log(subscriptionPlan);
    const reasons = [
        "Connect rates didn’t improve",
        'Price too high',
        'Missing features',
        "Switching to another provider",
        "Others (please share more details)"
    ];
    
    const [planDetails, setPlanDetails] = useState([
        { id: 1, name: 'Users', value: '2 users', className: 'del-style', planChangeText: '1 user' },
        { id: 2, name: 'Renewal amount', value: '$298', className: 'del-style', planChangeText: '$149' },
        { id: 3, name: 'Billing', value: 'Monthly', className: '', planChangeText: '' },
        { id: 4, name: 'Credits', value: '500/month', className: '', planChangeText: '' },
        { id: 5, name: 'Plan start date', value: '1 Jun, 2024', className: '', planChangeText: '' },
      ]);
    
    const selectedplanDetails = planDetails.slice(0, 2);

    const NotSubscribed = [
        { id: 1, name: `${subscriptionPlan['month'].credits} monthly Credits` },
        { id: 2, name: 'API Integrations' },
        { id: 3, name: 'CSV Upload' },
        { id: 4, name: 'Salesforce' },
        { id: 5, name: 'Google Sheets' },
        { id: 6, name: 'Hubspot' },
    ];


    useEffect(() => {
        handleGetPermission();
        handleGetCredits();
      }, []);

    
    // handle Methods  
    
    const handleReasonChange = (event) => {
        const reason = event.target.value;
        setSelectedReason(reason);
    };
    const handleTextareaChange = (event) => {
        setTextareaValue(event.target.value);
    };

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleClose2 = () => setShowCancellationReason(false);
    const handleShowCancellationReason = () => {
        setShowCancellationReason(true);
        setShowCancellationConfirmation(false);
    }

    const handleConfirmTextChange = (event) => {
        setConfirmText(event.target.value);
      };

    const handleClose3 = () => setShowCancellationConfirmation(false);
    const handleShowCancellationConfirmation = () => {
        // console.log(selectedReason);
        if(selectedReason.length<1){
            toast.warning('Please select a reason before proceeding.',{position:toast.POSITION.BOTTOM_LEFT});
            return;
        }
        if(textareaValue.length<5){
            toast.warning('Reason length should be more than 5 characters.',{position:toast.POSITION.BOTTOM_LEFT});
            return;
        }
        setShowCancellationConfirmation(true);
        setShowCancellationReason(false);
    }

    const handleshowRevertScheduleCancellationOpen = (inputType) =>{
        setShowRevertScheduleCancellation(true);
        setScheduleChangeRevertType(inputType);
    }
    const handleshowRevertScheduleCancellationClose = () =>{
        setShowRevertScheduleCancellation(false);
    }

    const handleCloseSubscription = () => {
        setIsEditSubscription(false);
        setOpenSubscriptionModal(false);
    }
    const handleOpenSubscription = (isEdit) => {
        
        setIsEditSubscription(false);
        if(isEdit){
            setIsEditSubscription(true);
        }
        setOpenSubscriptionModal(true);
    }

    const handleGetCredits = async () => {
        try {
            setIsLoading(true);
            const response = await getCurrentCredits();
            // console.log(response)
            if (response.data.statusCode === 200) {
                setCreditsAmount(response.data.result['credit_details'].credit_balance);
                setIsSubscribed(response.data.result.subscription_details.flag_subscription_present);
                // console.log(response.data.result.subscription_details.flag_subscription_present);
                if(response.data.result.subscription_details.flag_subscription_present){
                    setSubscriptionStatus(response.data.result.subscription_details.subscription_status);
                    setSubscriptionId(response.data.result.subscription_details.subscription_id);
                    setSubscriptionStartDate(response.data.result.subscription_details.start_date);
                    setSubscriptionEndDate(response.data.result.subscription_details.end_date);
                    setSubscriptionType(response.data.result.subscription_details.subscription_type);
                    const typeOfSubscription = response.data.result.subscription_details.subscription_type===0?'month':
                        response.data.result.subscription_details.subscription_type===3?'year':'';
                    setSelectedPlan(typeOfSubscription);
                    setNumberOfUser(response.data.result.subscription_details.quantity);
                    const details = response.data.result.subscription_details;


                    setPlanDetails(prevPlanDetails =>
                        prevPlanDetails.map(detail =>{
                            // extract the current subscription details
                            if (detail.name === 'Users') {
                                const scheduledusers = details.scheduled_metadata?details.scheduled_metadata.new_quantity:null
                                return { ...detail, value: details.quantity, planChangeText: scheduledusers};
                            } 
                            else if (detail.name === 'Renewal amount') {
                                const renewalAmount = details.subscription_type === 0 ? subscriptionPlan[typeOfSubscription].cost * details.quantity:
                                    details.subscription_type === 3 ? subscriptionPlan[typeOfSubscription].cost * 12 * details.quantity : 0;
                                const scheduledamount = details.scheduled_metadata?details.scheduled_metadata.renewal_amount:null
                                return { ...detail, value: `$${renewalAmount}`, planChangeText: scheduledamount };
                            } 
                            else if (detail.name === 'Billing') {
                                return { ...detail, value: details.subscription_type===0?'Monthly':details.subscription_type===3?'Yearly':'' };
                            } 
                            else if (detail.name === 'Credits') {
                                const credits = subscriptionPlan[typeOfSubscription].credits * subscriptionPlan[typeOfSubscription].multiplier * details.quantity;
                                const scheduledcredits = details.scheduled_metadata?details.scheduled_metadata.renewal_credits:null;
                                const value = details.subscription_type === 0 ? `${credits}/month`: details.subscription_type === 3 ? `${credits}/year`: '';
                                const planChangeText = details.scheduled_metadata ? (details.subscription_type === 0 ? `${scheduledcredits}/month`: details.subscription_type === 3 ? `${scheduledcredits}/year`: ''):null;
                                return { ...detail, value: value, planChangeText: planChangeText };
                            } 
                            else if (detail.name === 'Plan start date') {
                                return { ...detail, value: formatDate(details.start_date) };
                            }
                            return detail;     }                   )
                      );
                }
                // console.log(response.data.result['credit_details'].credit_balance);
            }
        }
        catch  (error) {
                toast.error("Something went wrong. Please try again after some time.",{position:toast.POSITION.BOTTOM_LEFT})
        }
        finally{
            setIsLoading(false);
        }
    }

    const handleUpdateSubscription = async (subscriptionAction) =>{

        if(subscriptionId.length<1){
            toast.error('No Subscription.');
            return;
        }

        if (subscriptionAction === 'cancellation' && confirmText !== 'CANCEL') {
            toast.error('Please type "CANCEL" to confirm.');
            return;
        }
        setIsConfirmationLoading(true);
        setDisablePurchaseButton(true);
        
        try {
            const payload =  {
                "updatetype": subscriptionAction,
                "updatemetadata": {
                    "cancellationreason": {
                        "reason": selectedReason,
                        "feedback": textareaValue
                        },
                }
                
            } 
            // console.log(payload);
            const response = await updateSubscription(payload);
            if (response.data.statusCode === 200) {
                setTimeout( () => {
                    window.location.reload()
                }, 2000); // 2-second delay
            }
            else{
                setTimeout(() => {
                    window.location.reload()
                }, 2000); // 2-second delay
            }
        }  
        catch  (error) {
            toast.error("Something went wrong. Please try again after some time.",{position:toast.POSITION.BOTTOM_LEFT})
        }
        finally{
            setIsConfirmationLoading(false);
            setDisablePurchaseButton(false);
            setShowCancellationConfirmation(false);
            setShowRevertScheduleCancellation(false);
            setSelectedReason(null);
            setTextareaValue('');
        }
        

    }

    // Method
    // Function to format the date
    const formatDate = (datetime) => {
        const date = new Date(datetime);
        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'short' });
        const year = date.getFullYear();
        
        // Get the ordinal suffix for the day
        const ordinalSuffix = (d) => {
            if (d > 3 && d < 21) return 'th'; // catch 11th-19th
            switch (d % 10) {
            case 1:  return 'st';
            case 2:  return 'nd';
            case 3:  return 'rd';
            default: return 'th';
            }
        };
        
        return `${day}${ordinalSuffix(day)} ${month} ${year}`;
    };  

    const handleGetPermission = async() =>{
        setIsPermissionLoading(true);
  
        try {
            const response = await GetPermission();
            if(response.statusCode !==200 || response?.result === null){
                return;
            }
            const subscriptionView = response?.result?.find(obj => obj?.component === "settingsviewsubscription");
  
            setShowSubscriptionTab(subscriptionView?.permission);
        } catch (error) {
            toast.error("Something went wrong. Please try again after some time.",{position:toast.POSITION.BOTTOM_LEFT})
        }
        finally{
          setIsPermissionLoading(false);
        }
        
    }

    return (<>
        {
            openSubscriptionModal && (
                <BuySubscriptionOrCredits onClose={handleCloseSubscription} checkoutType={'subscription'} isEditSubscription={isEditSubscription} 
                    subscriptionId={subscriptionId} subscriptionStatus={subscriptionStatus} subscriptionType={subscriptionType} 
                    subscribedUser={numberOfUser} subscriptionEndDate={subscriptionEndDate} formatDate={formatDate}/>
            )
        }
        <Container fluid className="main-page-wrap">
            <Row>
                <Col>
                    <div className="d-flex align-items-center justify-content-between page-header py-3">
                        <span class="font-18 dark-text poppins-semibold">Settings</span>
                    </div>
                </Col>
            </Row>
            <Row className="mt-4">
            <Col sm={3}>
                <Sidebar/>
            </Col>
                {showSubscriptionTab &&!isPermissionLoading && 
                <Col sm={9}>
                    <div className="profile-card mb-4">
                       
                        {!isLoading &&
                        <>
                            <div className="card-title">Your Subscription</div>
                            {/* Active Plan */} 
                            {isSubscribed && ([0, 1, 5, 6, 4].includes(subscriptionStatus)) &&<div className="card p-24px mx-w-500 mt-3">
                                {subscriptionStatus===1&&<span className="plan-status-tag green">Active Plan</span>}
                                {subscriptionStatus===0&&<>
                                    <div>
                                        <span className="plan-status-tag orange">Payment Pending</span>
                                        <FontAwesomeIcon icon={faCircleInfo}  className="icon-spacing" data-tooltip-id="infoTooltip" data-tooltip-content="You have subscribed but payment is still under processing." />
                                        <Tooltip id="infoTooltip" place="top" effect="solid" />
                                    </div>   
                                </>}
                                {subscriptionStatus===5&&<>
                                    <div>
                                        <span className="plan-status-tag orange">Cancellation Request Pending</span>
                                        <FontAwesomeIcon icon={faCircleInfo}  className="icon-spacing" data-tooltip-id="infoTooltip" data-tooltip-content="Your subscription cancellation request is under processing." />
                                        <Tooltip id="infoTooltip" place="top" effect="solid" />
                                    </div>   
                                </>}
                                {subscriptionStatus===6&&<>
                                    <div>
                                        <span className="plan-status-tag orange">Revert Cancellation Request Pending</span>
                                        <FontAwesomeIcon icon={faCircleInfo}  className="icon-spacing" data-tooltip-id="infoTooltip" data-tooltip-content="Your request for withdrawl of cancellation of subscription is under processing." />
                                        <Tooltip id="infoTooltip" place="top" effect="solid" />
                                    </div>   
                                </>}
                                {subscriptionStatus===4&&<>
                                    <div>
                                        <span className="plan-status-tag red">Past Due</span>
                                        <FontAwesomeIcon icon={faCircleInfo}  className="icon-spacing" data-tooltip-id="infoTooltip" data-tooltip-content="Payment overdue. Subscription will end soon." />
                                        <Tooltip id="infoTooltip" place="top" effect="solid" />
                                    </div>   
                                </>}

                                {/*  */}
                                <div className="d-flex justify-content-between align-items-center my-4">
                                    {/*  */}
                                    <div className="d-flex">
                                        <div>
                                            <img src={require("../../../../assets/img/proIcon.svg").default} alt="sureConnect" />
                                        </div>
                                        <div className="ms-2">
                                            <div><img src={require("../../../../assets/img/proText.svg").default} alt="sureConnect" /></div>
                                            <div className="font-12 mt-1">Renews on {formatDate(subscriptionEndDate)}</div>
                                        </div>
                                    </div>
                                    {/*  */}
                                    <div>
                                        <span className="font-20 poppins-bold dark-text">${subscriptionPlan[selectedPlan].cost}</span><span>/mo {numberOfUser>1&&<span>/user</span>}</span>
                                    </div>
                                </div>
                                {/*  */}
                                <div className="responsive-table">
                                    <table className="w-100">
                                        <tbody>
                                        {planDetails.map(item => (
                                            // ((item.name==='Users' && item.value>1)||item.name!=='Users') && ( 
                                            <tr key={item.id}>
                                                <td width={150} className="default-text">
                                                {item.name}:
                                                </td>
                                                <td className="lato-bold dark-text">
                                                {item.value}
                                                </td>
                                            </tr>
                                            // )
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                                {/*  */}
                                <div className="my-2"><a href="https://sureconnect.ai/pricing" target="_blank" className="blue-link poppins-medium font-12">View plan details <FontAwesomeIcon icon={faAngleRight} /></a></div>
                                <hr />
                                {(subscriptionStatus ===1 ||subscriptionStatus===4)&& <div className="d-flex align-items-center justify-content-between">
                                    {<button className="btn generate-api-btn small-btn" onClick={()=>handleOpenSubscription(true)}>Edit Plan</button>}
                                    <span className="poppins-medium grey-text font-12 cursor-pointer" onClick={handleShowCancellationReason}>Cancel Plan</span>
                                </div>}
                            </div>
                            }

                            {/* Cancellation Plan Change */}
                            {isSubscribed && subscriptionStatus === 2 &&<div className="card p-24px mx-w-500 mt-3">
                                <span className="plan-status-tag orange">Cancellation Scheduled</span>
                                {/*  */}
                                <div className="d-flex justify-content-between align-items-center my-4">
                                    {/*  */}
                                    <div className="d-flex">
                                        <div>
                                            <img src={require("../../../../assets/img/proIcon.svg").default} alt="sureConnect" />
                                        </div>
                                        <div className="ms-2">
                                            <div><img src={require("../../../../assets/img/proText.svg").default} alt="sureConnect" /></div>
                                            <div style={{ color: '#D26220', fontSize: '12px' }}>Your plan will expire on <span className="lato-bold">{formatDate(subscriptionEndDate)}</span> <i class="fa-regular fa-circle-info ms-1"></i>
                                            </div>
                                        </div>
                                    </div>
                                    {/*  */}
                                    <div>
                                        <span className="font-20 poppins-bold dark-text">${subscriptionPlan[selectedPlan].cost}</span><span>/mo {numberOfUser>1&&<span>/user</span>}</span>
                                    </div>
                                </div>
                                {/*  */}
                                <div className="responsive-table">
                                    <table className="w-100">
                                        {planDetails.map((item) => (
                                            ((item.name==='Users' && item.value>1)||item.name!=='Users' ) &&(<tr>
                                                <>
                                                    <td width={150} key={item.id}>
                                                        {item.name}:
                                                    </td>
                                                    <td className='lato-bold dark-text' key={item.id}>
                                                        <span >{item.value}</span>
                                                        {/* {
                                                            (item.planChangeText) &&
                                                            (<>
                                                                <span style={{ color: '#D26220' }} className="ms-2">$0</span>
                                                                <span>  <div style={{ backgroundColor: 'white', padding: '5px', borderRadius: '50%', display: 'inline-block' }}>
                                                                    <FontAwesomeIcon icon={faCircleInfo} className="fa-sharp fa-light fa-circle-info light-grey-text font-12 cursor-pointer" />
                                                                    </div>
                                                                </span>
                                                            </>)
                                                            
                                                            
                                                        } */}
                                                        {/* {item.name==='Billing' &&
                                                        <>
                                                        <span>  <div style={{ backgroundColor: 'white', padding: '5px', borderRadius: '50%', display: 'inline-block' }}>
                                                                    <FontAwesomeIcon icon={faCircleInfo} className="fa-sharp fa-light fa-circle-info light-grey-text font-12 cursor-pointer" />
                                                                    </div>
                                                                </span></>} */}
                                                    </td>
                                                </>
                                            </tr>)
                                        ))}
                                    </table>
                                </div>
                                {/*  */}
                                <div className="my-2"><a href="https://sureconnect.ai/pricing" target="_blank" className="blue-link poppins-medium font-12">View plan details <FontAwesomeIcon icon={faAngleRight} /></a></div>
                                <hr />
                                <div className="d-flex align-items-center justify-content-between">
                                    <button className="btn generate-api-btn small-btn" onClick={()=>handleshowRevertScheduleCancellationOpen('cancel_cancellation')}>Revert Cancel Request</button>
                                </div>
                            </div>}

                            {/* Scheduling or Scheduled Plan Change */}
                            {isSubscribed && [7, 8, 9, 10].includes(subscriptionStatus)&&<div className="card p-24px mx-w-500 mt-3">
                                <span className="plan-status-tag orange">{subscriptionStatus === 10 ?<span>Reverting Scheduled Plan Change</span> :(subscriptionStatus === 8 ?<span>Scheduling Plan Change</span> : <span>Scheduled Plan Change</span>)}</span>
                                {/*  */}
                                <div className="d-flex justify-content-between align-items-center my-4">
                                    {/*  */}
                                    <div className="d-flex">
                                        <div>
                                            <img src={require("../../../../assets/img/proIcon.svg").default} alt="sureConnect" />
                                        </div>
                                        <div className="ms-2">
                                            <div><img src={require("../../../../assets/img/proText.svg").default} alt="sureConnect" /></div>
                                            <div style={{ color: '#D26220', fontSize: '12px' }}>Plan change effective {[8, 9, 10].includes(subscriptionStatus) ? <span className="lato-bold">{formatDate(subscriptionEndDate)}</span> : <b>after Payment</b>}.
                                            </div>
                                        </div>
                                    </div>
                                    {/*  */}
                                    <div>
                                        <span className="font-20 poppins-bold dark-text">${subscriptionPlan[selectedPlan].cost}</span><span>/mo {numberOfUser>1&&<span>/user</span>}</span>
                                    </div>
                                </div>
                                {/*  */}
                                <div className="responsive-table">
                                    <table className="w-100">
                                        {planDetails.map((item) => (
                                        <tr>
                                        <>
                                        <td width={150} key={item.id + "-name"}>
                                            {item.name}:
                                        </td>
                                        <td className='lato-bold dark-text' key={item.id + "-value"}>
                                            
                                            {
                                            (item.planChangeText) &&
                                            (<>
                                                <span className="del-style">{item.value}</span>
                                                <span style={{ color: '#D26220' }} className="ms-2">{item.planChangeText}</span>
                                            </>)
                                            }
                                            {
                                            (!item.planChangeText) && 
                                            (<>
                                                <span >{item.value}</span>
                                            </>
                                            )
                                            }
                                        </td>
                                        </>
                                    </tr>
                                    ))}
                                    </table>
                                </div>
                                {/*  */}
                                <div className="my-2"><a href="https://sureconnect.ai/pricing" target="_blank" className="blue-link poppins-medium font-12">View plan details <FontAwesomeIcon icon={faAngleRight} /></a></div>
                                <hr />
                                <div className="d-flex align-items-center justify-content-between">
                                    {subscriptionStatus !== 10 && <button className="btn generate-api-btn small-btn" onClick={()=>subscriptionStatus === 7 ? handleshowRevertScheduleCancellationOpen('cancel_increment'): handleshowRevertScheduleCancellationOpen('cancel_decrement')}>Revert Change Request</button>}
                                </div>
                            </div>}

                            {/* Not Subscribed */}
                            {(!isSubscribed)&&<div className="card p-24px mx-w-500 mt-3">
                                <span className="plan-status-tag red">Not Subscribed</span>
                                {/*  */}
                                <div className="d-flex justify-content-between align-items-center my-4">
                                    {/*  */}
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <img src={require("../../../../assets/img/proIcon.svg").default} alt="sureConnect" />
                                        </div>
                                        <div className="ms-2">
                                            <img src={require("../../../../assets/img/proText.svg").default} alt="sureConnect" />
                                        </div>
                                    </div>
                                    {/*  */}
                                    <div>
                                        <span className="font-20 poppins-bold dark-text">${subscriptionPlan.year.cost}</span><span>/mo {false&&<span>/user</span>}</span>
                                    </div>
                                </div>

                                <div className="mb-4 grey-text">SureConnect requires an active plan to function. Please subscribe to our monthly or yearly plan to start verifying phone numbers.</div>
                                {/*  */}
                                <div className="responsive-table">
                                    <table width={410}>
                                        <ul className="custom-flex-ul">
                                            {NotSubscribed.map((item) => (
                                                <>
                                                    <li key={item.id}>
                                                        {item.name}
                                                    </li>
                                                </>
                                            ))}
                                        </ul>
                                    </table>
                                </div>
                                {/*  */}
                                <hr />
                                <div className="d-flex align-items-center justify-content-between">
                                    <button className="btn blue-btn-primary small-btn" onClick={()=>handleOpenSubscription(false)}>Subscribe Now</button>
                                </div>
                            </div>}
                            {/* Expired or cancelled subscription */}
                            {(isSubscribed && [3, 12].includes(subscriptionStatus))&&<div className="card p-24px mx-w-500 mt-3">
                                <span className="plan-status-tag red">Expired</span>
                                {/*  */}
                                <div className="d-flex justify-content-between align-items-center mt-4 mb-2">
                                    {/*  */}
                                    <div className="d-flex">
                                        <div>
                                            <img src={require("../../../../assets/img/proIcon.svg").default} alt="sureConnect" />
                                        </div>
                                        <div className="ms-2">
                                            <div><img src={require("../../../../assets/img/proText.svg").default} alt="sureConnect" /></div>
                                            <div className="mt-1" style={{ color: '#EB5757', fontSize: '12px' }}>Your Plan expired on  <span className="lato-bold">{formatDate(subscriptionEndDate)}</span> <i className="fa-regular fa-info-circle six-c-7 ms-1"></i> </div>
                                        </div>
                                    </div>
                                    {/*  */}
                                    <div>
                                        <span className="font-20 poppins-bold dark-text">${subscriptionPlan[selectedPlan].cost}</span><span>/mo {numberOfUser>1&&<span>/user</span>}</span>
                                    </div>
                                </div>
                                {/* <div className="mb-4 grey-text">SureConnect requires an active plan to function. Please subscribe to our monthly or yearly plan to start verifying phone numbers.</div> */}
                                {/*  */}
                                {/* <div className="responsive-table">
                                    <table width={410}>
                                        <ul className="custom-flex-ul">
                                            {NotSubscribed.map((item) => (
                                                <>
                                                    <li key={item.id}>
                                                        {item.name}
                                                    </li>
                                                </>
                                            ))}
                                        </ul>
                                    </table>
                                </div> */}
                                {/*  */}
                                <hr />
                                <div className="d-flex align-items-center justify-content-between">
                                    <button className="btn blue-btn-primary small-btn" onClick={()=>handleOpenSubscription(false)}>Reactivate Your Plan</button>
                                </div>
                            </div>}
                        </>
                        }
                        {
                            isLoading && (
                            <div className="loading-animation">
                                <LoadingAnimation/>
                                <span>
                                Loading data, please wait...
                                </span>
                            </div>
                            )
                        }
                        {/* Modals */}
                        <>
                            {/* <Button variant="primary" onClick={handleShow}>
                                Click here to view plan change scheduled! modal
                            </Button> */}
                            <br />
                            <br />
                            {/* <Button variant="primary" onClick={handleShowCancellationReason}>
                                Click here to view confirm cancellation modal
                            </Button>

                            <br />
                            <Button variant="primary" onClick={handleShowCancellationConfirmation}>
                                COnfirmation model
                            </Button> */}

                            {/* Plan Change Scheduled! Modal */}
                            <Modal show={show} onHide={handleClose} className='modal-no-border'>
                                <Modal.Header closeButton>
                                    <Modal.Title><span className="round-modal-icon orange">
                                        <i className="fa-regular fa-circle-exclamation"></i>
                                    </span>{" "}
                                        Plan Change Scheduled!</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div>You have requested to make the following changes to your plan at the end of this billing cycle. Please cancel your request first if you would like to edit this plan.</div>
                                    <div className="responsive-table">
                                        <table className="w-100">
                                            {selectedplanDetails.map((item) => (
                                                <tr>
                                                    <>
                                                        <td width={150} key={item.id}>
                                                            {item.name}:
                                                        </td>
                                                        <td className='lato-bold dark-text' key={item.id}>
                                                            <span className={item.className}>{item.value}</span>
                                                            {
                                                                item.planChangeText &&
                                                                <>
                                                                    <span style={{ color: '#D26220' }} className="ms-2">{item.planChangeText}</span>
                                                                    <span><i className="fa-regular fa-info-circle light-grey-text font-12 ms-1 cursor-pointer"></i></span>
                                                                </>
                                                            }
                                                        </td>
                                                    </>
                                                </tr>
                                            ))}
                                        </table>
                                    </div>
                                </Modal.Body>

                                <Modal.Footer>
                                    <div className="text-end w-100">
                                        <Button className="btn red-btn font-12 small-btn" variant="primary">
                                            Cancel Request
                                        </Button>
                                    </div>
                                </Modal.Footer>
                            </Modal>

                            {/* We’re sorry to see you go! Reason modal */}
                            <Modal show={showCancellationReason} onHide={handleClose2} className='modal-no-border'>
                                <Modal.Header closeButton>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="text-center">
                                        <img src={require("../../../../assets/img/sad-emoji.svg").default} alt="sureConnect" />
                                        <h3 className="poppins-semibold font-18 dark-text my-3">We’re sorry to see you go!</h3>
                                        <p className="lato-mediium mb-5">Before you go, kindly let us know how SureConnect can be better for you. We're here to support you!</p>
                                    </div>
                                    <div className="form-group mb-4">
                                        <label className="grey-text lato-medium mb-1">Please select the reason</label>
                                        <div className="select-option">
                                            <i class="fa-solid fa-caret-down select-caret"></i>
                                            <select className="form-control" value={selectedReason} onChange={handleReasonChange}>
                                                <option value="" disabled style={{ color: 'grey' }}>Select Reason</option>
                                                {reasons.map((reason, index) => (
                                                    <option key={index} value={reason}>{reason}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group mb-4">
                                        <label className="grey-text lato-medium mb-1">How can we make it better?</label>
                                        <textarea name="" id="" className="form-control" value={textareaValue} 
                                        onChange={handleTextareaChange} maxLength="100"></textarea>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer  className="no-shadow pt-0">
                                    <div className="text-end w-100">
                                        <Button className="btn btn-cancel mr-15px" variant="secondary" onClick={handleClose2}>
                                            Don't Cancel
                                        </Button>
                                        <Button className="btn red-btn font-12 small-btn" variant="primary" onClick={handleShowCancellationConfirmation}>
                                            Confirm Cancellation
                                        </Button>
                                    </div>
                                </Modal.Footer>
                            </Modal>

                            {/* Final Cancellation Modal */}
                            <Modal show={showCancellationConfirmation} onHide={handleClose3} className='modal-no-border'>
                                <Modal.Header closeButton>
                                    <Modal.Title><span className="round-modal-icon orange">
                                    <FontAwesomeIcon icon={faCircleExclamation} className="fa-regular fa-circle-exclamation" />
                                    </span>{" "}
                                    Are you sure you want to cancel?</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div>
                                        <p className="lato-mediium">By canceling your subscription, you will not able to verify your
                                            phone numbers in SureConnect and remaining credit will become obsolete. 
                                        </p>
                                    </div>
                                     <div className="form-group">
                                        <label className="grey-text lato-medium">Type 'CANCEL' to Confirm</label>
                                        <input name="" id="" className="form-control" placeholder="Type...." value={confirmText} onChange={handleConfirmTextChange} maxLength={10}></input>
                                    </div>
                                    <div>
                                    <p className="mt-3"><strong>Note: </strong> Canceling your subscription today will keep your plan active until the end of your billing cycle on <span className="lato-bold dark-text">{formatDate(subscriptionEndDate)}</span> </p>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer className="no-shadow pt-0">
                                    <div className="text-end w-100">
                                    <Button className="btn btn-cancel mr-15px" variant="secondary" onClick={handleShowCancellationReason}>
                                            Go Back
                                        </Button>
                                    <Button className="btn red-btn font-12 small-btn position-relative" variant="primary" disabled={disablePurchaseButton} onClick={()=>handleUpdateSubscription('cancellation')}>
                                            Confirm Cancellation
                                            {isConfirmationLoading&&<div className="spinner-border position-absolute spinner" role="status" >
                                                                        <span className="visually-hidden">Loading...</span>
                                                                    </div>}
                                        </Button>
                                        
                                    </div>
                                </Modal.Footer>
                            </Modal>

                            {/* Revert schedule cancellation  */}
                            <Modal show={showRevertScheduleCancellation} onHide={handleshowRevertScheduleCancellationClose} className='modal-no-border'>
                                <Modal.Header closeButton>
                                    <Modal.Title><span className="round-modal-icon orange">
                                        <i className="fa-regular fa-circle-exclamation"></i>
                                    </span>{" "}
                                    Cancel Scheduled Change?</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div>By cancelling the change, your current subscription plan will continue. Do you want to proceed?</div>
                                </Modal.Body>

                                <Modal.Footer className="no-shadow pt-0">
                                    <div className="text-end w-100">
                                    <Button  className="btn btn-cancel mr-15px" variant="secondary"  onClick={handleshowRevertScheduleCancellationClose}>
                                            Do not Cancel
                                        </Button>
                                        <Button className="btn red-btn small-btn font-12 position-relative" variant="primary" disabled={disablePurchaseButton} onClick={()=>handleUpdateSubscription(scheduleChangeRevertType)}>
                                            Yes, Cancel
                                            {isConfirmationLoading&&<div className="spinner-border position-absolute spinner" role="status" >
                                                                        <span className="visually-hidden">Loading...</span>
                                                                    </div>}
                                        </Button>
                                    </div>
                                </Modal.Footer>
                            </Modal>
                        </>

                    </div>
                </Col>
                }
                {
                  isPermissionLoading && (
                  <div className="loading-animation">
                      <LoadingAnimation/>
                      <span>
                      Loading data, please wait...
                      </span>
                  </div>
                  )
                }
                {!showSubscriptionTab &&!isPermissionLoading &&
                      <Col sm={9}>
                      <div className="profile-card mb-4">
                      <PermissionDenied/>
                      </div>
                      </Col>
                }

            </Row>
        </Container>
        </>
    );
}

export default SubscriptionComponent;