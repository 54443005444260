import React, { useState } from "react";
import UploadedComponent from "../../uploads/uploaded.component";
import { getAppFields } from "../../../apis/mappingHandler";

const MappingModal = ({ selectedFile, onFileDelete, columns, mapping, onMappingChange, mappingErrorMessage, mappingWarningMessage }) => {
    const handleMappingChange = (field, column) => {
        onMappingChange(field, column);
    }
    const handleDeleteFile = () => {
        onFileDelete();
    }
    const errorColumns = []
    for(const [field,col] of Object.entries(mapping)){
        for(const [field1,col1] of Object.entries(mapping)){
            if(field !== field1 && col === col1){
                errorColumns.push(field)
                errorColumns.push(field1)
            }
        }
      };
    const appFields = getAppFields();
  return (
    <>
        <div className="form-steps mb-3">
            <div>Upload CSV</div>
            <div><i className='fa-solid fa-chevron-right'></i></div>
            <div className='active'>Map Fields</div>
            <div><i className='fa-solid fa-chevron-right'></i></div>
            <div>Review</div>
        </div>
        {/* <UploadedComponent file={selectedFile} onDelete={handleDeleteFile}/> */}
        <div className='columnWrap my-3'>
            <div className="header lato-medium">
                <div className='min-w-200 me-3'>SureConnect Fields</div>
                <div className='min-w-200 ps-5'>CSV Header</div>
            </div>
            {appFields.map((field) => (
                <div className='custom-row' key={field.name}>
                    <label className='mb-1 dark-text text-capitalize'>
                        {field.name} {field.required && <span style={{ color: 'red' }}>*</span>}
                    </label>
                    <i className="fa-regular fa-left-right" style={{color: "#C3C8CF"}}></i>
                    <select className={`form-control ${errorColumns.includes(field.name) ? 'is-invalid' : ''}`}
                        value={mapping[field.name] || ''}
                        onChange={(e) => handleMappingChange(field.name,e.target.value)}
                    >
                        <option value="">Select</option>
                        {columns.map((column) => (
                        <option key={column} value={column}>
                            {column}
                        </option>
                        ))}
                    </select>
                </div>
            ))}
        </div>
        {mappingErrorMessage && (
        <p className="text_error_message mx-24">{mappingErrorMessage}</p>
        )}
        {mappingWarningMessage && (
            <p className="font-14" style={{ color: 'red' }}>{mappingWarningMessage}</p>
        )}
    </>
  );
};

export default MappingModal;