import { useEffect, useState } from "react";
import Pagination from "../../../../shared/pagination/pagination.component";
import IntegrationService from "../../../../services/integrationservice";
import { useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import { getDurationInReadableFormat } from "../../../../utils/helper.utils";
import moment from "moment";
import SpinnerLoader from "../../../../shared/spinner-loader/spinner-loader.component";
import FilterMenu from "../../../../shared/filter/filter-menu.component";
import { FieldType } from "../../integrations-constants";
import { getDatesByCriterionValue } from "../../../../utils/filter.utils";
import FilterBar from "../../../../shared/filter-bar.component";
import LoadingAnimation from "../../../lottie-files/loading.lotte";

export default function IntegrationSyncHistory() {
  const [isLoading, setIsLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(100);
  const { integration, configuration } = useOutletContext();
  const [filters, setFilters] = useState([]);
  const dateFormat = "YYYY-MM-DD";

  const [payload, setPayload] = useState({
    integrationid: integration.integrationId,
    integrationname: configuration.name,
    filters: "",
    pageindex: 1,
    pagesize: 50,
  });

  const [syncHistoryList, setSyncHistoryList] = useState([]);

  useEffect(() => {
    if (!payload.filters) {
      onApplyFilter({
        crietriaValue: "last30Days",
        fieldId: "startDate",
        fieldName: "Start Date",
        fieldSecondaryValue: moment().format(dateFormat),
        fieldType: 3,
        fieldValue: moment().subtract(30, "days").format(dateFormat),
        textValues: [],
      });
    } else {
      getSyncHistory();
    }
  }, []);

  useEffect(() => {
    getSyncHistory();
  }, [payload.pageindex]);

  async function getSyncHistory() {
    setIsLoading(true);

    try {
      const response = await IntegrationService.SyncHistory(payload);

      if (response) {
        setTotalRows(response.totalrows);

        const crmsynchistorylist = (response.crmsynchistorylist || []).map(
          (item) => {
            return transformCrmSyncListItem(item);
          }
        );

        setSyncHistoryList(crmsynchistorylist);
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error("Something went wrong, please try again.");
    }
  }

  function onPageChange(page) {
    setPayload({ ...payload, pageindex: page });
    // getSyncHistory();
  }

  function onApplyFilter(data) {
    let filterList = [];

    if (data.fieldType === FieldType.Date && !data.fieldValue) {
      const dates = getDatesByCriterionValue(data.crietriaValue);
      data.fieldValue = dates.startDate;
      data.fieldSecondaryValue =
        data.crietriaValue === "today" || data.crietriaValue === "yesterday"
          ? ""
          : dates.endDate;
    }

    if (Array.isArray(data.fieldValue)) {
      filterList = data.fieldValue.map((item, index) => ({
        id: item,
        text: data.textValues[index],
        secondaryText: "",
      }));
    } else {
      filterList = [
        {
          id: data.fieldValue,
          text: data.fieldValue,
          secondaryText: data.fieldSecondaryValue,
        },
      ];
    }

    const foundIndex = filters.findIndex((d) => d.id === data.fieldId);
    const filterObj = {
      name: data.fieldName,
      id: data.fieldId,
      type: data.fieldType,
      separator: getSeparatorTextByCriterionValue(data.crietriaValue),
      list: filterList,
    };

    if (foundIndex > -1) {
      filters[foundIndex] = filterObj;
    } else {
      filters.push(filterObj);
    }

    setFilters([...filters]);
    processFilters(filters);
    getSyncHistory();
  }

  function processFilters(appliedFilters) {
    const _filters = {};
    appliedFilters.forEach((filter) => {
      switch (filter.id) {
        case "startDate":
          _filters["fromdate"] = filter.list[0].text;
          _filters["todate"] =
            filter.separator === "between"
              ? filter.list[0].secondaryText
              : filter.list[0].text;
          _filters["dateCondition"] = filter.separator === "between" ? 2 : 1;
          break;
        default:
          _filters[filter.id] = filter.list[0].id;
      }
    });

    payload["filters"] =
      JSON.stringify(_filters) === "{}" ? "" : JSON.stringify(_filters);
    setPayload({ ...payload });
  }

  function onRemoveFilter(filterIndex, itemIndex) {
    filters[filterIndex].list.splice(itemIndex, 1);

    if (!filters[filterIndex].list.length) {
      filters.splice(filterIndex, 1);
    }

    setFilters([...filters]);
    processFilters(filters);
    getSyncHistory();
  }

  function onClearFilters() {
    setFilters([]);
    processFilters([]);
    getSyncHistory();
  }

  return (
    <div id="SyncHistory" className="flex-grow-1 d-flex flex-column">
      <div className="d-flex align-items-center justify-content-between mb-3">
        <div className="d-flex gap-2 align-items-center">
          <FilterMenu
            filterOptions={getFilterOptions({ configuration })}
            onFilterApplied={onApplyFilter}
          />
        </div>
        <div className="d-flex gap-3 align-items-center">
          <span class="refreshIcon widget-icon" onClick={getSyncHistory}>
            <i class="fa-regular fa-redo-alt"></i>
          </span>
          <Pagination
            totalItems={totalRows}
            pageSize={payload.pagesize}
            pageChanged={onPageChange}
          />
        </div>
      </div>
      {filters.length ? (
        <div className="search-response ps-0">
          <FilterBar
            filters={filters}
            onRemove={onRemoveFilter}
            onClear={onClearFilters}
          />
        </div>
      ) : undefined}
      <div className="flex-grow-1 h-0">
        {!isLoading ? (
          <div className="table-responsive mb-5 colored-table td-transparent flat-table minus-m-25">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Sync Time</th>
                  <th></th>
                  <th>Object</th>
                  <th style={{ minWidth: "125px" }}>Total Records</th>
                  <th>Processed</th>
                  <th>Errors</th>
                  <th>Skipped</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {syncHistoryList.map((item) => (
                  <tr>
                    <td>
                      {item.duration !== null ? (
                        <div
                          style={{
                            minWidth: "max-content",
                            borderRight: "solid thin #eeeeee",
                          }}
                        >
                          {/* <i className="fa-regular fa-plus-square six-c-7"></i> */}
                          <span className="default-text">{item.duration}</span>
                        </div>
                      ) : (
                        <div>--mins</div>
                      )}
                    </td>
                    <td>
                      <div
                        className="from-clr font-12"
                        style={{ minWidth: "max-content" }}
                      >
                        {item.startDate}
                      </div>
                      <div
                        className="to-clr font-12"
                        style={{ minWidth: "max-content" }}
                      >
                        {item.endDate ? item.endDate : "-"}
                      </div>
                    </td>
                    <td className="lato-medium default-text">
                      {item.objectname}
                    </td>
                    <td className="lato-medium default-text">
                      {item.totalrecords}
                    </td>
                    <td className="lato-medium default-text">
                      {item.processedrecords}
                    </td>
                    <td className="lato-medium default-text">
                      <span
                        className={`cursor-pointer ${
                          item.errorrecords ? "blue-link" : undefined
                        }`}
                      >
                        {item.errorrecords}
                      </span>
                    </td>
                    <td className="lato-medium default-text">
                      {item.skippedrecords}
                    </td>
                    <td>
                      <span
                        className={`tag rounded-tag ${getTagColor(
                          item.status
                        )}`}
                      >
                        {item.status}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <SpinnerLoader />
        )}
      </div>
    </div>
  );
}

function getFilterOptions({ configuration }) {
  const objectTypes = configuration.defaultConfig.types.map((t) => ({
    id: t.typeId,
    text: t.displayName,
  }));

  return [
    {
      id: "status",
      text: "Status",
      type: FieldType.MultiSelect,
      selectOptions: {
        data: [
          { id: 1, text: "Running" },
          { id: 2, text: "Completed" },
          { id: 3, text: "Failed" },
          { id: 4, text: "Cancelled" },
        ],
      },
    },
    {
      id: "startDate",
      text: "Start Date",
      type: FieldType.Date,
      criteria: [
        { name: "Today", value: "today", showField: false },
        { name: "Yesterday", value: "yesterday", showField: false },
        { name: "Last 7 days", value: "last7Days", showField: false },
        { name: "Last 15 days", value: "last15Days", showField: false },
        { name: "Last 30 days", value: "last30Days", showField: false },
        { name: "Custom Date", value: "between", showField: true },
      ],
    },
    {
      id: "objecttype",
      text: "Object Type",
      type: FieldType.MultiSelect,
      selectOptions: {
        data: objectTypes,
      },
    },
  ];
}

function getTagColor(status) {
  if (status === "Running") {
    return "orange";
  } else if (status === "Completed") {
    return "green";
  } else {
    return "red";
  }
}

function transformCrmSyncListItem(item) {
  if (
    item.endDate &&
    item.startDate &&
    item.startDate !== "0001-01-01T00:00:00" &&
    item.endDate !== "0001-01-01T00:00:00"
  ) {
    item["duration"] = getDurationInReadableFormat(
      item.startDate,
      item.endDate
    )?.split(", ")[0];
    const starts = moment(new Date(item.startDate));
    const ends = moment(new Date(item.endDate));
    const duration = moment.duration(ends.diff(starts));

    let text = "";

    for (const key in duration["_data"]) {
      if (
        duration["_data"][key] !== 0 &&
        key !== "seconds" &&
        key !== "milliseconds" &&
        (key === "minutes" ? duration["_data"].days === 0 : true)
      ) {
        text += `${
          duration["_data"][key] === 1
            ? duration["_data"][key] + " " + timerep[key + "1"] + " "
            : duration["_data"][key] + " " + timerep[key + "2"] + " "
        }`;
      }

      if (
        duration["_data"].days === 0 &&
        duration["_data"].hours === 0 &&
        duration["_data"].minutes === 0 &&
        key === "seconds"
      ) {
        text += `${
          duration["_data"][key] > 0
            ? duration["_data"][key] + " sec"
            : 1 + " sec"
        }`;
      }
    }

    item.duration = text;
  } else {
    item["duration"] = null;
  }

  item.startDate =
    item.startDate && item.startDate !== "0001-01-01T00:00:00"
      ? moment(item.startDate).format("MMM D, h:mm a")
      : null;
  item.endDate =
    item.endDate && item.endDate !== "0001-01-01T00:00:00"
      ? moment(item.endDate).format("MMM D, h:mm a")
      : null;

  let totalRecords = item.inboundstats.total + item.outboundstats.total;
  let processedRecords =
    item.inboundstats.inserts +
    item.outboundstats.inserts +
    item.inboundstats.updates +
    item.outboundstats.updates;
  let errorRecords = item.inboundstats.failed + item.outboundstats.failed;
  let skippedRecords = item.inboundstats.skipped + item.outboundstats.skipped;

  const totalSkippedStats = {
    ...(item.inboundstats.skippedStats || {}),
  };
  const outboundSkippedStats = item.outboundstats.skippedStats || {};
  Object.keys(outboundSkippedStats).forEach((key) => {
    totalSkippedStats[key] =
      (totalSkippedStats[key] || 0) + outboundSkippedStats[key];
  });
  if (item.inboundstats?.dependentObjects?.length > 0) {
    for (const inboundDependentObj of item.inboundstats?.dependentObjects) {
      totalRecords = totalRecords + inboundDependentObj.total;
      processedRecords =
        processedRecords +
        inboundDependentObj.inserts +
        inboundDependentObj.updates;
      errorRecords = errorRecords + inboundDependentObj.failed;
      skippedRecords = skippedRecords + inboundDependentObj.skipped;
      const skippedStats = inboundDependentObj.skippedStats || {};
      Object.keys(skippedStats).forEach((key) => {
        totalSkippedStats[key] =
          (totalSkippedStats[key] || 0) + skippedStats[key];
      });
    }
  }
  if (item.outboundstats?.dependentObjects?.length > 0) {
    for (const outboundDependentObj of item.outboundstats?.dependentObjects) {
      totalRecords = totalRecords + outboundDependentObj.total;
      processedRecords =
        processedRecords +
        outboundDependentObj.inserts +
        outboundDependentObj.updates;
      errorRecords = errorRecords + outboundDependentObj.failed;
      skippedRecords = skippedRecords + outboundDependentObj.skipped;
      const skippedStats = outboundDependentObj.skippedStats || {};
      Object.keys(skippedStats).forEach((key) => {
        totalSkippedStats[key] =
          (totalSkippedStats[key] || 0) + skippedStats[key];
      });
    }
  }

  const transformedItem = {
    historyid: item.historyid,
    inboundstats: item.inboundstats,
    outboundstats: item.outboundstats,
    status: item.status,
    startDate: item.startDate,
    endDate: item.endDate,
    objectname: item.objectname,
    duration: item.duration,
    clicked: item.clicked,
    totalrecords: totalRecords,
    processedrecords: processedRecords,
    errorrecords: errorRecords,
    skippedrecords: skippedRecords,
    failurereason: item.failureReason,
    totalSkippedStats:
      Object.keys(totalSkippedStats).length > 0 ? totalSkippedStats : null,
  };

  return transformedItem;
}

const timerep = {
  days1: "day",
  days2: "days",
  hours1: "hr",
  hours2: "hrs",
  minutes1: "min",
  minutes2: "mins",
};

function getSeparatorTextByCriterionValue(value) {
  switch (value) {
    case "today":
    case "yesterday":
      return "equal to";
    case "last7Days":
    case "last15Days":
    case "last30Days":
      return "between";
    default:
      return value;
  }
}
