import React from "react";
import './list.component.scss'
import ListCard from "./card/listcard.component";
import { useRef } from "react";
import axios from "axios";
import qs from 'qs'
import { useEffect,useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Tooltip } from "react-tooltip";
import 'react-tooltip/dist/react-tooltip.css'
import { useCookies } from 'react-cookie';

import Papa from 'papaparse';
import { ToastContainer, toast } from 'react-toastify';
import { Button, Modal } from 'react-bootstrap';
import 'react-toastify/dist/ReactToastify.css';

import { saveAs } from 'file-saver';

import csvFile from "../../../../assets/csv/Sample.csv";
import excelFile from "../../../../assets/csv/guide.xlsx";

import UploadComponent from '../../uploads/upload.component';
import UploadedComponent from '../../uploads/uploaded.component';
import AnalyzingComponent from '../../uploads/analyzing.component';
import ResultsComponent from '../../uploads/analysis.results.component';
import DownloadComponent from "../download/download.component";
import FilterApp from "../filter/filter.component";
import { regenerateTokens,userLogout, getCurrentCredits, downloadValidatedCSV } from "../../../apis/pvlapis";
import { getAccessTokenCookie } from "../../../apis/tokenhandler";
import LoadingAnimation from "../lottie-files/loading.lotte";
import EmptyCard from "./emptylist.component";
import { Mixpanel } from "../../../configs/mixPanel";
import UploadModal from "../modals/uploadmodal.component";
import UploadedModal from "../modals/uploadedmodal.component";
import { automapColumns, getAppFields, addMappedColumns, getColumnMappingforBackend } from "../../../apis/mappingHandler";
import MappingModal from "../modals/mappingmodal.component";
import AnalyzingModal from "../modals/analyzingmodal.component";
import ResultsModal from "../modals/resultsmodal.component";
import BeforeAnalyzeMessageModal from '../modals/beforeanalyzemodal.component';

//API URLS for endpoints
const getProjectUrl = process.env.REACT_APP_PVL_APP_URL+"/pvl/get_user_account_projects_details";
const validateDataUrl = process.env.REACT_APP_PVL_APP_URL+"/pvl/upload_and_validate_prospect_data";
const executeProjectUrl = process.env.REACT_APP_PVL_APP_URL+"/pvl/request_project_execution";
const getDownloadUrl = process.env.REACT_APP_PVL_APP_URL+"/pvl/get_project_data";
const apiKey = process.env.REACT_APP_PVL_API_KEY;


const ListComponent = () => {
  const userDetails = JSON.parse(localStorage.getItem('PVLUserDetails'));
  const navigate = useNavigate();
  let userId = null;
  if(userDetails){
    userId = userDetails.userid;
  }
  //To store the project details
  const [callProjects, setCallProjects] = useState(true);
  const [projects, setProjects] = useState([]);
  const [accountUsers, setAccountUsers] = useState([]);
  //to store the selected file
  const [selectedFile, setSelectedFile] = useState(null);
  //to store the selected file data
  const [selectedFileData, setSelectedFileData] = useState(null);
  //to store if the csv file is valid or not
  const [validCsvData, setValidCsvData] = useState(false)
  //to disable the validate button if there are any problems with the file
  const [disableValidateButton,setDisableValidateButton] = useState("true");
  //to disable the next button that open mapping
  const [disableMappingButton, setDisableMappingButton] = useState(true);
  //to store the error message to be shown if we have any errors in the data
  const [dataErrorMessage,setDataErrorMessage] = useState(null);
  const [dataWarningMessage, setDataWarningMessage] = useState(null);
  const [dataAvailable, setDataAvailable] = useState(false);
  //Have a loader variable when loading the page for the first time to represent loading state
  const [isLoading, setIsLoading] = useState(true);
  //to store the project name
  const [projectName,setProjectName] = useState(null);
  //to store the validation results
  const [validationResults,setValidationResults] = useState(null);
  const [disableAnalyzeButton, setDisableAnalyzeButton] = useState(false);
  const [analyzeErrorMessage, setAnalyzeErrorMessage] = useState(null);
  //to store the project id
  const [projectId, setProjectId] = useState(null);
  const [projectNameErrorMessage, setProjectNameErrorMessage] = useState(null);

  //to store the file uploaded or not
  const [openUploadModal,setOpenUploadModal] = useState(false);
  const [openUploadedModal,setOpenUploadedModal] = useState(false);
  const [openResultsModal,setOpenResultsModal] = useState(false);
  const [openAnlaysisModal,setOpenAnlaysisModal] = useState(false);
  const [openMappingModal, setOpenMappingModal] = useState(false);
  const [isBeforeAnalyzeMessageOpen, setisBeforeAnalyzeMessageOpen] = useState(false);

  //For column mapping
  //to store the mapping of the selected file
  const [columnMapping, setColumnMapping] = useState({});
  const [csvColumns, setCsvColumns] = useState([]);
  const [mappingErrorMessage, setMappingErrorMessage] = useState(null);
  const [mappingWarningMessage, setMappingWarningMessage] = useState(null);

  //States to handle the race conditions during filtering to store the queueddrequests and stop calling when a response is yet to be received
  //State to check if an api request to fetch project details is already made and waiting for response
  const [responsePending, setResponsePending] = useState(false);
  //State to add a request to queue to be called later
  const [queuedRequests,setQueuedRequests] = useState(false);
  //State to check the queued results and fetch the lists if there are queued requests
  const [checkQueuedRequests, setCheckQueuedRequests] = useState(false);

  //Download states
  const [openDownloadResults,setOpenDownloadResults] = useState(false);
  const [downloadProjectId, setDownloadProjectId] = useState(null);
  const [projectDetails,setProjectDetails] = useState(null);
  const [checkboxValues, setCheckboxValues] = useState(null);
  const [disableDownloadButton, setDisableDownloadButton] = useState(true);

  //State variables for List page settings
  const [nextPage, setNextPage] = useState(false);
  const [previousPage, setPreviousPage] = useState(false);
  const [pageDataCount, setpageDataCount] = useState(25);
  const [searchChange, setSearchChange] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [pageIndex, setPageIndex] = useState(1);
  const [sortByValue, setSortByValue] = useState(3);
  const [sortByDirection, setSortByDirection] = useState(2);
  const [pageFilters, setPageFilters] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [lowerIndex, setLowerIndex] = useState(0);
  const [upperIndex, setUpperIndex] = useState(0);
  //Filters
  const [showDropdown, setShowDropdown] = useState(false);
  // const [selectedFilter, setSelectedFilter] = useState('');
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [totalRecordsLowerLimit, setTotalRecordsLowerLimit] = useState(null);
  const [totalRecordsUpperLimit, setTotalRecordsUpperLimit] = useState(null);
  const [statusSelection, setStatusSelection] = useState([]);
  //for projects stats
  const [completedProjects, setCompletedProjects] = useState(null);
  const [inProgressProjects, setInProgressProjects] = useState(null);
  const [failedProjects, setFailedProjects] = useState(null);
  const [waitingProjects, setWaitingProjects] = useState(null);
  const [rejectedProjects, setRejectedProjects] = useState(null);
  const dropdownRef = useRef(null);
  
  // const [currentCreditsAmount, setCurrentCreditsAmount] = useState(0);

  //Create a mapping for project execution status
  const projectExecutionStatusMapping = {
    "1": "In Progress",
    "2": "Stopped",
    "4": "Completed",
    "5": "Failed",
    "6": "Waiting",
    "7": "Rejected" 
  }


  //variable to store the required columns
  const requiredCsvColumns = ['prospectname'];
  const phoneNumbersThreshold = 3;

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  const handleClickOutside = (event) => {
    //Close the dropdown when user hits outside the dropdown
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  //Download Modules
  const handleCheckboxChange = (values) => {
    setCheckboxValues(values);
  };

  useEffect(() => {
    if (checkboxValues!==null && Object.values(checkboxValues).includes(true) && projectDetails.projectStats[0].completedProspects!==0) {
      // Perform any actions with the selected file here
        for(const key in checkboxValues){
          if(checkboxValues[key]){
            if(projectDetails.projectStats[0][key]!==0){
            setDisableDownloadButton(false);
          }
        }
      }
    }
    else{

      setDisableDownloadButton(true);
    }
  }, [checkboxValues]);
  const handleOpenMapping = () => {
    setOpenAnlaysisModal(false);
    setOpenResultsModal(false);
    setOpenUploadedModal(false);
    setisBeforeAnalyzeMessageOpen(false);
    setOpenMappingModal(true);
  }

  const handleOpenBeforeAnalyze = () => {
    // When user clicks on start verification button
    setOpenResultsModal(false);
    setOpenAnlaysisModal(false);
    setOpenUploadedModal(false);
    setOpenMappingModal(false);
    setisBeforeAnalyzeMessageOpen(true);
  }

  const handleBackToOpenResult = () => {
    setOpenAnlaysisModal(false);
    setOpenUploadedModal(false);
    setOpenMappingModal(false);
    setisBeforeAnalyzeMessageOpen(false);
    setOpenResultsModal(true);
  }

  const handleMappingChange = (appField, csvColumn) => {
    setColumnMapping((prevMapping) => {
      //Create a new mapping object
      const newMapping ={ ...prevMapping };
      //Check if the appField is empty string. If yes, then delete the column mapping
      if(csvColumn === ""){
        delete newMapping[appField];
      }
      else{
      //Add the new mapping
      newMapping[appField] = csvColumn;
      }
      return newMapping;
    });
  };
//Use Effect for field mapping
useEffect(() => {
  if(columnMapping){
    let multipleFiledsMapped = false;
    //Mandatory fields to be mapped
    const mandatoryFields = ["first name", "phone1"];
    const warningFields = ["company name", "email", "linkedin url"];
    const mappedFields = Object.keys(columnMapping);
    //Check if all items are present in the mapping
    const containsAllItems = mandatoryFields.every(item => mappedFields.includes(item));
    for(const [field,col] of Object.entries(columnMapping)){
      for(const [field1,col1] of Object.entries(columnMapping)){
          if(field !== field1 && col === col1){
            multipleFiledsMapped = true;
          }
      }
    };
    if(multipleFiledsMapped){
      toast.warning("Cannot map multiple fields with same CSV header.",{position:toast.POSITION.BOTTOM_LEFT})
      setMappingErrorMessage("Cannot map multiple fields with same CSV header.");
      setMappingWarningMessage(null);
      setDisableValidateButton(true);
    }
    else if(!containsAllItems){
      setMappingErrorMessage("All mandatory fields are not mapped.");
      setMappingWarningMessage(null);
      setDisableValidateButton(true);
    }
    else{
      setMappingErrorMessage(null);
      //Check if all the warning fields are present in the mapping
      const containsAllWarningItems = warningFields.every(item => mappedFields.includes(item));
      if(!containsAllWarningItems){
        setMappingWarningMessage("Consider mapping all fields for better accuracy.")
      }
      else{
        setMappingWarningMessage(null);
      }
      setDisableValidateButton(false);
    }
  }
}, [columnMapping]);
  const handleCloseDownload = () => {
    setOpenDownloadResults(false);
    setDownloadProjectId(null);
    setDisableDownloadButton(true);
    setProjectDetails(null);
    setCheckboxValues(null);

  }

  const handleDownloadValidatedCSV = async(id) => {
    Mixpanel.track("Download Validated CSV button clicked");
    await downloadValidatedCSV(id);
  }

  const handleGetCredits = async() => {
    try{
      const response = await getCurrentCredits();
      if(response.data.statusCode === 200){
        // setCurrentCreditsAmount(response.data.result['current_credits']);
        // return response.data.result['current_credits'];
        return response.data.result['credit_details']['credit_balance'];
      }
      else{
        toast.error("Could not load your credits. Please try again.",{position: toast.POSITION.BOTTOM_LEFT});
        return null;
      }
    }
    catch{
      toast.error("Something went wrong. Please try again after some time.",{position: toast.POSITION.BOTTOM_LEFT});
      return null;
    }
  }
  const handleOpenDownload = (project) => {
    setDownloadProjectId(project.projectId);
    setProjectDetails(project);
  }
  useEffect(() => {
    if(downloadProjectId){
      setOpenDownloadResults(true);
    }
  }, [downloadProjectId]);

  const handleDownloadResults = async() => {
    Mixpanel.track("Internal Download Results button clicked");
    setOpenDownloadResults(false);
    const accessToken = getAccessTokenCookie();
    //Create the payload using the checkbox data
    let checkBoxedData = {};
    //If completedProjects is selected, we will get the analysisResult.
    if(checkboxValues.completedProspects === true){
      checkBoxedData = {
        "analysisResult": checkboxValues.completedProspects,
      }
    }
    else{
      //Payload to get each result using the checkbox values
      checkBoxedData = {
        "analysisResult": checkboxValues.completedProspects,
        "likelyAnswer": checkboxValues.answerProspects,
        // "likelyWrong": checkboxValues.wrongProspects,
        "likelyVoicemail": checkboxValues.voicemailProspects,
        "likelyGatekeeper": checkboxValues.gatekeeperProspects,
        "other": checkboxValues.otherProspects,
        "verificationFailed": checkboxValues.failedProspects
      }
    }
    //Create the payload using the checkboxdata. we will only pass the list of keys selected by the user(where it is true)
    const requiredData = Object.keys(checkBoxedData).filter(key => checkBoxedData[key] === true);

    const config = {
      // headers: { Authorization: `Brearer ${accessToken}` },
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'X-API-Key': apiKey
      },

      params: {
        'projectId':downloadProjectId,
        'requiredData': requiredData
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      }
    };

    try {
      const response = await axios.get(getDownloadUrl, config);

      if(response.data.statusCode === 200){

        const data = response.data.result
        //Iterate through each url in the result.
        for(const key in data){
          //Open the url in a new tab to download the results
          if (data.hasOwnProperty(key)) {
            window.open(data[key], '_blank');
          }
        }

      }
      //Failed due to expired tokens
      else if(response.data.statusCode === 457){
        //Regenerate tokens
        const status = await regenerateTokens();
        if(status === 200){
          await handleDownloadResults();
        }
        //Logout if regeneration failed
        else{
          await userLogout();
          navigate("/");
        }
      }
      //Log out if the token is invalid
      else if(response.data.statusCode === 455){
        // console.log("LOGGING OUT");
        await userLogout();
        navigate("/");
      }
      //Error because of third party service. Retry
      else if (response.data.statusCode === 453){
        sleep(4000);
        await handleDownloadResults();
      }
      //Unknown error. Log error
      else{
        toast.error("Couldn't process your request. Please try again!",{position:toast.POSITION.BOTTOM_LEFT})
      }
    } catch (error) {

      toast.error("Something went wrong. Please try again after some time.",{position:toast.POSITION.BOTTOM_LEFT})
    }

  }

  const downloadSampleCsv = async() => {
    //To download sample CSV file. Not being used any more
    Papa.parse(csvFile, {
      download: true,
      complete: function (results) {
        // Access the parsed CSV data
        const records = results.data;
        // Convert the CSV data to a Blob
        const csvData = Papa.unparse(records);
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });

        // Save the Blob as a file
        saveAs(blob, 'Sample.csv');
      },
    });
  };
  const downloadSampleExcel = async () => {
    //To download the guide excel
    const response = await fetch(excelFile);
    const data = await response.blob();
    saveAs(data, 'Guide_To_Sample_Csv.xlsx');
  };
  //Reset Project Details
  const resetProjectDetails = () => {
    //Called when ever upload process is cancelled or project is executed
    setProjectName(null);
    setSelectedFile(null);
    setSelectedFileData(null);
    setDataErrorMessage(null);
    setDataWarningMessage(null);
    setValidCsvData(false);
    setDisableValidateButton("true");
    setDisableMappingButton(true);
    setValidationResults(null);
    setProjectId(null);
    setDisableAnalyzeButton(false);
    setAnalyzeErrorMessage(null);
    setProjectNameErrorMessage(null);
    setOpenUploadModal(false);
    setOpenUploadedModal(false);
    setOpenMappingModal(false);
    setOpenResultsModal(false);
    setOpenAnlaysisModal(false);
    setisBeforeAnalyzeMessageOpen(false);
  }
  const handleOpenUpload = () => {
    if(!openUploadedModal){
      Mixpanel.track("Add list button clicked");
    }
    resetProjectDetails();
    setOpenUploadModal(true);
  }
  const handleOpenUploaded = () => {
    //Open the Uploaded modal
    setOpenMappingModal(false);
    setOpenUploadedModal(true);
  }
  const recreateCsvFile = () => {
    //Add the new mapped columns to the CSV
    const newCsvData = addMappedColumns(selectedFileData, columnMapping);
    //Unparse the CSV data
    const newCsvUnparsed = Papa.unparse(newCsvData);
    //Recreate the csv string from the unparsed data
    const blob = new Blob([newCsvUnparsed], { type: 'text/csv' });
    // Create a new File object from the Blob(csv string)
    const file = new File([blob], projectName, { type: 'text/csv' });
    return file
  }
  //function to handle the analyze button click
  const HandleAnalyze = async () => {
    setDisableAnalyzeButton(true);
    Mixpanel.track("Analyze list button clicked");

    const accessToken = getAccessTokenCookie();
    try {
      const config = {
        // headers: { Authorization: `Brearer ${accessToken}` },

        headers: {
          'Accept': 'application/json',
          Authorization: `Bearer ${accessToken}`,
          'X-API-Key': apiKey
        }
      };
      const payload = {
        projectId: projectId,
      };
      //Reset the project details
      resetProjectDetails();

      const response = await axios.post(executeProjectUrl, payload,config);

      if (response.data.statusCode === 200){
        setOpenResultsModal(false);
        toast.success("Your list is added to the queue. Waiting for Admin approval.",{position: toast.POSITION.BOTTOM_LEFT});
        await sleep(4000);
        window.location.reload();
      }
      else if (response.data.statusCode === 423){
        toast.error("Insufficient Credits! Please add more credits to execute the list.",{position: toast.POSITION.BOTTOM_LEFT});
        await sleep(4000);
        // window.location.href = "/list"
      }
      else if(response.data.statusCode === 457){
        const status = await regenerateTokens();
        if(status === 200){
          await HandleAnalyze();
        }
        else{
          await userLogout();
          navigate("/");
        }
      }
      else if(response.data.statusCode === 455){
        // console.log("LOGGING OUT");
        await userLogout();
        navigate("/");
      }
      else if (response.data.statusCode === 453){
        sleep(4000);
        await HandleAnalyze();
      }
      else{
        toast.error("There was a problem processing your file. Please try again.",{position: toast.POSITION.BOTTOM_LEFT})
      }
      // navigate('/list');

    } catch (error) {
      // console.error(error);
      toast.error("Something went wrong. Please try again after some time.",{position: toast.POSITION.BOTTOM_LEFT})
    };

  }
  //Whenever a user enter the project name we come here
  const handleProjectName = (name) =>{
    const projectName = name.trimStart();
    // const projectName = event.target.value;
      // console.log(projectName);
      //Check the projectName
      if (projectName.length > 150){
        //Set the error message
        setProjectNameErrorMessage("List Name is too long");
        //Disable the validate button if the name is too long
        setProjectName(projectName);
      }
      else{
        setProjectNameErrorMessage(null);
        //We will disable the validate button if no projectname is entered or the data is not valid
        setProjectName(projectName);
      }
  }
  //Whenever a user selects a file we come here
  const handleFileSelect = (file) => {
    setOpenUploadModal(false);
    setSelectedFile(file);

  };

  const handleDeleteFile = () => {
    resetProjectDetails();
    setOpenUploadedModal(false);
    setOpenResultsModal(false);
    setOpenAnlaysisModal(false);
    setOpenUploadModal(true);
  }

  const handleValidate = async () => {
    Mixpanel.track("Validate list button clicked");
    //Recreate the new file after handling the mapping
    const file = recreateCsvFile();
    const accessToken = getAccessTokenCookie();
    setOpenMappingModal(false);
    setOpenAnlaysisModal(true);
    // Make API request
    try {
      const config = {
        // headers: { 'Accept': 'application/json',Authorization: `Brearer ${accessToken}`,'Content-Type': 'multipart/form-data',  },
        headers: {
          'Accept': 'application/json',
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'multipart/form-data',
          'X-API-Key': apiKey
        }
      };
      //store the payload to be sent
      const data = new FormData();
        data.append('userId',userId);
      // data.append('accountId',accountId);
        data.append('projectName',projectName.trim());
        data.append('csvFile', file);
        const finalColumnMapping = getColumnMappingforBackend(columnMapping);
        data.append('columnMapping',finalColumnMapping);

        const response = await axios.post(validateDataUrl, data,config);

      if (response.data.statusCode === 200) {
        setValidationResults(response.data);
          if(response.data.result.report.passCount <1){ 
          setDisableAnalyzeButton(true);
          setAnalyzeErrorMessage("Passed contacts not found for analysis.");
        }
        if(response.data.result.report.passCount <20){ 
          setDisableAnalyzeButton(true);
          setAnalyzeErrorMessage("Please have atleast 20 passed contacts for verification.");
        }
        const currentCreditsAmount = await handleGetCredits();
        if(!currentCreditsAmount){
          setDisableAnalyzeButton(true);
          setAnalyzeErrorMessage("Could not load your credits. Please try again.")
        }
        if(response.data.result.report.passCount > currentCreditsAmount){
          setDisableAnalyzeButton(true);
          setAnalyzeErrorMessage("Insufficient Funds! Purchase more credits to execute the list.");
        }
        //set the project id
        setProjectId(response.data.result.projectId);
        setOpenAnlaysisModal(false);
        setOpenResultsModal(true);
      }
      else if(response.data.message === 'SureConnect.ai - upload_and_validate_prospect_data endpoint failed! --> List name already in use.'){

        setOpenAnlaysisModal(false);
        setOpenUploadedModal(true);
        setProjectNameErrorMessage("List Name already exists");
      }
      else if(response.data.statusCode === 457){
        const status = await regenerateTokens();
        if(status === 200){
          await handleValidate();
        }
        else{
          await userLogout();
          navigate("/");
        }
      }
      else if(response.data.statusCode === 455){
        // console.log("LOGGING OUT");
        await userLogout();
        navigate("/");
      }
      else if (response.data.statusCode === 453){
        sleep(4000);
        await handleValidate();
      }
      else{
        toast.error("Couldn't process your request. Please try again!",{position:toast.POSITION.BOTTOM_LEFT});
        await sleep(4000);
        window.location.href = "/list"
      }
    } catch (error) {
      console.error(error);
        toast.error("Something went wrong. Please try again after some time.",{position:toast.POSITION.BOTTOM_LEFT});
      await sleep(4000);
      window.location.href = "/list"
    };
  }
  //Whenever a user uploads a file, we check the properties of the selected file here
  const filePropertiesCheck = () => {
    if (selectedFile) {
      //Set the projectname as file name if the project name is not created
      if (!projectName) {
        handleProjectName(selectedFile.name.replace(".csv", ""));
      }
      const fileSize = selectedFile.size;
      //Maximum size of the file we are going to allow
      const maxSize = 1024*1024*2;
      //Check if it is a valid CSV file
      if(!selectedFile.type.toLowerCase().includes("csv")){
        setDataErrorMessage("Please upload a valid CSV file.");
      }
      //Check the file size
      else if (fileSize> maxSize){
        setDataErrorMessage(`File Size is more than 2 MB`);
      }
      else{
      Papa.parse(selectedFile, {
        skipEmptyLines: true,
        header: true,
        complete: function (results) {
          // Here, 'results' will contain the parsed CSV data
          setCsvColumns(results.meta.fields);
          // Accessing column headers
          const csvColumnNames = results.meta.fields;
          //Do automapping of the CSV columns
          setColumnMapping(automapColumns(csvColumnNames));
          //Get the number of columns in the CSV
          const numberOfColumns = csvColumnNames.length;
          //Iterate through the CSV rows and check if all the rows contain the same number of columns.
          for(let i=0; i<results.data.length; i++){
            //Check if all fields contains all csv columns
            if(Object.keys(results.data[i]).length !== numberOfColumns){
              setDataErrorMessage("Invalid CSV. Please make sure that all the rows contain the same number of columns.");
              setValidCsvData(false);
            }
          }
          //Check if the number of records are not exceeding 10000
          if(results.data.length>10000){
            setValidCsvData(false);
            setDataErrorMessage(`Number of contacts should not be more than 10000`);
          }
          //If there are no records, mark as invalid csv
          else if (results.data.length < 1){
            setValidCsvData(false);
            setDataErrorMessage(`No contacts found`);
          }
          //If count of records is less than 10, then raise error
          else if (results.data.length < 20){
            setValidCsvData(false);
            setDataErrorMessage(`Please upload a file with atleast 20 records!`);
          }
          else{
          setSelectedFileData(results.data);
          setValidCsvData(true);
          }
        },
      });
    }
  }
};

useEffect(() => {
  if(validCsvData && projectNameErrorMessage === null){
    setDisableMappingButton(false);
  }
  else{
    setDisableMappingButton(true);
  }
}, [validCsvData, projectNameErrorMessage]);
  useEffect(() => {
    if (selectedFile) {
      // Perform any actions with the selected file here
      setOpenResultsModal(false);
      setOpenUploadedModal(true);
      filePropertiesCheck();
      // handleGetCredits();
    }
  }, [selectedFile]);

  const getProjects = async () => {
    //Check if we are waiting for a response
    if(responsePending){
      //Make the queued requests true to call later
      setQueuedRequests(true);
      return;
    }
    const accessToken = getAccessTokenCookie();
    const config = {
      // headers: { Authorization: `Brearer ${accessToken}` },
          headers: { 'Authorization': `Bearer ${accessToken}`,
                      'X-API-Key': apiKey }
    };
    const payload = {
      // 'accountId':accountId, 
          'userId':userId, 
      "pageIndex": pageIndex,
      "pageDataCount": pageDataCount,
      "search": searchTerm,
      "sortByValue": sortByValue,
      "sortByDirection": sortByDirection,
      "filters": pageFilters
    };

    try {
      setResponsePending(true);
      setIsLoading(true);
      setNextPage(false);
      setPreviousPage(false);
      const response = await axios.post(getProjectUrl, payload, config);
      // console.log(response.data);
          if(response.data.statusCode === 200){
        setProjects(response.data.result.data);
        setPreviousPage(response.data.result.pagination["previous"]);
        setNextPage(response.data.result.pagination["next"]);
        setTotalRecords(response.data.result.totalRecords);
        if(response.data.result.totalRecords === 0){
          setDataAvailable(false);
        }
        else{
          setDataAvailable(true);
        }
            setUpperIndex((pageIndex-1)*pageDataCount+response.data.result.data.length);
            let completed = 0
            let inProgress = 0
            let failed = 0
            let waiting = 0
            let rejected = 0
            let userDetails = [];
            for(const data in response.data.result.data){
              if (!userDetails.some((dict) => dict.name === response.data.result.data[data].userFullName)) {
                const newUser = {id: userDetails.length, name: response.data.result.data[data].userFullName}
                userDetails = userDetails.concat(newUser);
              }
              const status = response.data.result.data[data].projectExecutionStatus
              if(status === 4){
            completed = completed + 1;
          }
              else if(status === 5){
                failed =failed + 1;
          }
              else if(status === 1){
                inProgress = inProgress + 1;
              }
              else if(status === 6){
                waiting = waiting + 1;
              }
              else if(status === 7){
                rejected = rejected + 1;
              }
            }
            // console.log(userDetails);
            setAccountUsers(userDetails);
            setCompletedProjects(completed);
            setInProgressProjects(inProgress);
            setFailedProjects(failed);
            setRejectedProjects(rejected);
            setWaitingProjects(waiting);
            if(response.data.result.data.length === 0){
              setLowerIndex((pageIndex-1)*pageDataCount);
        }
            else{
              setLowerIndex((pageIndex-1)*pageDataCount+1);
        }

      }
          else if(response.data.statusCode === 457){
        const status = await regenerateTokens();
            if(status === 200){
          await getProjects();
        }
            else{
          await userLogout();
          navigate("/");
        }
      }
          else if(response.data.statusCode === 455){
        // console.log("LOGGING OUT");
        await userLogout();
        navigate("/");
      }
          else if (response.data.statusCode === 453){
        sleep(4000);
        await getProjects();
      }

    } catch (error) {
      // console.log(error);
          toast.error("Something went wrong. Please try again after some time.",{position:toast.POSITION.BOTTOM_LEFT})
        }
        finally{
          setIsLoading(false);
          //Set the response pending to false
          setResponsePending(false);
          //Check if there are any pending requests
          setCheckQueuedRequests(!checkQueuedRequests);
        }


  };

  // useEffect(() => {
  //   console.log("List Use Effect called");
  // }, []);
  useEffect(() => {
    // console.log(pageFilters);
    getProjects();
  }, [callProjects]);
  useEffect(() => {
    // console.log(pageFilters);
    //Check if some requests are queued
    if(queuedRequests){
      //Set the queued requests to false and call the getProjects
      setQueuedRequests(false);
      getProjects();
    }
  }, [checkQueuedRequests]);


  //All filtering, sorting, searching handlers
  const handleNextPage = () => {

      if(nextPage){
        setPageIndex(pageIndex+1);
      setCallProjects(!callProjects);
    }
  }
  const handlePreviousPage = () => {
      if(previousPage){
        setPageIndex(pageIndex-1);
      setCallProjects(!callProjects);
    }
  }
  const handleSorting = (field) => {

      if(sortByDirection === 1){
      setSortByDirection(2);
    }
      else{
      setSortByDirection(1);
    }
      if(field!==-1){

      setSortByValue(field);
    }
    setPageIndex(1);
    setCallProjects(!callProjects);
  }
  // const handleToggleSorting = () => {
  //   if(sortByDirection === 1){
  //     setSortByDirection(2);
  //   }
  //   else{
  //     setSortByDirection(1);
  //   }
  //   setCallProjects(!callProjects);
  // }

  const handleSearchKeyDown = (event) => {
    if (event.key === 'Enter') {
      setSearchTerm(searchChange);
      setSearchChange("");
      setPageIndex(1);
      setCallProjects(!callProjects);
    }
  };

  const handleSearchChange = (event) => {
    setSearchChange(event.target.value);
    setSearchTerm(event.target.value);
    setPageIndex(1);
    setCallProjects(!callProjects);
  };

  
    const handleTotalRecordsLowerLimitChange = (lower) => {
      setTotalRecordsLowerLimit(lower);
    };
  
    const handleTotalRecordsUpperLimitChange = (upper) => {
      setTotalRecordsUpperLimit(upper);
    };
  
    const handleStatusSelectionChange = (selectedOptions) => {
      setStatusSelection(selectedOptions);
    };

    const handleFilteredUsers = (users) => {
      setFilteredUsers(users);
    }
  
    const handleApplyFilter = (selectedFilter) => {
      setPageIndex(1);
      // Call the handleFilters function with the selected filter option and its corresponding value
      let fieldType = 1
      let fieldValue = []
      //Field type is 0 if it is prospectcount
      if(selectedFilter === 'prospectCount'){
      fieldType = 0;
        if(totalRecordsLowerLimit === null || totalRecordsUpperLimit === null){
          return;
        }
        //Check if lower limit is less than upper limit
        if(parseInt(totalRecordsLowerLimit, 10)>parseInt(totalRecordsUpperLimit, 10)){
          return;
        }
      }
      else if(selectedFilter === 'userName'){
        fieldValue = filteredUsers;
      }
      //Add the field value as the selected status
      else if(selectedFilter === "projectExecutionStatus"){
      fieldValue = statusSelection;
    }
    // console.log(statusSelection);
    //Default dict for a new filter.
    const newFilter = {
      "fieldName": selectedFilter,
      "fieldType": fieldType,
      "fieldMinValue": totalRecordsLowerLimit,
      "fieldMaxValue": totalRecordsUpperLimit,
      "fieldValue": fieldValue
    };
    //Check if the field name is already present in the existing filters
    const index = pageFilters.findIndex((f) => f.fieldName === newFilter.fieldName);
    // console.log("Index",index);
    //If field name is already present
    if (index !== -1) {
      // If filter with same fieldname already exists, replace its fieldvalue
      const updatedFilters = [...pageFilters];
        if (selectedFilter === "projectExecutionStatus" && fieldValue.length ===0){
          //Remove the filter from the updated filters if all status are cleared
        updatedFilters.splice(index, 1);
      }
        else if(selectedFilter === "prospectCount" && (totalRecordsLowerLimit ===null || totalRecordsLowerLimit.length ===0 || totalRecordsUpperLimit === null || totalRecordsUpperLimit.length === 0)){
          //Remove the filter from the filters if the limits are cleared
        updatedFilters.splice(index, 1);
      }
        else{
          //Else update the index of the updated filters with new filters.
        updatedFilters[index] = newFilter;
      }
      //set the new page filters. 
      setPageFilters(updatedFilters);
    }

    else {
      // Add new filter to the pageFilters array
        if(newFilter.fieldName === "projectExecutionStatus" && newFilter.fieldValue.length === 0){
        return;
      }
        else if(selectedFilter === "prospectCount" && (totalRecordsLowerLimit ===null || totalRecordsLowerLimit.length ===0 || totalRecordsUpperLimit === null || totalRecordsUpperLimit.length === 0)){
        return;
      }
        else{
        setPageFilters([...pageFilters, newFilter]);
      }
    }
    //Trigger the useEffect on callProjects to call the getProjectDetails with new filters
    setCallProjects(!callProjects);
    // setSelectedFilter(null);
    // Close the dropdown after applying the filter
    // setShowDropdown(false);
  };

  const handleClearFilters = () => {
    //Clear all the selected filters
    setShowDropdown(false);
    setPageFilters([]);
    setSearchTerm("");
    setSearchChange("");
    setTotalRecordsLowerLimit(null);
    setTotalRecordsUpperLimit(null);
    setStatusSelection([]);
    setPageIndex(1);
    setCallProjects(!callProjects);
  }

  const handleClearProjectStatus = (statusId) => {
    setPageIndex(1);
    let updatedList = []
    //close the dropdown
    setShowDropdown(false);
    //Get the index of the filter
    const index = pageFilters.findIndex((f) => f.fieldName === "projectExecutionStatus");
    if(index !== -1){
      if(statusId){
        //Modify the status selection
        const modifiedStatusSelection = statusSelection.filter(id => id !== statusId);
        setStatusSelection(modifiedStatusSelection);
          //If the modified status list is length zero remove the index from page filters
          if(modifiedStatusSelection.length ===0){
            updatedList = [...pageFilters];
              updatedList.splice(index, 1);
          }
          //Else modify the filter at the index
          else{
            updatedList = pageFilters.map(filter => {
              if (filter.fieldName === "projectExecutionStatus") {
                  return { ...filter, fieldValue: modifiedStatusSelection };
              }
              else{
                return filter;
              }
            });
          }
        }
      else{
        setStatusSelection([]);
        updatedList = [...pageFilters];
        updatedList.splice(index, 1);
      }
      setPageFilters(updatedList);
      setCallProjects(!callProjects);
  }
  }


  const handleClearTotalRecords = () => {
    setPageIndex(1);
    setShowDropdown(false);
    setTotalRecordsLowerLimit(null);
    setTotalRecordsUpperLimit(null);
    const index = pageFilters.findIndex((f) => f.fieldName === "prospectCount");
    // console.log(index,pageFilters);
      if(index !== -1){
      let updatedList = [...pageFilters];
      updatedList.splice(index, 1);
      setPageFilters(updatedList);
      setCallProjects(!callProjects);
    }
  }
  const handleClearSearchTerm = () => {
    setPageIndex(1);
    setShowDropdown(false);
    setSearchTerm("");
    setSearchChange("");
    setCallProjects(!callProjects);
  }
  return (
    <div className="list-bg">
      {/* <!-- header --> */}
      <div className="d-flex align-items-center justify-content-between page-header">
        <div>
          <span className="font-18 dark-text poppins-semibold">Lists</span>
          <span className="font-14 grey-text ms-2 lato-regular">{totalRecords} Lists</span>
        </div>
        <div>
          <button className="btn blue-btn-primary" data-tooltip-id="listtips" data-tooltip-content="Add a new list" onClick={handleOpenUpload}>
            <i className="fa-regular fa-plus me-2" ></i>Add List
          </button>
          {
            (openUploadModal || openUploadedModal || openMappingModal || openResultsModal || openAnlaysisModal || openDownloadResults || isBeforeAnalyzeMessageOpen) &&(
          <div className="upload-wrap mt-5">
            {/* upload Modal */}
            <Modal
              show={openUploadModal}
              onHide={resetProjectDetails}
              backdrop="static"
              keyboard={false}
              aria-labelledby="uploadModalLabel"
              className='modal-no-border modal-lg'
            >
              <Modal.Header className="modal-header" closeButton>
                <Modal.Title className="modal-title" id="uploadModalLabel">
                  <span className="round-modal-icon grey">
                    <i className="fa-solid fa-arrow-up-from-bracket"></i>
                  </span>{" "}
                  Upload New List
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <UploadModal 
                  onFileChange={handleFileSelect} 
                />
              </Modal.Body>
              <Modal.Footer className="modal-footer mt-3">
                <div className="primary-blue-text font-12 poppins-medium cursor-pointer" onClick={downloadSampleExcel}>
                  {/* <i className="fa-regular fa-arrow-down-to-bracket me-1"></i>
                  CSV Upload Instructions */}
                </div>
                <div>
                  <Button className="btn btn-cancel mr-15px" variant="secondary" onClick={resetProjectDetails}>
                    Cancel
                  </Button>
                  <Button className="btn blue-btn-primary font-12" variant="primary" disabled={true}>
                    Next<i className="fa-regular fa-arrow-right ms-2"></i>
                  </Button>
                </div>
              </Modal.Footer>
            </Modal>
            {/* Uploaded Modal */}
            <Modal
              show={openUploadedModal}
              onHide={resetProjectDetails}
              backdrop="static"
              keyboard={false}
              aria-labelledby="uploadedModalLabel"
              className='modal-no-border modal-lg'
            >
              <Modal.Header className="modal-header" closeButton>
                <Modal.Title className="modal-title" id="uploadedModalLabel">
                  <span className="round-modal-icon grey">
                    <i className="fa-solid fa-arrow-up-from-bracket"></i>
                  </span>{" "}
                  Upload New List
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <UploadedModal
                  file={selectedFile}
                  onFileDelete={resetProjectDetails}
                  dataErrorMessage={dataErrorMessage}
                  dataWarningMessage={dataWarningMessage}
                  projectNameErrorMessage={projectNameErrorMessage}
                  name={projectName}
                  onNameChange={handleProjectName} 
                />
                  {/* <div className="input-form my-3">
                    <label>
                      Add a Title to your List
                    </label>
                    <input className={`form-control ${projectNameErrorMessage ? 'is-invalid' : ''}`} type="text" value={projectName} onChange={(e) => handleProjectName(e.target.value)} />
                  </div>
                <UploadedComponent file={selectedFile} onDelete={handleDeleteFile}/>
                {dataErrorMessage && (
                  <p className="font-14" style={{ color: 'red' }}>{dataErrorMessage}</p>
                )}
                {!dataErrorMessage && (
                <>
                  {dataWarningMessage && (
                    <p className="text_error_message">{dataWarningMessage}</p>
                  )}
                  {projectNameErrorMessage && (
                    <p className="font-14" style={{ color: 'red' }}>{projectNameErrorMessage}</p>
                  )}
                </>
              )} */}
              </Modal.Body>
              <Modal.Footer className="modal-footer mt-3">
          {/* <div className="primary-blue-text font-12 poppins-medium cursor-pointer"onClick={downloadSampleExcel}>
                  <i className="fa-regular fa-arrow-down-to-bracket me-1"></i>
                  CSV Upload Instructions
                </div> */}
                <div className='font-12 poppins-medium cursor-pointer'>
                      <Button variant="secondary" className="btn back-btn mr-15px" onClick={handleOpenUpload}>
                      <i className='fa-solid fa-arrow-left me-1'></i>Back
                  </Button>
                </div>
                <div>
                <Button variant="secondary" className="btn btn-cancel mr-15px" onClick={resetProjectDetails}>
                          Cancel
                      </Button>
                  <Button className='btn blue-btn-primary' variant="primary" disabled={disableMappingButton} onClick={handleOpenMapping}>
                    Next
                    <i className="fa-regular fa-arrow-right ms-2"></i>
                  </Button>
                </div>
              </Modal.Footer>
            </Modal>
            {/* Mapping Modal  */}
            <Modal
              show={openMappingModal}
              onHide={resetProjectDetails}
              backdrop="static"
              keyboard={false}
              aria-labelledby="mappingModalLabel"
              className='modal-no-border modal-lg'
            >
              <Modal.Header className="modal-header" closeButton>
                <Modal.Title className="modal-title" id="mappingModalLabel">
                  <span className="round-modal-icon grey">
                    <i className="fa-solid fa-arrow-up-from-bracket"></i>
                  </span>{" "}
                  Upload New List
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <MappingModal
                  selectedFile={selectedFile} 
                  onFileDelete={resetProjectDetails} 
                  columns={csvColumns} 
                  mapping={columnMapping} 
                  onMappingChange={handleMappingChange} 
                  mappingErrorMessage={mappingErrorMessage} 
                  mappingWarningMessage={mappingWarningMessage}  
                  />
              </Modal.Body>
              <Modal.Footer className="modal-footer mt-3">
                <div className='font-12 poppins-medium cursor-pointer'>
                        <Button className="btn back-btn" variant="secondary" onClick={handleOpenUploaded}>
                        <i className='fa-solid fa-arrow-left me-1'></i>Back
                  </Button>
                </div>
                <div>
                <Button variant="secondary" className="btn btn-cancel mr-15px" onClick={resetProjectDetails}>
                          Cancel
                        </Button>
                  <Button className='btn blue-btn-primary' variant="primary" disabled={disableValidateButton} onClick={handleValidate}>
                    Next
                    <i className="fa-regular fa-arrow-right ms-2"></i>
                  </Button>
                </div>
              </Modal.Footer>
            </Modal>

            {/* Analyzing Modal */}
            <Modal
              show={openAnlaysisModal}
              onHide={resetProjectDetails}
              backdrop="static"
              keyboard={false}
              aria-labelledby="analyzingModalLabel"
              className='modal-no-border modal-lg'
            >
              <Modal.Header className="modal-header" closeButton>
                <Modal.Title className="modal-title" id="analyzingModalLabel">
                  <span className="round-modal-icon grey">
                    <i className="fa-solid fa-arrow-up-from-bracket"></i>
                  </span>{" "}
                  Upload New List
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <AnalyzingModal
                  selectedFile={selectedFile}
                  onFileDelete={resetProjectDetails} />
          {/* <AnalyzingComponent file={selectedFile} onDelete={handleDeleteFile}/> */}
              </Modal.Body>
              <Modal.Footer className="modal-footer mt-3">
                <div className='font-12 poppins-medium cursor-pointer'>
                        <Button className="btn back-btn" variant="secondary" disabled={true}>
                        <i className='fa-solid fa-arrow-left me-1'></i>Back
                  </Button>
                </div>
                <div>
                <Button variant="secondary" className="btn btn-cancel mr-15px" onClick={resetProjectDetails}>
                          Cancel
                        </Button>
                  <Button className='btn blue-btn-primary' variant="primary" disabled={true}>
                    Next
                    <i className="fa-regular fa-arrow-right ms-2"></i>
                  </Button>
                </div>
              </Modal.Footer>
            </Modal>
            {/* Analysis result Modal */}
            <Modal
              show={openResultsModal && selectedFile}
              onHide={resetProjectDetails}
              backdrop="static"
              keyboard={false}
              aria-labelledby="resultsModalLabel"
              className='modal-no-border modal-lg'
            >
              <Modal.Header className="modal-header" closeButton>
                <Modal.Title className="modal-title" id="resultsModalLabel">
                  <span className="round-modal-icon grey">
                    <i className="fa-solid fa-arrow-up-from-bracket"></i>
                  </span>{" "}
                  Upload New List
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <ResultsModal 
                selectedFile={selectedFile} 
                onFileDelete={resetProjectDetails} 
                analyzeErrorMessage={analyzeErrorMessage} 
                results={validationResults}
                />
          {/* <ResultsComponent file={selectedFile} results={validationResults} onDelete={handleDeleteFile}/>
          {analyzeErrorMessage && (
                                <p className="font-14 text_error_message">{analyzeErrorMessage}</p>
                              )} */}
              </Modal.Body>
              <Modal.Footer>
              <div className='d-flex align-items-center'>
              <Button className="btn back-btn mr-15px" variant="secondary" onClick={handleOpenMapping}>
              <i className='fa-solid fa-arrow-left me-1'></i>Back
                  </Button>
                <div className="primary-blue-text font-12 poppins-medium cursor-pointer"onClick={() => handleDownloadValidatedCSV(projectId)}>
                  <i className="fa-regular fa-arrow-down-to-bracket me-1"></i>
                  Validated CSV
                </div>
              </div>
                <div>
                  <Button className="btn btn-cancel mr-15px" variant="secondary" onClick={resetProjectDetails}>
                          Cancel
                        </Button>
                  <Button className='btn blue-btn-primary' variant="primary" disabled={disableAnalyzeButton} onClick={handleOpenBeforeAnalyze}>
                    Next
                    <i className="fa-regular fa-arrow-right ms-2"></i>
                  </Button>
                </div>
              </Modal.Footer>
            </Modal>
            {/* Before Analyze Submit, Message Model */}
            <Modal
              show={isBeforeAnalyzeMessageOpen}
              onHide={resetProjectDetails}
              backdrop="static"
              keyboard={false}
              aria-labelledby="beforeAnalyzeModalLabelInList"
              className='modal-no-border modal-lg'
            >
              <Modal.Header className="modal-header" closeButton>
                <Modal.Title className="modal-title" id="beforeAnalyzeModalLabelInList">
                  <span className="round-modal-icon grey">
                    <i className="fa-solid fa-arrow-up-from-bracket"></i>
                  </span>{" "}
                  Upload New List
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <BeforeAnalyzeMessageModal />
              </Modal.Body>
              <Modal.Footer>
                <div className='d-flex align-items-center'>
                  <Button className="btn back-btn mr-15px" variant="secondary" onClick={handleBackToOpenResult}>
                    <i className='fa-solid fa-arrow-left me-1'></i> Back
                  </Button>
                  <div className="primary-blue-text font-12 poppins-medium cursor-pointer" onClick={() => handleDownloadValidatedCSV(projectId)}>
                    <i className="fa-regular fa-arrow-down-to-bracket me-1" ></i>
                    Validated CSV
                  </div>
                </div>
                <div>                        
                  <Button className="btn btn-cancel mr-15px" variant="secondary" onClick={resetProjectDetails}>
                    Cancel
                  </Button>
                  <Button className='btn blue-btn-primary' variant="primary" disabled={disableAnalyzeButton} onClick={HandleAnalyze}>
                  Start Verifying
                    <i className="fa-regular fa-arrow-right ms-2"></i>
                  </Button>
                </div>
              </Modal.Footer>
            </Modal>
            {/* Download Result Modal */}
            <Modal
              show={openDownloadResults}
              onHide={handleCloseDownload}
              backdrop="static"
              keyboard={false}
              aria-labelledby="resultsModalLabel"
              className='modal-no-border'
            >
              <Modal.Header className="modal-header" closeButton>
                <Modal.Title className="modal-title" id="resultsModalLabel">
                  <span className="round-modal-icon grey">
                    <i className="fa-regular fa-arrow-down-to-bracket"></i>
                  </span>{" "}
                  Download Results
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
          <DownloadComponent onCheckboxChange={handleCheckboxChange} projectDetails={projectDetails}/>
              </Modal.Body>
              <Modal.Footer>
              <div className='d-flex align-items-center'>
              <Button className="btn back-btn mr-15px" variant="secondary" onClick={handleOpenMapping}>
              <i className='fa-solid fa-arrow-left me-1'></i>Back
                  </Button>
          <div className="primary-blue-text font-12 poppins-medium cursor-pointer" onClick={() => handleDownloadValidatedCSV(downloadProjectId)}>
                  <i className="fa-regular fa-arrow-down-to-bracket me-1"></i>
                  Validated CSV
                </div>
              </div>
                <div>
                  <Button className="btn btn-cancel mr-15px" variant="secondary" onClick={handleCloseDownload}>
                    Cancel
                  </Button>
                  <Button className='btn blue-btn-primary' variant="primary" onClick={handleDownloadResults} disabled={disableDownloadButton}>
                    <i className="fa-regular fa-arrow-down-to-bracket me-1"></i> Download
                  </Button>
                </div>
              </Modal.Footer>
            </Modal>

          </div>
          )
        }
        </div>
      </div>

      {/* <!-- Action Items bar --> */}

      <div
        className="d-flex align-items-center justify-content-between action-items-bar my-3"
      >
        <div className="d-flex">
          <div className="form">
            <i className="fa-regular fa-search"></i>
            <input
              type="text"
              className="form-control form-input"
              placeholder="Search"
              value={searchChange}
              onChange={handleSearchChange}
              onKeyDown={handleSearchKeyDown}
            />
          </div>
          <div className="list-widget-relative"ref={dropdownRef}>
            <div className="widget-icon ms-2 cursor-pointer" data-tooltip-content="Add filters" data-tooltip-id="listtips" onClick={() => setShowDropdown(!showDropdown)}>
              <i className={`${pageFilters.length > 0 ? "fa-solid" : "fa-regular"} fa-filter`} style={pageFilters.length > 0 ? {color: "#5C3CE4"} : {}}></i>
            </div>
            {showDropdown && (
              <div className="dropdown">
                <ul style={{ listStyleType: 'none', padding: 0, textAlign: 'left' }}>
                  <li><FilterApp onLowerChange={handleTotalRecordsLowerLimitChange}
                    onUpperChange={handleTotalRecordsUpperLimitChange} onStatusChange={handleStatusSelectionChange} applyFilter={handleApplyFilter}
                    lower={totalRecordsLowerLimit} upper={totalRecordsUpperLimit} status={statusSelection}
                    completed={completedProjects} failed={failedProjects} inprogress={inProgressProjects}
                    waiting={waitingProjects} rejected={rejectedProjects}
            onTotalClear={handleClearTotalRecords} onStatusClear={handleClearProjectStatus}/></li>
                </ul>
              </div>
            )}
          </div>
        </div>
        <div className="d-flex align-items-center">
          <div className="widget-icon cursor-pointer" onClick={() => handleSorting(-1)}>
        {sortByDirection === 2? (<i className="fa-regular fa-arrow-down-wide-short" data-tooltip-content="Sort by Ascending" data-tooltip-id="listtips"></i>)
        :(<i className="fa-regular fa-arrow-up-wide-short" data-tooltip-content="Sort by Descending" data-tooltip-id="listtips"></i>)}
          </div>
          <div className="widget-icon ms-2 cursor-pointer" data-tooltip-content="Refresh" data-tooltip-id="listtips" onClick={getProjects}>
            <i className="fa-regular fa-arrow-rotate-right"></i>
          </div>
          <div className="mx-2 grey-text font-14 d-flex align-items-center pagination">
            <span>{lowerIndex} - {upperIndex} of {totalRecords}</span>
            <div className={`widget-icon ms-2 w-auto text-center ${!previousPage ? 'disabled' : 'cursor-pointer'}`} onClick={!previousPage ? null : handlePreviousPage}>
              <div className="prev-btn" data-tooltip-content="Previous Page" data-tooltip-id="listtips">
                <i className="far fa-chevron-left" ></i>
              </div>
            </div>
            <div className={`widget-icon ms-2 w-auto text-center ${!nextPage ? 'disabled' : 'cursor-pointer'}`} onClick={!nextPage ? null : handleNextPage}>
              <div className="next-btn" data-tooltip-content="Next Page" data-tooltip-id="listtips">
                <i className="far fa-chevron-right"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Table --> */}
  {(pageFilters.length>0 || searchTerm) && (
    <p className="search-response">
      {searchTerm && (<span className="filter-tag"> Search By <i>{searchTerm}</i><i className="fa-regular fa-times ms-1 cursor-pointer" onClick={handleClearSearchTerm}></i></span>)}
    {pageFilters.map((filter, index) => (
      <span key={index}>
        {filter.fieldName === "userName" && (
          <span className="filter-tag"> Uploaded By&nbsp; {" "}
          {filter.fieldValue.map((value, valueIndex) => (
                  <span key={valueIndex}>
                    {value}
                  </span>
                ))}
          </span>)
        }

              {filter.fieldName === "projectExecutionStatus" && (
                <span className="filter-tag">Status is{" "}
                  {filter.fieldValue.map((value, valueIndex) => (
                    <span key={valueIndex}>
                      <span className="dark-text lato-bold applied-filter-clr">{projectExecutionStatusMapping[value]}&nbsp;</span>
                      <span><i className="fa-regular fa-times ms-1 cursor-pointer" onClick={() => handleClearProjectStatus(value)}></i></span>
                      &nbsp;
                    </span>
                  ))}
                </span>)}

              {filter.fieldName === "prospectCount" && (<span className="filter-tag"> Contacts between <i>{filter.fieldMinValue}</i> To <i>{filter.fieldMaxValue}</i><i className="fa-regular fa-times ms-1 cursor-pointer" onClick={handleClearTotalRecords}></i></span>)}
            </span>
          ))}
          <span className="clear-all primary-blue-text poppins-medium" onClick={handleClearFilters}> Clear All</span>
        </p>
      )}
      {!isLoading && dataAvailable && (
      <div className="table-responsive table-list-cusotom table-fixed-height">
        <table className="table pb-0 mb-0">
          <thead>
            <tr className="text-start">
              <td data-tooltip-id="listtips" data-tooltip-content="Name of the list added for analysis" onClick={() => handleSorting(2)}>List Name{sortByValue === 2 && sortByDirection === 1 && <span>&uarr;</span>}
                {sortByValue === 2 && sortByDirection === 2 && <span>&darr;</span>}</td>
              {/* <td className="w-25rem">List Name<span>&uarr;</span></td> */}
              <td data-tooltip-id="listtips" data-tooltip-content="Total prospects in the list" onClick={() => handleSorting(1)}>Contacts{sortByValue === 1 && sortByDirection === 1 && <span>&uarr;</span>}
                {sortByValue === 1 && sortByDirection === 2 && <span>&darr;</span>}</td>
              <td data-tooltip-id="listtips" data-tooltip-content="Status of list analysis">Status</td>
              <td data-tooltip-id="listtips" data-tooltip-content="Analysis progress">Results</td>
              <td className="text-center" data-tooltip-id="listtips" data-tooltip-content="Name of the uploader">Added by</td>
              <td data-tooltip-id="listtips" data-tooltip-content="Date of upload" onClick={() => handleSorting(0)}>Added on{sortByValue === 0 && sortByDirection === 1 && <span>&uarr;</span>}
                {sortByValue === 0 && sortByDirection === 2 && <span>&darr;</span>}</td>
              <td></td>
              {/* <td className="w-15rem"></td> */}
              <td>{/* It just for making some space*/}</td>
            </tr>
          </thead>

          <tbody>
      {projects && projects.length>0? (projects.map((project) => (
              // project.projectStats[0].totalProspects >0 &&(
              <ListCard key={project.projectId} project={project} onDownload={handleOpenDownload} />
              //  )
            ))) : (
              <tr></tr>
            )}
          </tbody>

        </table>
      </div>
      )}
      {
        isLoading && (
          <div className="loading-animation">
            <LoadingAnimation/>
            <span>
              Loading data, please wait...
            </span>
          </div>
        )
      }
      {
        !dataAvailable && !isLoading &&(
      <EmptyCard messHead={"No lists found!"} MessDescription={"Upload and verify your list of phone numbers today."} onClick={handleOpenUpload} />
      )
    }
      < ToastContainer />
      < Tooltip id="listtips" />
    </div>

  )
};

export default ListComponent;