import React, { useState } from "react";
import AnalyzingComponent from "../../uploads/analyzing.component";

const AnalyzingModal = ({ selectedFile, onFileDelete}) => {
    const handleDeleteFile = () => {
        onFileDelete();
    }
  return (
    <>
        <div className="form-steps mb-3">
            <div>Upload CSV</div>
            <div><i className='fa-solid fa-chevron-right'></i></div>
            <div className='active'>Map Fields</div>
            <div><i className='fa-solid fa-chevron-right'></i></div>
            <div>Review</div>
        </div>
        <AnalyzingComponent file={selectedFile} onDelete={handleDeleteFile} />
    </>
  );
};

export default AnalyzingModal;