import React from 'react';
import { Navigate } from 'react-router-dom';
import { getAccessTokenCookie, getRefreshToken } from '../apis/tokenhandler';

//AuthGuard is used to avoid users accessing the internal pages of the portal without valid token.
const AuthGuard = (props) => {
//   const isLoggedIn = getAccessTokenCookie();
// Get the refresh token
  const isLoggedIn = getRefreshToken()

//Check if the refresh token is present. If yes return to the page user is trying to access. Else navigate to the login page.
  if (isLoggedIn) {
    return props.children;
  } else {
    return <Navigate to="/" />;
  }
};

export default AuthGuard;