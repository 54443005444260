import React, { useState } from "react";
import './credits-history.component.scss';
import { Tooltip } from "react-tooltip";
import 'react-tooltip/dist/react-tooltip.css'

const PurchaseCard = ({purchase}) => {
  // console.log(purchase)
  const statusMap = {
    0: { text: 'Completed', tooltip: 'Transaction Successful',style: 'completed' },
    1: { text: 'Failed', tooltip: 'Transaction Failed',style:'failed' },
    2: { text: 'Processing', tooltip: 'Transaction is still in process. Check your account for debited balance.',style: 'inprogress' }
  };

    const dateformatter = (date) => {
        const formatedDate = new Date(date).toLocaleDateString(undefined, {
          day: "numeric",
          month: "short",
          year: "numeric",
        });
        const formattedTime = new Date(date).toLocaleTimeString(undefined, {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        });
        const [month, day, year] = formatedDate.split(" ");
        const formattedDay = day.includes(",") ? day.replace(",", "") : day; 
        const outputDate = `${formattedDay} ${month}, ${year}`;
        return `${outputDate} ${formattedTime} UTC`;
      } 
    // const invoiceId = purchase.id;
    const transactionReason = purchase.transactionReason;
    const transactionCredits = purchase.transactionCredits;
    const transactionStatus = statusMap[purchase.transactionStatus];
    let userCompleteName = purchase.userFullName;
    if(!userCompleteName){
      userCompleteName = "";
    }
    let userFullName = userCompleteName;
    if(userFullName.length > 10){
      userFullName = userFullName.slice(0,10) + "..."
    }
    const transactionDate = dateformatter(purchase.trasactionEntryDate);
    const creditBalance = purchase.creditBalance;
    return (
        <>
        <tr className="text-start main-tr-row">
          <td className="text-center">
            <span data-tooltip-id="purchasecardcomponent" data-tooltip-content={`Transaction done by ${userCompleteName}`}>{userFullName}</span>
          </td>
          <td className="text-center">
            <span data-tooltip-id="purchasecardcomponent" data-tooltip-content={`${transactionCredits} credits purchased`}> {transactionCredits} </span>
          </td>
          <td className="text-center">
            <span data-tooltip-id="purchasecardcomponent" data-tooltip-content={`Transaction type is ${transactionReason}`}>{transactionReason}</span>
          </td>
          <td className="text-center">
          <span className={`status ${transactionStatus.style}`} data-tooltip-content={transactionStatus.tooltip} data-tooltip-id="purchasecardcomponent">{transactionStatus.text}</span>
          </td>
          <td className="text-center">
            <span data-tooltip-id="purchasecardcomponent" data-tooltip-content={`Balance after the transaction ${creditBalance}`}>{creditBalance}</span>
          </td>
          <td className="text-center">
            <span data-tooltip-id="purchasecardcomponent" data-tooltip-content={`Transaction completed on ${transactionDate}`}>{transactionDate}</span>
          </td>
        </tr>
        <tr className="inside-tr">
        </tr>
      <Tooltip id = "purchasecardcomponent"/>
      </>
    );
}

export default PurchaseCard;