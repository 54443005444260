import React, { useState } from "react";
import { Tooltip } from "react-tooltip";
import 'react-tooltip/dist/react-tooltip.css'
import defaultProfilePic from '../../../../../assets/img/user-dummy.svg'
import { Mixpanel } from "../../../../configs/mixPanel";
import { useRef } from "react";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import './../publicapi.component.scss'
import { NavLink } from 'react-router-dom';
const ApiCard = ({event, onResend}) => {
    const reactAppUrl = process.env.REACT_APP_PVL_APP_URL;
    const [openDetails, setOpenDetails] = useState(false);
    const [showUrl, setShowUrl] = useState(true);
    const [showParameters,setShowParameters] = useState(true);
    const [showHeaders,setShowHeaders]  = useState(true);
    const [showBody,setShowBody] = useState(true);
    const [disableResendWebhook, setDisableResendWebhook] = useState(false);
    const requestMethodStypeMapping = {
        "POST": "post",
        "GET": "get"
    }


    const toggleOpenDetails = () => {
      setOpenDetails(!openDetails);
    }
    const toggleShowUrl = () => {
        setShowUrl(!showUrl);
    }
    const toggleShowBody = () => {
        setShowBody(!showBody);
    }
    const toggleShowParameters = () => {
        setShowParameters(!showParameters);
    }
    const toggleShowHeaders = () => {
        setShowHeaders(!showHeaders);
    }

    const startsWithTwo = (number) => {
        const numberString = number.toString();
        return numberString.charAt(0) === '2';
      };

    const resendWebhookRequest = () => {
        try{
            if(disableResendWebhook){
                return;
            }
            else{
                setDisableResendWebhook(true);
                onResend(event.eventid);
            }
        }
        catch(err){

        }
        finally{
            setDisableResendWebhook(false);
        }
        
    }
    const formatDictValue = (inputDict) => {
        const outputDict = {};
        for (const [key, value] of Object.entries(inputDict)) {
            if(value=== null || value === undefined){
                outputDict[key] = "null";
            }
             else if (Array.isArray(value)) {
                if (value.length > 1){
                    outputDict[key] = [...value.slice(0,1), '...'];
                }
                else{
                    outputDict[key] = value
                }
            }
            else if (typeof value === 'object') {
                outputDict[key] = formatDictValue(value);
              }
              else{
                outputDict[key] = value;
              }
        }
        return outputDict;
    }
      const formatJsonValue = (value) => {
        if(value === null || value === undefined){
            return "null";
        }
        else if (Array.isArray(value)) {
            if (value.length > 1) {
                return [...value.slice(0,1),"..."]
            }
            else{
                return value;
            }
    } else if (typeof value === 'object') {
        const outputDict = formatDictValue(value);
        return outputDict;
    }
    else{
        return value;
    }
}
    const dateformatter = (date) => {
        const formatedDate = new Date(date).toLocaleDateString(undefined, {
          day: "numeric",
          month: "short",
          year: "numeric",
        });
        const formattedTime = new Date(date).toLocaleTimeString(undefined, {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        });
        const [month, day, year] = formatedDate.split(" ");
        const formattedDay = day.includes(",") ? day.replace(",", "") : day; 
        const outputDate = `${formattedDay} ${month}, ${year}`;
        return `${outputDate} ${formattedTime} UTC`;
      }
    const eventType = event.eventtype;
    let originalUrlAddress = event.destination;
    let originalProjectID = null;
    if (event.encodedprojectid){
        originalProjectID = event.encodedprojectid
    }
    let urlAddress = event.destination;
    let showResend = false;
    if (eventType === 0){
        originalUrlAddress = reactAppUrl + urlAddress
    }
    else{
        showResend = true
    }
    const requestMethod = event.requestmethod;
    const responseStatus = event.responsestatus;
    const requestHeader = event.header;
    const requestPayload = event.payload;
    const requestResponse = event.response;
    const responsetime = event.timetakenforrequest;
    const formattedCreatedDate = dateformatter(event.createddate);
    let statusCodeStyle = 'error';
    if (startsWithTwo(responseStatus)){
        statusCodeStyle = 'success'
    }
    const [key, setKey] = useState('Request');
    return(
        <>
        <tr>
            <td>
            <div className="d-flex align-items-center">
            {
                        !openDetails && (
                            <div className="me-2 six-c-7 cursor-pointer"><i className="fa-regular fa-plus-square"data-tooltip-content="Expand" data-tooltip-id="apicardtips" onClick={toggleOpenDetails}></i></div>
                        )
                    }
                    {
                        openDetails && (
                            <div className="me-2 six-c-7 cursor-pointer"><i className="fa-regular fa-minus-square"data-tooltip-content="Collapse" data-tooltip-id="apicardtips" onClick={toggleOpenDetails}></i></div>
                        )
                    }
            </div>
            </td>
            <td>
                <div className="d-flex align-items-center">
                    <div className="text-limit-5rem" data-tooltip-content={originalProjectID} data-tooltip-id="apicardtips">{originalProjectID}</div>
                </div>
            </td>
            <td>
                <div className="text-limit-10rem grey-text" data-tooltip-content={originalUrlAddress} data-tooltip-id="apicardtips">{originalUrlAddress}</div>
            </td>
            <td>
                <span className={`tag ${requestMethodStypeMapping[requestMethod]} lato-bold`} data-tooltip-content={`${requestMethod} Method`} data-tooltip-id="apicardtips">{requestMethod}</span>
            </td>
            <td className="grey-text" data-tooltip-content={`Request created at ${formattedCreatedDate}`} data-tooltip-id="apicardtips">
                {formattedCreatedDate}
            </td>
            <td className="grey-text" data-tooltip-content={`Response time is ${responsetime}ms`} data-tooltip-id="apicardtips">
                {responsetime}ms
            </td>
            <td>
                <span className={`tag ${statusCodeStyle}`} data-tooltip-content={`Response is ${statusCodeStyle}`} data-tooltip-id="apicardtips">{responseStatus}</span>
            </td>
            {
                showResend ? (
                    <td>
                        <span className={`${disableResendWebhook ? 'disabled' : 'cursor-pointer'}`} data-tooltip-content="Resend webhook" data-tooltip-id="apicardtips"><i className="fa-solid fa-reply" onClick={resendWebhookRequest}></i></span>
                    </td>
                ):(
                    <td></td>
                )
            }
        </tr>
        {openDetails && (
        <tr>
            <td colSpan={6} className="grey-bg">
                <div className="profile-card mb-4">
                    <div className="logsTabs">
                        <Tabs
                            defaultActiveKey="profile"
                            id="uncontrolled-tab-example"
                            activeKey={key}
                            onSelect={(k) => setKey(k)}
                            className="mb-3 mt-3"
                        >
                            <Tab eventKey="Request" title="Request">
                                <div className="d-flex">
                                    {
                                        showUrl && (
                                            <div><i className="fa-solid fa-caret-down six-c-7 me-2" onClick={toggleShowUrl}></i></div>
                                        )
                                    }
                                    {
                                        !showUrl && (
                                            <div><i className="fa-solid fa-caret-right six-c-7 me-2" onClick={toggleShowUrl}></i></div>
                                        )
                                    }
                                    <div>
                                        <div className="poppins-bold ">URL</div>
                                        {
                                            showUrl && (
                                                <div className="default-text">{originalUrlAddress}</div>
                                            )
                                        }
                                    </div>
                                </div>

                                <div className="d-flex mt-4 max-w-900">
                                    {
                                        showParameters && (
                                            <div><i className="fa-solid fa-caret-down six-c-7 me-2" onClick={toggleShowParameters}></i></div>
                                        )
                                    }
                                    {
                                        !showParameters && (
                                            <div><i className="fa-solid fa-caret-right six-c-7 me-2" onClick={toggleShowParameters}></i></div>
                                        )
                                    }
                                    <div className="w-100">
                                        <div className="poppins-bold ">Parameters</div>
                                        {
                                            showParameters && (
                                                <div className="font-12 radius-box mt-2">
                                                    {Object.keys(requestPayload).map(key => (
                                                        <div key={key} className="row">
                                                            <div className="col-3 grey-text border-right">
                                                                <div className="my-2 ms-3">{key}</div>
                                                            </div>
                                                            <div className="col-9 default-text">
                                                                <div className="my-2 ms-1">
                                                                    <pre>{JSON.stringify(formatJsonValue(requestPayload[key]),null,2)}</pre>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="Response" title="Response">
                                <div className="d-flex mt-4 max-w-900">
                                    {
                                        showHeaders && (
                                            <div><i className="fa-solid fa-caret-down six-c-7 me-2" onClick={toggleShowHeaders}></i></div>
                                        )
                                    }
                                    {
                                        !showHeaders && (
                                            <div><i className="fa-solid fa-caret-right six-c-7 me-2" onClick={toggleShowHeaders}></i></div>
                                        )
                                    }
                                    
                                    <div className="w-100">
                                        <div className="poppins-bold ">Headers</div>
                                        {
                                            showHeaders && (
                                                <div className="default-text">
                                                    <div className="my-2 ms-1">
                                                    <pre>
                                                    {Object.keys(requestHeader).map(key => (
                                                        <div key={key}>
                                                            <span className="poppins-bold">{key}:</span> <span>{requestHeader[key]}</span>
                                                        </div>
                                                    ))}
                                                    </pre>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>

                                <div className="d-flex mt-4 max-w-900">
                                    {
                                        showBody && (
                                            <div><i className="fa-solid fa-caret-down six-c-7 me-2" onClick={toggleShowBody}></i></div>
                                        )
                                    }
                                    {
                                        !showBody && (
                                            <div><i className="fa-solid fa-caret-right six-c-7 me-2" onClick={toggleShowBody}></i></div>
                                        )
                                    }
                                    <div className="w-100">
                                        <div className="poppins-bold ">Body</div>
                                        {
                                            showBody && requestResponse && (
                                                <div className="font-12 radius-box mt-2">
                                                    {Object.keys(requestResponse).map(key => (
                                                        <div key={key} className="row">
                                                            <div className="col-3 grey-text border-right">
                                                                <div className="my-2 ms-3">{key}</div>
                                                            </div>
                                                            <div className="col-9 default-text">
                                                                <div className="my-2 ms-1">
                                                                    <pre>{JSON.stringify(formatJsonValue(requestResponse[key]),null,2)}</pre>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </td>
        </tr>
        )}
        < Tooltip id="apicardtips" />
        </>
    );
}

export default ApiCard;