import mixpanel from 'mixpanel-browser';
const mixPanelToken = process.env.REACT_APP_MIX_PANEL_TOKEN;
mixpanel.init(mixPanelToken, { debug: false});

//This component contains the helper functions for mixpanel events tracking
let TRACKING_ACTIVE = false;  // Set to false if working in dev environment. Or use a snippet which evaluates to true in production like env === production

let actions = {
  identify: (id) => {
    // console.log("Tracking",id);
    //Register the tracking id
    if (TRACKING_ACTIVE) mixpanel.identify(id);
  },
  alias: (id) => {
    if (TRACKING_ACTIVE) mixpanel.alias(id);
  },
  track: (name, props) => {
    //The event to be tracked
    if (TRACKING_ACTIVE) mixpanel.track(name, props);
  },
  track_links: (div, name, props) => {
    if (TRACKING_ACTIVE) mixpanel.track_links(div, name, props);
  },
  register: (props) => {
    if (TRACKING_ACTIVE) mixpanel.register(props);
  },
  reset: () => {
    if (TRACKING_ACTIVE) mixpanel.reset();
  },
  people: {
    set: (props) => {
      if (TRACKING_ACTIVE) mixpanel.people.set(props);
    },
    increment: (props) => {
      if (TRACKING_ACTIVE) mixpanel.people.increment(props);
    },
  },
};

export let Mixpanel = actions;