// src/Banner.js

import React, { useState } from 'react';
import './header.component.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation,faTriangleExclamation,faCircleCheck, faTimes, faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import BuySubscriptionOrCredits from '../base-content/billing/buycredits.component';


const Banner = ({ bannerType, subscriptionStatus, isSubscribed, endDate,isWithinThreeDays }) => {
  const [openSubscriptionModal, setOpenSubscriptionModal] = useState(false);

  const [isBannerVisible, setBannerVisible] = useState(true);
  const handleOpenSubscriptionModal = () => {
    setOpenSubscriptionModal(true);
  }
  const handleClose = () => {
    setBannerVisible(false);
    sessionStorage.setItem('isBannerClosed',true);
  };

  const handleCloseSubscriptionModal = () => {
    setOpenSubscriptionModal(false);
  }
  return (
    
    <>
    {
        openSubscriptionModal && (
          <BuySubscriptionOrCredits onClose={handleCloseSubscriptionModal} checkoutType={'subscription'}/>
          // <SubscriptionModal onClose={handleSubscriptionClose}/>
        )
      }
    {/* Subsciption Expired */}
    { isSubscribed&&[3, 12].includes(subscriptionStatus) && !sessionStorage.getItem('isBannerClosed') && (<div className="banner error">
      {/* <FontAwesomeIcon icon={faTriangleExclamation}  style={{ color: 'red' }}/>         */}
     
      <span> 
      <i className='fa-solid fa-warning red-icon me-1 font-14'></i>
        <span className='lato-bold dark-text'> No Active Subscription! </span>SureConnect requires an 
        active subscription to verify phone number. 
        {/* <button className='rounded-border-btn poppins-medium' onClick={handleOpenSubscriptionModal}>Subscribe Now</button> */}
        </span>
        <button
            className="close-btn"
            onClick={handleClose}            
          >
            {/* <FontAwesomeIcon icon={faTimes} /> */}
            <i className='fa-solid fa-times'></i>
          </button>
    </div>)}

    {/* Subsciption Decrement Scheduled */}
    { isSubscribed && subscriptionStatus === 9 && !sessionStorage.getItem('isBannerClosed') && (<div className="banner warning">
      <i className='fa-solid fa-exclamation-circle warning-icon me-1 font-14'></i>
        <span > License Reduction Scheduled. Ensure your active users match the new license count to prevent automatic deactivation.</span>
        <button
            className="close-btn"
            onClick={handleClose}            
          >
            {/* <FontAwesomeIcon icon={faTimes} /> */}
            <i className='fa-solid fa-times'></i>
          </button>
    </div>)}

    {/* // When user have not taken any subscription */}
    { !isSubscribed  && !sessionStorage.getItem('isBannerClosed') && (<div className="banner error">
        {/* <FontAwesomeIcon icon={faCircleExclamation} /> */}
        <i className='fa-solid fa-warning warning-icon me-1 font-14'></i>
        <span> 
        <span className='lato-bold dark-text'> No Active Subscription! </span>SureConnect requires an 
        active subscription to verify phone number. 
        {/* <button className='rounded-border-btn poppins-medium' onClick={handleOpenSubscriptionModal}>Subscribe Now</button> */}
        </span>
        <button
            className="close-btn"
            onClick={handleClose}            
          >
            {/* <FontAwesomeIcon icon={faTimes} /> */}
            <i className='fa-solid fa-times'></i>
          </button>
    </div>)}

    { isSubscribed && subscriptionStatus===0 && (<div className="banner info">
        {/* <FontAwesomeIcon icon={faCircleExclamation} /> */}
        <i className='fa-solid fa-exclamation-circle grey-icon me-1 font-14'></i>
        <span> 
        <span className='lato-bold dark-text'> Subscribed but Payment Under Processing! </span>SureConnect requires an 
        active subscription to verify phone number. 
        </span>
    </div>)}
    
    {  isSubscribed && subscriptionStatus === 2 && !sessionStorage.getItem('isBannerClosed') &&(<div className="banner warning">
      {/* <FontAwesomeIcon icon={faTriangleExclamation} /> */}
      <i className='fa-solid fa-exclamation-circle warning-icon me-1 font-14'></i>
        <span> 
        Your subscription is scheduled to cancel at <span className='lato-bold dark-text'>{endDate}</span>. You will lose all of your credits. Are you sure?
        </span>
        <button
            className="close-btn"
            onClick={handleClose}            
          >
            {/* <FontAwesomeIcon icon={faTimes} /> */}
            <i className='fa-solid fa-times'></i>
          </button>
    </div>)}

    {  isSubscribed && subscriptionStatus === 5 && !sessionStorage.getItem('isBannerClosed') &&(<div className="banner warning">
      {/* <FontAwesomeIcon icon={faTriangleExclamation} /> */}
      <i className='fa-solid fa-exclamation-circle warning-icon me-1 font-14'></i>
        <span> 
        You have requested for cancellation of subscription.
        </span>
        <button
            className="close-btn"
            onClick={handleClose}            
          >
            {/* <FontAwesomeIcon icon={faTimes} /> */}
            <i className='fa-solid fa-times'></i>
          </button>
    </div>)}

    {  isSubscribed && subscriptionStatus === 6 && !sessionStorage.getItem('isBannerClosed') &&(<div className="banner warning">
      {/* <FontAwesomeIcon icon={faTriangleExclamation} /> */}
      <i className='fa-solid fa-warning red-icon me-1 font-14'></i>
        <span> 
        You have requested for withdrawl of subscription cancellation.
        </span>
        <button
            className="close-btn"
            onClick={handleClose}           
          >
            {/* <FontAwesomeIcon icon={faTimes} /> */}
            <i className='fa-solid fa-times'></i>
          </button>
    </div>)}

    {  isSubscribed && subscriptionStatus === 4 && !sessionStorage.getItem('isBannerClosed') &&(<div className="banner warning">
      {/* <FontAwesomeIcon icon={faTriangleExclamation} /> */}
      <i className='fa-solid fa-warning red-icon me-1 font-14'></i>
        <span> 
        Payment overdue. Renew now to keep your credits.
        </span>
        <button
            className="close-btn"
            onClick={handleClose}            
          >
            {/* <FontAwesomeIcon icon={faTimes} /> */}
            <i className='fa-solid fa-times'></i>
          </button>
    </div>)}

    {  isSubscribed && subscriptionStatus === 1 && isWithinThreeDays && !sessionStorage.getItem('isBannerClosed') &&(<div className="banner info">
      {/* <FontAwesomeIcon icon={faCircleInfo} /> */}
      <i className='fa-solid fa-exclamation-circle warning-icon me-1 font-14'></i>
        <span> 
        Your Subscription Expires on <span className='lato-bold dark-text'>{endDate}</span>. Renew to Enjoy Continuous Access.
        </span>
        <button
            className="close-btn"
            onClick={handleClose}            
          >
            {/* <FontAwesomeIcon icon={faTimes} /> */}
            <i className='fa-solid fa-times'></i>
          </button>
    </div>)}
    </>
    
  );
};

export default Banner;
