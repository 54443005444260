import React from "react";
import { useState, useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import './publicapi.component.scss'
import { NavLink } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import axios from "axios";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { generateApiKey, getApiKey,fetchApiWebhookEvents,resendWebhook, GetPermission } from "../../../apis/pvlapis";
import ApiCard from "./card/apicard.component";
import { useRef } from "react";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { Tooltip } from "react-tooltip";
import 'react-tooltip/dist/react-tooltip.css'
import EventsFilterApp from "./eventsfilter.component";
import LoadingAnimation from "../lottie-files/loading.lotte";
import EmptyCard from "../list/emptylist.component";
import Sidebar from "../side-bar.component";
import PermissionDenied from "../../shared/permission-denied.component";

const PublicAPI = () => {

    // API Documentation link
    const apiDocLink = "https://documenter.getpostman.com/view/34788185/2sA3JKeiDs"
    //State variables for List page settings
    const [callEvents, setCallEvents] = useState(true);
    const [eventsData,setEventsData] = useState(null);
    const [responsePending, setResponsePending] = useState(false);
    const [dataAvailable,setDataAvailable] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isPermissionLoading, setIsPermissionLoading] = useState(false);
    const [queuedRequests,setQueuedRequests] = useState(false);
    const [checkQueuedRequests,setCheckQueuedRequests] = useState(false);
    //Filters
    const [nextPage, setNextPage] = useState(false);
    const [previousPage, setPreviousPage] = useState(false);
    const [pageDataCount, setpageDataCount] = useState(50);
    const [searchChange, setSearchChange] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const [pageIndex, setPageIndex] = useState(1);
    const [sortByValue, setSortByValue] = useState(0);
    const [sortByDirection, setSortByDirection] = useState(2);
    const [pageFilters, setPageFilters] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [lowerIndex, setLowerIndex] = useState(0);
    const [upperIndex, setUpperIndex] = useState(0);
    const [showDropdown, setShowDropdown] = useState(false);
    // const [selectedFilter, setSelectedFilter] = useState('');
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [methodSelection, setMethodSelection] = useState([]);
    const [responseSelection, setResponseSelection] = useState([]);
    const [eventSelection, setEventSelection] = useState([]);

    const [showApiTab, setShowApiTab] = useState(null);
    const dropdownRef = useRef(null);

    const [apiKey, setApiKey] = useState("");
    const [showApiKey, setShowApiKey] = useState(null);
    const [disableGenerateButton, setDisableGenerateButton] = useState(false);
    const evenTypeMapping = {
        "1": "Webhook",
        "0": "API"
    }
    const handleGenerateAPIKey = async () => {
        try {
            setDisableGenerateButton(true);
            const response = await generateApiKey();
            if (response.data.statusCode === 200) {
                setApiKey(response.data.result.api_key);
                toast.success("API Key generated successfully", { position: toast.POSITION.BOTTOM_LEFT });
            }
            else {
                toast.error("Something went wrong. Please try again after some time.", { position: toast.POSITION.BOTTOM_LEFT });
            }
        }
        catch (err) {
            // console.log(err)
        }
        finally {
            setDisableGenerateButton(false);
        }
    }

    const handleGetAPIKey = async () => {
        try {
            setDisableGenerateButton(true);
            const response = await getApiKey();
            if (response.data.statusCode === 200) {
                setApiKey(response.data.result.api_key)
            }
            else {
                toast.error("Something went wrong. Please try again after some time.", { position: toast.POSITION.BOTTOM_LEFT });
            }
        }
        catch (err) {
            // console.log(err)
        }
        finally {
            setDisableGenerateButton(false);
        }
    }

    //Fetch the api and webhook details
    const handleFetchApiWebhookEvents = async () => {
        //Check if we are waiting for a response
        if(responsePending){
          //Make the queued requests true to call later
          setQueuedRequests(true);
          return;
        }
        const payload = {
          "pageIndex": pageIndex,
          "pageDataCount": pageDataCount,
          "search": searchTerm,
          "sortByValue": sortByValue,
          "sortByDirection": sortByDirection,
          "filters": pageFilters
        };
        try {
          setResponsePending(true);
          setIsLoading(true);
          setNextPage(false);
          setPreviousPage(false);
          const response = await fetchApiWebhookEvents(payload);
          // console.log(response.data);
            if(response.data.statusCode === 200){
            setEventsData(response.data.result.data);
            setPreviousPage(response.data.result.pagination["previous"]);
            setNextPage(response.data.result.pagination["next"]);
            setTotalRecords(response.data.result.totalRecords);
            if(response.data.result.data.length === 0){
              setDataAvailable(false);
            }
            else{
              setDataAvailable(true);
            }
                setUpperIndex((pageIndex-1)*pageDataCount+response.data.result.data.length);
                if(response.data.result.data.length === 0){
                  setLowerIndex((pageIndex-1)*pageDataCount);
            }
                else{
                  setLowerIndex((pageIndex-1)*pageDataCount+1);
            }
    
          }
          else{
            toast.error("Something went wrong. Please try again.",{position: toast.POSITION.BOTTOM_LEFT});
          }
        } catch (error) {
          // console.log(error);
              toast.error("Something went wrong. Please try again after some time.",{position:toast.POSITION.BOTTOM_LEFT})
            }
            finally{
              setIsLoading(false);
              //Set the response pending to false
              setResponsePending(false);
              //Check if there are any pending requests
              setCheckQueuedRequests(!checkQueuedRequests);
            }
      };
      const handleResendWebhookEvent = async (eventid) => {
        try {
          const payload = {
            "eventId": eventid
          }
            const response = await resendWebhook(payload);
            if (response.data.statusCode === 200) {
              toast.success("Webhook resent successfully!", { position: toast.POSITION.BOTTOM_LEFT });
            }
            else {
                toast.error("Something went wrong. Please try again after some time.", { position: toast.POSITION.BOTTOM_LEFT });
            }
        }
        catch (err) {
            // console.log(err)
        }
    }
    const copyToClipboard = () => {
        navigator.clipboard.writeText(apiKey)
            .then(() => {
                toast.info("Copied successfully", { position: toast.POSITION.BOTTOM_LEFT });
            })
            .catch(err => {
                // console.log(err)
            });
    };
      //All filtering, sorting, searching handlers
  const handleNextPage = () => {
    if(nextPage){
      setPageIndex(pageIndex+1);
      setCallEvents(!callEvents);
  }
}
const handleClickOutside = (event) => {
  if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
    setShowDropdown(false);
  }
};

useEffect(() => {
  document.addEventListener('mousedown', handleClickOutside);
  return () => {
    document.removeEventListener('mousedown', handleClickOutside);
  };
}, []);
const handlePreviousPage = () => {
    if(previousPage){
      setPageIndex(pageIndex-1);
      setCallEvents(!callEvents);
  }
}

const handleSorting = (field) => {
    if(sortByDirection === 1){
    setSortByDirection(2);
  }
    else{
    setSortByDirection(1);
  }
    if(field!==-1){
    setSortByValue(field);
  }
  setPageIndex(1);
  setCallEvents(!callEvents);
}

const handleSearchKeyDown = (event) => {
  if (event.key === 'Enter') {
    setSearchTerm(searchChange);
    setSearchChange("");
    setPageIndex(1);
    setCallEvents(!callEvents);
  }
};

const handleSearchChange = (event) => {
  setSearchChange(event.target.value);
  setSearchTerm(event.target.value);
  setPageIndex(1);
  setCallEvents(!callEvents);
};


  const handleMethodSelectionChange = (selectedOptions) => {
    setMethodSelection(selectedOptions);
  };

  const handleResponseSelection = (selectedOptions) => {
    setResponseSelection(selectedOptions);
  };
  const handleEventSelection = (selectedOptions) => {
    setEventSelection(selectedOptions);
  };

  const handleFilteredUsers = (users) => {
    setFilteredUsers(users);
  }
  const handleApiDocumentationClick = () => {
    // Open the apidoc link in a new window
    window.open(apiDocLink, '_blank');
  }

  const handleApplyFilter = (selectedFilter) => {
    setPageIndex(1);
    // Call the handleFilters function with the selected filter option and its corresponding value
    let fieldType = 1
    let fieldValue = []
    if(selectedFilter === 'responsestatus'){
      fieldValue = responseSelection;
    }
    else if(selectedFilter === "requestmethod"){
        fieldValue = methodSelection;
    }
    else if(selectedFilter === "eventtype"){
        fieldValue = eventSelection;
    }
  const newFilter = {
    "fieldName": selectedFilter,
    "fieldType": fieldType,
    "fieldMinValue": 0,
    "fieldMaxValue": 0,
    "fieldValue": fieldValue
  };
  const index = pageFilters.findIndex((f) => f.fieldName === newFilter.fieldName);
  // console.log("Index",index);
  if (index !== -1) {
    // If filter with same fieldname already exists, replace its fieldvalue
    const updatedFilters = [...pageFilters];
      if (selectedFilter === "requestmethod" && fieldValue.length ===0){
      updatedFilters.splice(index, 1);
    }
    else if (selectedFilter === "responsestatus" && fieldValue.length ===0){
        updatedFilters.splice(index, 1);
      }
      else if (selectedFilter === "eventtype" && fieldValue.length ===0){
        updatedFilters.splice(index, 1);
      }
      else{
      updatedFilters[index] = newFilter;
    }
    setPageFilters(updatedFilters);
  }

  else {
    // Add new filter to the pageFilters array
      if(newFilter.fieldName === "requestmethod" && newFilter.fieldValue.length === 0){
      return;
    }
    else if(newFilter.fieldName === "responsestatus" && newFilter.fieldValue.length === 0){
        return;
      }
    else if(newFilter.fieldName === "eventtype" && newFilter.fieldValue.length === 0){
        return;
      }
      else{
      setPageFilters([...pageFilters, newFilter]);
    }
  }
  setCallEvents(!callEvents);
};

const handleClearFilters = () => {
  setShowDropdown(false);
  setMethodSelection([])
  setResponseSelection([]);
  setEventSelection([]);
  setPageFilters([]);
  setSearchTerm("");
  setSearchChange("");
  setMethodSelection([]);
  setPageIndex(1);
  setCallEvents(!callEvents);
}

const handleClearSelectedFilter = (statusId,filtername) => {
  setPageIndex(1);
  let updatedList = []
  let modifiedValue = []
  //close the dropdown
  setShowDropdown(false);
  //Get the index of the filter
  const index = pageFilters.findIndex((f) => f.fieldName === filtername);
  if(index !== -1){
    if(statusId){
      //Modify the status selection
      if (filtername === "requestmethod"){
        const modifiedMethodSelection = methodSelection.filter(id => id !== statusId);
        modifiedValue = modifiedMethodSelection;
        setMethodSelection(modifiedMethodSelection);
      }
      else if (filtername === "responsestatus"){
        const modifiedResponseStatus = responseSelection.filter(id => id !== statusId);
        modifiedValue = modifiedResponseStatus;
        setResponseSelection(modifiedResponseStatus);
      }
      else if (filtername === "eventtype"){
        const modifiedEventSelection = eventSelection.filter(id => id !== statusId);
        modifiedValue = modifiedEventSelection;
        setEventSelection(modifiedEventSelection);
      }
        //If the modified status list is length zero remove the index from page filters
        if(modifiedValue.length ===0){
          updatedList = [...pageFilters];
            updatedList.splice(index, 1);
        }
        //Else modify the filter at the index
        else{
          updatedList = pageFilters.map(filter => {
            if (filter.fieldName === filtername) {
                return { ...filter, fieldValue: modifiedValue };
            }
            else{
              return filter;
            }
          });
        }
      }
    else{
        if (filtername === "requestmethod"){
            setMethodSelection([]);
        }
        else if (filtername === "responsestatus"){
            setResponseSelection([]);
        }
        else if (filtername === "eventtype"){
            setEventSelection([]);
        }
      updatedList = [...pageFilters];
      updatedList.splice(index, 1);
    }
    setPageFilters(updatedList);
    setCallEvents(!callEvents);
}
}

const handleClearSearchTerm = () => {
  setPageIndex(1);
  setShowDropdown(false);
  setSearchTerm("");
  setSearchChange("");
  setCallEvents(!callEvents);
}

    useEffect(() => {
        // Function to call when the page is loaded
        handleGetAPIKey();
        handleFetchApiWebhookEvents();
        // setEventsData(jsonData);
    }, []);

    useEffect(() => {
        if (apiKey.length > 80) {
            setShowApiKey("..." + apiKey.slice(-80))
        }
        else{
          setShowApiKey(apiKey);
        }
    }, [apiKey]);

    useEffect(() => {
        handleFetchApiWebhookEvents();
    }, [callEvents]);

    useEffect(() => {
        //Check if some requests are queued
        if(queuedRequests){
          //Set the queued requests to false and call the apiwebhookevents
          setQueuedRequests(false);
          handleFetchApiWebhookEvents();
        }
      }, [checkQueuedRequests]);

      
      const handleGetPermission = async() =>{
        setIsPermissionLoading(true);
  
        try {
            const response = await GetPermission();
            if(response.statusCode !==200 || response?.result === null){
                return;
            }
            const settingsviewapi = response?.result?.find(obj => obj?.component === "settingsviewapi");
  
            setShowApiTab(settingsviewapi?.permission);
        } catch (error) {
            toast.error("Something went wrong. Please try again after some time.",{position:toast.POSITION.BOTTOM_LEFT})
        }
        finally{
          setIsPermissionLoading(false);
        }
        
    }
    useEffect(() => {
        handleGetPermission();
    }, []);
    const [key, setKey] = useState('Request');
    return (
        <Container fluid className="main-page-wrap">
            <Row>
                <Col>
                    <div className="d-flex align-items-center justify-content-between page-header py-3">
                        <span class="font-18 dark-text poppins-semibold">Settings</span>
                    </div>
                </Col>
            </Row>
            <Row className="mt-4">
                <Col sm={3}>
                    <Sidebar/>
                </Col>
                {showApiTab && !isPermissionLoading &&<Col sm={9}>
                    <div className="profile-card mb-4">
                        <div className="d-flex align-items-center justify-content-between">
                        <div className="card-title api-div ps-0">API Key</div>
                        <button className="btn generate-api-btn small-btn" onClick={handleGenerateAPIKey} disabled={disableGenerateButton}>Generate New API Key</button>
                        </div>
                        {apiKey && (
                            <div className="key-wrap d-flex align-items-center justify-content-between">
                                <span className="text-to-copy" data-tooltip-content="Your api key" data-tooltip-id="apitips">{showApiKey}</span>
                                <button className="btn cBlueButtonLight dInlineBlock ng-star-inserted" onClick={copyToClipboard}>
                                    <i className="far fa-copy mr-1"></i> Copy
                                </button>
                            </div>
                        )}
                        <div className="api-documentation-text api-div ps-0">Refer to the <span className="api-documentation" onClick={handleApiDocumentationClick}>API Documentation</span> for more details.</div>
                    </div>
                    <div className="profile-card mb-4">
                        <div className="card-title api-div ps-0">API Logs</div>
                        <div>
                            <div
                                className="d-flex align-items-center justify-content-between action-items-bar my-3"
                            >
                                <div className="d-flex">
                                    <div className="form">
                                        <i className="fa-regular fa-search"></i>
                                        <input
                                            type="text"
                                            className="form-control form-input"
                                            placeholder="Search"
                                            value={searchChange}
                                            onChange={handleSearchChange}
                                            onKeyDown={handleSearchKeyDown}
                                            data-tooltip-content="Search by list id"
                                            data-tooltip-id="apitips"
                                        />
                                    </div>
                                    <div className="list-widget-relative" ref={dropdownRef}>
                                        <div className="widget-icon ms-2 cursor-pointer" data-tooltip-content="Add filters" data-tooltip-id="apitips" onClick={() => setShowDropdown(!showDropdown)}>
                                            <i className={`${pageFilters.length > 0 ? "fa-solid" : "fa-regular"} fa-filter`} style={pageFilters.length > 0 ? { color: "#5C3CE4" } : {}}></i>
                                        </div>
                                        {showDropdown && (
                                            <div className="dropdown">
                                                <ul style={{ listStyleType: 'none', padding: 0, textAlign: 'left' }}>
                                                <li><EventsFilterApp onMethodChange={handleMethodSelectionChange} onEventChange={handleEventSelection} onResponseChange={handleResponseSelection} 
                                                        applyFilter={handleApplyFilter}
                                                        methods={methodSelection} responses={responseSelection} events={eventSelection}
                                                        onFilterClear={handleClearSelectedFilter}/></li>
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="d-flex align-items-center">
                                    <div className="widget-icon cursor-pointer" onClick={() => handleSorting(-1)}>
                                        {sortByDirection === 2 ? (<i className="fa-regular fa-arrow-down-wide-short" data-tooltip-content="Sort by Ascending" data-tooltip-id="apitips"></i>)
                                            : (<i className="fa-regular fa-arrow-up-wide-short" data-tooltip-content="Sort by Descending" data-tooltip-id="apitips"></i>)}
                                    </div>
                                    <div className="widget-icon ms-2 cursor-pointer" data-tooltip-content="Refresh" data-tooltip-id="apitips" onClick={handleFetchApiWebhookEvents}>
                                        <i className="fa-regular fa-arrow-rotate-right"></i>
                                    </div>
                                    <div className="mx-2 grey-text font-14 d-flex align-items-center pagination">
                                        <span>{lowerIndex} - {upperIndex} of {totalRecords}</span>
                                        <div className={`widget-icon ms-2 w-auto text-center ${!previousPage ? 'disabled' : 'cursor-pointer'}`} onClick={!previousPage ? null : handlePreviousPage}>
                                            <div className="prev-btn" data-tooltip-content="Previous Page" data-tooltip-id="apitips">
                                                <i className="far fa-chevron-left" ></i>
                                            </div>
                                        </div>
                                        <div className={`widget-icon ms-2 w-auto text-center ${!nextPage ? 'disabled' : 'cursor-pointer'}`} onClick={!nextPage ? null : handleNextPage}>
                                            <div className="next-btn" data-tooltip-content="Next Page" data-tooltip-id="apitips">
                                                <i className="far fa-chevron-right"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            {(pageFilters.length>0 || searchTerm) && (
                                <p className="search-response">
                                {searchTerm && (<span className="filter-tag"> Search By <i>{searchTerm}</i><i className="fa-regular fa-times ms-1 cursor-pointer" onClick={handleClearSearchTerm}></i></span>)}
                                {pageFilters.map((filter, index) => (
                                <span key={index}>
                                    {filter.fieldName === "requestmethod" && (
                                        <span className="filter-tag">Method is{" "}
                                        {filter.fieldValue.map((value, valueIndex) => (
                                            <span key={valueIndex}>
                                            <span className="dark-text lato-bold applied-filter-clr">{value}&nbsp;</span>
                                            <span><i className="fa-regular fa-times ms-1 cursor-pointer" onClick={() => handleClearSelectedFilter(value,"requestmethod")}></i></span>
                                            &nbsp;
                                            </span>
                                        ))}
                                        </span>)}
                                        {filter.fieldName === "responsestatus" && (
                                        <span className="filter-tag">Response is{" "}
                                        {filter.fieldValue.map((value, valueIndex) => (
                                            <span key={valueIndex}>
                                            <span className="dark-text lato-bold applied-filter-clr">{value}&nbsp;</span>
                                            <span><i className="fa-regular fa-times ms-1 cursor-pointer" onClick={() => handleClearSelectedFilter(value,"responsestatus")}></i></span>
                                            &nbsp;
                                            </span>
                                        ))}
                                        </span>)}
                                        {filter.fieldName === "eventtype" && (
                                        <span className="filter-tag">Type is{" "}
                                        {filter.fieldValue.map((value, valueIndex) => (
                                            <span key={valueIndex}>
                                            <span className="dark-text lato-bold applied-filter-clr">{evenTypeMapping[value]}&nbsp;</span>
                                            <span><i className="fa-regular fa-times ms-1 cursor-pointer" onClick={() => handleClearSelectedFilter(value,"eventtype")}></i></span>
                                            &nbsp;
                                            </span>
                                        ))}
                                        </span>)}
                                        </span>
                                    ))}
                                    <span className="clear-all primary-blue-text poppins-medium" onClick={handleClearFilters}> Clear All</span>
                                    </p>
                                )}
                            {!isLoading && dataAvailable && (
                            <div style={{ margin: '0 -24px' }}>
                                <table className="table">
                                    <thead>
                                        <tr className="grey-text">
                                          <td></td>
                                            <td data-tooltip-id="apitips" data-tooltip-content="ID of the list">List ID</td>
                                            <td data-tooltip-id="apitips" data-tooltip-content="Destination url of the request">URL</td>
                                            <td data-tooltip-id="apitips" data-tooltip-content="Request method">Method</td>
                                            <td data-tooltip-id="apitips" data-tooltip-content="Request time" onClick={() => handleSorting(0)}>Date{sortByValue === 0 && sortByDirection === 1 && <span>&uarr;</span>}
                                            {sortByValue === 0 && sortByDirection === 2 && <span>&darr;</span>}</td>
                                            <td data-tooltip-id="apitips" data-tooltip-content="Time taken for response in ms" onClick={() => handleSorting(2)}>Response Time{sortByValue === 2 && sortByDirection === 1 && <span>&uarr;</span>}
                                            {sortByValue === 2 && sortByDirection === 2 && <span>&darr;</span>}</td>
                                            <td data-tooltip-id="apitips" data-tooltip-content="Status of request">Status</td>
                                            <td></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {eventsData && eventsData.length>0? (eventsData.map((event) => (
                                        <ApiCard key={event.createddate} event={event} onResend={handleResendWebhookEvent} />
                                        ))) : (
                                        <tr></tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            )}
                            {
                                isLoading && (
                                <div className="loading-animation">
                                    <LoadingAnimation/>
                                    <span>
                                    Loading data, please wait...
                                    </span>
                                </div>
                                )
                            }
                            {
                                !dataAvailable && !isLoading &&(
                                    <EmptyCard messHead={"No events found!"} MessDescription={"Use SureConnect API to start validating your lists."} onClick={handleFetchApiWebhookEvents} styling={'d-none'} />
                            )
                            }
                        </div>
                    </div>
                </Col>
                }
                {
                  isPermissionLoading && (
                  <div className="loading-animation">
                      <LoadingAnimation/>
                      <span>
                      Loading data, please wait...
                      </span>
                  </div>
                  )
                }
                {!showApiTab && !isPermissionLoading &&
                  <Col sm={9}>
                    <div className="profile-card mb-4">
                      <PermissionDenied/>
                    </div>
                  </Col>
                }
            </Row>
            < ToastContainer />
            < Tooltip id="apitips" />
        </Container>
    );
}

export default PublicAPI;