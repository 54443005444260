import React, { useState } from "react";
import './invoice.component.scss'
import { Tooltip } from "react-tooltip";
import 'react-tooltip/dist/react-tooltip.css'

const InvoiceCard = ({invoice, onDownload}) => {

      function formatEpochToDateTime(epochTimestamp) {
        const date = new Date(epochTimestamp * 1000);
      
        const dateFormatOptions = {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        };
      
        const timeFormatOptions = {
          hour: '2-digit',
          minute: '2-digit',
          hour12: true,
        };
      
        const formattedDate = date.toLocaleDateString('en-US', dateFormatOptions);
        const formattedTime = date.toLocaleTimeString('en-US', timeFormatOptions);
      
        return `${formattedDate} ${formattedTime}`;
      }      

    const openDownloadModal = () => {
      // console.log("Download here");
      // console.log(projectId,project);
      onDownload(invoice);
    }
    const invoiceId = invoice.id;
    const invoiceNumber = invoice.number;
    const invoiceAmount = invoice.amount/100;
    const invoiceCurrency = invoice.currency;
    const invoiceStatus = invoice.status;
    const invoicePaidTime = formatEpochToDateTime(invoice.paiddatetime);
    const invoiceUrl = invoice.url;
    return (
        <>
        <tr className="text-start main-tr-row">
          <td>
            <span data-tooltip-content={`Invoice Number ${invoiceNumber}`} data-tooltip-id="invoicecardcomponent">{invoiceNumber}</span>
          </td>
          <td>
            <span data-tooltip-content={`Invoice Amount $${invoiceAmount}`} data-tooltip-id="invoicecardcomponent">${invoiceAmount}</span>
          </td>
          <td>
            <span data-tooltip-content={`Invoice Paid at ${invoicePaidTime}`} data-tooltip-id="invoicecardcomponent">{invoicePaidTime}</span>
          </td>
          <td>
          <span className="cursor-pointer" data-tooltip-content="Download Invoice" data-tooltip-id="invoicecardcomponent" onClick={() => openDownloadModal()}
              ><img src={require("../../../../assets/img/CTA.svg").default}
            /></span>
          </td>
        </tr>
        <tr className="inside-tr">
        </tr>
      <Tooltip id = "invoicecardcomponent"/>
      </>
    );
}

export default InvoiceCard;