import {React, useState} from 'react';
import './login.component.scss';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function DefaultForgotPassword({onSubmit, disableButton}){
    const [userEmail, setUserEmail] = useState("");
    const [invalidUserEmail, setInvalidUserEmail] = useState(null);
    const handleEmailChange = (event) => {
        setUserEmail(event.target.value);
        setInvalidUserEmail(null);
    }
    const handleSendClick = async(e) => {
        e.preventDefault();
        //Check the validity of the entered
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(userEmail)) {
          setInvalidUserEmail("Enter a valid email address.");
          return
        }
        onSubmit(userEmail.trim());
    }
    return(
        <div className="forget-default">
              <div className="text-center font-20 dark-text poppins-medium mb-4">
              Forgot Password?
              </div>
              <p className="text-center font-14 grey-text mw-388">Enter your registered email ID and we’ll send you a password reset link.</p>
              <form>
                <input
                  type="email"
                  value={userEmail}
                  className={`form-control ${invalidUserEmail ? 'is-invalid' : ''}`}
                  placeholder="john@example.com"
                  onChange={handleEmailChange}
                  required
                />
                {invalidUserEmail && (
                  <div className=" font-14  lato-regular text_error_message">
                  {invalidUserEmail}
                </div>
                )}
                <br></br>
                {/* <Link to="/home"> */}
                  <button className="btn blue-btn-primary w-100 my-3 btn-lg" disabled={disableButton} onClick={handleSendClick}>Send Reset Link</button>
                {/* </Link> */}
              </form>
              <div className="font-14 poppins-regular text-center mt-3">
              Remembered password?
                <Link to="/">
                <span className="primary-blue-text poppins-medium ms-1 cursor-pointer">
                Login
                </span>
                </Link>
              </div>
              < ToastContainer />
            </div>
    )
}