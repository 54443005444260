import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import './profile.component.scss'
import { NavLink } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import defaultProfile from '../../../../assets/img/user-dummy.svg'
import { profileChanges, passwordChange, GetPermission, UpdateAccontInfo } from "../../../apis/pvlapis";
import Sidebar from "../side-bar.component";
import { Button } from "react-bootstrap";
import { setUserDetails } from "../../../apis/tokenhandler";

const ProfileComponent = () => {
    //Get the user details from local storage.
    const userDetails = JSON.parse(localStorage.getItem('PVLUserDetails'));
    //By default consider the display picture as defaultprofile pic
    let displaypicture = defaultProfile;
    if (userDetails) {
        //If display picture present in user details, replace the default pic with user pic
        if (userDetails.displaypicture !== null) {
            displaypicture = userDetails.displaypicture
        }
    }
    const [newProfilePic, setNewProfilePic] = useState(null);
    const [newFirstName, setNewFirstName] = useState('');
    const [accountName, setAccountName] = useState('');
    const [websiteName, setWebsiteName] = useState('');
    const [newLastName, setNewLastName] = useState('');
    const [newMobileNumber, setNewMobileNumber] = useState('');
    const [profilePic, setProfilePic] = useState(displaypicture);
    const [disablePasswordSave, setDisablePasswordSave] = useState(true);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [passwordError, setPasswordError] = useState(null);
    const [invalidNewPassword, setInvalidNewPassword] = useState(null);
    const [invalidOldPassword, setInvalidOldPassword] = useState(null);
    const [invalidNewConfirmPassword, setInvalidNewConfirmPassword] = useState(null);
    const [isPermissionLoading, setIsPermissionLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const accountNameRef = useRef();
    const websiteNameRef = useRef();

    const [showAccountSettingTab, setShowAccountSettingTab] = useState(false);
    const [disableAccountDetailsButton, setDisableAccountDetailsButton] = useState(false);

    const navigate = useNavigate();
    // let profilePic = defaultProfile;
    let userName = null;
    let userEmail = null;
    let userFirstName = null;
    let userLastName = null;
    let userMobile = null;
    if (userDetails) {
        // profilePic = userDetails.displaypicture;
        userName = userDetails.userfullname;
        userEmail = userDetails.useremail;
        const splittedUserName = userName.split(" ");
        if(userDetails.userfirstname){
            userFirstName = userDetails.userfirstname;
        }
        else{
            userFirstName=splittedUserName[0];
        }
        if(userLastName = userDetails.userfamilyname){
            userLastName = userDetails.userfamilyname;
        }
        else{
            userLastName=splittedUserName.slice(1).join(' ');
        }
        // setAccountName(userDetails?.accountname ?? 'Unknown');
        
        
        userMobile = userDetails.usermobilenumber;
    }
    const handleSaveProfile = async () => {
        //Check if user details is present
        if (userDetails) {
            //If the user has update any value, then only append the form data to hit the endpoint to update the profile
            if (newFirstName !== '' || newFirstName !== '' || newMobileNumber !== '' || newProfilePic !== null) {
                const formData = new FormData();
                formData.append("userId", userDetails.userid);
                formData.append("profilePicture", newProfilePic);
                formData.append("firstName", newFirstName.trim());
                formData.append("lastName", newLastName.trim());
                formData.append("mobileNumber", newMobileNumber.trim());
                await profileChanges(formData, navigate)
            }
        }


    }
    const handleSavePassword = async () => {
        //Flag to store the status of the checks. By default consider no checks failed
        let checks_failed = false;
        //Check if old password is entered
        if (oldPassword.trim() === "") {
            setInvalidOldPassword("Enter your old password");
            checks_failed = true;
            return;
        }
        //Check the validity of  the new password
        if (!validatePassword(newPassword)) {
            setInvalidNewPassword("Password length should be minimum of 8 characters and maximum of 15 characters. Should have at least one special character, one number, one lowercase, and one uppercase letter.");
            checks_failed = true;
        }
        //Check if password and confirm password are matching
        if (newPassword !== confirmNewPassword) {
            setInvalidNewConfirmPassword("New password and confirm password do not match");
            checks_failed = true;
        }
        //Check if old password and new password are same.
        if (newPassword === oldPassword){
            setInvalidNewPassword("New password cannot be same as old password");
            checks_failed = true;
        }
        //Return if any checks failed.
        if(checks_failed){
            // setNewPassword('');
            // setConfirmNewPassword('');
            return;
        }
        try {
            //Payload to change password
            const changes = {
                "oldPassword": oldPassword,
                "newPassword": newPassword,
                "newConfirmPassword": confirmNewPassword
            }
            // console.log(changes);
            await passwordChange(changes, navigate);
            setNewPassword('');
            setOldPassword('');
            setConfirmNewPassword('');
            setPasswordError(null);
        }
        catch(error){
            // toast.error("Could not change password!",{position: toast.POSITION.BOTTOM_LEFT});
        }
    };
    const handleDeleteProfilePic = () => {
        //Remove the old pic and set the default pic as new profile pic
        setNewProfilePic(defaultProfile);
        setProfilePic(defaultProfile);
    }
    const handleFileChange = (event) => {
        //Set the uploaded profile pic as new profile pic
        setNewProfilePic(event.target.files[0]);
        setProfilePic(URL.createObjectURL(event.target.files[0]));
        // console.log(profilePic);
        // onFileSelect(file);
    };
    const handleNewFirstName = (event) => {
        setNewFirstName(event.target.value);
    }
    const handleNewLastName = (event) => {
        setNewLastName(event.target.value);
    }
    const handleNewMobileNumber = (event) => {
        setNewMobileNumber(event.target.value);
    }
    const handleOldPassword = (event) => {
        const enteredOldPassword = event.target.value;
        setOldPassword(enteredOldPassword);
        if(enteredOldPassword.trim() >0 ){
            setInvalidOldPassword("Enter your old password");
        }
        else{
            setInvalidOldPassword(null);
        }
    }
    const handleNewPassword = (event) => {
        const enterNewPassword = event.target.value;
        setNewPassword(enterNewPassword);
        //Check the validity of the new password
        if(!validatePassword(enterNewPassword)){
            setInvalidNewPassword("Password length should be minimum of 8 characters and maximum of 15 characters. Should have at least one special character, one number, one lowercase, and one uppercase letter.");
        }
        else{
            setInvalidNewPassword(null);
        }
        setInvalidNewConfirmPassword(null);
    }
    const handleNewConfirmPassword = (event) => {
        const enteredConfirmPassword = event.target.value;
        setConfirmNewPassword(enteredConfirmPassword);
        //Check if the password is same as confirmed password
        if(enteredConfirmPassword != newPassword){
            setInvalidNewConfirmPassword("Password should match");
        }
        else{
            setInvalidNewConfirmPassword(null);
        }
    }

    const validatePassword = (password) => {
        //Regex to check password validity
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+[{\]};:'",<.>/?]).{8,15}$/;
        return passwordRegex.test(password);
    };

    const handleUpdateAccountDetails = async()=>{
        try {
            setIsLoading(true);
            setDisableAccountDetailsButton(true);
            setAccountName(accountNameRef.current.value);
            setWebsiteName(websiteNameRef.current.value);
            const response = await UpdateAccontInfo({accountName:accountNameRef.current.value,website:websiteNameRef.current.value});
            if(response.statusCode === 200){
                const user = JSON.parse(localStorage.getItem('PVLUserDetails'));
                console.log(user);
                user.accountname = accountNameRef.current.value;
                user.accountwebsitename = websiteNameRef.current.value;
                setUserDetails(JSON.stringify(user));
                toast.success("Account Details updated successfully.", { position: toast.POSITION.BOTTOM_LEFT });
            }
            else{
                toast.error("Something went wrong.", { position: toast.POSITION.BOTTOM_LEFT });

            }

        } catch (error) {
            
        }
        finally{
            setIsLoading(false);
            setDisableAccountDetailsButton(false);
        }
    }
    const handleGetPermission = async() =>{
        setIsPermissionLoading(true);
  
        try {
            const response = await GetPermission();
            console.log(response);
            if(response.statusCode !==200 || response?.result === null){
                return;
            }
            const accountSetting = response?.result?.find(obj => obj?.component === "editaccountsettings");
            if(accountSetting?.permission){
                setShowAccountSettingTab(accountSetting?.permission);
            }
            else{
                setShowAccountSettingTab(false);
            }
        } catch (error) {
            toast.error("Something went wrong. Please try again after some time.",{position:toast.POSITION.BOTTOM_LEFT})
        }
        finally{
          setIsPermissionLoading(false);
        }
        
    }
    useEffect(()=>{
        handleGetPermission();
    },[])
    useEffect(()=>{
        if(userDetails?.accountwebsitename){
           setWebsiteName(userDetails?.accountwebsitename);
        }
        if(userDetails?.accountname){
            setAccountName(userDetails?.accountname ?? 'Unknown');
        }
    },[userDetails])

    return (
        <Container fluid className="main-page-wrap">
            <Row>
                <Col>
                    <div className="d-flex align-items-center justify-content-between page-header py-3">
                        <span class="font-18 dark-text poppins-semibold">Settings</span>
                    </div>
                </Col>
            </Row>
            <Row className="mt-4">
                <Col sm={3}>
                    <Sidebar/>
                </Col>
                <Col sm={9}>
                    <div className="profile-card mb-4">
                        <div className="card-title">Profile Settings</div>
                        <Row className="mt-4">
                            <Col sm={8}>
                                <Row>
                                    <Col>
                                        <div className="form-group mb-4">
                                            <label htmlFor="" className="grey-text lato-medium">First Name</label>
                                            <input className="form-control readonly-input" value={newFirstName} placeholder={userFirstName} onChange={handleNewFirstName} type="text" readOnly />
                                        </div>
                                        <div className="form-group mb-4">
                                            <label htmlFor="" className="grey-text lato-medium">Email</label>
                                            <input className="form-control readonly-input" placeholder={userEmail} type="email" readOnly />
                                        </div>
                                        <button className="btn blue-btn-primary small-btn d-none" onClick={handleSaveProfile} >Save</button>
                                    </Col>
                                    <Col>
                                        <div className="form-group mb-4">
                                            <label htmlFor="" className="grey-text lato-medium">Last Name</label>
                                            <input className="form-control readonly-input" value={newLastName} onChange={handleNewLastName} placeholder={userLastName} type="text" readOnly />
                                        </div>
                                        <div className="form-group mb-4">
                                            <label htmlFor="" className="grey-text lato-medium">Phone Number</label>
                                            <input className="form-control readonly-input" value={newMobileNumber} onChange={handleNewMobileNumber} placeholder={userMobile} type="phone" readOnly />
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm={4} className="text-center">
                                <div className="big-profile-pic"><img
                                    src={profilePic}
                                    width="100"
                                    height="100"
                                    className="rounded-circle" alt=""
                                />
                                    <div className="inside-wrap d-none">
                                        <i className="fa-regular fa-trash-alt font-20 cursor-pointer" onClick={handleDeleteProfilePic}></i>
                                    </div>
                                </div>
                                <button className="rounded-border-btn mt-3 d-none" onClick={() => {
                                    document.querySelector("input[type=file]").click();
                                }}>
                                    <i className="fa-solid fa-arrow-up-from-bracket me-1"></i>Upload
                                </button>
                                <input
                                    type="file"
                                    accept=".jpg, .png"
                                    style={{ display: "none" }}
                                    onChange={handleFileChange}
                                />
                            </Col>
                        </Row>
                    </div>
                    
                    <div className="profile-card mb-4">
                        <div className="card-title">Account Settings</div>
                        <Row className="mt-4">
                            <Col sm={8}>
                            <Row>
                                <Col md={6}>
                                    <div className="form-group mb-4">
                                    <label htmlFor="" className="grey-text lato-medium">Account Name</label>
                                    <input ref={accountNameRef} className="form-control readonly-input" defaultValue={accountName} placeholder={'Alex Inc'}  type="text"  readOnly={showAccountSettingTab === false} maxLength={20} />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="form-group mb-4">
                                    <label htmlFor="" className="grey-text lato-medium">Website</label>
                                    <input ref={websiteNameRef} className="form-control readonly-input" defaultValue={websiteName} placeholder={'https://'} type="text" readOnly={showAccountSettingTab === false} maxLength={20}/>
                                    </div>
                                </Col>
                                </Row>
                                {showAccountSettingTab && <Row>
                                    <Col>
                                    <Button className="btn blue-btn-primary font-12 position-relative custom-button" variant="primary" disabled={disableAccountDetailsButton} onClick={handleUpdateAccountDetails} >
                                            Save
                                            {isLoading&&<div className="spinner-border position-absolute spinner" role="status" >
                                                <span className="visually-hidden">Loading...</span>
                                            </div>}
                                    </Button>
                                    </Col>

                                </Row>}
                            </Col>
                            <Col sm={4} className="text-center">
                            </Col>
                        </Row>
                    </div>

                    <div className="profile-card mb-4">
                        <div className="card-title">Change Password</div>
                        <Row className="mt-4">
                            <Col sm={8}>
                                <Row>
                                    <Col>
                                        <div className="form-group mb-4">
                                            <label htmlFor="" className="grey-text lato-medium">Enter Current Password</label>
                                            <input className={`form-control ${invalidOldPassword ? 'is-invalid' : ''}`} value={oldPassword} onChange={handleOldPassword} placeholder="••••••••" type="password" />
                                            {invalidOldPassword && (
                                                <div className=" font-14  lato-regular text_error_message text_error_message">
                                                        {invalidOldPassword}
                                                </div>
                                            )}
                                        </div>
                                    </Col>
                                    <Col></Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="form-group mb-4">
                                            <label htmlFor="" className="grey-text lato-medium">New Password</label>
                                            <input className={`form-control ${invalidNewPassword ? 'is-invalid' : ''}`} value={newPassword} onChange={handleNewPassword} placeholder="••••••••" type="password" />
                                            {invalidNewPassword && (
                                                <div className=" font-14  lato-regular text_error_message text_error_message">
                                                        {invalidNewPassword}
                                                </div>
                                            )}
                                        </div>
                                        {/* {passwordError && <p className="error-message">{passwordError}</p>} */}
                                        <button className="btn blue-btn-primary small-btn" onClick={handleSavePassword}>Save</button>

                                    </Col>
                                    <Col>
                                        <div className="form-group mb-4">
                                            <label htmlFor="" className="grey-text lato-medium">Confirm New Password</label>
                                            <input className={`form-control ${invalidNewConfirmPassword ? 'is-invalid' : ''}`} value={confirmNewPassword} onChange={handleNewConfirmPassword} placeholder="••••••••" type="password" />
                                            {invalidNewConfirmPassword && (
                                                <div className=" font-14  lato-regular text_error_message text_error_message">
                                                        {invalidNewConfirmPassword}
                                                </div>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm={4} className="text-center"></Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default ProfileComponent;