import React, { useEffect, useState } from "react";
import { ToastContainer,toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './invoice.component.scss'
import { NavLink } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InvoiceCard from "./invoicecard.component";
import { Tooltip } from "react-tooltip";
import 'react-tooltip/dist/react-tooltip.css'
import { async } from "q";
import { getInvoicesList,  getCustomerAddress, editAddress, GetPermission } from "../../../apis/pvlapis";
import LoadingAnimation from "../lottie-files/loading.lotte";
import EmptyCard from "../list/emptylist.component";
import { AddressForm } from "./address.component";
import Sidebar from "../side-bar.component";
import PermissionDenied from "../../shared/permission-denied.component";
const InvoiceComponent = () => {
    const [invoicePageCount, setInvoicePageCount] = useState(10);
    const [invoices, setInvoices] = useState([]);
    const [completeInvoices, setCompleteInvoices] = useState([]);
    const [pageIndex, setPageIndex] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [minIndex, setMinIndex] = useState(0);
    const [maxIndex, setMaxIndex] = useState(0);
    const [previousPage, setPreviousPage] = useState(false);
    const [nextPage, setNextPage] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [dataAvailable, setDataAvailable] = useState(false);
    const [customerAddress, setCustomerAddress] = useState(null);
    const [companyName, setCompanyName] = useState(null);
    const [addressLine, setAddressLine] = useState(null);
    const [cityName, setCityName] = useState(null);
    const [stateName, setStateName] = useState(null);
    const [countryName, setCountryName] = useState(null);
    const [pinCode, setPinCode] = useState(null);
    const [openAddressForm, setOpenAddressForm] = useState(null);
    const [disableEditButton, setDisableEditButton] = useState(false);
    const handleOpenDownload = (invoice) =>{
        const url = invoice.url
        window.open(url);
    }
    const getInvoices = async() =>{
        try{
            setIsLoading(true);
            const response = await getInvoicesList();
            if(response.data.statusCode === 200){
                setCompleteInvoices(response.data.result['invoices']);
            }
            else{
                toast.error("Something went wrong. Please try again!",{position:toast.POSITION.BOTTOM_LEFT});
            }
        }
        catch(error){
            toast.error("Something went wrong. Please try again after some time!",{position:toast.POSITION.BOTTOM_LEFT});
        }
        finally{
            setIsLoading(false);
        }
    }

    const getAddress = async() => {
        try{
            setDisableEditButton(true);
            const response = await getCustomerAddress();
            if(response.data.statusCode === 200){
                const address = response.data.result['address']
                if(address){
                    setCustomerAddress(address);
                    setCompanyName(address['line1']);
                    setAddressLine(address['line2']);
                    setCityName(address['city']);
                    setCountryName(address['country']);
                    setStateName(address['state']);
                    setPinCode(address['postal_code']);
                }
            }
            else if(response.data.statusCode === 422){
                //                console.log('No Address Found');
            }
            else{
                toast.error("Something went wrong. Please try again!",{position:toast.POSITION.BOTTOM_LEFT});
            }
        }
        catch(err){
            toast.error("Something went wrong. Please try again after some time!",{position:toast.POSITION.BOTTOM_LEFT});
        }
        finally{
            setDisableEditButton(false);
        }
    }

    const handleNextPage = () => {
        setPageIndex(pageIndex+1);
    }
    const handlePreviousPage = () => {
        setPageIndex(pageIndex-1);
    }

    const saveNewAddress = async(newAddress) => {
        try{
            handleCloseAddressForm();
            // console.log("NEW ADDRESS",newAddress);
            const payload = {
                "address": newAddress
            }
            const response = await editAddress(payload);
            // console.log(response);
            if(response.data.statusCode === 200){
                setCustomerAddress(newAddress);
                toast.success("Address Changed Successfully!",{position:toast.POSITION.BOTTOM_LEFT});
            }
            else{
                toast.error("Something went wrong. Please try again after some time!",{position:toast.POSITION.BOTTOM_LEFT});
            }
        }
        catch{
            toast.error("Something went wrong. Please try again after some time!",{position:toast.POSITION.BOTTOM_LEFT});
        }
        
    }
    
    const handleCloseAddressForm = () => {
        setOpenAddressForm(false);
    }

    const handleOpenAddressForm = () => {
        setOpenAddressForm(true);
    }

    useEffect(() => {
        getInvoices();
        getAddress();
      }, []);
    
    useEffect(() => {
        if(completeInvoices.length>0){
            setDataAvailable(true);
            const length = completeInvoices.length
            setTotalCount(length);
            const from = (pageIndex-1)*invoicePageCount + 1;
            let to = pageIndex*invoicePageCount;
            if(to > length+1){
                to = length;
                setNextPage(false);
            }
            else{
                setNextPage(true);
            }
            if(from === 1 ){
                setPreviousPage(false);
            }
            else{
                setPreviousPage(true);
            }
            setMinIndex(from);
            setMaxIndex(to);
            setInvoices(completeInvoices.slice(from-1,to));
        }
        else{
            setDataAvailable(false);
        }
        
      }, [completeInvoices,pageIndex]);

    const [showInvoiceTab, setShowInvoiceTab] = useState(null);
    const handleGetPermission = async() =>{
        setIsLoading(true);
  
        try {
            const response = await GetPermission();
            if(response.statusCode !==200 || response?.result === null){
                return;
            }
            const settingsviewcredithistoryandinvoice = response?.result?.find(obj => obj?.component === "settingsviewcredithistoryandinvoice");

            setShowInvoiceTab(settingsviewcredithistoryandinvoice?.permission);
        } catch (error) {
            toast.error("Something went wrong. Please try again after some time.",{position:toast.POSITION.BOTTOM_LEFT})
        }
        finally{
            setIsLoading(false);
        }
        
    }
    useEffect(() => {
        handleGetPermission();
    }, []);
    return (
        <Container fluid className="main-page-wrap">
            {
            openAddressForm && (
                <AddressForm onClose={handleCloseAddressForm} onSave={saveNewAddress} address={customerAddress} />
            )
        }
            <Row>
                <Col>
                    <div className="d-flex align-items-center justify-content-between page-header py-3">
                        <span className="font-18 dark-text poppins-semibold">Settings</span>
                    </div>
                </Col>
            </Row>
            <Row className="mt-4">
                <Col sm={3}>
                    <Sidebar/>
                </Col>
                {!showInvoiceTab && !isLoading &&<Col sm={9}>
                    <div className="profile-card mb-4">
                    <PermissionDenied/>
                    </div>
                    </Col>
                }
                {showInvoiceTab && <Col sm={9}>
                    <div className="profile-card mb-4">
                        <div className="card-title mb-4">Invoice Settings</div>

                        <div className="card">
                            <div className="d-flex justify-content-between p-20">
                                {
                                    customerAddress && (
                                        <div>
                                            <div className="dark-text poppins-bold mb-3">Billing Address</div>
                                            <div className="dark-text lato-bold">{customerAddress.line1}</div>
                                            <div>{customerAddress.line2}, {customerAddress.city} {customerAddress.postal_code}</div>
                                        </div>
                                    )
                                }
                                {
                                    !customerAddress && (
                                <div>
                                    <div className="dark-text poppins-bold mb-3">Billing Address</div>
                                    <div className="dark-text lato-bold">Address Not Found</div>
                                    <div></div>
                                </div>
                                )
                                }
                                <div>
                                    <button className="btn action-btn" disabled={disableEditButton} onClick={handleOpenAddressForm}><i className="fa-regular fa-pen-to-square me-1"></i>Edit</button>
                                </div>
                            </div>
                        </div>

                        <div className="card">
                            { !isLoading &&<>
                                <div
                                    className="d-flex align-items-center justify-content-between action-items-bar my-3 px-2 ps-4"
                                >
                                    <div className="d-flex font-16 dark-text poppins-semibold">
                                            Invoices
                                    </div>
                                    <div className="d-flex align-items-center">
                                    <div className="mx-2 grey-text font-14 d-flex align-items-center pagination">
                                        <span>{minIndex} - {maxIndex} of {totalCount}</span>
                                        <div className={`widget-icon ms-2 w-auto text-center ${!previousPage ? 'disabled' : 'cursor-pointer'}`} onClick={!previousPage ? null : handlePreviousPage}>
                                        <div className="prev-btn" data-tooltip-content="Previous Page" data-tooltip-id="invoicecomponent">
                                            <i className="far fa-chevron-left" ></i>
                                        </div>
                                        </div>
                                        <div className={`widget-icon ms-2 w-auto text-center ${!nextPage ? 'disabled' : 'cursor-pointer'}`} onClick={!nextPage ? null : handleNextPage}>
                                        <div className="next-btn" data-tooltip-content="Next Page" data-tooltip-id="invoicecomponent">
                                            <i className="far fa-chevron-right"></i>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            
                                <table className="table pb-0 mb-0">
                                    <thead>
                                        <tr className="text-start">
                                        <td><span data-tooltip-id="invoicecomponent" data-tooltip-content="Invoice Number">Invoice no.</span>
                                        </td>
                                        {/* <td className="w-25rem">List Name<span>&uarr;</span></td> */}
                                        <td><span data-tooltip-id="invoicecomponent" data-tooltip-content="Invoice Amount">Amount</span>
                                        </td>
                                        <td><span data-tooltip-id="invoicecomponent" data-tooltip-content="Invoice Paid Date">Invoice Date</span></td>
                                        <td></td>
                                        <td>{/* It just for making some space*/}</td>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {invoices && invoices.length>0? (invoices.map((invoice) => (
                                            <InvoiceCard key={invoice.id} invoice={invoice} onDownload={handleOpenDownload} />
                                        ))) : (
                                        <tr>
                                            
                                        </tr>
                                        )}
                                    </tbody>

                                </table>
                            </>
                            }
                            {
                                isLoading && (
                                <div className="loading-animation">
                                    <LoadingAnimation/>
                                    <span>
                                    Loading data, please wait...
                                    </span>
                                </div>
                                )
                            }
                            {
                                !dataAvailable && !isLoading &&(
                            <EmptyCard messHead={"No invoice found!"} MessDescription={"Purchase credits today to start seeing the invoices."} styling={"d-none"} />
                            )
                            }
                        </div>

                    </div>
                </Col>}
                {
                    isLoading && (
                    <div className="loading-animation">
                        <LoadingAnimation/>
                        <span>
                        Loading data, please wait...
                        </span>
                    </div>
                    )
                }
                
            </Row>
            < ToastContainer />
            <Tooltip id = "invoicecomponent"/>
        </Container>
    );
}

export default InvoiceComponent;