import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import TeamService from '../../../apis/teamservice';
import { RolesEnum } from '../../Modals/enum-modal';
import { ChangeUserRole, SendJoinAccountInvitation } from '../../../apis/pvlapis';

const AddEditUsers = ({handleClose, GetAccountMemberDetails, clicktype, userFirstName, setUserFirstName, userLastName, setUserLastName, emailId, 
                        setEmailId,selectedRole,setSelectedRole, userId=null}) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleLastNameChange = (event)=>{
        setUserLastName(event.target.value);
    }
    const handleFirstNameChange = (event)=>{
        setUserFirstName(event.target.value);
    }
    const handleEmailIdChange =(event) =>{
        setEmailId(event.target.value);
    }
    const onRoleChange = (event) => {
        const role = event.target.value;
        setSelectedRole(role);
    };

    const validateEmail = (email) => {
        // A simple email regex pattern
        const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return regex.test(email);
    };

    const handleAddOrUpdateUser = async() =>{
        if(userFirstName?.length<1){
            toast.error("Please write first Name.", { position: toast.POSITION.BOTTOM_LEFT });
            return;
        }
        else if(userLastName?.length<1){
            toast.error("Please write last Name.", { position: toast.POSITION.BOTTOM_LEFT });
            return;
        }
        else if(emailId?.length<1){
            toast.error("Please write email id.", { position: toast.POSITION.BOTTOM_LEFT });
            return;
        }
        else if(!validateEmail(emailId)){
            toast.error("Please write valid email id.", { position: toast.POSITION.BOTTOM_LEFT });
            return;
        }
        else if(selectedRole<1){
            toast.error("Please select role.", { position: toast.POSITION.BOTTOM_LEFT });
            return;
        }
        try {
            if(clicktype==='Invite'){
                const res = await SendJoinAccountInvitation({inviteeEmail: emailId, roleType: selectedRole, 
                    firstName:userFirstName, lastName:userLastName});
                if(res.statusCode === 200){
                    toast.success("Invitation sent successfully.", { position: toast.POSITION.BOTTOM_LEFT });
                    await GetAccountMemberDetails();
                }
                else if(res.statusCode === 403){
                    toast.error("Invitation already sent. Please ask user to join the account.",{position:toast.POSITION.BOTTOM_LEFT})
                }
                else if(res.statusCode === 431){
                    toast.error("Buy additional licenses to invite more users.",{position:toast.POSITION.BOTTOM_LEFT})
                }
                else if(res.statusCode === 432){
                    toast.error("Subscribe to invite users.",{position:toast.POSITION.BOTTOM_LEFT})
                }
                else{
                    toast.error("Something went wrong. Please try again after some time.",{position:toast.POSITION.BOTTOM_LEFT})
                }
            }
            else if(clicktype === 'Edit' && userId>0){
                const changeType = 0;
                const res = await ChangeUserRole(changeType, userId, selectedRole);
                if(res.statusCode === 200){
                    toast.success("User Details updated successfully.", { position: toast.POSITION.BOTTOM_LEFT });
                    await GetAccountMemberDetails();
                }
                else if(res.statusCode === 403){
                    toast.error("User doesn't have permission to update.", { position: toast.POSITION.BOTTOM_LEFT }); 
                }
                else if(res.statusCode === 431){
                    toast.error("Buy additional licenses to invite more users.",{position:toast.POSITION.BOTTOM_LEFT})
                }
                else if(res.statusCode === 432){
                    toast.error("Subscribe to invite users.",{position:toast.POSITION.BOTTOM_LEFT})
                }
                else if(res.statusCode === 472){
                    toast.error("You do not have the Permissions. Please contact the Admin.",{position:toast.POSITION.BOTTOM_LEFT})
                }
                else{
                    toast.error("Something went wrong. Please try again after some time.",{position:toast.POSITION.BOTTOM_LEFT})
                }
            }
            setIsLoading(true);          
        } catch (error) {
            toast.error("Something went wrong. Please try again after some time.",{position:toast.POSITION.BOTTOM_LEFT})
        } finally{
            // setTimeout( () => {
                
            // }, 10000); // 2-second delay
            setIsLoading(false);
            handleCloseModal();
        }
    }

   const handleCloseModal =() =>{
    handleClose();
   }

  return (
    <Modal show={true} onHide={handleCloseModal} className='modal-no-border'>
                <Modal.Header closeButton className="modal-header">
                    <Modal.Title><span className="round-modal-icon grey">
                        <i className="fa-solid fa-user-plus"></i>
                    </span>{" "}
                        {clicktype} User</Modal.Title>
                    
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">Invite your team to your SureConnect account.</div>

                    <div className="form-group mb-3 d-flex gap-4 inline">
                        <div className='flx-w'>
                            <label className='mb-1' htmlFor="Full Name">First Name</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder={clicktype === 'Edit' ? '' : 'First Name'}
                                value={userFirstName} 
                                onChange={handleFirstNameChange} 
                                maxLength={20}
                                disabled={clicktype==='Edit'}
                                required />
                        </div>
                        <div className="flx-w">
                            <label className='mb-1' htmlFor="Full Name">Last Name</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder={clicktype === 'Edit' ? '' : 'Last Name'}
                                value={userLastName} 
                                onChange={handleLastNameChange} 
                                disabled={clicktype==='Edit'}
                                maxLength={20}
                                required />

                        </div>
                    </div>
                    <div className="form-group mb-3">
                        <label className='mb-1' htmlFor="Full Name">Email ID</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Enter your Email ID"
                            value={emailId} 
                            onChange={handleEmailIdChange} 
                            disabled={clicktype==='Edit'}
                            maxLength={30}
                            required />

                    </div>
                    <div className="form-group mb-3">
                        <label className='mb-1' htmlFor="Full Name">Select Role</label>
                            <div className="dropdown">
                                <div className="select-option">
                                    <i class="fa-solid fa-caret-down select-caret"></i>
                                    <select className="form-control" value={selectedRole} onChange={onRoleChange}>
                                        <option value="" disabled style={{ color: 'grey' }}>
                                        Select Role
                                        </option>
                                        {Object.entries(RolesEnum).map(([role, value]) => (
                                        role !== 'Owner' && ( // Exclude the "Owner" role
                                            <option key={value} value={value}>
                                            {role}
                                            </option>
                                        )
                                        ))}
                                    </select>
                                </div>
                            </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <div className="text-end w-100">
                        <Button className="btn btn-cancel mr-15px" variant="secondary" onClick={handleCloseModal}>
                            Cancel
                        </Button>
                        <Button className="btn blue-btn-primary font-12 position-relative" variant="primary" onClick={handleAddOrUpdateUser}>
                            {clicktype==='Invite'&&<span>Send Invite</span>}
                            {clicktype==='Edit'&&<span>Update User</span>}
                            {isLoading&&<div className="spinner-border position-absolute spinner" role="status" >
                          <span className="visually-hidden">Loading...</span>
                      </div>}
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
  )
}

export default AddEditUsers;