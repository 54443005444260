import React from "react";

import { useState, useEffect } from 'react';


const DownloadComponent = ({onCheckboxChange,projectDetails}) =>{

    let projectName = "List Details";
    let totalRecords = 126;
    let completedRecords = 0;
    let answerRecords = 0;
    // let wrongRecords = 0;
    // let unreachableRecords = 0;
    let otherRecords = 0;
    let voicemailRecords = 0;
    let gatekeeperRecords = 0;
    let failedRecords = 0;
    if(projectDetails){
    projectName = projectDetails.projectName;
    totalRecords = projectDetails.projectStats[0].totalProspects;
    completedRecords = projectDetails.projectStats[0].completedProspects;
    answerRecords = projectDetails.projectStats[0].answerProspects;
    // wrongRecords = projectDetails.projectStats[0].wrongProspects;
    // unreachableRecords = projectDetails.projectStats[0].unreachableProspects;
    otherRecords = projectDetails.projectStats[0].otherProspects;
    voicemailRecords = projectDetails.projectStats[0].voicemailProspects;
    gatekeeperRecords = projectDetails.projectStats[0].gatekeeperProspects;
    failedRecords = projectDetails.projectStats[0].failedProspects;
    }
    const [checkboxValues, setCheckboxValues] = useState({
      completedProspects: true,
      answerProspects: true,
      // wrongProspects: true,
      otherProspects: true,
      gatekeeperProspects: true,
      voicemailProspects: true,
      failedProspects: true
    });

      // Checkbox change handler
      const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
      
        setCheckboxValues((prevCheckboxValues) => {
          const updatedCheckboxValues = { ...prevCheckboxValues, [name]: checked };
      
          if (name === 'completedProspects') {
            // If "completedProspects" checkbox is selected, update completedProspects other checkboxes accordingly
            updatedCheckboxValues.answerProspects = checked;
            // updatedCheckboxValues.wrongProspects = checked;
            updatedCheckboxValues.otherProspects = checked;
            updatedCheckboxValues.gatekeeperProspects = checked;
            updatedCheckboxValues.voicemailProspects = checked;
            updatedCheckboxValues.failedProspects = checked;
          } else {
            // Check if completedProspects other checkboxes are selected, and update "completedProspects" checkbox accordingly
            const { answerProspects, otherProspects, gatekeeperProspects, voicemailProspects, failedProspects } = updatedCheckboxValues;
            updatedCheckboxValues.completedProspects = answerProspects && otherProspects && gatekeeperProspects && voicemailProspects && failedProspects ;
          }
          onCheckboxChange(updatedCheckboxValues);
          return updatedCheckboxValues;
        });
      
        // Invoke the callback with the updated checkbox values
        
      };
      useEffect(() => {
        onCheckboxChange(checkboxValues)   
      }, [checkboxValues]);

    return (
      <div>
        <div className='uploaded-wrap'>
              <div className='header d-flex justify-content-between align-items-center'>
                <div className='d-flex align-items-center'>
                  <i className="fa-solid fa-file-csv green-text"></i>
                  <span className='ms-2 font-14 dark-text lato-medium'>{projectName}</span>
                  <i className="fa-solid fa-circle font-4 mx-2 light-grey-text"></i>
                  <span className='font-14 light-grey-text'>{totalRecords} Contacts</span>
                </div>
                
              </div>
          </div>
        <div>
     
          <p className="grey-text mt-4 lato-medium">Select the results to be included in the sheet</p>

          <label className="CheckboxContainer mb-2 d-block">
          <input
          type="checkbox"
          name="completedProspects"
          checked={checkboxValues.completedProspects}
          onChange={handleCheckboxChange}
          className=""
        />
          <span className="CheckedMark"></span>
          <span className="ms-4 ps-1 dark-text lato-medium">All Results ({completedRecords})</span>
          </label>
      
      <label className="CheckboxContainer mb-2 d-block">
        <input
          type="checkbox"
          name="answerProspects"
          checked={checkboxValues.answerProspects}
          onChange={handleCheckboxChange}
          // disabled={checkboxValues.completedProspects}
          class=""
        />
        <span className="CheckedMark"></span>
        <span className="ms-4 ps-1 dark-text lato-medium">Likely Answer ({answerRecords})</span>
        
      </label>  
      <label className="CheckboxContainer mb-2 d-block">
        <input
          type="checkbox"
          name="voicemailProspects"
          checked={checkboxValues.voicemailProspects}
          onChange={handleCheckboxChange}
          // disabled={checkboxValues.completedProspects}
          class=""
        />
        <span className="CheckedMark"></span>
        <span className="ms-4 ps-1 dark-text lato-medium"> Likely Voicemail ({voicemailRecords})</span>
       
      </label>   
      <label className="CheckboxContainer mb-2 d-block">
        <input
          type="checkbox"
          name="gatekeeperProspects"
          checked={checkboxValues.gatekeeperProspects}
          onChange={handleCheckboxChange}
          // disabled={checkboxValues.completedProspects}
          class=""
        />
        <span className="CheckedMark"></span>
        <span className="ms-4 ps-1 dark-text lato-medium"> Likely Gatekeeper ({gatekeeperRecords})</span>
       
      </label> 
      <label className="CheckboxContainer mb-2 d-block">
        <input
          type="checkbox"
          name="otherProspects"
          checked={checkboxValues.otherProspects}
          onChange={handleCheckboxChange}
          // disabled={checkboxValues.completedProspects}
          class=""
        />
        <span className="CheckedMark"></span>
        <span className="ms-4 ps-1 dark-text lato-medium"> Others ({otherRecords})</span>
       
      </label>
      {/* <label className="CheckboxContainer mb-2 d-block">
        <input
          type="checkbox"
          name="wrongProspects"
          checked={checkboxValues.wrongProspects}
          onChange={handleCheckboxChange}
          // disabled={checkboxValues.completedProspects}
          class=""
        />
        <span className="CheckedMark"></span>
        <span className="ms-4 ps-1 dark-text lato-medium"> Likely Wrong ({wrongRecords})</span>
       
      </label> */}
      <label className="CheckboxContainer mb-2 d-block">
        <input
          type="checkbox"
          name="failedProspects"
          checked={checkboxValues.failedProspects}
          onChange={handleCheckboxChange}
          // disabled={checkboxValues.completedProspects}
          class=""
        />
        <span className="CheckedMark"></span>
        <span className="ms-4 ps-1 dark-text lato-medium"> Failed Contacts</span>
      </label>  
      </div>
      </div>
    );

}

export default DownloadComponent;