import {React, useEffect, useState} from 'react';
import './emailverification.component.scss';

const VerifySent = ({messHead, messDes, userEmail, onResend}) => {
  const handleResend = async() => {
    onResend();
  }
    return(
        <div className="verify-email">

              <div className="text-center mb-3">
              <img src={require("../../../assets/img/verify-email.svg").default} alt="Success" />
              </div>

              <div className="text-center font-18 dark-text poppins-semibold mb-2">
              {messHead}
              </div>
              <p className="text-center font-14 grey-text mw-388 pt-1 poppins-regular">{messDes}&nbsp; <span>{userEmail}</span>. 
              Please verify your email ID to complete the Sign up process.</p>
              <div className="font-14 poppins-regular text-center mt-3 d-none">
              Didn’t receive the link?
                <span className="primary-blue-text poppins-medium ms-1 cursor-pointer" onClick={handleResend}>
                Resend Link
                </span>
              </div>
              </div>
    );
};

export { VerifySent };
