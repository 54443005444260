import React, { useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import './credits-history.component.scss'
import { NavLink } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useState } from 'react';
import UsageComponent from "./usagehistory.component";
import PurchaseComponent from "./purchasehistory.component";
import Sidebar from "../side-bar.component";
import { GetPermission } from "../../../apis/pvlapis";
import PermissionDenied from "../../shared/permission-denied.component";
import LoadingAnimation from "../lottie-files/loading.lotte";

const CreditsHistoryComponent = () => {
    const [showInvoiceTab, setShowInvoiceTab] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const handleGetPermission = async() =>{
        setIsLoading(true);
  
        try {
            const response = await GetPermission();
            if(response.statusCode !==200 || response?.result === null){
                return;
            }
            const settingsviewcredithistoryandinvoice = response?.result?.find(obj => obj?.component === "settingsviewcredithistoryandinvoice");
  
            setShowInvoiceTab(settingsviewcredithistoryandinvoice?.permission);
        } catch (error) {
            toast.error("Something went wrong. Please try again after some time.",{position:toast.POSITION.BOTTOM_LEFT})
        }
        finally{
            setIsLoading(false);
        }
        
    }
    useEffect(() => {
        handleGetPermission();
    }, []);
    const [key, setKey] = useState('UsageHistory');
    return (
        <Container fluid className="main-page-wrap">
            <Row>
                <Col>
                    <div className="d-flex align-items-center justify-content-between page-header py-3">
                        <span class="font-18 dark-text poppins-semibold">Settings</span>
                    </div>
                </Col>
            </Row>
            <Row className="mt-4">
                <Col sm={3}>
                    <Sidebar/>
                </Col>
                {showInvoiceTab && !isLoading && <Col sm={9}>
                    <div className="profile-card mb-4">
                        <div className="card-title">Credits History</div>

                        <div>
                            <Tabs
                                defaultActiveKey="profile"
                                id="uncontrolled-tab-example"
                                activeKey={key}
                                onSelect={(k) => setKey(k)}
                                className="mb-3 mt-3"
                            >
                                <Tab eventKey="UsageHistory" title="Usage History">
                                    <UsageComponent />
                                </Tab>
                                <Tab eventKey="PurchaseHistory" title="Purchase History">
                                    <PurchaseComponent />
                                </Tab>
                            </Tabs>
                        </div>

                    </div>
                    </Col>
                }
                {
                    isLoading && (
                    <div className="loading-animation">
                        <LoadingAnimation/>
                        <span>
                        Loading data, please wait...
                        </span>
                    </div>
                    )
                }
                {!showInvoiceTab && !isLoading &&<Col sm={9}>
                    <div className="profile-card mb-4">
                    <PermissionDenied/>
                    </div>
                    </Col>
                }
            </Row>
            < ToastContainer />
        </Container>
    );
}

export default CreditsHistoryComponent;