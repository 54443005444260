import {React, useEffect, useState} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import './login.component.scss';
import { Link } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import { useGoogleLogin } from '@react-oauth/google';
import Cookies from 'universal-cookie';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { sendLoginDataToCrisp, userLogin, inhouseLogin, regenerateVerifyEmail , acceptAccountInviation, verifyRecaptcha} from '../../apis/pvlapis';
import { msalInstance } from '../../configs/azureConfig';
import { setAccessTokenCookie, setRefreshToken, setUserDetails } from '../../apis/tokenhandler';
import { VerifySent } from '../emailvalidate/verifyemail.emailvalidate';
import ReCAPTCHA from "react-google-recaptcha";
import { Mixpanel } from '../../configs/mixPanel';
import { Crisp } from 'crisp-sdk-web';
import CreateUserAccountModal from '../base-content/modals/create-account-modal.component';

const newcookies = new Cookies();
export default function Login({redirectUrl='', showErrorMessage, errorMessage, errorDescription,signUpSuccessfull }) {
  
  const navigate = useNavigate();
  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  const [ user, setUser ] = useState(null);
  const [ source, setSource ] = useState(null);
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [showEmailSent, setShowEmailSent] = useState(false);
  const [showLoginForm, setShowLoginForm] = useState(true);
  const [verificationMessage, setVerificationMessage] = useState("");
  const [verificationDescription, setVerificationDescription] = useState("");
  const [verificationToken, setVerificationToken] = useState(null);
  const [invalidUserEmail, setInvalidUserEmail] = useState(null);
  const [invalidUserPassword, setInvalidUserPassword] = useState(null);
  const [disableLoginButton, setDisableLoginButton] = useState(false);
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [invalidRecaptcha, setInvalidRecaptcha] = useState(null);
  const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
  const [recaptcha, setRecaptcha] = useState(null);
  const [openCreateUserAccount,setOpenCreateUserAccount] = useState(false);
  const [userIdAccountCreate ,setUserIdAccountCreate] = useState(null);

  const { token } = useParams();

  const handleEmailChange = (e) => {
    setUserEmail(e.target.value);
    setInvalidUserEmail(null);
  };

  const handlePasswordChange = (e) => {
    setUserPassword(e.target.value);
    setInvalidUserPassword(null);
  };
  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };
  //Login using Inhouse
  const loginInhouse = async(e) => {
    Mixpanel.track("Login button clicked");
      e.preventDefault();
      let checks_passed = true;
      //Check email validity
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(userEmail)) {
        setInvalidUserEmail("Enter a valid email address");
        setUserPassword('');
        checks_passed = false
      }
      //Check password validity
      if(userPassword.trim().length === 0){
        setInvalidUserPassword("Please enter your password");
        checks_passed = false
      }
      //Check for captcha value
      if(!recaptchaValue){
        setInvalidRecaptcha("Captcha is required");
        checks_passed = false;
        return;
      }
      //Check captcha validity
      if(!await verifyRecaptcha(recaptchaValue)){
        recaptcha.reset();
        setInvalidRecaptcha("Invalid Captcha");
        setRecaptchaValue(null);
        checks_passed=false;
      }
      if(!checks_passed){
        return;
      }
      try{
        //Disable the login button to avoid multiple hits
        setDisableLoginButton(true);
        const requestForm = {
          "userEmail": userEmail.trim(),
          "password": userPassword.trim()
        }
        setSource("inhouse");
        //Hit the inhouse login endpoint
        const response = await inhouseLogin(requestForm, navigate);
        if (response.data.statusCode === 200){
          //Set the access and refresh tokens
          setAccessTokenCookie(response.data.result.tokens.accessToken);
          setRefreshToken(response.data.result.tokens.refreshToken);
          const userDetails = response.data.result.userDetails;
          const userDetailsString = JSON.stringify(userDetails);
          setUserDetails(userDetailsString);
          // console.log(response.data.result.userDetails.userid);
          Mixpanel.identify(userDetails.userid);
          Mixpanel.people.set({
            "$name": userDetails.userfullname,
            "$email": userDetails.useremail,
            "Account": userDetails.accountid
          })
          sendLoginDataToCrisp(userDetails);
          if(redirectUrl.length>0 && !signUpSuccessfull){
            navigate(redirectUrl)
          }
          else if(redirectUrl.length>0 && signUpSuccessfull){
            navigate('/home',{state:{showJoinedModel:true}});
          }
          else{
            navigate('/home');
          }
        }
        else if (response.data.statusCode === 456){
          toast.error("User does not exist. Please Sign up!",{position: toast.POSITION.BOTTOM_LEFT});
        }
        else if(response.data.statusCode === 460){
          toast.info("Please verify your email before trying to login.",{position: toast.POSITION.BOTTOM_LEFT});
          setVerificationMessage("Email Not Verified");
          setVerificationDescription("A verification link has been sent to your email ID");
          setShowLoginForm(false);
          setShowEmailSent(true);
        }
        else if (response.data.statusCode === 461){
          // console.log("User not exist");
          toast.error("Incorrect Credentials!",{position: toast.POSITION.BOTTOM_LEFT});
        }
        else if(response.data.statusCode === 202){

        }
        else if(response.data.statusCode === 425){
          if(redirectUrl.length>0 && response.data.result.tokens.accessToken.length > 0){
            navigate(redirectUrl,{state:{tokenTologinAccount:response.data.result.tokens.accessToken,
                                        loginUserName : userEmail.trim(),
                                        loginPwd : userPassword.trim(),
                                        userIdAccountCreate : response.data.result.userid,
                                        source: 'inhouse'}}
                                      );
          }
          else{
            setOpenCreateUserAccount(true);
            setUserIdAccountCreate(response.data.result.userid);
          }
        }
        else{
          toast.error("Something went wrong. Please try again.",{position: toast.POSITION.BOTTOM_LEFT});
        }
      }
      catch(error){
        // console.log("Caught Error", error);
      }
      finally{
        setUserEmail('');
        setUserPassword('');
        setDisableLoginButton(false);
        recaptcha.reset();
      }
    }
  const handleResendEmail = async() => {
    // console.log("RESENDING");
    try{
      const payload = {
        "userEmail": userEmail,
      }
      const response = await regenerateVerifyEmail(payload);
      if (response === 200){
        setShowEmailSent(true);
        toast.success("Please check your mail inbox!",{position: toast.POSITION.BOTTOM_LEFT});
      }
      else if(response === 463){
        setShowEmailSent(false);
        setShowLoginForm(true);
        toast.info("Email already verified. Please login!",{position: toast.POSITION.BOTTOM_LEFT});
      }
      else if(response === 456){
        setShowEmailSent(false);
        setShowLoginForm(true);
        toast.error("User does not exist. Please Sign up!",{position: toast.POSITION.BOTTOM_LEFT});
      }
      else{
        setShowEmailSent(false);
        setShowLoginForm(true);
        toast.error("Something went wrong. Please try again!",{position: toast.POSITION.BOTTOM_LEFT});
      }
    }
    catch(err){
      toast.error("Something went wrong. Please try again after some time.",{position: toast.POSITION.BOTTOM_LEFT});
    }
  }
  //Login using Office
  const loginOffice = async () => {
    Mixpanel.track("Login button clicked");
    const loginRequest = {
        scopes:  ["openid", "profile", "User.Read", "email"]  // Add additional scopes as needed
    };

    try {
        // Attempt login using a popup window
        const response = await msalInstance.loginPopup(loginRequest);

        setUser({access_token: response.accessToken});
        setSource("office365");

    } catch (err) {
        console.error("Error logging in:", err);
    }
};
  // Login using Google
  const loginGoogle = useGoogleLogin({
        onSuccess: (codeResponse) => {
          Mixpanel.track("Login button clicked");
          setUser(codeResponse);

          setSource("google");
        },
        onError: (error) => toast.error("Please try again!",{position: toast.POSITION.BOTTOM_LEFT})
    });

  const loginUser = async() => {
    try{
      const response = await userLogin(user.access_token, source, navigate,showErrorMessage?'':redirectUrl);
      if(response.data.statusCode === 425){
        if(redirectUrl.length>0 && response.data.result.tokens.accessToken.length > 0){
          navigate(redirectUrl,{state:{tokenTologinAccount:response.data.result.tokens.accessToken,
                                      loginUserName : userEmail.trim(),
                                      loginPwd : userPassword.trim(),
                                      userIdAccountCreate : response.data.result.userid,
                                      source:source,
                                      accessToken:user.access_token}}
                                    );
        }
        else{
        setOpenCreateUserAccount(true);
        setUserIdAccountCreate(response.data.result.userid);
      }
      }
    }
    catch (error) {
      // toast.error("Please try again after some time!",{position: toast.POSITION.BOTTOM_LEFT});
    }
  }
  const handleAcceptAccountInviation = async() => {
    try{
      const payload = {
        "token": token
      }
      if(redirectUrl?.length>0){
        return;
      }
      const response = await acceptAccountInviation(payload);
      if (response.data.statusCode === 200){
        setAccessTokenCookie(response.data.result.tokens.accessToken);
        setRefreshToken(response.data.result.tokens.refreshToken);
        const userDetails = response.data.result.userDetails;
        const userDetailsString = JSON.stringify(userDetails);
        setUserDetails(userDetailsString);
        // console.log(response.data.result.userDetails.userid)
        Mixpanel.identify(userDetails.userid);
        Mixpanel.people.set({
          "$name": userDetails.userfullname,
          "$email": userDetails.useremail,
          "Account": userDetails.accountid
        })
        navigate('/home');
      }
      else if (response.data.statusCode === 456){
        toast.error("Please Sign up and then accept the invitation.",{position: toast.POSITION.BOTTOM_LEFT});
      }
      else if(response.data.statusCode === 455){
        toast.error("Invalid Link! Please use the original link or ask for a new invitation request.",{position: toast.POSITION.BOTTOM_LEFT});
      }
      else if(response.data.statusCode === 462){
        toast.error("Invitation link expired! Please ask for a new invitation request.",{position: toast.POSITION.BOTTOM_LEFT});
      }
      else if(response.data.statusCode === 460){
        toast.info("Please verify your email before trying to accept the invitation.",{position: toast.POSITION.BOTTOM_LEFT});
        setVerificationMessage("Email Not Verified");
        setVerificationDescription("A verification link was sent to your email ID");
        setShowLoginForm(false);
        setShowEmailSent(true);
      }
      else{
        toast.error("Could not accept the invitation. Please try again!",{position: toast.POSITION.BOTTOM_LEFT});
      }


    }
    catch(error){
      toast.error(`Something went wrong. Please try again after some time.`,{position: toast.POSITION.BOTTOM_LEFT});
    }
  }
  
    useEffect(
      () => {
          if (user) {
            loginUser();
          }
      },
      [ user ]
  );
  useEffect(() => {
    if(token){
      handleAcceptAccountInviation();
    }
}, [token]);

  return (
    <>
    {openCreateUserAccount &&(<CreateUserAccountModal showModal={openCreateUserAccount} setShowModal={setOpenCreateUserAccount} userId={userIdAccountCreate}/>)}
      <div className="login-wrap">
        <div className="left">
          <div className="inside-wrap">
          {
              showErrorMessage && (

            <div className="error_message">
              <img src={require("../../../assets/img/alert-circle.svg").default} alt="logo" className="me-2"/>
              <div className="message-block">
                <div className="font-14  poppins-medium mb-1">
                  {errorMessage}
                </div>
                <div className=" font-14  lato-regular">
                {errorDescription}
                </div>
              </div>
            </div>
              )
            }
          {
              redirectUrl.length>0 && !showErrorMessage && !signUpSuccessfull && (

            <div className="join_account_message">
              <div className="message-block">
                <div className="font-14  poppins-medium mb-1">
                  Welcome to Sure connect.
                </div>
                <div className=" font-14  lato-regular">
                Please login to join the account.
                </div>
              </div>
            </div>
              )
            }
            <div>
              <img src={require("../../../assets/img/sc-logo-signup.svg").default} className='logo-img' alt="logo" />
            </div>
            <div className="form-wrap">
              
              {showEmailSent && (
                  <VerifySent messDes={verificationDescription} messHead={verificationMessage} userEmail={userEmail} onResend={handleResendEmail}/>
                )}
            {showLoginForm && (
            <div className="sign-up-block">
              <div className="text-center font-20 dark-text poppins-medium mb-4">
                Login
              </div>
              <form>
                <input
                  type="email"
                  value={userEmail}
                  className={`form-control ${invalidUserEmail ? 'is-invalid' : ''}`}
                  placeholder="john@example.com"
                  onChange={handleEmailChange}
                  required
                />
                {invalidUserEmail && (
                      <div className=" font-14  lato-regular text_error_message text_error_message">
                              {invalidUserEmail}
                        </div>
                )}
                <input
                  type="password"
                  value={userPassword}
                  className={`form-control ${invalidUserPassword ? 'is-invalid' : ''}`}
                  placeholder="••••••••"
                  onChange={handlePasswordChange}
                  required
                />
                {invalidUserPassword && (
                      <div className=" font-14  lato-regular text_error_message text_error_message">
                              {invalidUserPassword}
                        </div>
                )}
                <Link to="/forgot-password">
                  <div className="primary-blue-text poppins-medium font-12 mt-1 text-end cursor-pointer">
                    Forgot password?
                  </div>
                </Link>
                <ReCAPTCHA
                  ref={ref => setRecaptcha(ref)}
                  sitekey={recaptchaSiteKey}
                  onChange={handleRecaptchaChange}
                />
                {
                  invalidRecaptcha && (
                    <div className=" font-14  lato-regular text_error_message text_error_message">
                              {invalidRecaptcha}
                    </div>
                  )
                }
                {/* <Link to="/home"> */}
                  <button className="btn blue-btn-primary w-100 my-3 btn-lg"disabled={disableLoginButton} onClick={loginInhouse}>Login</button>
                {/* </Link> */}
                <div className="text-center">OR</div>
                <div className="social-login-btn text-center">
                  <img
                    src={require("../../../assets/img/google-icon.svg").default}
                    alt="google-icon"
                    className="me-2"
                  />
                  <span className="poppins-medium font-14 default-text" onClick={() => loginGoogle()}>
                    Login with Google
                  </span>
                </div>
                <div className="social-login-btn text-center">
                  <img
                    src={require("../../../assets/img/office-365-icon.svg").default}
                    alt="office-365-icon"
                    className="me-2"
                  />
                  <span className="poppins-medium font-14 default-text" onClick={() => loginOffice()}>
                    Login with Office365
                  </span>
                </div>
              </form>
              <div className="font-14 poppins-regular text-center mt-4">
                Don’t have an account?
                <Link to="/signup">
                <span className="primary-blue-text poppins-medium ms-1 cursor-pointer">
                  Sign up
                </span>
                </Link>
              </div>
          </div>
          )}
          </div>
          </div>
        </div>
        <div className="right">
          <div className="inside-wrap">
          {/* <img src={require("../../../assets/img/rhs-img.png")} alt="rhs-image" /> */}
            <iframe className='testi-video mb-4' width="95%" height="334" src="https://www.youtube.com/embed/CtcG24EeJHo?si=C9GoMhrvQq-lIGFq" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
            <img src={require("../../../assets/img/Signup.png")} alt="rhs-image" className='testi-video-text'  />
          </div>
        </div>
        < ToastContainer / >
      </div>
    </>
  );
}
