export default function FilterBar({
  filters = [],
  onRemove = () => {},
  onClear = () => {},
}) {
  return (
    <>
      <span>
        {filters.map((d, filterIndex) => (
          <span key={d.name} className="filter-tag">
            {d.name} {d.separator}{" "}
            {d.list.map((item, itemIndex) => (
              <span>
                <span className="dark-text lato-bold applied-filter-clr">
                  {item.text}
                </span>{" "}
                {item.secondaryText ? (
                  <>
                    and{" "}
                    <span className="dark-text lato-bold applied-filter-clr">
                      {item.secondaryText}
                    </span>
                  </>
                ) : undefined}
                <span onClick={() => onRemove(filterIndex, itemIndex)}>
                  <i className="fa-regular fa-times ms-1 cursor-pointer"></i>
                </span>
              </span>
            ))}
          </span>
        ))}
      </span>
      <span
        className="clear-all primary-blue-text poppins-medium"
        onClick={onClear}
      >
        {" "}
        Clear All
      </span>
    </>
  );
}
