// import React, { useState } from "react";
// import ResultsComponent from "../../uploads/analysis.results.component";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const BeforeAnalyzeMessageModal = ({}) => {
    // const handleDeleteFile = () => {
    //     onFileDelete();
    // }
  return (
    <>
        <div className="form-steps mb-3">
            <div>Upload CSV</div>
            <div><i className='fa-solid fa-chevron-right'></i></div>
            <div>Map Fields</div>
            <div><i className='fa-solid fa-chevron-right'></i></div>
            <div className='active'>Review</div>
        </div>
        {/* <ResultsComponent file={selectedFile} onDelete={handleDeleteFile} results={results} /> */}
        <br />
        <div className='text-align-center d-flex align-items-center justify-content-center mb-3 align-content-center gap-2'>
            <FontAwesomeIcon icon={faInfoCircle} className="fa-sharp fa-light fa-circle-info font-16 cursor-pointer" style={{ fontSize: '20px', color: '#6C79A2' }} />
            <div className="text-center font-18 dark-text poppins-semibold">
                Please Note
            </div>
        </div>
        <p className="text-center font-14 grey-text pt-1 poppins-regular">
            <span>We’re currently processing high volumes.</span>
            <span> Validation may take a <b>few hours to a day</b>, depending on your list size and other factors.</span>
            <span> You’ll <b>receive an email</b> once it’s completed.</span>
            <br />
            <br />
            <span>Thanks for your patience!</span>
        </p>
        {/* {analyzeErrorMessage && (
            <p className="font-14 mt-2" style={{ color: 'red' }}>{analyzeErrorMessage}</p>
        )} */}
    </>
  );
};

export default BeforeAnalyzeMessageModal;