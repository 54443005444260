import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import TeamService from '../../../apis/teamservice';
import { userLogout } from '../../../apis/pvlapis';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const CreateUserAccountModal = ({showModal,setShowModal,userId}) => {
    // When user is not part of any account
    const navigate = useNavigate();

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const handleOnSignoutClick = async() =>{
        await userLogout();
        setShowModal(false);
        sleep(100);
        navigate("/");
    }

  const handleOnCreateAccoutClick = async() =>{
    try {
        await TeamService.CreateUserAccount(navigate,userId);
    } catch (error) {
        toast.error(`Something went wrong. Please try again after some time.`,{position: toast.POSITION.BOTTOM_LEFT});
    }  
    setShowModal(false);
  }

  

  return (
    <Modal
      show={showModal}
      size="sg"
      aria-labelledby="contained-modal-title-vcenter"
      onHide={handleOnSignoutClick}
      // centered
      className='modal-no-border'
    >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
            <div className="verify-fail">
                <div className="text-center mb-3">
                <img src={require("../../../../assets/img/alert.svg").default} alt="Failed" />
                </div>
                
                <div className="text-center font-18 dark-text poppins-semibold mb-2">
                No account found!
                </div>
                <p className="text-center font-14 grey-text mw-388 pt-1 poppins-regular">
                You are not part of any account. Please create a new account to start using SureConnect
                </p>
                {/* <div className="text-center mt-3">
                    <button className="btn blue-btn-primary font-12 position-relative btn btn-primary" >
                    Create account</button>
                </div> */}
            </div>
        </Modal.Body>
        <Modal.Footer  className="no-shadow pt-0">
          <div className="text-end w-100">
              <Button className="btn btn-cancel mr-15px" variant="secondary" onClick={handleOnSignoutClick}>
                  Cancel
              </Button>
              <Button className="btn blue-btn-primary font-12 small-btn" variant="primary" onClick={handleOnCreateAccoutClick} >
                Create account
              </Button>
          </div>
        </Modal.Footer>
    </Modal>      
  )
}

export default CreateUserAccountModal