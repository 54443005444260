import React, { useEffect } from "react";
import { useState } from "react";
import './eventsfilter.component.scss';
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';
import { Tooltip } from "react-tooltip";
import 'react-tooltip/dist/react-tooltip.css'

const EventsFilterApp = ({onMethodChange,onResponseChange,onEventChange,responses,methods,events,applyFilter,onFilterClear}) => {
    const [methodSelection, setMethodSelection] = useState(methods);
    const [responseSelection,setResponseSelection] = useState(responses);
    const [eventSelection,setEventSelection] = useState(events);
    const handleMethodChange = (event) => {
    const { value, checked } = event.target;
    let method = []
    if (checked) {
        method = [...methodSelection, value];
    } else {
        method = methodSelection.filter((method) => method !== value);
    }
    onMethodChange(method);
    setMethodSelection(method);
    };

    const handleEventChange = (event) => {
        // console.log("EVENT TYOE CHAINGING FILTER")
        const { value, checked } = event.target;
        let events = []
        if (checked) {
            events = [...eventSelection, value];
        } else {
            events = eventSelection.filter((events) => events !== value);
        }
        onEventChange(events);
        setEventSelection(events);
        };


    const handleResponseChange = (event) => {
    const { value, checked } = event.target;
    let responses = []
    if (checked) {
        responses = [...responseSelection, value];
    } else {
        responses = responseSelection.filter((responses) => responses !== value);
    }
    onResponseChange(responses);
    setResponseSelection(responses);
    };

    const handleClearMethod = () => {
        onFilterClear(null,"requestmethod");
    };

    const handleClearEventType = () => {
        onFilterClear(null,"eventtype");
        };

    const handleClearResponseStatus = () => {
        onFilterClear(null,"responsestatus");
    };

    useEffect(() => {
    if(methodSelection){
    applyFilter("requestmethod");
    }
    }, [methodSelection]);

    useEffect(() => {
    if(responseSelection){
    applyFilter("responsestatus");
    }
    }, [responseSelection]);

    useEffect(() => {
        if(eventSelection){
        applyFilter("eventtype");
        }
        }, [eventSelection]);

    return (
        <Accordion>
            <Accordion.Item eventKey="0">
                <Accordion.Header data-tooltip-content="Filter by request method" data-tooltip-id="Filters">Request Method</Accordion.Header>
                <Accordion.Body className="p-3">
                    <label className="CheckboxContainer mb-1 mt-1 d-block">
                        <input
                            type="checkbox"
                            name="Status"
                            value="POST"
                            checked={methodSelection.includes("POST")}
                            onChange={handleMethodChange}
                            className=""
                        />
                        <span className="CheckedMark"></span>
                        <span className="ms-4 ps-1 dark-text lato-medium">POST</span>
                    </label>
                    <label className="CheckboxContainer mb-1 mt-1 d-block">
                        <input
                            type="checkbox"
                            name="Status"
                            value="GET"
                            checked={methodSelection.includes("GET")}
                            onChange={handleMethodChange}
                            className=""
                        />
                        <span className="CheckedMark"></span>
                        <span className="ms-4 ps-1 dark-text lato-medium">GET</span>
                    </label>
                    <div className="text-end font-12 poppins-medium primary-blue-text cursor-pointer" onClick={handleClearMethod}>
                        Clear
                    </div>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1" className="d-none">
                <Accordion.Header data-tooltip-content="Filter by request method" data-tooltip-id="Filters">Event Type</Accordion.Header>
                <Accordion.Body className="p-3">
                    <label className="CheckboxContainer mb-1 mt-1 d-block">
                        <input
                            type="checkbox"
                            name="Status"
                            value="0"
                            checked={eventSelection.includes("0")}
                            onChange={handleEventChange}
                            className=""
                        />
                        <span className="CheckedMark"></span>
                        <span className="ms-4 ps-1 dark-text lato-medium">API</span>
                    </label>
                    <label className="CheckboxContainer mb-1 mt-1 d-block">
                        <input
                            type="checkbox"
                            name="Status"
                            value="1"
                            checked={eventSelection.includes("1")}
                            onChange={handleEventChange}
                            className=""
                        />
                        <span className="CheckedMark"></span>
                        <span className="ms-4 ps-1 dark-text lato-medium">Webhook</span>
                    </label>
                    <div className="text-end font-12 poppins-medium primary-blue-text cursor-pointer" onClick={handleClearEventType}>
                        Clear
                    </div>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
                <Accordion.Header data-tooltip-content="Filter by response status" data-tooltip-id="Filters">Response Status</Accordion.Header>
                <Accordion.Body className="p-3">
                    <label className="CheckboxContainer mb-1 mt-1 d-block">
                        <input
                            type="checkbox"
                            name="Status"
                            value="455"
                            checked={responseSelection.includes("455")}
                            onChange={handleResponseChange}
                            className=""
                        />
                        <span className="CheckedMark"></span>
                        <span className="ms-4 ps-1 dark-text lato-medium">455</span>
                    </label>
                    <label className="CheckboxContainer mb-1 mt-1 d-block">
                        <input
                            type="checkbox"
                            name="Status"
                            value="500"
                            checked={responseSelection.includes("500")}
                            onChange={handleResponseChange}
                            className=""
                        />
                        <span className="CheckedMark"></span>
                        <span className="ms-4 ps-1 dark-text lato-medium">500</span>
                    </label>
                    <label className="CheckboxContainer mb-1 mt-1 d-block">
                        <input
                            type="checkbox"
                            name="Status"
                            value="200"
                            checked={responseSelection.includes("200")}
                            onChange={handleResponseChange}
                            className=""
                        />
                        <span className="CheckedMark"></span>
                        <span className="ms-4 ps-1 dark-text lato-medium">200</span>
                    </label>
                    <label className="CheckboxContainer mb-1 mt-1 d-block">
                        <input
                            type="checkbox"
                            name="Status"
                            value="401"
                            checked={responseSelection.includes("401")}
                            onChange={handleResponseChange}
                            className=""
                        />
                        <span className="CheckedMark"></span>
                        <span className="ms-4 ps-1 dark-text lato-medium">401</span>
                    </label>
                    <label className="CheckboxContainer mb-1 mt-1 d-block">
                        <input
                            type="checkbox"
                            name="Status"
                            value="459"
                            checked={responseSelection.includes("459")}
                            onChange={handleResponseChange}
                            className=""
                        />
                        <span className="CheckedMark"></span>
                        <span className="ms-4 ps-1 dark-text lato-medium">459</span>
                    </label>
                    <div className="text-end font-12 poppins-medium primary-blue-text cursor-pointer" onClick={handleClearResponseStatus}>
                        Clear
                    </div>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3" className="d-none">
                <Accordion.Header>Added by</Accordion.Header>
                <Accordion.Body>
                    <input type="text" className="form-control" />
                    <div className="text-end font-12 poppins-medium primary-blue-text cursor-pointer mt-2">
                        Clear
                    </div>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4" className="d-none">
                <Accordion.Header>Added Date</Accordion.Header>
                <Accordion.Body>
                    <div className="d-flex align-items-center justify-content-between mb-1">
                        <Form.Check
                            type="radio"
                            id="Today"
                            label="Today"
                        />
                        <div className="light-grey-text font-12">14 Jun</div>
                    </div>

                    <div className="d-flex align-items-center justify-content-between mb-1">
                        <Form.Check
                            type="radio"
                            id="Yesterday"
                            label="Yesterday"
                        />
                        <div className="light-grey-text font-12">13 Jun</div>
                    </div>

                    <div className="d-flex align-items-center justify-content-between mb-1">
                        <Form.Check
                            type="radio"
                            id="Lastweek"
                            label="Last week"
                        />
                        <div className="light-grey-text font-12">4 - 10 Jun</div>
                    </div>

                    <div className="text-end font-12 poppins-medium primary-blue-text cursor-pointer mt-2">
                        Clear
                    </div>
                </Accordion.Body>
            </Accordion.Item>
            <Tooltip id = "Filters"/>
        </Accordion>
    );
}

export default EventsFilterApp;