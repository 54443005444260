import React from 'react';
// common phone number cols names; these will be appended after the order specific names (_1, _2, etc)
const commonPhoneNumberColNames = ["mobilephone","mobilenumber","mobile","phone","telephone", "phonenumber", "corporatephone"]
// Mapping of the field with the most popularly corresponding column names.
const fieldMappingRules = {
    "first name": ["firstname", "fname", "givenname", "userfirstname"],
    "last name": ["lastname","lname","surname","familyname","userlastname"],
    "email": ["prospectemail","email","emailid","useremail","emailaddress","customeremail","contactemail","personalemail","workemail","mail"],
    "linkedin url": ["prospectlinkedinprofile","linkedin","linkedinprofile","linkedinurl","lnprofile","lnurl"],
    "company name": ["prospectcompanyname","companyname","organization", "company","employer", "business","firm","enterprise","orgname","corpname", "org"],
    "phone1": ['prospectphonenumber1', 'phone1', 'mobile1',"mobilephone","mobilenumber","mobile","phone","primaryphone","primarymobile","firstphone","firstmobile", "telephone", "phonenumber"],
    // "phone1": ["prospectphonenumber1", "phone1", "mobile1","primaryphone","primarymobile","firstphone","firstmobile"].concat(commonPhoneNumberColNames),
    // "phone2": ["prospectphonenumber2", "phone2", "mobile2","secondaryphone", "secondarymobile","alternatephone"].concat(commonPhoneNumberColNames),
    // "phone3": ["prospectphonenumber3", "phone3", "mobile3", "alternatephone"].concat(commonPhoneNumberColNames),
    "prospect name": ["prospectname", "fullname", "username", "name"]
  }

//Field mapping we show in UI to the final column name we have in the csv
const finalFieldMapping = {
    "first name": "prospectname",
    "last name": "prospectname",
    "phone1": "prospectphonenumber_1",
    "phone2": "prospectphonenumber_2",
    "phone3": "prospectphonenumber_3",
    "company name": "prospectcompanyname",
    "email": "prospectemail",
    "linkedin url": "prospectlinkedinprofile"
  }

//App fields to be shown in the UI
const appFields = [
  {name: "first name", required: true},
  {name: "last name", required: false},
  {name: "phone1", required: true},
  {name: "phone2", required: false},
  {name: "phone3", required: false},
  {name: "company name", required: false},
  {name: "email", required: false},
  {name: "linkedin url", required: false}
]

const getColumnMappingforBackend = (mapping) => {
  let columnMapping = {"prospectname":[]}
  for (const [appField, csvColumn] of Object.entries(mapping)){
    if(appField === "first name" || appField === "last name"){
      columnMapping["prospectname"].push(csvColumn);
    }
    else{
      columnMapping[finalFieldMapping[appField]] = csvColumn;
    }
  }
  return columnMapping;
}

const addMappedColumns = (csvData, mapping) => {
    const existingColumns = Object.keys(csvData[0]);
    const newCsvData = csvData.map((row) => {
      const newRow = { ...row };
      for (const [appField, csvColumn] of Object.entries(mapping)) {
        //Create prospectname from first name, last name
        if(appField === "first name"){
          if (Object.keys(mapping).includes("last name")){
            //Check if prospectname already exists
            if(existingColumns.includes("prospectname")){
              //Rename existing prospectname to prospectname_1
              newRow["prospectname_1"] = row["prospectname"];
              newRow["prospectname"] = `${row[mapping["first name"]]} ${row[mapping["last name"]]}`;
            }
            //Directly create the new row if not existing
            else{
              newRow["prospectname"] = `${row[mapping["first name"]]} ${row[mapping["last name"]]}`;
            }
          }
          else{
            if(existingColumns.includes("prospectname")){
              //Rename existing prospectname to prospectname_1
              newRow["prospectname_1"] = row["prospectname"];
              newRow["prospectname"] = row[mapping["first name"]];
            }
            else{
              newRow["prospectname"] = row[mapping["first name"]];
            }
          }
        }
        // Create prospectname from last name if first name is not present
        else if (appField === "last name"){
          // Skip the last name field if first name is present as it will be combined to create prospect name
          if (Object.keys(mapping).includes("first name")) {
            continue;
          }
          else{
            if(existingColumns.includes("prospectname")){
              //Rename existing prospectname to prospectname_1
              newRow["prospectname_1"] = row["prospectname"];
              newRow["prospectname"] = row[mapping["last name"]];
            }
            else{
              newRow["prospectname"] = row[mapping["last name"]];
            }
          }
        }
        else{
          //If the final mapping column is already present in the CSV, then create a new column for it
          if (existingColumns.includes(finalFieldMapping[appField])){
            newRow[`${finalFieldMapping[appField]}_1`] = row[finalFieldMapping[appField]];
            newRow[finalFieldMapping[appField]] = row[csvColumn];
          }
          else{
            //Create the new column
            newRow[finalFieldMapping[appField]] = row[csvColumn];
          }
        }
      }
      return newRow;
    });
    return newCsvData;
  };

const getAppFields = () => {
    return appFields;
}

const automapColumns = (csvColumnNames) => {
    //Used to map the columns to fields
    //Default value of mapped fields
    const mappedFields = {}
    //Iterate through the column names
    csvColumnNames.forEach((column) => {
      for (const [field, aliases] of Object.entries(fieldMappingRules)) {
        //Get the lower case and remove the special characters
        let cleanedColumn = column.toLowerCase().replace(/[^a-z0-9]/g, '');
        if (aliases.includes(cleanedColumn)) {
          //Get the index of the cleaned column in the aliases
          const index = aliases.indexOf(cleanedColumn);
          //Check if the field is already present in the mappingFields and the index of existing value in aliases is less than the index
          //If yes, then ignore the mapping
          if (Object.keys(mappedFields).includes(field) && aliases.indexOf(mappedFields[field].toLowerCase().replace(/[^a-z0-9]/g, '')) < index) {
            continue;
          }
          else{
            if(field === "prospect name"){
              if(!("first name" in mappedFields && "last name" in mappedFields)){
                mappedFields["first name"] = column;
                }
            }
            else{
              mappedFields[field] = column;
            }
          }
          break;
        }
      }
    });
    //Check if the first name and last name are present, if first name value is present in prospect name, delete the last name
    if("first name" in mappedFields && "last name" in mappedFields){
      //Check if the value of first name is from prospect name in field mapping rules
      if(fieldMappingRules["prospect name"].includes(mappedFields["first name"].toLowerCase().replace(/[^a-z0-9]/g, ''))){
        delete mappedFields["last name"];
      }
    }
    return mappedFields;
  }
export {
    getAppFields,
    addMappedColumns,
    automapColumns,
    getColumnMappingforBackend
  };