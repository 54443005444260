import React from "react";
import './app.component.scss'
import SidebarMenu from "./sidebar-menu/sidebar-menu.component";
import HeaderComponent from "./header/header.component";
import HelpComponent from "./base-content/help/help.component";

const HelpApp = () => {
    return (
<div className="container-fluid">
  <div className="row">
      <div className="col-sm-auto sidebar-menu sticky-top">
        <SidebarMenu />
      </div>
      <div className="col-sm min-vh-100 p-0">
        <div>
            <HeaderComponent />
            <HelpComponent />
        </div>
      </div>
  </div>
</div>
    );
}

export default HelpApp;