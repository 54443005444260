import React, { useState } from "react";
import visaImg from '../../../../assets/img/visa.svg'
const CardComponent = ({card,onDelete,onDefault}) => {
    // console.log(card);
    const [isLoading, setIsLoading] = useState(false);
    const last4 = card.last4;
    const expiryMonth = card.expiryMonth;
    const expiryYear = card.expiryYear.slice(-2);
    const cardBrand = card.brand;
    const isDefault = card.default;
    const handleDeleteCard = () => {
        onDelete(card.id);
    }
    const handleChangeDefaultCard = () => {
        if(!isDefault){
            setIsLoading(true);
            onDefault(card.id);
        }
    }
    return(
        <div className="cc-card-wrap">
        <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center">
                <div className="card-icon">
                    <img src={visaImg} alt="VISA" />
                </div>
                <div className="ms-3">
                    <div className="grey-text lato-medium">Card number</div>
                    <div className="dark-text lato-bold">xxxx xxxx xxxx {last4}</div>
                </div>
            </div>
            <div>
                <div className="grey-text lato-medium">Expiry</div>
                <div className="dark-text lato-bold">{expiryMonth}/{expiryYear}</div>
            </div>
        </div>
        <div className="d-flex justify-content-between align-items-center mt-4">
            <div>
                <button className="btn action-btn d-none"><i className="fa-regular fa-pen-to-square me-1"></i>Edit</button>
                <button className="btn action-btn ms-2" onClick={handleDeleteCard}><i className="fa-regular fa-trash-alt me-1"></i>Delete</button>
            </div>
            <div>
                <div className="form-check form-switch">
                    <input 
                    className="form-check-input" 
                    type="checkbox" 
                    role="switch" 
                    id="flexSwitchCheckChecked"
                    checked={isDefault}
                    onChange={handleChangeDefaultCard}
                     />
                    <label className="form-check-label grey-text min-w-auto mb-0" for="flexSwitchCheckChecked">Set as Default</label>
                </div>
            </div>
        </div>
    </div>
    )
}

export default CardComponent;