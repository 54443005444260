import axios from "axios";
import Cookies from "universal-cookie";
import { regenerateTokens, userLogout } from "../../apis/pvlapis";
import { setupCache } from "axios-cache-interceptor";
import { convertMinutesToMilliseconds } from "../utils/helper.utils";

const cookies = new Cookies();
const baseURL = process.env.REACT_APP_PVL_INTEGRATION_URL;

const instance = axios.create({
  baseURL: baseURL,
  timeout: 100000,
  headers: { "Content-Type": "application/json" },
});

const apiClient = setupCache(instance, { methods: ["get", "post"] });

apiClient.interceptors.request.use(
  function (config) {
    config.headers.Authorization = `Bearer ${cookies.get("accessToken")}`;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

apiClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 457) &&
      !originalRequest._retry
    ) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers["Authorization"] = `Bearer ${token}`;
            return axios(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      try {
        const status = await regenerateTokens();
        if (status === 200) {
          const token = cookies.get("accessToken");
          originalRequest.headers["Authorization"] = `Bearer ${token}`;
          processQueue(null, token);
          return axios(originalRequest);
        } else {
          await userLogout();
        }
      } catch (err) {
        processQueue(err, null);
        return Promise.reject(err);
      } finally {
        isRefreshing = false;
      }
    }

    return Promise.reject(error);
  }
);

const handleResponse = (response) =>
  response.data ? JSON.parse(JSON.stringify(response.data)) : response.data;

const handleError = (error) => {
  console.error("API call error:", error);
  throw error;
};

const IntegrationService = {
  Connect: async (integrationName, integrationMode) => {
    return await apiClient
      .get(
        `/api/integrationAuth?name=${integrationName}&integrationMode=${integrationMode}`,
        { cache: false }
      )
      .then((res) => res.data);
  },

  Integrations: async () => {
    try {
      const response = await apiClient.get("/api/integrations/list", {
        cache: false,
      });
      return handleResponse(response);
    } catch (error) {
      handleError(error);
    }
  },

  CRMConfigurations: async () => {
    try {
      const response = await apiClient.get(
        "/api/integrations/crmconfigurationslist"
      );
      return handleResponse(response);
    } catch (error) {
      handleError(error);
    }
  },

  SyncSettings: async (integrationId) => {
    try {
      const response = await apiClient.get(
        `/api/integrationSyncSetting/syncSettings?integrationId=${integrationId}`,
        { cache: false }
      );
      return handleResponse(response);
    } catch (error) {
      handleError(error);
    }
  },

  UpdateSyncSetting: async (payload) => {
    try {
      const response = await apiClient.put(
        `/api/integrationSyncSetting/updateSyncSetting`,
        payload
      );
      return handleResponse(response);
    } catch (error) {
      handleError(error);
    }
  },

  CrmFields: async (objectTypeName, integrationId, integrationName) => {
    try {
      const response = await apiClient.get(
        `/api/integrations/getCRMFields?typeName=${objectTypeName}&integrationId=${integrationId}&integrationName=${integrationName}`,
        { cache: { ttl: convertMinutesToMilliseconds(60) } }
      );
      return handleResponse(response);
    } catch (error) {
      handleError(error);
    }
  },

  FieldMappings: async (integrationId, integrationType, objectType) => {
    try {
      const response = await apiClient.get(
        `/api/fieldMapping/fieldMappings?integrationId=${integrationId}&integrationType=${integrationType}&objectTypes=${objectType}`,
        { cache: false }
      );
      return handleResponse(response);
    } catch (error) {
      handleError(error);
    }
  },

  UpdateObjectConfiguration: async (syncSettingId, status) => {
    return await apiClient.put(
      `/api/integrationSyncSetting/configureSyncObjects?syncSettingId=${syncSettingId}&enableObjectMapping=${status}`
    );
  },

  UpdateFieldMapping: async (payload) => {
    try {
      const response = await apiClient.put(
        `/api/fieldMapping/upsertFieldMapping`,
        payload
      );
      return handleResponse(response);
    } catch (error) {
      handleError(error);
    }
  },

  DeleteMapping: async (fieldMappingIds) => {
    return await apiClient.delete(
      `/api/fieldMapping/deleteFieldMapping?fieldMappingIds=${fieldMappingIds}`
    );
  },

  UserMappings: async (integrationId) => {
    try {
      const response = await apiClient.get(
        `/api/userMapping/userMappings?integrationId=${integrationId}`,
        { cache: false }
      );
      return handleResponse(response);
    } catch (error) {
      handleError(error);
    }
  },

  UpdateUserMapping: async (payload) => {
    try {
      const response = await apiClient.put(
        `/api/userMapping/upsertUserMapping`,
        payload
      );
      return handleResponse(response);
    } catch (error) {
      handleError(error);
    }
  },

  DeleteUserMapping: async (integrationId, userId) => {
    try {
      const response = await apiClient.delete(
        `/api/userMapping/deleteUserMapping?integrationId=${integrationId}&userId=${userId}`
      );
      return handleResponse(response);
    } catch (error) {
      handleError(error);
    }
  },

  toggleRecordActivity: async (integrationId, status) => {
    try {
      const response = await apiClient.put(
        `/api/integrations/configureActivity?integrationId=${integrationId}&enableActivity=${status}`
      );
      return handleResponse(response);
    } catch (error) {
      handleError(error);
    }
  },

  ConfigurationSummary: async (integrationId) => {
    try {
      const response = await apiClient.get(
        `/api/integrations/ConfigurationSummary?integrationId=${integrationId}`,
        { cache: false }
      );
      return handleResponse(response);
    } catch (error) {
      handleError(error);
    }
  },

  GetSyncLogsByCategory: async (payload) => {
    try {
      const response = await apiClient.post(
        `/api/integrationlogs/GetCRMLogsByCategory`,
        payload,
        { cache: false }
      );
      return handleResponse(response);
    } catch (error) {
      handleError(error);
    }
  },

  GetCRMLogsGroupedData: async (payload) => {
    try {
      const response = await apiClient.post(
        `/api/integrationlogs/GetCRMLogsGroupedData`,
        payload,
        { cache: false }
      );
      return handleResponse(response);
    } catch (error) {
      handleError(error);
    }
  },

  GetCRMLogsCount: async (integrationId, type) => {
    try {
      const response = await apiClient.get(
        `/api/integrationlogs/GetCRMLogsCountPerDay?integrationid=${integrationId}&graphtype=${type}`,
        { cache: false }
      );
      return handleResponse(response);
    } catch (error) {
      handleError(error);
    }
  },

  SyncHistory: async (payload) => {
    try {
      const response = await apiClient.post(
        `/api/CRMSyncHistory/getCRMSyncHistory`,
        payload,
        { cache: false }
      );
      return handleResponse(response);
    } catch (error) {
      handleError(error);
    }
  },

  DisconnectIntegration: async (integrationId) => {
    try {
      const response = await apiClient.delete(
        `/api/integrations/disconnectintegration?integrationid=${integrationId}`
      );
      return handleResponse(response);
    } catch (error) {
      handleError(error);
    }
  },

  InvokeSync: async (payload) => {
    try {
      const response = await apiClient.post(
        `/api/integrations/syncnow`,
        payload,
        { cache: false }
      );
      return handleResponse(response);
    } catch (error) {
      handleError(error);
    }
  },

  CancelSync: async (integrationId) => {
    try {
      const response = await apiClient.get(
        `/api/integrations/cancelsync?integrationid=${integrationId}`,
        { cache: false }
      );
      return handleResponse(response);
    } catch (error) {
      handleError(error);
    }
  },

  SyncStatus: async (integrationId, objectTypes) => {
    try {
      const response = await apiClient.get(
        `/api/integrations/syncstatus?integrationid=${integrationId}&objecttypes=${objectTypes}`,
        { cache: false }
      );
      return handleResponse(response);
    } catch (error) {
      handleError(error);
    }
  },

  CrmUsers: async (integrationId, integrationName) => {
    try {
      const response = await apiClient.post(
        `/api/integrations/getCRMUsers?integrationId=${integrationId}&integrationName=${integrationName}`,
        {},
        { cache: { ttl: convertMinutesToMilliseconds(60) } }
      );
      return handleResponse(response);
    } catch (error) {
      handleError(error);
    }
  },

  GetCrmApiUsage: async (integrationId) => {
    try {
      const response = await apiClient.get(
        `/api/integrationAPILimit/getCRMApiUsage?integrationId=${integrationId}`,
        { cache: false }
      );
      return handleResponse(response);
    } catch (error) {
      handleError(error);
    }
  },

  GetApiLimitUsage: async (integrationId) => {
    try {
      const response = await apiClient.get(
        `/api/integrationAPILimit/getAPILimitUsage?integrationId=${integrationId}`,
        { cache: false }
      );
      return handleResponse(response);
    } catch (error) {
      handleError(error);
    }
  },

  UpdateApiLimit: async (payload) => {
    try {
      const response = await apiClient.put(
        `/api/integrationAPILimit/updateApiLimit`,
        payload
      );
      return handleResponse(response);
    } catch (error) {
      handleError(error);
    }
  },

  ValidationDetails: async (integrationId) => {
    try {
      const response = await apiClient.get(
        `/api/integrations/ValidationDetails?integrationid=${integrationId}`,
        { cache: false }
      );
      return handleResponse(response);
    } catch (error) {
      handleError(error);
    }
  },
};

export default IntegrationService;
