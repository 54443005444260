import React from "react";
import { Tooltip } from "react-tooltip";
import 'react-tooltip/dist/react-tooltip.css'
import './sidebar-menu.component.scss'
import { NavLink } from 'react-router-dom';

const SidebarMenu = () => {
    return (
        <div className="d-flex flex-sm-column flex-row flex-nowrap align-items-center sticky-top sidebar-menu-btn h-100">
            <a href="/home" className="d-block p-3 link-dark text-decoration-none" title="">
                <img src={require("../../../assets/img/logo.svg").default} alt="Logo" />
            </a>
            <ul className="nav nav-pills nav-flush flex-sm-column flex-row flex-nowrap mb-auto mx-auto text-center justify-content-between w-100 px-3 align-items-center">
                <li className="nav-item" data-tooltip-content="Home" data-tooltip-id="sidebar">
                    <NavLink exact to="/home" activeClassName="active" className="nav-link py-3 px-2" aria-current="page">
                        <i className="fa-regular fa-house"></i>
                    </NavLink>
                </li>
                <li className="nav-item" data-tooltip-content="All Lists" data-tooltip-id="sidebar">
                    <NavLink to="/list" activeClassName="active" aria-current="page" className="nav-link py-3 px-2">
                        <i className="fa-regular fa-rectangle-history"></i>
                    </NavLink>
                </li>
            </ul>
            <ul className="nav nav-pills nav-flush flex-sm-column flex-row flex-nowrap mt-auto mx-auto text-center justify-content-between w-100 px-3 align-items-center">
                <li className="nav-item d-none" data-tooltip-content="Settings" data-tooltip-id="sidebar">
                    <NavLink to="/setting" activeClassName="active" aria-current="page" className="nav-link py-3 px-2">
                        <i className="fa-regular fa-gear"></i>
                    </NavLink>
                </li>
                <li className="nav-item text-center font-12" data-tooltip-content="Join our Slack group for support" data-tooltip-id="sidebar">
                    <NavLink to="https://sureconnect-workspace.slack.com/archives/C079C10AYCW" activeClassName="active" aria-current="page" className="nav-link py-3 px-2 sidebar-slack-help-option" target="_blank">
                    <img src={require("../../../assets/img/slack-help-icon.svg").default} alt="Logo" />
                    <span className="font-14 mt-1">Help</span>
                    </NavLink>
                </li>
                <li className="nav-item" data-tooltip-content="How-To Guide" data-tooltip-id="sidebar">
                    <NavLink to="https://help.sureconnect.ai/en/article/getting-started-with-sureconnect-1ls0q3q/" activeClassName="active" aria-current="page" className="nav-link py-3 px-2" target="_blank">
                        <i className="fa-regular fa-circle-question"></i>
                    </NavLink>
                </li>
            </ul>
            {/* <div className="dropdown">
      <a href="#" className="d-flex align-items-center justify-content-center p-3 link-dark text-decoration-none dropdown-toggle" id="dropdownUser3" data-bs-toggle="dropdown" aria-expanded="false">
          <i className="fa-regular fa-gear"></i>
      </a>
      <ul className="dropdown-menu text-small shadow" aria-labelledby="dropdownUser3">
          <li><a className="dropdown-item" href="#">New project...</a></li>
          <li><a className="dropdown-item" href="#">Settings</a></li>
          <li><a className="dropdown-item" href="#">Profile</a></li>
      </ul>
  </div> */}
            <Tooltip id="sidebar" />
        </div>
    )
};

export default SidebarMenu;