import React, { useEffect, useState } from 'react'
import { Accordion } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Tooltip } from "react-tooltip";
import 'react-tooltip/dist/react-tooltip.css'

const UsersComponentFilter = ({onLowerChange,lower,onUpperChange,upper,onRolesChange, onStatusChange,applyFilter,
    status, roles, onTotalClear, transactionType,hidePaymentStyle,
    type,onTypeChange,onTypeClear}) => {
    const [totalCreditsLowerLimit, setTotalCreditsLowerLimit] = useState(lower);
    const [totalCreditsUpperLimit, setTotalCreditsUpperLimit] = useState(upper);
    const [rolesSelection, setRolesSelection] = useState(roles);
    const [statusesSelection, setStatusesSelection] = useState(status);
    const [typeSelection, setTypeSelection] = useState(type)
    const [selectedFilter, setSelectedFilter] = useState(null);
    const [invalidCreditsInput, setInvalidCreditsInput] = useState(null);
    let transactionTypeText = 'Credits Used';
    if(transactionType === 0){
        transactionTypeText = 'Credits Purchased'
    }

    const handleLowerLimitChange = (event) => {
        const value = event.target.value;
        onLowerChange(value);
        setSelectedFilter("transactionCredits");
        setTotalCreditsLowerLimit(value);
        if((parseInt(value, 10)>parseInt(totalCreditsUpperLimit, 10))){
            setInvalidCreditsInput("Min credits cannot be more than Max credits.");
        }
        else{
            setInvalidCreditsInput(null);
        }
      };
    
      const handleUpperLimitChange = (event) => {
        const value = event.target.value;
        setTotalCreditsUpperLimit(value);
        onUpperChange(value);
        if((parseInt(value, 10)<parseInt(totalCreditsLowerLimit, 10))){
            setInvalidCreditsInput("Min credits cannot be more than Max credits.");
        }
        else{
            setInvalidCreditsInput(null);
        }
      };

      const handleCheckboxChange = (event) => {
        const { value, checked } = event.target;
        let status = []
        if (checked) {
            status = [...rolesSelection, value];
        //   setrolesSelection([...rolesSelection, value]);
        } else {
            status = rolesSelection.filter((status) => status !== value);
        //   setrolesSelection(rolesSelection.filter((status) => status !== value));
        }
        
        onRolesChange(status);
        setRolesSelection(status);
      };

      const handleStatusCheckboxChange = (event) => {
        const { value, checked } = event.target;
        let status = []
        if (checked) {
            status = [...statusesSelection, value];
        //   setrolesSelection([...rolesSelection, value]);
        } else {
            status = statusesSelection.filter((status) => status !== value);
        //   setrolesSelection(rolesSelection.filter((status) => status !== value));
        }
        
        onStatusChange(status);
        setStatusesSelection(status);
      };

      const handleStatusChange = (event) => {
        const newType = [event.target.value]
        onTypeChange(newType);
        setTypeSelection(newType);
      };
      const handleClearStatusType = () => {
        onTypeClear();
    };

      const handleClearRoles = () => {
        onRolesChange([]);
        setRolesSelection([]);
      };
      const handleStatusRoles = () => {
        onStatusChange([]);
        setStatusesSelection([]);
      };
      
      const handleClearTotalRecords = () => {
        onTotalClear();
      }

      useEffect(() => {
        if(rolesSelection){
            // console.log("rolesSelection");
        applyFilter("transactionStatus");
        }
        
      }, [rolesSelection]);

      useEffect(() => {
        if(typeSelection){
        applyFilter("transactionReason");
        }
      }, [typeSelection]);

      useEffect(() => {
        applyFilter("transactionCredits"); 
        // console.log(totalCreditsLowerLimit);
      }, [totalCreditsLowerLimit,totalCreditsUpperLimit]);

    return (
        <Accordion>
            <Accordion.Item eventKey="0">
                <Accordion.Header className={hidePaymentStyle} data-tooltip-content="Filter by Roles" data-tooltip-id="Filters">Roles</Accordion.Header>
                <Accordion.Body className="p-3">
                    <label className="CheckboxContainer mb-1 mt-1 d-block">
                        <input
                            type="checkbox"
                            name="Status"
                            value="Admin"
                            checked={rolesSelection.includes("Admin")}
                            data-tooltip-content="Search by transaction reason" 
                            data-tooltip-id="listtips"
                            onChange={handleCheckboxChange}
                            className=""
                        />
                        <span className="CheckedMark"></span>
                        <span className="ms-4 ps-1 dark-text lato-medium">Admin <span className="light-grey-text font-12 ms-1 d-none"></span></span>
                    </label>
                    <label className="CheckboxContainer mb-1 mt-1 d-block">
                        <input
                            type="checkbox"
                            name="Status"
                            value="Member"
                            checked={rolesSelection.includes("Member")}
                            onChange={handleCheckboxChange}
                            className=""
                        />
                        <span className="CheckedMark"></span>
                        <span className="ms-4 ps-1 dark-text lato-medium">Member<span className="light-grey-text font-12 ms-1 d-none"></span></span>
                    </label>
                    <label className="CheckboxContainer mb-1 mt-1 d-block">
                        <input
                            type="checkbox"
                            name="Status"
                            value="Owner"
                            checked={rolesSelection.includes("Owner")}
                            onChange={handleCheckboxChange}
                            className=""
                        />
                        <span className="CheckedMark"></span>
                        <span className="ms-4 ps-1 dark-text lato-medium">Owner <span className="light-grey-text font-12 ms-1 d-none"></span></span>
                    </label>
                    <div className="text-end font-12 poppins-medium primary-blue-text cursor-pointer" onClick={handleClearRoles}>
                        Clear
                    </div>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
                <Accordion.Header className={hidePaymentStyle} data-tooltip-content="Filter by Status" data-tooltip-id="Filters">Status</Accordion.Header>
                <Accordion.Body className="p-3">
                    <label className="CheckboxContainer mb-1 mt-1 d-block">
                        <input
                            type="checkbox"
                            name="Status"
                            value="Invited"
                            checked={statusesSelection.includes("Invited")}
                            data-tooltip-content="Search by transaction reason" 
                            data-tooltip-id="listtips"
                            onChange={handleStatusCheckboxChange}
                            className=""
                        />
                        <span className="CheckedMark"></span>
                        <span className="ms-4 ps-1 dark-text lato-medium">Invited <span className="light-grey-text font-12 ms-1 d-none"></span></span>
                    </label>
                    <label className="CheckboxContainer mb-1 mt-1 d-block">
                        <input
                            type="checkbox"
                            name="Status"
                            value="Active"
                            checked={statusesSelection.includes("Active")}
                            onChange={handleStatusCheckboxChange}
                            className=""
                        />
                        <span className="CheckedMark"></span>
                        <span className="ms-4 ps-1 dark-text lato-medium">Active<span className="light-grey-text font-12 ms-1 d-none"></span></span>
                    </label>
                    <label className="CheckboxContainer mb-1 mt-1 d-block">
                        <input
                            type="checkbox"
                            name="Status"
                            value="Expired"
                            checked={statusesSelection.includes("Expired")}
                            onChange={handleStatusCheckboxChange}
                            className=""
                        />
                        <span className="CheckedMark"></span>
                        <span className="ms-4 ps-1 dark-text lato-medium">Expired <span className="light-grey-text font-12 ms-1 d-none"></span></span>
                    </label>
                    <label className="CheckboxContainer mb-1 mt-1 d-block">
                        <input
                            type="checkbox"
                            name="Status"
                            value="Disabled"
                            checked={statusesSelection.includes("Disabled")}
                            onChange={handleStatusCheckboxChange}
                            className=""
                        />
                        <span className="CheckedMark"></span>
                        <span className="ms-4 ps-1 dark-text lato-medium">Disabled <span className="light-grey-text font-12 ms-1 d-none"></span></span>
                    </label>
                    <div className="text-end font-12 poppins-medium primary-blue-text cursor-pointer" onClick={handleStatusRoles}>
                        Clear
                    </div>
                </Accordion.Body>
            </Accordion.Item>
            <Tooltip id = "Filters"/>
        </Accordion>
    );
}

export default  UsersComponentFilter;