import {
  createContext,
  forwardRef,
  memo,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Dropdown } from "react-bootstrap";
import SelectInput from "../../../../shared/select/select-input.component";
import IntegrationService from "../../../../services/integrationservice";
import { useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import { getInboundFilterOperators } from "../../integrations-constants";
import moment from "moment";

const filterRulesObj = {
  crmfieldid: "",
  crmfieldname: "",
  crmfieldtype: "",
  operator: 1,
  value: "",
  values: [],
};

const filterGroupObj = {
  condition: "AND",
  rules: [{ ...filterRulesObj }],
};

const conditions = ["AND", "OR"];

const InboundFilterContext = createContext({});

const IntegrationInboundFilter = forwardRef(({ filters = [] }, ref) => {
  const { configuration, integration } = useOutletContext();
  const [inboundFilters, setInboundFilters] = useState([...filters]);
  const [crmFieldOptions, setCrmFieldOptions] = useState({});
  const [filtersCount, setFiltersCount] = useState({});
  const [dataTypesMappingObj, setDataTypesMappingObj] = useState({});
  const [crmUsersData, setCrmUsersData] = useState([]);

  useEffect(() => {
    setInboundFilters([...filters]);

    if (filters.length) {
      const objectTypeIds = [];
      filters.forEach((item) => {
        updateFiltersCount(item.filter, item.objectId);
        objectTypeIds.push(item.objectId);
      });

      getCrmFieldsByObjectType(objectTypeIds);
    }
  }, [filters]);

  useEffect(() => {
    getOwnersData();
  }, []);

  useImperativeHandle(ref, () => {
    return {
      getFilters() {
        return getInboundFilters();
      },
    };
  });

  function getInboundFilters() {
    const isValid = (g) => {
      let valid = true;
      const newRules = [];

      for (const r of g.rules) {
        if (r?.condition && r?.rules?.length) {
          return isValid(r);
        } else if (!r?.condition) {
          if (r.crmfieldid && !r.value && !r.values) {
            valid = false;
          } else if (r.crmfieldid) {
            if (r.crmfieldtype === "datetime") {
              r.value = moment.utc(r.value).format();
            }
            newRules.push(r);
          }
        }
      }

      g.rules = newRules.filter((r) =>
        r?.condition ? r?.rules?.length : true
      );
      return valid;
    };

    const _inboundFilters = [...inboundFilters];

    for (const obj of _inboundFilters) {
      if (!isValid(obj.filter)) {
        throw new Error("Field cannot be empty for Criteria.");
      }

      obj.filter.rules = obj.filter?.rules?.filter((r) =>
        r?.condition ? (r?.rules ? r?.rules?.length : false) : r?.crmfieldid
      );
    }

    return _inboundFilters;
  }

  function getOwnersData() {
    IntegrationService.CrmUsers(
      integration.integrationId,
      configuration.name
    ).then((d) => {
      const mappedD = (d?.result ?? []).map((u) => ({
        id: u.id || u.email,
        text: u.name,
        additional: u,
      }));
      setCrmUsersData(mappedD);
    });
  }

  function handleUpdateFilters(objectIndex, updatedFilterObj) {
    inboundFilters[objectIndex].filter = updatedFilterObj;
    updateFiltersCount(updatedFilterObj, inboundFilters[objectIndex].objectId);

    setInboundFilters([...inboundFilters]);
  }

  async function getCrmFieldsByObjectType(objectTypeIds) {
    const filterValidationObj =
      configuration.uiConfig.uiproperties.configuresyncsettings.inboundfilters;
    const _validDataTypeObj = {};
    const _dataTypeMappingObj = {};

    filterValidationObj.supporteddatatypes.forEach((type) => {
      _validDataTypeObj[type] = true;
    });

    filterValidationObj.datatypeoperatormapping.forEach((obj) => {
      _dataTypeMappingObj[obj.datatype] = {
        operators: obj.operators,
        typeofvaluedropdown: obj.typeofvaluedropdown,
      };
    });

    setDataTypesMappingObj(_dataTypeMappingObj);

    const options = { ...crmFieldOptions };

    for (const id of objectTypeIds) {
      const crmObjectName = configuration.defaultConfig.types.find(
        (o) => o.typeId === id
      )?.name;

      await IntegrationService.CrmFields(
        crmObjectName,
        integration.integrationId,
        configuration.name
      ).then((result) => {
        options[id] = (result || [])
          .filter(
            (item) =>
              _validDataTypeObj.hasOwnProperty(item.type) ||
              filterValidationObj.supportedfieldids.includes(item.id)
          )
          .map((item) => ({
            id: item.id,
            text: item.name,
            additional: item,
          }));
      });
    }

    setCrmFieldOptions(options);
  }

  function updateFiltersCount(filter, objectId) {
    let count = 0;

    const addRules = (g) => {
      g.rules.forEach((r) => {
        if (r?.condition) {
          addRules(r);
        } else {
          count = count + 1;
        }
      });
    };

    addRules(filter);

    const _filtersCount = { ...filtersCount };
    _filtersCount[objectId] = count;
    setFiltersCount({ ..._filtersCount });
  }

  return inboundFilters.map((obj, objIndex) => (
    <div
      key={obj.objectId}
      className={`${obj.inboundSyncEnabled === 2 ? "d-none" : ""}`}
    >
      <div className="mt-5 mb-3">
        <span>
          <i className="fa-regular fa-filter six-c-7"></i>
        </span>
        <span className="lato-bold dark-text ms-2">
          {obj.objectName} Inbound Filters
        </span>
        <div className="grey-text mt-2">
          Fetch {obj.objectName.toLowerCase() + "s"} based on the filter
          conditions configured below.
        </div>
        <div className="lato-medium">
          <strong>Note:</strong> All date-time fileds will be considered in UTC
          timezone.
        </div>
      </div>
      <div className="summary-wrap max-w-800 mb-4">
        <InboundFilterContext.Provider
          value={{
            crmFieldData: crmFieldOptions?.[obj.objectId] ?? [],
            filtersCount: filtersCount[obj.objectId],
            configuration,
            dataTypesMappingObj,
            crmUsersData,
          }}
        >
          <FilterCriteriaGroup
            group={obj.filter}
            showRemove={false}
            onUpdate={(updatedFilter) =>
              handleUpdateFilters(objIndex, updatedFilter)
            }
          />
        </InboundFilterContext.Provider>
      </div>
    </div>
  ));
});

const FilterCriteriaGroup = memo(
  ({ group, showRemove = true, onRemoveGroup, onUpdate }) => {
    const [_group, _setGroup] = useState(group);
    const { filtersCount, configuration } = useContext(InboundFilterContext);

    const maxFiltersAllowed =
      configuration.uiConfig.uiproperties.configuresyncsettings.inboundfilters
        .maxfiltersallowed;

    function handleAddRule() {
      if (filtersCount >= maxFiltersAllowed) {
        toast.warning(`Maximum ${maxFiltersAllowed} filters can be added.`);
        return;
      }

      const rules = [..._group.rules];
      rules.push({ ...filterRulesObj });
      _setGroup({ ..._group, rules: rules });

      triggerUpdate({ ..._group, rules: rules });
    }

    function handleRemoveRule(removedIndex) {
      const rules = [..._group.rules];
      rules.splice(removedIndex, 1);
      _setGroup({ ..._group, rules: rules });

      triggerUpdate({ ..._group, rules: rules });
    }

    function handleAddGroup() {
      if (filtersCount >= maxFiltersAllowed) {
        toast.warning(`Maximum ${maxFiltersAllowed} filters can be added.`);
        return;
      }

      const rules = [..._group.rules];
      rules.push({ ...filterGroupObj });
      _setGroup({ ..._group, rules: rules });

      triggerUpdate({ ..._group, rules: rules });
    }

    function handleConditionChange(condition) {
      const g = { ..._group };
      g.condition = condition;
      _setGroup({ ...g });
      triggerUpdate(g);
    }

    function handleUpdateGroup(ruleIndex, updatedObj) {
      const rules = [..._group.rules];
      rules[ruleIndex] = updatedObj;
      _setGroup({ ..._group, rules: rules });

      triggerUpdate({ ..._group, rules: rules });
    }

    function triggerUpdate(obj) {
      onUpdate(obj);
    }

    return (
      <div>
        <div
          className="d-flex align-items-center justify-content-between"
          style={{ zIndex: "1", position: "relative" }}
        >
          <div className="btn-group">
            {conditions.map((condition) => (
              <button
                className={`btn white-grp-btn change-condition ${_group.condition === condition ? "active" : undefined}`}
                onClick={() => handleConditionChange(condition)}
              >
                {" "}
                {condition}{" "}
              </button>
            ))}
          </div>
          <div className="d-flex align-items-center gap-10">
            <button className="btn icon-btn-add" onClick={handleAddRule}>
              <i className="fas fa-plus add-criteria"></i>
            </button>
            <Dropdown>
              <Dropdown.Toggle className="minimal-btn hide">
                <i className="far fa-ellipsis-v light-grey-text ellipse-icon"></i>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={(event) => handleAddGroup(event)}>
                  Add Group
                </Dropdown.Item>
                {showRemove ? (
                  <Dropdown.Item onClick={onRemoveGroup}>
                    Remove Group
                  </Dropdown.Item>
                ) : undefined}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <div>
          <ul className={`timeline-list ${showRemove ? "ps-2" : ""}`}>
            {_group.rules.map((rule, ruleIndex) => (
              <FilterCriteriaRules
                key={ruleIndex}
                rule={rule}
                onUpdate={(value) => handleUpdateGroup(ruleIndex, value)}
                onRemove={() => handleRemoveRule(ruleIndex)}
              />
            ))}
          </ul>
        </div>
      </div>
    );
  }
);

const FilterCriteriaRules = memo(({ rule, onRemove, onUpdate }) => {
  const { crmFieldData, dataTypesMappingObj, crmUsersData } =
    useContext(InboundFilterContext);
  const { configuration } = useOutletContext();
  const [operators, setOperators] = useState([]);
  const [valueSelectData, setValueSelectData] = useState([]);
  const [isMultipleSelection, setIsMultipleSelection] = useState(false);

  useEffect(() => {
    if (rule.crmfieldtype) {
      const _operators = getInboundFilterOperators(
        dataTypesMappingObj[rule.crmfieldtype]?.operators ?? []
      );

      setOperators([..._operators]);
    }
  }, [dataTypesMappingObj]);

  useEffect(() => {
    if (crmFieldData?.length && rule.crmfieldid) {
      const foundData = crmFieldData.find((d) => d.id === rule.crmfieldid);

      if (foundData) {
        getValueFieldData(foundData);
      }
    }
  }, [crmFieldData]);

  function handleFilterValueChange(value) {
    const _operators = getInboundFilterOperators(
      dataTypesMappingObj[value.additional.type]?.operators ?? []
    );
    const _rule = { ...rule };

    _rule.crmfieldid = value.id;
    _rule.crmfieldname = value.text;
    _rule.crmfieldtype = value.additional.type;
    _rule.operator = _operators?.[0]?.id ?? null;
    _rule.value = null;
    _rule.values = null;

    setOperators([..._operators]);
    onUpdate({ ..._rule });

    getValueFieldData(value);
  }

  function getValueFieldData(value) {
    if (value.additional?.pickListValues?.length) {
      setIsMultipleSelection(false);
      setValueSelectData(
        value.additional.pickListValues.map((item) => ({
          id: item.name,
          text: item.displayName,
          additional: item,
        }))
      );
    } else if (
      configuration.uiConfig.uiproperties.configuresyncsettings.inboundfilters.crmownerfieldids.includes(
        value.id
      )
    ) {
      setIsMultipleSelection(true);
      setValueSelectData([...crmUsersData]);
    } else {
      setIsMultipleSelection(false);
      setValueSelectData([]);
    }
  }

  function handleOperatorChange(value) {
    const _rule = { ...rule };

    _rule.operator = value;
    onUpdate({ ..._rule });
  }

  function handleValueChange(value) {
    const _rule = { ...rule };
    _rule.value = value;
    _rule.values = null;

    onUpdate({ ..._rule });
  }

  function handleSelectionChanges(values) {
    const _rule = { ...rule };

    _rule.value = null;

    if (isMultipleSelection) {
      _rule.values = values.map((v) => ({ id: v.id, text: v.text }));
    } else {
      _rule.values = [{ id: values.id, text: values.text }];
    }

    onUpdate({ ..._rule });
  }

  return rule?.condition ? (
    <li className="group-list-item timeline-criteria">
      <FilterCriteriaGroup
        group={rule}
        onRemoveGroup={onRemove}
        onUpdate={onUpdate}
      />
    </li>
  ) : (
    <li className="timeline-criteria">
      <div className="d-flex" style={{ gap: "2%" }}>
        <SelectInput
          style={{ width: "32%" }}
          defaultValue={rule.crmfieldid}
          placeholder="Select field"
          data={crmFieldData}
          onSelectionChanges={handleFilterValueChange}
          allowSearch={true}
        />
        <SelectInput
          style={{ width: "32%" }}
          placeholder="Select operator"
          defaultValue={rule.operator}
          data={operators}
          onValueChanges={handleOperatorChange}
        />
        {(() => {
          switch (dataTypesMappingObj[rule.crmfieldtype]?.typeofvaluedropdown) {
            case "text":
              return (
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter value"
                  style={{ width: "32%" }}
                  value={rule.value}
                  onChange={(event) => handleValueChange(event.target.value)}
                />
              );
            case "number":
              return (
                <input
                  type="number"
                  className="form-control"
                  placeholder="Enter value"
                  style={{ width: "32%" }}
                  value={rule.value}
                  onChange={(event) => handleValueChange(event.target.value)}
                />
              );
            case "date":
              return (
                <input
                  type="date"
                  className="form-control"
                  placeholder="Enter value"
                  style={{ width: "32%" }}
                  value={rule.value}
                  onChange={(event) =>
                    handleValueChange(
                      moment(new Date(event.target.valueAsNumber)).format(
                        "YYYY-MM-DD"
                      )
                    )
                  }
                />
              );
            case "datetime":
              return (
                <input
                  type="datetime-local"
                  className="form-control"
                  placeholder="Enter value"
                  style={{ width: "32%" }}
                  value={
                    rule.value?.includes(":00Z")
                      ? rule.value.replace(":00Z", "")
                      : rule.value
                  }
                  onChange={(event) =>
                    handleValueChange(
                      moment
                        .utc(new Date(event.target.valueAsNumber))
                        .format("YYYY-MM-DDTHH:mm:ss")
                    )
                  }
                />
              );
            default:
              return (
                <SelectInput
                  style={{
                    width: "32%",
                  }}
                  inputStyles={{ maxHeight: "100px", overflowY: "auto" }}
                  placeholder="Select value"
                  defaultValue={rule.values}
                  multiple={isMultipleSelection}
                  onSelectionChanges={handleSelectionChanges}
                  data={valueSelectData}
                />
              );
          }
        })()}
        <div className="d-flex align-items-center" style={{ width: "67px" }}>
          <button className="btn icon-btn-remove" onClick={onRemove}>
            <i className="fas fa-minus"></i>
          </button>
        </div>
      </div>
    </li>
  );
});

export { IntegrationInboundFilter, filterGroupObj, filterRulesObj };
