import { useEffect, useRef, useState } from "react";
import "./integrations.component.scss";
import "./../../../../App.css";
import { useNavigate } from "react-router-dom";
import IntegrationService from "../../services/integrationservice";
import { toast } from "react-toastify";
import ConfirmationModal from "../../shared/confirmation-modal.component";

const getImageUrl = (imageName) => {
  try {
    return require(`../../../../assets/img/${imageName}`);
  } catch (error) {
    console.error(`Image not found: ${imageName}`);
    return null;
  }
};

const IntegrationCard = ({
  integration,
  configuration,
  setIntegrationInfo,
}) => {
  const imageUrl = getImageUrl(
    configuration.integrationConfig.details.imageUrl
  );
  const confirmationModalRef = useRef();
  const [isConfirmationButtonLoading, setIsConfirmationButtonLoading] =
    useState(false);
  const navigate = useNavigate();

  const handleSwitchClick = async () => {
    if (!!integration) {
      confirmationModalRef.current.show();
    } else {
      connectIntegration();
    }
  };

  const connectIntegration = async () => {
    try {
      const authUrl = await IntegrationService.Connect(configuration.name);
      const authWindow = window.open(authUrl, "_blank", "width=600,height=600");

      const interval = setInterval(async () => {
        if (authWindow.closed) {
          clearInterval(interval);
          updateIntegrationInfo();
        }
      }, 1000);
    } catch (error) {
      toast.error("Failed to initiate connection");
    }
  };

  const disconnectIntegration = async () => {
    setIsConfirmationButtonLoading(true);
    try {
      const response = await IntegrationService.DisconnectIntegration(
        integration.integrationId
      );
      if (response) {
        toast.success(response);
      }

      setIsConfirmationButtonLoading(false);
      confirmationModalRef.current.hide();
      updateIntegrationInfo();
    } catch (error) {
      toast.error(error?.message ?? "Something went wrong, please try again.");
      setIsConfirmationButtonLoading(false);
    }
  };

  const getIntegrationInfo = () => {
    if (!!integration && !integration.isSetupCompleted) {
      navigate(`/integrations/${configuration.name}/configure`, {
        state: {
          integrationType: configuration.crmConfigurationId,
        },
      });
    } else if (!!integration) {
      navigate(`/integrations/${configuration.name}`, {
        state: {
          integrationType: configuration.crmConfigurationId,
        },
      });
    }
  };

  const updateIntegrationInfo = async () => {
    try {
      const data = await IntegrationService.Integrations();
      const integrationData = data.filter(
        (x) => x.integrationType === configuration.crmConfigurationId
      );

      const integrationsObj = {};
      data.forEach((int) => {
        if (!integrationsObj[int.integrationType]) {
          integrationsObj[int.integrationType] = int;
        }
      });

      setIntegrationInfo(integrationsObj);

      if (integrationData.length > 0) {
        getIntegrationInfo(integrationData[0]);
      }
    } catch (error) {
      toast.error("Failed to fetch integrations");
    }
  };

  return (
    <>
      <div className="card inte-card position-relative">
        {integration?.oauthStatus === 2 && (
          <div className="error-text">
            <i className="fas fa-exclamation-triangle pr-5px"></i>Connection
            expired. Click
            <span
              className="blue-link font-13 cursor-pointer"
              href="#"
              onClick={connectIntegration}
            >
              &nbsp;here&nbsp;
            </span>
            to reconnect.
          </div>
        )}
        <div
          className={`d-flex align-items-center justify-content-between p-20px ${integration?.oauthStatus === 2 ? "mt-2" : ""}`}
        >
          <div className="d-flex align-items-center">
            <div
              className="card inte-card mb-0 d-flex align-items-center justify-content-center"
              style={{
                backgroundColor: "#FAFBFC",
                width: "60px",
                height: "60px",
                borderColor:"#F0F1F3",
                boxShadow:"none"
              }}
            >
              <img src={imageUrl} alt={configuration.name} />
            </div>
            <div className="ms-3">
              <div className="lato-bold dark-text">
                {configuration.integrationConfig.details.displayName}
              </div>
              <div className="grey-text mt-1">
                {getCardDescription(configuration.crmConfigurationId)}
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center gap-30 poppins-medium grey-text font-12">
            {!!integration && (
              <div
                className="cursor-pointer six-c-7"
                onClick={() => getIntegrationInfo()}
              >
                <i className="fa-regular fa-gear me-1"></i>
                Manage
              </div>
            )}
            <div className="custom-toggle">
              <input
                type="checkbox"
                id={configuration.name + "-toggle"}
                checked={!!integration}
                onChange={handleSwitchClick}
              />
              <label for={configuration.name + "-toggle"}></label>
            </div>
          </div>
        </div>
      </div>
      <ConfirmationModal
        ref={confirmationModalRef}
        title="Confirm"
        message="Are you sure you want to disconnect the integration ?"
        iconClass="far fa-trash-alt"
        confirmButtonText="Disconnect"
        onConfirm={disconnectIntegration}
        disabled={isConfirmationButtonLoading}
      />
    </>
  );
};

function getCardDescription(configurationId) {
  switch (configurationId) {
    case 1:
      return `Seamlessly verify your leads' phone numbers with our Salesforce
                integration.`;
    case 2:
      return `Test your salesforce configuration before applying changes on production.`;
    default:
      return "";
  }
}

export default IntegrationCard;
