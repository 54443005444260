import React from "react";
import './list.component.scss'

const EmptyCard = ({messHead,MessDescription,onClick,styling}) => {
    const handleButtonClick = () => {
        onClick();
    }
    const buttonClass = `btn blue-btn-primary small-btn mt-3 ${styling}`
    return(
        <div className="empty-screen">
        <div>
          <div><img src={require("../../../../assets/img/noDocuments.png")} alt="empty" /></div>
          <div className="poppins-medium text-dark font-16 my-2">{messHead}</div>
          <div className="grey-text font-14 poppins-regular">{MessDescription}</div>
          <div>
            <button className={buttonClass} data-tooltip-content="Add a new list" onClick={handleButtonClick}><i class="fa-regular fa-plus me-2"></i>Add List</button>
          </div>
        </div>
      </div>
    );
}

export default EmptyCard;