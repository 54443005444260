import React from 'react';
import { Link } from 'react-router-dom';
import './paymentsuccess.scss'
import Modal from 'react-bootstrap/Modal';


const PaymentSuccess = ({messHead,messDescription,onDone}) => {
    const handleCloseModal = () => {
        onDone();
    }

  return(
    <>
    <Modal
            show={true}
            size="sg"
            aria-labelledby="contained-modal-title-vcenter"
            // centered
            onHide={handleCloseModal}
        >
            <Modal.Body>
            <div className="verify-sucess">

                <div className="text-center mb-3">
                    <img src={require("../../../../../assets/img/Success.svg").default} alt="Success" />
                </div> 
                <div className="text-center font-18 dark-text poppins-semibold mb-2">
                    {messHead}
                </div>
                <p className="text-center font-14 grey-text mw-388 pt-1 poppins-regular">{messDescription}</p>
                <div className="text-center mt-3">
                    <button className="btn blue-btn-primary w-20 mb-3" onClick={handleCloseModal}>Done</button>
                </div>
            </div>
            </Modal.Body>
    </Modal>

            
    </>

  );
};

export { PaymentSuccess };