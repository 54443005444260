import { useState } from "react";
import "./pagination.component.css";

export default function Pagination({ totalItems, pageSize, pageChanged }) {
  const [pageIndex, setPageIndex] = useState(1);

  function changePage(page) {
    setPageIndex(page);
    pageChanged(page);
  }

  return (
    <span
      className={`pagination-buttons-block ${totalItems > 0 ? undefined : "d-none"}`}
    >
      <span className="page-range">
        <span>
          {(pageIndex - 1) * pageSize + 1} -
          {pageIndex * pageSize > totalItems
            ? totalItems
            : (pageIndex - 1) * pageSize + pageSize}
        </span>
        <span> of {totalItems} </span>
      </span>
      <span className="pagination-buttons">
        <button
          disabled={pageIndex <= 1}
          className={`widget-icon ${pageIndex <= 1 ? "disable-btn" : undefined}`}
          onClick={() => changePage(pageIndex - 1)}
        >
          <span className="fas fa-chevron-left light-grey-text"></span>
        </button>
        <button
          disabled={pageIndex * pageSize >= totalItems}
          className={`widget-icon ${pageIndex * pageSize >= totalItems ? "disable-btn" : undefined}`}
          onClick={() => changePage(pageIndex + 1)}
        >
          <span className="fas fa-chevron-right light-grey-text"></span>
        </button>
      </span>
    </span>
  );
}
