import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { toTitleCase } from "../../../../utils/helper.utils";
import { useContext, useEffect, useState } from "react";
import { IntegrationContext } from "../../integrations-constants";

const configuringSteps = [
  {
    name: "Configure Objects",
    route: "objects",
    validationKey: "enableconfigureobjectstab",
  },
  {
    name: " Configure Activity",
    route: "activity",
    validationKey: "enableactivitytab",
  },
  {
    name: "Map Users",
    route: "usermapping",
    validationKey: "enableusermappingtab",
  },
  {
    name: "Sync Settings",
    route: "settings",
    validationKey: "enablesyncsettingstab",
  },
];

export default function IntegrationConfiguration() {
  const { integration, configuration, setIntegration } =
    useContext(IntegrationContext);
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const [steps, setSteps] = useState([]);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  const integrationName = configuration.name;

  /**
   * Will add this function in respective pages if validation required through setValidationFn.
   * @returns whether the navigation should be allowed i.e true or false
   */
  let navigationValidationFn = () => true;

  /**
   * Set the validation function from respective page
   * @param {*} fn callback function which will be called before navigation
   */
  function setValidationFn(fn) {
    navigationValidationFn = fn;
  }

  useEffect(() => {
    setSteps(
      configuringSteps.filter(
        (d) => configuration.uiConfig.uiproperties.integration[d.validationKey]
      )
    );

    const _currentStepIndex = configuringSteps.findIndex((s) =>
      location.pathname.includes(s.route)
    );

    if (_currentStepIndex > -1) {
      setCurrentStepIndex(_currentStepIndex);
    }
  }, [configuration]);

  function navigateToStep(step, stepIndex) {
    if (navigationValidationFn && !navigationValidationFn()) {
      return;
    }

    setValidationFn(() => true);
    setCurrentStepIndex(stepIndex);
    navigate(`/integrations/${configuration.name}/configure/${step}`, {
      state: location.state,
    });
  }

  function goToIntegrationsPage() {
    if (integration.isSetupCompleted) {
      navigate(`/integrations/${configuration.name}/summary`, {
        state: location.state,
      });
    } else {
      navigate("/integrations");
    }
  }

  function checkIfActive(route) {
    return params["*"] === `configure/${route}`;
  }

  function goToNextStep() {
    if (currentStepIndex < steps.length - 1) {
      navigateToStep(steps[currentStepIndex + 1].route, currentStepIndex + 1);
    } else {
      navigate(`/integrations/${configuration.name}/summary`, {
        state: location.state,
      });
    }
  }

  function goBack() {
    if (currentStepIndex > 0) {
      navigateToStep(steps[currentStepIndex - 1].route, currentStepIndex - 1);
    }
  }

  return (
    <div className="mb-4">
      <div className="d-flex align-items-center mb-4">
        <button className="btn return-back-btn" onClick={goToIntegrationsPage}>
          <i className="fa-solid fa-arrow-left me-2"></i>Back
        </button>
        <div className="d-flex align-items-center ms-3">
          <span className="poppins-medium dark-text font-16">
            {toTitleCase(integrationName)}
          </span>
          <span>
            <i className="fa-solid fa-angle-right mx-2"></i>
          </span>
          <span className="poppins-medium grey-text">Configuration</span>
        </div>
      </div>
      <div className="form-steps justify-content-start gap-30 my-3 minus-left-right-24">
        {steps.map((item, index) => (
          <>
            <div
              key={item.route}
              className={`${checkIfActive(item.route) ? "active" : undefined}`}
              // onClick={() => navigateToStep(item.route, index)}
            >
              {item.name}
            </div>
            {index === steps.length - 1 ? undefined : (
              <div>
                <i className="fa-solid fa-chevron-right"></i>
              </div>
            )}
          </>
        ))}
      </div>
      <Outlet
        context={{
          integration,
          configuration,
          goBack,
          goToNextStep,
          setIntegration,
          setValidationFn,
          currentStepIndex,
        }}
      />
    </div>
  );
}
