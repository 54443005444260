import { useContext, useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import IntegrationService from "../../../../services/integrationservice";
import { toast } from "react-toastify";
import { FieldType, IntegrationContext } from "../../integrations-constants";
import { formatDistanceToNow } from "date-fns";
import moment from "moment";
import { toTitleCase } from "../../../../utils/helper.utils";
import Pagination from "../../../../shared/pagination/pagination.component";
import FilterMenu from "../../../../shared/filter/filter-menu.component";
import FilterBar from "../../../../shared/filter-bar.component";
import {
  getDatesByCriterionValue,
  getSeparatorTextByCriterionValue,
} from "../../../../utils/filter.utils";
import SpinnerLoader from "../../../../shared/spinner-loader/spinner-loader.component";
import LoadingAnimation from "../../../lottie-files/loading.lotte";
import IntegrationLogsGraph from "./integration-logs-graph.component";

export default function IntegrationLogs() {
  const [logsByCategory, setLogsByCategory] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { integration, configuration } = useContext(IntegrationContext);
  const [totalRows, setTotalRows] = useState(0);
  const [filters, setFilters] = useState([]);

  const [payload, setPayload] = useState({
    integrationid: integration?.integrationId,
    filters: "",
    category: "",
    fromdate: "",
    todate: "",
    pageindex: 1,
    pagesize: 50,
  });

  useEffect(() => {
    fetchCRMLogsByCategory();
  }, [params, location, integration?.integrationId, payload.pageindex]);

  const fetchCRMLogsByCategory = async () => {
    setIsLoading(true);
    try {
      const logs = await IntegrationService.GetCRMLogsGroupedData(payload);
      setLogsByCategory(logs?.crmlogslist);
      setTotalRows(logs.totalrows);
      setIsLoading(false);
    } catch (error) {
      toast.error("Failed to fetch logs");
      setIsLoading(false);
    }
  };

  function onPageChange(page) {
    setPayload({ ...payload, pageindex: page });
  }

  const formatTime = (date, suffix) => {
    const formatted = formatDistanceToNow(new Date(date + "Z"), {
      addSuffix: true,
    });
    if (suffix === "old") {
      return formatted.replace(/ago$/, "old").replace(/^about\s/, "");
    }
    return formatted.replace(/^about\s/, "");
  };

  const navigateToErrors = (log) => {
    navigate(`/integrations/salesforce/errors`, {
      state: {
        ...location.state,
        log: log,
        appliedFilters: filters,
      },
    });
  };

  function onApplyFilter(data) {
    let filterList = [];

    if (data.fieldType === FieldType.Date && !data.fieldValue) {
      const dates = getDatesByCriterionValue(data.crietriaValue);
      data.fieldValue = dates.startDate;
      data.fieldSecondaryValue =
        data.crietriaValue === "today" || data.crietriaValue === "yesterday"
          ? ""
          : dates.endDate;
    }

    if (Array.isArray(data.fieldValue)) {
      filterList = data.fieldValue.map((item, index) => ({
        id: item,
        text: data.textValues[index],
        secondaryText: "",
      }));
    } else {
      filterList = [
        {
          id: data.fieldValue,
          text: data.fieldValue,
          secondaryText: data.fieldSecondaryValue,
        },
      ];
    }

    const foundIndex = filters.findIndex((d) => d.id === data.fieldId);
    const filterObj = {
      name: data.fieldName,
      id: data.fieldId,
      type: data.fieldType,
      separator: getSeparatorTextByCriterionValue(data.crietriaValue),
      list: filterList,
    };

    if (foundIndex > -1) {
      filters[foundIndex] = filterObj;
    } else {
      filters.push(filterObj);
    }

    setFilters([...filters]);
    processFilters(filters);
    fetchCRMLogsByCategory();
  }

  function processFilters(appliedFilters) {
    const _filters = {};
    appliedFilters?.forEach((filter) => {
      switch (filter.id) {
        case "date":
          _filters["fromdate"] = filter.list[0].text;
          _filters["todate"] =
            filter.separator === "between"
              ? filter.list[0].secondaryText
              : filter.list[0].text;
          _filters["dateCondition"] = filter.separator === "between" ? 2 : 1;
          break;
        default:
          _filters[filter.id] = filter.list[0].id;
      }
    });

    payload["filters"] =
      JSON.stringify(_filters) === "{}" ? "" : JSON.stringify(_filters);
    setPayload({ ...payload });
  }

  function onRemoveFilter(filterIndex, itemIndex) {
    filters[filterIndex].list.splice(itemIndex, 1);

    if (!filters[filterIndex].list.length) {
      filters.splice(filterIndex, 1);
    }

    setFilters([...filters]);
    processFilters(filters);
    fetchCRMLogsByCategory();
  }

  function onClearFilters() {
    setFilters([]);
    processFilters([]);
    fetchCRMLogsByCategory();
  }

  return isLoading ? (
    <div className="loading-animation">
      <LoadingAnimation />
      <span>Loading data, please wait...</span>
    </div>
  ) : (
    <>
      <div className="mb-10px">
        <IntegrationLogsGraph
          integrationId={integration.integrationid}
          initialView={1}
        />
      </div>
      <div id="ErrorWarnings">
        <div className="d-flex align-items-center justify-content-between mb-3">
          <div className="d-flex gap-2 align-items-center">
            <FilterMenu
              filterOptions={getFilterOptions()}
              onFilterApplied={onApplyFilter}
            />
          </div>
          <div className="d-flex gap-3 align-items-center">
            <span
              class="refreshIcon widget-icon"
              onClick={fetchCRMLogsByCategory}
            >
              <i class="fa-regular fa-redo-alt"></i>
            </span>
            <Pagination
              totalItems={totalRows}
              pageSize={50}
              pageChanged={onPageChange}
            />
          </div>
        </div>
        {filters.length ? (
          <div className="search-response">
            <FilterBar
              filters={filters}
              onRemove={onRemoveFilter}
              onClear={onClearFilters}
            />
          </div>
        ) : undefined}
        <div className="flex-grow-1 h-0">
          {!isLoading ? (
            <div className="table-responsive mb-5 colored-table td-transparent flat-table minus-m-25">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th className="w-30rem">Error & Description</th>
                    <th className="text-center">Occurrences</th>
                    <th className="text-center w-10rem">Sync Type</th>
                    <th>Object</th>
                    <th className="w-12rem">Error last occurred on</th>
                  </tr>
                </thead>
                <tbody>
                  {logsByCategory?.map((log, index) => (
                    <tr key={index}>
                      <td className="w-30rem">
                        <div
                          className="blue-link cursor-pointer mb-1 line-break"
                          onClick={() => navigateToErrors(log)}
                        >
                          {toTitleCase(log.formattedcategory)}
                        </div>
                        <div
                          className="font-12 formatted-message default-text mb-2 line-break"
                          dangerouslySetInnerHTML={{
                            __html: log.formattedmessage,
                          }}
                        ></div>
                        <div className="font-12 light-grey-text d-flex align-items-center gap-10">
                          <span>
                            <i className="fa-regular fa-clock"></i>{" "}
                            {formatTime(log.lasterrordate, "ago")}
                          </span>
                          <span className="mb-5px">
                            <i className="fa-solid fa-circle light-grey-text small-dot"></i>
                          </span>
                          <span>{formatTime(log.createddate, "old")}</span>
                        </div>
                      </td>
                      <td className="text-center default-text">
                        {log.errorscount}
                      </td>
                      <td className="text-center">
                        <span className="sync-icon">
                          {log.direction === 2 ? (
                            <i className="fa-solid fa-arrow-left-long"></i>
                          ) : (
                            <i className="fa-solid fa-arrow-right-long"></i>
                          )}
                        </span>
                      </td>
                      <td className="default-text">{log.objectname}</td>
                      <td className="w-15rem grey-text">
                        {moment(log.lasterrordate).format("MMM D, h:mm:ss a")}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <SpinnerLoader />
          )}
        </div>
      </div>
    </>
  );
}

function getFilterOptions() {
  return [
    // {
    //   id: "synctype",
    //   text: "Sync Type",
    //   type: FieldType.MultiSelect,
    //   selectOptions: {
    //     data: [
    //       { id: 1, text: "Outbound" },
    //       { id: 2, text: "Inbound" },
    //     ],
    //   },
    // },
    {
      id: "date",
      text: "Date",
      type: FieldType.Date,
      criteria: [
        { name: "Today", value: "today", showField: false },
        { name: "Yesterday", value: "yesterday", showField: false },
        { name: "Last 7 days", value: "last7Days", showField: false },
        { name: "Last 15 days", value: "last15Days", showField: false },
        { name: "Last 30 days", value: "last30Days", showField: false },
        { name: "Custom Date", value: "between", showField: true },
      ],
    },
    {
      id: "objecttype",
      text: "Object Type",
      type: FieldType.MultiSelect,
      selectOptions: {
        data: [
          { id: 1, text: "Prospect" },
          { id: 2, text: "Task" },
          { id: 3, text: "Other" },
        ],
      },
    },
  ];
}
