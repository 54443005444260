import React, { useState, useEffect } from "react";
import './header.component.scss'
import defaultProfile from '../../../assets/img/user-dummy.svg'
import logoImg from '../../../assets/img/sc-logo-portal.svg'
import { NavLink } from 'react-router-dom';
import { GetAccountMembersDetails, GetPermission, GetUserConnectedAccountDetails, SwitchAccountUser, userLogout } from "../../apis/pvlapis";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { sendAccountInvitation, getCurrentCredits } from "../../apis/pvlapis";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BuySubscriptionOrCredits from "../base-content/billing/buycredits.component";
import { async } from "q";
import { Mixpanel } from "../../configs/mixPanel";
import { Tooltip } from "react-tooltip";
import 'react-tooltip/dist/react-tooltip.css'

import Banner from './banner';
import { createCheckout, getTierDetails } from '../../apis/pvlapis';
import { Crisp } from 'crisp-sdk-web';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus,faPlus,faCheckCircle, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { removeAccessTokenCookie, removeRefreshToken, removeUserDetails } from "../../apis/tokenhandler";
import Cookies from "universal-cookie";
import "./header.component.scss";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "react-tooltip/dist/react-tooltip.css";

const InitialIcon = ({ initials }) => {
  return (
    <div
      style={{
        display: 'flex',
        backgroundColor: '#F5F6FF',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 30,
        width: 32,
        height: 32,
      }}>
      <div style={{ color: '#6C79A2', fontSize: 12, fontWeight: 'bold' }}>{initials}</div>
    </div>
  );
};

const createInitialsFromFullName = (fullName) => {
  if (!fullName) fullName = 'Unknown';
  const names = fullName.split(' ');
  const initials = names
    .slice(0, 2) 
    .map((name) => name.charAt(0).toUpperCase())
    .join('');
  return initials;
};

const HeaderComponent = () => {
  const [userPermission, setUserPermission] = useState(null);
  const [userAccounts, setUserAccounts] = useState([]);
  const [isBuyCreditPermission, setIsBuyCreditPermission] = useState(null);
  const [creditsAmount, setCreditsAmount] = useState(0);
  const [disablePurchaseButton, setDisablePurchaseButton] = useState(false);
  let logoInside = logoImg;
  const navigate = useNavigate();

  const tiers = getTierDetails();
  const subscriptionDetails = tiers["tierJSON"].subscription;
  const [selectedSubscriptionCost,setSelectedSubscriptionCost] = useState(0);
  const [show, setShow] = useState(false);
  const dummyUserData = [
    {
      profilePic: 'https://example.com/path/to/profile-pic1.jpg',
      userName: 'John Doe',
      isVerified: true,
    },
    {
      profilePic: 'https://example.com/path/to/profile-pic2.jpg',
      userName: 'Jane Smith',
      isVerified: false,
    },
    {
      profilePic: 'https://example.com/path/to/profile-pic3.jpg',
      userName: 'Alice Johnson',
      isVerified: true,
    },
    {
      profilePic: 'https://example.com/path/to/profile-pic4.jpg',
      userName: 'Bob Brown',
      isVerified: false,
    },
    {
      profilePic: 'https://example.com/path/to/profile-pic5.jpg',
      userName: 'Charlie Davis',
      isVerified: true,
    },
    {
      profilePic: 'https://example.com/path/to/profile-pic6.jpg',
      userName: 'Diana Evans',
      isVerified: false,
    },
  ];

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const stripeKey = process.env.REACT_APP_PVL_STRIPE_SECRET_KEY;
  const handlePurchaseCredits = async () => {
    Mixpanel.track("Buy Credits button clicked");
    const plan = selectedPlan == "Yearly" ? "year" : "month";
    const tolt_referral = window.tolt_referral ? window.tolt_referral : "";
    const payload = {
      checkouttype: "subscription",
      checkoutdetails: {
        plan: plan,
        quantity: 1,
        tolt_referral: tolt_referral,
      },
    };
    try {
      setDisablePurchaseButton(true);
      // Create checkout
      const response = await createCheckout(payload);
      if (response.data.statusCode === 200) {
        toast.success("Please wait...", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        const sessionid = response.data.result["sessionid"];
        await handleCheckout(sessionid);
        sendBuyCreditsDatatoCrisp();
      } else {
        toast.error("Something went wrong. Please try again.", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
    }
    catch (err) {

      toast.error("Something went wrong. Please try again after some time.", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } finally {
      setDisablePurchaseButton(false);
    }
  };

  const sendBuyCreditsDatatoCrisp = async () => {
    Crisp.session.setSegments(["CreditsBought"]);
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.stripe.com/v3/";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleCheckout = async (sessionId) => {
    //Create an instance of stripe using stripe key
    const stripe = window.Stripe(stripeKey); // Replace with your actual Stripe Publishable API key
    //Get the sessionid
    const sessionid = sessionId;
    //Redirect to the stripe checkout page using sessionid
    const result = await stripe.redirectToCheckout({
      sessionId: sessionid,
    });
    if (result.error) {
      // Handle any errors that might occur during the redirect to Checkout Session
    }
  };


  const cookies = new Cookies();
  const handleLogout = async () => {
    await userLogout();
    sessionStorage.removeItem('isPVLSubscribed');
    sessionStorage.removeItem('isBannerClosed');
    removeRefreshToken();
    removeUserDetails();
    removeAccessTokenCookie();
    if(cookies.get('refreshToken')){
      cookies.remove('refreshToken');
    }
    if(cookies.get('accessToken')){
      cookies.remove('accessToken');
    }
   
    navigate('/')
  }

  const userDetails = JSON.parse(localStorage.getItem('PVLUserDetails'));
  let profilePic = defaultProfile;
  let userName = null;
  let userEmail = null;
  const[ userAccountName,setUserAccountName] = useState(null);
  if (userDetails) {
    if (userDetails.displaypicture !== null) {
      profilePic = userDetails.displaypicture;
    }
    userName = userDetails.userfullname;
    userEmail = userDetails.useremail;
  }

  const [showInvite, setShowInvite] = useState(false);
  const [inviteUserFullName, setInviteUserFullName] = useState("");
  const [inviteUserEmail, setInviteUserEmail] = useState("");
  const [isSubscribed, setIsSubscribed] = useState(true);
  const [isSubscriptionOngoing, setIsSubscriptionOngoing] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState(-1);
  const [subscriptionEndDate, setSubscriptionEndDate] = useState(null);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [subscriptionPlan, setSubscriptionPlan] = useState("yearly");
  const [numOfUsers, setNumOfUsers] = useState(1);
  const [invalidInviteeUserName, setInvalidInviteeUserName] = useState(null);
  const [invalidInviteeEmail, setInvalidInviteeEmail] = useState(null);
  const [openBuyCredits, SetOpenBuyCredits] = useState(false);
  const [openSubscriptionModal, setOpenSubscriptionModal] = useState(false);
  const [creditPerMonth, setCreditPerMonth] = useState(500);
  const [selectedPlan, setSelectedPlan] = useState("Yearly"); // Default to 'Yearly'
  const [checkoutType, setcheckoutType] = useState("");
  const [isWithinThreeDays, setIsWithinThreeDays] = useState(false);
  const [selectedSubscriptionDetails, setSelectedSubscriptionDetails] =
    useState(
      subscriptionDetails?.[selectedPlan == "Yearly" ? "year" : "month"]
    );

  const handleSelectPlan = (plan) => {
    setSelectedPlan(plan);
    const choosenPlan = plan === 'Yearly' ? 'year' : 'month';
    setSelectedSubscriptionDetails(subscriptionDetails[choosenPlan]);
  };

  const handleCloseInvite = () => setShowInvite(false);
  const handleShowInvite = () => setShowInvite(true);

  const handleAddUser = () => {
    setNumOfUsers((prevCount) => prevCount + 1);
  };

  const handleRemoveUser = () => {
    setNumOfUsers((prevCount) => (prevCount > 1 ? prevCount - 1 : 1));
  };

  const handleInviteEmailChange = (event) => {
    setInviteUserEmail(event.target.value);
    setInvalidInviteeEmail(null);
  };
  const handleInviteNameChange = (event) => {
    setInviteUserFullName(event.target.value);
    setInvalidInviteeUserName(null);
  };
  const handleSendInvite = async (e) => {
    e.preventDefault();
    let checks_failed = false;
    if (inviteUserFullName.trim().length === 0) {
      setInvalidInviteeUserName("Please enter the invitee name");
      checks_failed = true;
    }
    if (inviteUserFullName.trim().length > 50) {
      setInvalidInviteeUserName("User name is too big");
      checks_failed = true;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(inviteUserEmail)) {
      setInvalidInviteeEmail("Enter a valid email address.");
      checks_failed = true;
    }
    if (checks_failed) {
      return;
    }
    handleCloseInvite();
    try {
      const payload = {
        "inviteeEmail": inviteUserEmail
      }
      const response = await sendAccountInvitation(payload);
      if (response.data.statusCode === 200) {
        toast.success("Invite sent successfully!", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      } else if (response.data.statusCode === 460) {
        toast.warning(
          "User email not verified. Ask the user to verify his emailID first!",
          { position: toast.POSITION.BOTTOM_LEFT }
        );
      } else if (response.data.statusCode === 456) {
        toast.warning("Cannot invite users not present on SureConnect.ai!", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      } else if (response.data.statusCode === 454) {
        toast.success(
          "User account deactivated. Ask user to contact support!",
          { position: toast.POSITION.BOTTOM_LEFT }
        );
      } else {
        toast.error(`Something went wrong with invitation!`, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
    } catch (error) {
      
      toast.error(`Something went wrong. Please try again after some time.`, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  };

  const handleOpenBuyCredits = () => {
    Mixpanel.track("Add Credits button clicked");
    SetOpenBuyCredits(true);
    setcheckoutType("payasyougo");
  };

  const handleSubscription = () => {
    Mixpanel.track("Subscription button clicked");
    setOpenSubscriptionModal(true);
    SetOpenBuyCredits(true);
    setcheckoutType("subscription");
  };

  const handleSubscriptionClose = () => {
    setOpenSubscriptionModal(false);
  };

  const handleCloseBuyCredits = () => {
    SetOpenBuyCredits(false);
  };

  const handleGetCredits = async () => {
    try {
      const response = await getCurrentCredits();
      if (response.data.statusCode === 200) {
        Mixpanel.people.set({
          Credits: response.data.result["credit_details"].credit_balance,
        });
        setCreditsAmount(response.data.result["credit_details"].credit_balance);
        setIsSubscribed(
          response.data.result.subscription_details.flag_subscription_present
        );
        setIsSubscriptionOngoing(
          response.data.result.subscription_details.flag_subscription_ongoing
        );
        if (
          response.data.result.subscription_details.flag_subscription_present
        ) {
          setSubscriptionStatus(
            response.data.result.subscription_details.subscription_status
          );
          setSubscriptionEndDate(
            response.data.result.subscription_details.end_date
          );
          // Get the current date and time
          const currentDateTime = new Date();

          // Calculate the difference in time (milliseconds)
          const timeDifference =
            new Date(response.data.result.subscription_details.end_date) -
            currentDateTime;

          // Convert the difference from milliseconds to days
          const differenceInDays = timeDifference / (1000 * 60 * 60 * 24);

          // Check if the difference is less than or equal to 3 days
          setIsWithinThreeDays(differenceInDays <= 3);

          if (
            !sessionStorage.getItem("isPVLSubscribed") &&
            response.data.result.subscription_details.subscription_status === 3
          ) {
            SetOpenBuyCredits(true);
            setcheckoutType("subscription");
          }
        } else if (!sessionStorage.getItem("isPVLSubscribed")) {
          SetOpenBuyCredits(true);
          setcheckoutType("subscription");
        }
      }
    }
    catch(error) {
      toast.error("Something went wrong. Please try again after some time.",{position:toast.POSITION.BOTTOM_LEFT})
    }
  }

  const handleSwitchAccount = async(user) =>{
    try {
      if(typeof user?.accountid === 'undefined'){
        toast.error("No account found. Logging out",{position:toast.POSITION.BOTTOM_LEFT});
        handleLogout();
      }
      await SwitchAccountUser(navigate,user.accountid);
    } catch (error) {
      toast.error("Something went wrong. Please try again after some time.",{position:toast.POSITION.BOTTOM_LEFT})
    }
  }

  const formatDate = (datetime) => {
    const date = new Date(datetime);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();

    // Get the ordinal suffix for the day
    const ordinalSuffix = (d) => {
      if (d > 3 && d < 21) return "th"; // catch 11th-19th
      switch (d % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };

    return `${day}${ordinalSuffix(day)} ${month} ${year}`;
  };

  const handleGetPermission = async() =>{
    try {
      const response = await GetPermission();
      const buycreditsObject = response?.result?.find(obj => obj?.component === "buycredits");
      setIsBuyCreditPermission(buycreditsObject?.permission);
      setUserPermission(response);
    } catch (error) {
      toast.error("Something went wrong. Please try again after some time.",{position:toast.POSITION.BOTTOM_LEFT})   
    }
    
  }

  const handleGetUserAccounts = async() =>{
    try {
      const response = await GetUserConnectedAccountDetails();
      if(response.data.statusCode === 200){
        setUserAccounts(response.data.result);
        const currentAccount = response.data.result.find(obj => obj.accountid === userDetails?.accountid);
        if(typeof currentAccount === 'undefined'){
        }
        setUserAccountName(currentAccount.accountname);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again after some time.",{position:toast.POSITION.BOTTOM_LEFT})   

    }
    
  }


  useEffect(() => {
    handleGetCredits();
    handleGetPermission();
    handleGetUserAccounts();
  }, []);

  return (
    <>
      {
        openBuyCredits && (
          <BuySubscriptionOrCredits onClose={handleCloseBuyCredits} checkoutType={checkoutType}/>
          // <SubscriptionModal onClose={handleSubscriptionClose}/>
        )
      }
      {/* {
        openSubscriptionModal && (
          <SubscriptionModal onClose={handleSubscriptionClose}/>
        )
      } */}
      <>
    {(!isSubscribed || [1,2,3,4,5,6,9,12].includes(subscriptionStatus))
    &&<Banner message={"showSubscription"} handleShow={handleShow} bannerType={1} subscriptionStatus={subscriptionStatus} isSubscribed={isSubscribed} endDate={formatDate(subscriptionEndDate)} isWithinThreeDays={isWithinThreeDays}/>}
        <nav className="navbar navbar-expand-lg bg-white">
          <div className="container-fluid">
            <a className="navbar-brand poppins-medium font-18 fw-bold" href="#">
              <img
                src={logoInside}
                className="header-img"
                alt="Sure Connect by outplay"
              />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarScroll"
              aria-controls="navbarScroll"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarScroll">
              <div className="me-auto"></div>
              <div className="d-flex">
                <ul
                  className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll d-flex align-items-center"
                  style={{ "--bs-scroll-height": "100px" }}
                >
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href="/credits-history"
                      data-tooltip-content="Available Credits"
                      data-tooltip-id="Header"
                    >
                      <span>
                        <img
                          src={
                            require("../../../assets/img/luckycoins.svg")
                              .default
                          }
                        />
                      </span>
                      <span className="poppins-medium dark-text ms-1">
                        {creditsAmount}
                      </span>{" "}
                      Credits
                    </a>
                  </li>
                  <li className="nav-item mx-4">
                    {isSubscribed && isSubscriptionOngoing 
                      // [1, 2, 4, 5, 6, 7, 8, 9, 10, 11].includes(subscriptionStatus)
                        && 
                        <>
                      <a 
                      href="#" 
                        className={`nav-link poppins-medium ${isBuyCreditPermission ? 'primary-blue-text' : 'grey-text'}`} 
                        onClick={isBuyCreditPermission ? handleOpenBuyCredits : (e) => e.preventDefault()} 
                        style={{ pointerEvents: isBuyCreditPermission ? 'auto' : 'none', textDecoration: 'none' }}
                        
                        data-tooltip-content={!isBuyCreditPermission ? "You do not have permission to buy credits" : ""}
                        // data-tooltip-content="You do not have permission to buy credits"
                        data-tooltip-id="Header1"
                      >
                        Buy Credits
                      </a> 
                      <Tooltip id="Header1" place="top" effect="solid" /> 
                      </>
                    }

                    {(!isSubscribed || [3, 12].includes(subscriptionStatus)) && 
                        <a className={`nav-link poppins-medium ${isBuyCreditPermission?"rounded-border-btn":"rounded-grey-border-btn"}`} href="#" onClick={handleSubscription}>Subscribe</a>
                    }

                    {isBuyCreditPermission && (isSubscribed  && subscriptionStatus ===0 )&&
                    <>
                      <a href="#" data-tooltip-id="buttonTooltip" 
                        data-tooltip-content="Your payment is still pending. Please make payment and check back in sometime." >Subscription Pending</a>
                      <Tooltip id="buttonTooltip" place="top" effect="solid" /> 
                    </>
                    }

                  </li>
                  <li className="nav-item mx-3 dropdown d-none">
                    <div className="dropdown-container">
                      <a className="nav-link font-16" href="#">
                        <i className="fa-regular fa-bell"></i>
                      </a>
                      <div className="dropdown-content notification-dropdown">
                        <div className="d-flex align-items-center justify-content-between mb-2">
                          <div className="poppins-medium font-14 dark-text">
                            Notifications
                          </div>
                          <div>
                            <i className="fa-regular fa-check-double"></i>
                          </div>
                        </div>
                        {/* Empty State */}
                        <div className="w-100 h-100 d-flex align-items-center justify-content-center text-center py-4">
                          <div>
                            <div>
                              <i className="fa-solid fa-bell font-100 light-grey-text mb-3 opacity-50"></i>
                            </div>
                            <div className="poppins-medium text-dark font-14 my-2">
                              No new notifications!
                            </div>
                            <div className="light-grey-text font-12 poppins-regular">
                              You haven’t received any notifications yet.
                            </div>
                          </div>
                        </div>
                        {/* Listing */}
                        <div className="listing">
                          <div className="d-flex">
                            <div>
                              <div className="circular-icon green"></div>
                            </div>
                            <div>
                              <div>
                                <span className="text-dark lato-bold">
                                  Hubspot Import.csv{" "}
                                </span>
                                <span className="grey-text">
                                  validation completed.
                                </span>
                              </div>
                              <div className="light-grey-text">
                                Jul 6, 2023 08:23 PM
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Listing */}
                        <div className="listing">
                          <div className="d-flex">
                            <div>
                              <div className="circular-icon grey"></div>
                            </div>
                            <div>
                              <div>
                                <span className="text-dark lato-bold">
                                  US Phone list{" "}
                                </span>
                                <span className="grey-text">
                                  validation initiated.
                                </span>
                              </div>
                              <div className="light-grey-text">
                                Jul 6, 2023 08:23 PM
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Listing */}
                        <div className="listing">
                          <div className="d-flex">
                            <div>
                              <div className="circular-icon red"></div>
                            </div>
                            <div>
                              <div>
                                <span className="text-dark lato-bold">
                                  UK Inbound.csv{" "}
                                </span>
                                <span className="grey-text">
                                  validation failed!
                                </span>
                              </div>
                              <div className="light-grey-text">
                                Jul 6, 2023 08:23 PM
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="nav-item mx-3 dropdown">
                    <div className="dropdown-container">
                      <button type="button" className="btn p-0 border-0">
                        <img
                          src={profilePic}
                          width="36"
                          height="36"
                          className="rounded-circle"
                          alt=""
                        />
                      </button>
                      <div className="dropdown-content">
                        <NavLink
                          to="/profile"
                          activeClassName="active"
                          aria-current="page"
                        >
                          <a className="dropdown-item cursor-pointer profile-bg">
                            <div className="d-flex">
                              <div>
                                <img
                                src={profilePic}
                                width="36"
                                height="36"
                                className="rounded-circle mw-36px" alt=""
                                />
                              </div>
                              <div className="ms-2">
                                <div className="poppins-semibold dark-text font-12">
                                  {userName}
                                </div>
                                <div className="font-11 six-c-7">
                                  {userEmail}
                                </div>
                              </div>
                            </div>
                          </a>
                        </NavLink>
                        <NavLink>
                          <a className="dropdown-item cursor-pointer switchAccount">
                            <div className="font-10 mb-1 poppins-medium text-uppercase light-grey-text">Account</div>
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="d-flex align-items-center">
                                <div>
                                  {/* <img
                                  src={profilePic}
                                  width="32"
                                  height="32"
                                  className="rounded-circle" alt=""
                                  /> */}
                                  <InitialIcon initials={createInitialsFromFullName(userAccountName)} />
                                </div>
                                <div className="poppins-medium font-12 dark-text ms-2">{userAccountName || 'Unknown'}</div>
                              </div>
                              <div><i className="fa-solid fa-angle-right light-grey-text"></i></div>
                            </div>
                            {<div className="switchAccountWrap " >
                              <ul>
                              {userAccounts?.map((user, index) => (
                                <li key={index} onClick={()=>handleSwitchAccount(user)}>
                                  <div className="d-flex align-items-center justify-content-between">
                                    <div className="d-flex align-items-center">
                                      <div>
                                        {/* <img
                                          src={user.profilePic}
                                          width="32"
                                          height="32"
                                          className="rounded-circle"
                                          alt=""
                                        /> */}
                                        <InitialIcon initials={createInitialsFromFullName(user.accountname)} />
                                      </div>
                                      <div className="lato-regular font-14 dark-text ms-2">{user.accountname || 'Unknown'}</div>
                                    </div>
                                    {user?.accountid === userDetails?.accountid && (
                                      <div><i className="fa-solid fa-circle-check green-icon"></i></div>
                                    )}
                                  </div>
                                </li>
                              ))}
                              </ul>
                            </div>
                            }
                          </a>
                        </NavLink>
                        <NavLink to="/profile" activeClassName="active" aria-current="page">
                          <a className="dropdown-item cursor-pointer">
                            <i class="fa-regular fa-gear font-14 light-grey-text me-2"></i>
                            Settings
                          </a>
                        </NavLink>
                        <a
                          className="dropdown-item cursor-pointer d-none"
                          onClick={handleShowInvite}
                        >
                          <i class="fa-regular fa-user-plus font-14 light-grey-text me-2"></i>
                          Invite Users
                        </a>
                        {/* <hr /> */}
                        <a
                          className="dropdown-item cursor-pointer"
                          onClick={handleLogout}
                        >
                          <i className="fa-regular fa-sign-out-alt font-14 light-grey-text me-2"></i>
                          Signout
                        </a>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </>

      <Modal
        show={showInvite}
        onHide={handleCloseInvite}
        className="modal-no-border"
      >
        <Modal.Header className="modal-header" closeButton>
          <Modal.Title>
            <span className="round-modal-icon grey">
              <i className="fa-regular fa-user-plus"></i>
            </span>{" "}
            Invite User
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>Invite your team to your SureConnect account</div>
          <div className="form-group my-2">
            <label htmlFor="Full Name">Full Name</label>
            <input
              type="text"
              className={`form-control ${invalidInviteeUserName ? "is-invalid" : ""}`}
              placeholder="Alex Walker"
              value={inviteUserFullName}
              onChange={handleInviteNameChange}
              required
            />
            {invalidInviteeUserName && (
              <div className=" font-14  lato-regular text_error_message">
                {invalidInviteeUserName}
              </div>
            )}
          </div>
          <div className="form-group my-2">
            <label htmlFor="Full Name">Email ID</label>
            <input
              type="email"
              className={`form-control ${invalidInviteeEmail ? "is-invalid" : ""}`}
              placeholder="alexwalker@martinz.co.in"
              value={inviteUserEmail}
              onChange={handleInviteEmailChange}
              required
            />
            {invalidInviteeEmail && (
              <div className=" font-14  lato-regular text_error_message">
                {invalidInviteeEmail}
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="text-end w-100">
            <Button
              className="btn btn-cancel mr-15px"
              variant="secondary"
              onClick={handleCloseInvite}
            >
              Cancel
            </Button>
            <Button
              className="btn blue-btn-primary font-12"
              variant="primary"
              onClick={handleSendInvite}
            >
              Send Invite
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
      <Tooltip id="Header" />

      <Modal
        show={show}
        onHide={handleClose}
        centered
        className="modal-no-border subscribeModal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <span className="round-modal-icon grey">
              <i className="fa-regular fa-rocket-launch"></i>
            </span>{" "}
            Subscribe to SureConnect
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            Subscribe to a monthly/yearly plan in order to continue using
            SureConnect.
          </div>
          <div className="row">
            <div className="col-lg-7">
              <div className="pro-wrap">
                <div className="header">
                  <div className="d-flex">
                    <div>
                      <img
                        src={require("../../../assets/img/proIcon.svg").default}
                      />
                    </div>
                    <div className="ms-2">
                      <div>
                        <img
                          src={
                            require("../../../assets/img/proText.svg").default
                          }
                        />
                      </div>
                      <div>
                        <span className="lato-bold dark-text">
                          {selectedSubscriptionDetails?.credits}
                        </span>{" "}
                        Credits/mo{numOfUsers > 1 && <span>/user</span>}
                      </div>
                    </div>
                  </div>
                </div>
                {/*  */}
                {numOfUsers > 1 && (
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                      <div className="lato-bold me-3 dark-text">
                        Select no. of Users
                      </div>
                      <div className="d-flex align-items-center gap-10">
                        <span
                          className="widget-icon small"
                          onClick={handleRemoveUser}
                          style={{ cursor: "pointer" }}
                        >
                          <FontAwesomeIcon icon={faMinus} />
                        </span>
                        <span className="lato-bold">{numOfUsers}</span>
                        <span
                          className="widget-icon small"
                          onClick={handleAddUser}
                          style={{ cursor: "pointer" }}
                        >
                          <FontAwesomeIcon icon={faPlus} />
                        </span>
                      </div>
                    </div>
                    <div>
                      <span>
                        <img
                          src={
                            require("../../../assets/img/luckycoins.svg")
                              .default
                          }
                        />
                      </span>
                      <span className="poppins-medium dark-text ms-1">
                        {creditsAmount}
                      </span>{" "}
                      Credits
                    </div>
                  </div>
                )}
                {/*  */}
                {/* <div className="row my-3">
                  <div className="col">
                    <div className="duration-card">
                      <span><i className="fa-solid fa-check-circle"></i></span>
                      <div className="font-12 lato-medium">Monthly</div>
                      <div className="font-24 poppins-bold dark-text">$149</div>
                      <div className="font-12">/mo /user, billed monthly</div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="duration-card active">
                      <span><i className="fa-solid fa-check-circle"></i></span>
                      <div className="font-12 lato-medium">Yearly <span className="discount-tag">SAVE 30%</span></div>
                      <div className="font-24 poppins-bold dark-text">$99</div>
                      <div className="font-12">/mo /user, billed yearly</div>
                    </div>
                  </div>
                </div> */}
                <div className="row my-3">
                  <div className="col">
                    <div
                      className={`duration-card ${selectedPlan === "Monthly" ? "active" : ""}`}
                      onClick={() => handleSelectPlan("Monthly")}
                    >
                      {selectedPlan === "Monthly" && (
                        <span>
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            style={{ color: "#7D63E9" }}
                          />
                        </span>
                      )}
                      <div className="font-12 lato-medium">Monthly</div>
                      <div className="font-24 poppins-bold dark-text">
                        ${subscriptionDetails?.month?.cost}
                      </div>
                      <div className="font-12">
                        /mo {numOfUsers > 1 && <span>/user</span>}, billed
                        monthly
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div
                      className={`duration-card ${selectedPlan === "Yearly" ? "active" : ""}`}
                      onClick={() => handleSelectPlan("Yearly")}
                    >
                      {selectedPlan === "Yearly" && (
                        <span>
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            style={{ color: "#7D63E9" }}
                          />
                        </span>
                      )}
                      <div className="font-12 lato-medium">
                        Yearly <span className="discount-tag">SAVE 30%</span>
                      </div>
                      <div className="font-24 poppins-bold dark-text">
                        ${subscriptionDetails?.year?.cost}
                      </div>
                      <div className="font-12">
                        /mo {numOfUsers > 1 && <span>/user</span>}, billed
                        yearly
                      </div>
                    </div>
                  </div>
                </div>
                {/*  */}
                <div>
                  <a
                    href="https://sureconnect.ai/pricing"
                    target="_blank"
                    className="blue-link poppins-medium font-12"
                  >
                    View plan details <FontAwesomeIcon icon={faAngleRight} />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="summary-wrap">
                <h5>Summary</h5>
                <div className="d-flex align-items-center justify-content-between mb-2">
                  <div className="lato-medium">SureConnect PRO<br />{selectedPlan==='Yearly' &&<span> ${selectedSubscriptionDetails.cost}/mo - Yearly</span>}</div>
                  <div className='dark-text lato-bold'>$ {selectedPlan==='Yearly'? selectedSubscriptionDetails.cost*12:selectedSubscriptionDetails.cost}</div>
                </div>
                {/* <div className="d-flex align-items-center justify-content-between mb-2">
                  <div className="lato-medium">No. of Users</div>
                  <div className='dark-text lato-bold'>{numOfUsers} </div>
                </div> */}
                <div className="d-flex align-items-center justify-content-between mb-2">
                  <div className="lato-medium">Credits/month</div>
                  <div className="dark-text lato-bold">
                    <span>{selectedSubscriptionDetails?.credits}</span>
                  </div>
                </div>
                <div className="total d-flex align-items-center justify-content-between pt-3">
                  <div className="poppins-semibold dark-text font-16">
                    Total
                  </div>
                  <div className="amount lato-bold">
                    $
                    <span>
                      {selectedPlan == "Yearly"
                        ? selectedSubscriptionDetails?.cost * 12
                        : selectedSubscriptionDetails?.cost}
                    </span>
                    {/* ${dollarValue} */}
                  </div>
                </div>
                {selectedPlan == "Yearly" && (
                  <div className="text-end font-12 mt-1">
                    You just saved{" "}
                    <span className="dark-text lato-bold">
                      $
                      {subscriptionDetails?.month?.cost * 12 -
                        subscriptionDetails?.year?.cost * 12}
                    </span>{" "}
                    🎉
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="text-end w-100">
            <Button
              className="btn btn-cancel mr-15px"
              variant="secondary"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              className="btn blue-btn-primary font-12"
              variant="primary"
              onClick={handlePurchaseCredits}
            >
              Subscribe & Pay
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default HeaderComponent;
