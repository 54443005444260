import { useCombobox } from "downshift";
import { useEffect, useMemo, useRef, useState } from "react";
import { Overlay } from "react-bootstrap";
import { Tooltip } from "react-tooltip";

export default function CustomDropdown({
  data = [],
  value = null,
  allowSearch = true,
  onSelectItem = (item) => {},
  renderDefaultItem = null,
  children,
  props,
}) {
  const target = useRef();
  const [inputValue, setInputValue] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);

  const items = useMemo(
    () => getFilteredItems(data, inputValue),
    [data, inputValue]
  );

  useEffect(() => {
    if (value && data.length) {
      setDefaultValue();
    }
  }, [value, data]);

  function setDefaultValue() {
    const _item = data.find((item) =>
      typeof value === "object" ? item.id === value?.id : item.id === value
    );

    setSelectedItem(_item ?? null);
  }

  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    getItemProps,
    getInputProps,
  } = useCombobox({
    items: items,
    itemToString: (item) => (item ? item.text : ""),
    inputValue,
    selectedItem,
    stateReducer(state, actionAndChanges) {
      const { changes, type } = actionAndChanges;

      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          return {
            ...changes,
            isOpen: false,
            highlightedIndex: 0, // with the first option highlighted.
          };
        default:
          return changes;
      }
    },
    onStateChange({
      inputValue: newInputValue,
      type,
      selectedItem: newSelectedItem,
    }) {
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputBlur:
          if (newSelectedItem && selectedItem?.id !== newSelectedItem.id) {
            setSelectedItem(newSelectedItem);
            onSelectItem(newSelectedItem);
            setInputValue("");
          }
          break;

        case useCombobox.stateChangeTypes.InputChange:
          setInputValue(newInputValue);

          break;
        default:
          break;
      }
    },
  });

  function getFilteredItems(newItems, searchText) {
    const text = searchText?.toLowerCase();

    return newItems.filter((d) => d.text.toLowerCase().includes(text));
  }

  return (
    <div {...props}>
      <div
        className="d-inline-block cursor-pointer"
        {...getToggleButtonProps()}
      >
        <div className="d-inline-block" ref={target}>
          {children}
        </div>
      </div>
      <Overlay
        show={isOpen}
        target={target.current}
        placement="top"
        containerPadding={20}
      >
        <div
          style={{
            width: target.current?.clientWidth + "px",
            minWidth: "250px",
            overflowX: "hidden",
          }}
        >
          <div
            class="custom-dropdown"
            {...getMenuProps({}, { suppressRefError: true })}
          >
            {allowSearch && isOpen ? (
              <div className="px-1 py-1 border-bottom-0">
                <input
                  placeholder="Search"
                  className="custom-search-input"
                  onClick={(event) => event.stopPropagation()}
                  {...getInputProps({}, { suppressRefError: true })}
                />
              </div>
            ) : undefined}
            <div style={{ maxHeight: "200px", overflowY: "auto" }}>
              {isOpen && renderDefaultItem ? (
                <div {...getItemProps({ item: null })}>
                  {renderDefaultItem()}
                </div>
              ) : undefined}
              {isOpen &&
                items.map((item, index) => {
                  return (
                    <div
                      key={item.id}
                      className={`cursor-pointer custom-dropdown-item text-truncate ${selectedItem?.id === item.id ? "highlighted" : ""}`}
                      data-tooltip-id="listItem"
                      data-tooltip-content={item.text}
                      {...getItemProps({
                        item,
                        index,
                      })}
                    >
                      {item.text}
                    </div>
                  );
                })}
              {!items.length ? (
                <div className="custom-dropdown-item">No Data Found!</div>
              ) : undefined}
            </div>
          </div>
          <Tooltip id="listItem" positionStrategy="fixed" />
        </div>
      </Overlay>
    </div>
  );
}
