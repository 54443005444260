import React, { useEffect } from 'react';
import { useState } from 'react';
import { Tooltip } from "react-tooltip";
import 'react-tooltip/dist/react-tooltip.css'
import qs from 'qs'
import { Button, Modal } from 'react-bootstrap';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import './card.component.scss';
import DownloadComponent from '../../download/download.component';
import { useCookies } from 'react-cookie';
import defaultProfilePic from '../../../../../assets/img/user-dummy.svg'
import { async } from 'q';
import { downloadValidatedCSV } from '../../../../apis/pvlapis';
import { Mixpanel } from '../../../../configs/mixPanel';

const getDownloadUrl = process.env.REACT_APP_PVL_APP_URL+"/pvl/get_project_data";
const apiKey = process.env.REACT_APP_PVL_API_KEY;

const CardComponent = ({project}) => {
  // console.log(project);
  const [openDownloadResults,setOpenDownloadResults] = useState(false);
  const [downloadProjectId, setDownloadProjectId] = useState(null);
  const [projectDetails,setProjectDetails] = useState(null);
  const [checkboxValues, setCheckboxValues] = useState(null);
  const [disableDownloadButton, setDisableDownloadButton] = useState(true);
  const [cookies, setCookie, removeCookie] = useCookies(['accessToken']);
  const handleCheckboxChange = (values) => {
    // console.log(values);
    setCheckboxValues(values);
  };

  useEffect(() => {
    if (checkboxValues!==null && Object.values(checkboxValues).includes(true) && projectDetails.projectStats[0].completedProspects!==0) {
      // Perform any actions with the selected file here
      // console.log("useeffect");
      // console.log(checkboxValues);
        for(const key in checkboxValues){
          if(checkboxValues[key]){
            // console.log(key);
            if(projectDetails.projectStats[0][key]!==0){
              setDisableDownloadButton(false);
              // console.log("logging false");
            }
          }
      }
    }
    else{
      // console.log("LOGGING TRUE");
      setDisableDownloadButton(true);
    }
  }, [checkboxValues]);
  const handleCloseDownload = () => {
    setOpenDownloadResults(false);
    setDownloadProjectId(null);
    setDisableDownloadButton(true);
    setProjectDetails(null);
    setCheckboxValues(null);

  }
  const handleOpenDownload = (projectId) => {
    //Handle the download project
    // console.log(project);
    // console.log(projectId);
    Mixpanel.track("External Download Results button clicked");
    setDownloadProjectId(projectId);
    setProjectDetails(project);
    //Open the download results modal
    setOpenDownloadResults(true);
  }

  const handleDownloadValidatedCSV = async(id) => {
    Mixpanel.track("Download Validated CSV button clicked");
    //Call the download validated csv function
    await downloadValidatedCSV(id);
  }
  const handleDownloadResults = async() => {
    // console.log("Downloading results");
    // console.log(downloadProjectId);
    // console.log(checkboxValues);
    Mixpanel.track("Internal Download Results button clicked");
    setOpenDownloadResults(false);
    let checkBoxedData = {};
    //if the completedProjects is selected only consider the analysisResult
    if(checkboxValues.completedProspects === true){
       checkBoxedData = {
        "analysisResult": checkboxValues.completedProspects,
      }
    }
    else{
      //Get the status of the checkboxes
       checkBoxedData = {
        "analysisResult": checkboxValues.completedProspects,
        "likelyAnswer": checkboxValues.answerProspects,
        // "likelyWrong": checkboxValues.wrongProspects,
        "likelyVoicemail": checkboxValues.voicemailProspects,
        "likelyGatekeeper": checkboxValues.gatekeeperProspects,
        "other": checkboxValues.otherProspects,
        "verificationFailed": checkboxValues.failedProspects
      }
    }
    //Create a list of keys where checkboxes are selected(true)
    const requiredData = Object.keys(checkBoxedData).filter(key => checkBoxedData[key] === true);
    // console.log(requiredData, typeof(requiredData));
    const accessToken = cookies.accessToken;
    const config = {
      headers: {
      Authorization: `Bearer ${accessToken}`,
      'X-API-Key': apiKey
      },
      
      params: {
        'projectId':downloadProjectId,
        'requiredData': requiredData
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      }
    };
    // console.log(config);
    try {
      const response = await axios.get(getDownloadUrl, config);
      // console.log(response);
      if(response.data.statusCode === 200){
        // console.log(response.data.result);
        const data = response.data.result
        //Iterate through each key in the result
        for(const key in data){
          //Open the url in a new tab
          if (data.hasOwnProperty(key)) {
            window.open(data[key], '_blank');
          }
        }
        
      }
    } catch (error) {
      // console.log("error");
      // console.log(error);
      toast.error("Something went wrong. Please try again after some time.",{position:toast.POSITION.BOTTOM_LEFT})
    }
    
  }



  const dateformatter = (date) => {
    //Used to format the date and time.
    //Format the date part of the given complete date
    const formatedDate = new Date(date).toLocaleDateString(undefined, {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
    //Format the time part of the given complete date
    const formattedTime = new Date(date).toLocaleTimeString(undefined, {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    //Get month, day, year of the formatted date.
    const [month, day, year] = formatedDate.split(" ");
    //Create the final formatted date in the required format.
    const formattedDay = day.includes(",") ? day.replace(",", "") : day; 
    //Combine the day month,year to create the final date
    const outputDate = `${formattedDay} ${month}, ${year}`;
    //Return the combined date and time in UTC
    return `${outputDate} ${formattedTime} UTC`;
  }
  const originalProjectName = project.projectName;
  let projectName = project.projectName
    if(projectName.length > 30){
      //Trim the project name if the size is more than 30 characters
      projectName = projectName.slice(0,30) + "..."
    }
    if(window.innerWidth < 767){
      // alert('mobile device');
      projectName = projectName.slice(0,15) + "..."
    }
  const projectStatus = project.projectExecutionStatus
  const projectStats = project.projectStats[0]
  const totalRecords = projectStats.totalProspects
  const completedRecords = projectStats.completedProspects
  const inprogressRecords = projectStats.inProgressProspects
  const answerRecords = projectStats.answerProspects
  // const wrongRecords = projectStats.wrongProspects
  const otherRecords = projectStats.otherProspects
  // const unreachableRecords = projectStats.unreachableProspects
  const voicemailRecords = projectStats.voicemailProspects
  const gatekeeperRecords = projectStats.gatekeeperProspects
  const userDetails = JSON.parse(localStorage.getItem('PVLUserDetails'));
  const userFullName = project.userFullName;
  const projectSource = project.projectSource;
  let userName = userFullName;
  if(userName.length > 30){
    //Trim the user name if the size is more than 30 characters.
    userName = userName.slice(0,30) + "...";
  }
  // userName = userDetails.userfullname;
  let userPic = defaultProfilePic
  if (project.displayPicture){
    userPic = project.displayPicture;
  }
  // else if(userDetails.displaypicture !== null){
  //   userPic = userDetails.displaypicture;
  // }
  const createdDate = dateformatter(project.projectCreatedDate)
  const completedDate = dateformatter(project.projectCompletedDate)
  const projectId = project.projectId
  //Mapping of the project execution status, to the status we show in UI along with tool tips and the styling
  const statusMap = {
    1: { text: 'In Progress', tooltip: 'Wait for Results',style: 'inProgress' },
    2: { text: 'Stopped', tooltip: 'Validation Stopped',style:'stopped' },
    4: { text: 'Completed', tooltip: 'Results Available',style: 'completed' },
    5: { text: 'Failed', tooltip: 'Validation Failed',style:'failed' },
    6: { text: 'Waiting', tooltip: 'Waiting for Approval',style:'waiting' },
    7: { text: 'Rejected', tooltip: 'Rejected by Admin. Contact support if this is wrong.',style:'rejected' },
  };
  //Get the project execution status mapping
  const projectStatusDetails = statusMap[projectStatus] || {
    text: 'In Progress',
    tooltip: 'Wait for Results',
    style: 'inProgress'
  };
  
    return(
      <div>
        <div className="card">
  <div className="card-header d-flex align-items-center justify-content-between">
    <div className='d-flex align-items-center'>
      {
        projectSource === 1 ? (
          <div className='rounded-icon grey me-2'>
            <i className='fa-regular fa-display-code'></i>
          </div>
        ):(
          <div className='rounded-icon green me-2'>
            <i className='fa-regular fa-file-csv'></i>
          </div>
        )
      }
    <div className="dark-text poppins-semibold font-16" data-tooltip-content={originalProjectName} data-tooltip-id="homecomponent">{projectName}</div>
    </div>
    <div className="d-flex align-items-center">
        <span className={`status me-2 ${projectStatusDetails.style}`}
            data-tooltip-content={projectStatusDetails.tooltip}
            data-tooltip-id="homecomponent">
        {projectStatusDetails.text}
        </span>        
        <span className="cursor-pointer" data-tooltip-content="Download Results" data-tooltip-id="homecomponent" onClick={() => handleOpenDownload(projectId)}><img src={require("../../../../../assets/img/CTA.svg").default} /></span>
    </div>
  </div>
  {/* Body */}
  <div className="card-body">
    <div className="d-flex align-items-center">
      <div className="font-14">
        <span className="grey-text">Total Contacts</span>
        <span className="dark-text ms-2 lato-bold">{totalRecords}</span>
      </div>
      <div className="mx-3 light-grey-text">|</div>
      <div className="font-14">
        <span className="grey-text">Completed</span>
        <span className="dark-text ms-2 lato-bold">{completedRecords}</span>
      </div>
      <div className="mx-3 light-grey-text">|</div>
      <div className="font-14">
        <span className="grey-text">In Progress</span>
        <span className="dark-text ms-2 lato-bold">{inprogressRecords}</span>
      </div>
    </div>
    <div className="my-3">
      <div className="progress">
        <div
          className="progress-bar valid"
          role="progressbar"
          aria-label="Segment one"
          data-tooltip-content={`Likely Answer contacts ${answerRecords}`} data-tooltip-id="homecomponent"
          style={{width: `${(answerRecords/totalRecords)*100}%`}}
          aria-valuenow="15"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
        <div
          className="progress-bar unverifiable"
          role="progressbar"
          aria-label="Segment two"
          data-tooltip-content={`Likely Voicemail contacts ${voicemailRecords}`} data-tooltip-id="homecomponent"
          style={{width: `${(voicemailRecords/totalRecords)*100}%`}}
          aria-valuenow="15"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
        <div
          className="progress-bar gatekeeper"
          role="progressbar"
          aria-label="Segment three"
          data-tooltip-content={`Likely Gatekeeper contacts ${gatekeeperRecords}`} data-tooltip-id="homecomponent"
          style={{width: `${(gatekeeperRecords/totalRecords)*100}%`}}
          aria-valuenow="30"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
        <div
          className="progress-bar duplicates"
          role="progressbar"
          aria-label="Segment four"
          data-tooltip-content={`Other contacts ${otherRecords}`} data-tooltip-id="homecomponent"
          style={{width: `${(otherRecords/totalRecords)*100}%`}}
          aria-valuenow="20"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
        {/* <div
          className="progress-bar invalid"
          role="progressbar"
          aria-label="Segment five"
          data-tooltip-content={`Likely Wrong contacts ${wrongRecords}`} data-tooltip-id="homecomponent"
          style={{width: `${(wrongRecords/totalRecords)*100}%`}}
          aria-valuenow="20"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div> */}
      </div>
    </div>
    <div className="row">
      <div className="col-6 mb-3">
        <div className="d-flex align-items-center">
          <span className="dot-icon valid"></span>
          <span className="font-14 grey-text ms-1" data-tooltip-content={`Likely Answer have 15-35% connect rate`} data-tooltip-id="homecomponent">Likely Answer</span>
          <span className="font-14 dark-text lato-bold ms-2">{answerRecords}</span>
          <span className="font-12 light-grey-text ms-1">({((answerRecords/totalRecords)*100).toFixed(0)}%)</span>
        </div>
      </div>
      <div className="col-6 mb-3">
        <div className="d-flex align-items-center">
          <span className="dot-icon unverifiable"></span>
          <span className="font-14 grey-text ms-1" data-tooltip-content={`Likely Voicemail have 2-10% connect rate`} data-tooltip-id="homecomponent">Likely Voicemail</span>
          <span className="font-14 dark-text lato-bold ms-2">{voicemailRecords}</span>
          <span className="font-12 light-grey-text ms-1">({((voicemailRecords/totalRecords)*100).toFixed(0)}%)</span>
        </div>
      </div>
      <div className="col-6 mb-3">
        <div className="d-flex align-items-center">
          <span className="dot-icon gatekeeper"></span>
          <span className="font-14 grey-text ms-1" data-tooltip-content={`Likely Gatekeeper have 0-2% connect rate`} data-tooltip-id="homecomponent">Likely Gatekeeper</span>
          <span className="font-14 dark-text lato-bold ms-2">{gatekeeperRecords}</span>
          <span className="font-12 light-grey-text ms-1">({((gatekeeperRecords/totalRecords)*100).toFixed(0)}%)</span>
        </div>
      </div>
      <div className="col-6 mb-3">
        <div className="d-flex align-items-center">
          <span className="dot-icon duplicates"></span>
          <span className="font-14 grey-text ms-1" data-tooltip-content={`Others have 0-1% connect rate`} data-tooltip-id="homecomponent">Others</span>
          <span className="font-14 dark-text lato-bold ms-2">{otherRecords}</span>
          <span className="font-12 light-grey-text ms-1">({((otherRecords/totalRecords)*100).toFixed(0)}%)</span>
        </div>
      </div>
      {/* <div className="col-6 mb-3">
        <div className="d-flex align-items-center">
          <span className="dot-icon invalid"></span>
          <span className="font-14 grey-text ms-1">Likely Wrong</span>
          <span className="font-14 dark-text lato-bold ms-2">{wrongRecords}</span>
          <span className="font-12 light-grey-text ms-1">({((wrongRecords/totalRecords)*100).toFixed(0)}%)</span>
        </div>
      </div> */}
    </div>
    {/* <!--  --> */}
    <div className="d-flex align-items-center mt-3">
      <div>
        <span>
          <img
            src={userPic}
            width="24"
            height="24"
            className="rounded-circle"
            data-tooltip-content={userFullName} data-tooltip-id="homecomponent"
          />
        </span>
        <span className="dark-text font-14 ms-1"> {userName}</span>
      </div>
      <div className="mx-3 light-grey-text">|</div>
      <div className="font-12 light-grey-text" data-tooltip-content={`Added on ${createdDate}`} data-tooltip-id="homecomponent">{createdDate}</div>
    </div>
  </div>
</div>
<div>
  <div className="upload-wrap mt-5">
  <Modal
        show={openDownloadResults}
        onHide={handleCloseDownload}
        backdrop="static"
        keyboard={false}
        aria-labelledby="resultsModalLabel"
        className='modal-no-border'
      >
        <Modal.Header className="modal-header" closeButton>
          <Modal.Title className="modal-title" id="resultsModalLabel">
            <span className="round-modal-icon grey">
              <i className="fa-regular fa-arrow-down-to-bracket"></i>
            </span>{" "}
            Download Results
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DownloadComponent onCheckboxChange={handleCheckboxChange} projectDetails={projectDetails}/>
        </Modal.Body>
        <Modal.Footer>
          <div className="primary-blue-text font-12 poppins-medium cursor-pointer"onClick={() => handleDownloadValidatedCSV(downloadProjectId)}>
            <i className="fa-regular fa-arrow-down-to-bracket me-1"></i>
            Validated CSV
          </div>
          <div>
            <Button className="btn btn-cancel mr-15px" variant="secondary" onClick={handleCloseDownload}>
              Cancel
            </Button>
            <Button className='btn blue-btn-primary' variant="primary" onClick={handleDownloadResults} disabled={disableDownloadButton}>
              <i className="fa-regular fa-arrow-down-to-bracket me-1"></i> Download
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
  </div>
</div>
<Tooltip id = "homecomponent"/>
</div>

    )
};

export default CardComponent;