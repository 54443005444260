import React, { useContext, useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import IntegrationService from "../../../../services/integrationservice";
import { IntegrationContext } from "../../integrations-constants";

// Register necessary components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const IntegrationLogsGraph = ({ integrationId, initialView }) => {
  const { integration, configuration } = useContext(IntegrationContext);
  const [barChartData, setBarChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "Errors Count",
        backgroundColor: "#c9d6fe",
        hoverBackgroundColor: "#c9d6fe",
        data: [],
        barPercentage: 1,
        borderRadius: 5,
      },
    ],
  });
  const [view, setView] = useState(initialView);

  useEffect(() => {
    setGraphView(view);
  }, [view]);

  useEffect(() => {
    if (barChartData.datasets[0].data.length > 0) {
      updateGradient();
    }
  }, [barChartData.datasets[0].data]);

  const getLogsCount = async () => {
    const response = await IntegrationService.GetCRMLogsCount(
      integration?.integrationId,
      view
    );
    const data = response.map((x) => x.count);
    setBarChartData((prevData) => ({
      ...prevData,
      datasets: [
        {
          ...prevData.datasets[0],
          data,
        },
      ],
    }));
  };

  const setGraphView = (view) => {
    let labels = [];
    if (view === 1) {
      labels = getDatesInRange(30);
    } else if (view === 2) {
      labels = getWeeklyLabels(30);
    } else {
      labels = getHoursInRange();
    }
    setBarChartData((prevData) => ({
      ...prevData,
      labels,
    }));
    if (integration?.integrationId != null) {
      getLogsCount();
    }
  };

  const getDatesInRange = (days) => {
    const dates = [];
    const date = new Date();
    const endDate = new Date();
    date.setDate(date.getDate() - days);
    while (date <= endDate) {
      dates.push(new Date(date).toDateString());
      date.setDate(date.getDate() + 1);
    }
    return dates;
  };

  const getWeeklyLabels = (days) => {
    const dates = [];
    let startDate = new Date();
    const endDate = new Date();
    startDate.setDate(startDate.getDate() - days);
    const dayOfWeek = startDate.getDay();
    const diff = startDate.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1);
    startDate.setDate(diff);

    while (startDate < endDate) {
      let parts = startDate.toDateString().split(" ");
      const startdate = `${parts[1]} ${parts[2]}`;
      startDate.setDate(startDate.getDate() + 6);
      if (startDate > endDate) {
        startDate = endDate;
        parts = startDate.toDateString().split(" ");
        const enddate = `${parts[1]} ${parts[2]}`;
        const weekLabel = startdate + "-" + enddate;
        dates.push(weekLabel);
        break;
      }
      parts = startDate.toDateString().split(" ");
      const enddate = `${parts[1]} ${parts[2]}`;
      const weekLabel = startdate + "-" + enddate;
      dates.push(weekLabel);
      startDate.setDate(startDate.getDate() + 1);
    }
    return dates;
  };

  const getHoursInRange = () => {
    const hours = [];
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    for (let i = 0; i < 24; i++) {
      hours.push(
        new Date(date).toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        })
      );
      date.setHours(date.getHours() + 1);
    }
    return hours;
  };

  const createGradient = (ctx) => {
    const gradient = ctx.createLinearGradient(0, 0, 0, 200);

    gradient.addColorStop(0, "#c9d6fe");
    gradient.addColorStop(0.8, "#dce8fa");
    gradient.addColorStop(1, "#f2edf7");

    return gradient;
  };

  const updateGradient = () => {
    if (barChartData.datasets[0].data.length > 0) {
      // Chart will be updated here
      setBarChartData((prevData) => ({
        ...prevData,
        datasets: [
          {
            ...prevData.datasets[0],
            backgroundColor: (ctx) => {
              const chart = ctx.chart;
              const gradient = createGradient(chart.ctx);
              return gradient;
            },
          },
        ],
      }));
    }
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    responsiveAnimationDuration: 1,
    legend: {
      display: false,
    },
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          color: "#626262",
          display: false,
        },
        stacked: true,
      },
      y: {
        grid: {
          color: "#e9ebec",
          lineWidth: 0,
          display: false,
          drawBorder: false,
        },
        ticks: {
          maxTicksLimit: 5,
          color: "#626262",
        },
        stacked: true,
        min: 0,
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: (context) => {
            return `${context.dataset.label}: ${context.raw}`;
          },
        },
      },
    },
  };

  return (
    <div className="p-relative">
      <div className="mt-3 mr-2">
        <div className="btn-group float-right">
          <span
            className={`primary-btn-group  btn btn-primary ${
              view === 1 ? "active" : ""
            }`}
            onClick={() => setView(1)}
          >
            Daily
          </span>
          <span
            className={`primary-btn-group  btn btn-primary ${
              view === 2 ? "active" : ""
            }`}
            onClick={() => setView(2)}
          >
            Weekly
          </span>
        </div>
      </div>
      <div className="chart">
        {barChartData.datasets[0].data.length > 0 && (
          <Bar
            data={barChartData}
            options={chartOptions}
            onBeforeRender={(chart) => {
              const ctx = chart.ctx;
              const gradient = createGradient(ctx);
              chart.data.datasets[0].backgroundColor = gradient;
              chart.update();
            }}
          />
        )}
      </div>
    </div>
  );
};

export default IntegrationLogsGraph;
