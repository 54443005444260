import { Button, Dropdown, Form } from "react-bootstrap";
import "./filter-menu.component.css";
import { useState } from "react";
import SelectInput from "../select/select-input.component";
import { FieldType } from "../../base-content/Integrations/integrations-constants";
import moment from "moment";
import { toast } from "react-toastify";

export default function FilterMenu({
  filterOptions = [],
  onFilterApplied = () => {},
}) {
  const [selectedOption, setSelectedOption] = useState(null);
  const [filterOpened, setFilterOpened] = useState(false);

  const selectOption = (option) => {
    setSelectedOption(option);
  };

  function applyFilter(data) {
    onFilterApplied(data);
    setFilterOpened(false);
    setSelectedOption(null);
  }

  return (
    <Dropdown
      id="filterMenu"
      autoClose="outside"
      show={filterOpened}
      onToggle={(value) => setFilterOpened(value)}
    >
      <Dropdown.Toggle id="dropdown-basic">
        <span className="filterIcon">
          <i className="fa-regular fa-filter"></i>
        </span>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {!selectedOption ? (
          <Filters options={filterOptions} onOptionSelected={selectOption} />
        ) : (
          <SelectedFilterView
            option={selectedOption}
            onBackClick={() => selectOption(null)}
            onFilterApplied={applyFilter}
          />
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}

function Filters({ options = [], onOptionSelected }) {
  return options.map((item) => (
    <Dropdown.Item key={item.id} onClick={() => onOptionSelected(item)}>
      {item.text}
    </Dropdown.Item>
  ));
}

function SelectedFilterView({
  option,
  onBackClick,
  onFilterApplied = () => {},
}) {
  const [filterValues, setFilterValues] = useState(getIntitalFilterValues());
  const [selectedCriterion, setSelectedCriterion] = useState(null);

  function getIntitalFilterValues() {
    return {
      fieldName: option.text,
      fieldId: option.id,
      fieldType: option.type,
      crietriaValue: option?.criteria ? "" : "in",
      fieldValue: "",
      fieldSecondaryValue: "",
      textValues: [], // For Select Text Values
    };
  }

  function onChangeCriteria(criterion) {
    setSelectedCriterion(criterion);
    setFilterValues({ ...filterValues, crietriaValue: criterion.value });
  }

  function onChangeSelectValues(selectedItems) {
    if (Array.isArray(selectedItems)) {
      setFilterValues({
        ...filterValues,
        fieldValue: selectedItems.map((d) => d.id),
        textValues: selectedItems.map((d) => d.text),
      });
    } else {
      setFilterValues({
        ...filterValues,
        fieldValue: [selectedItems.id],
        textValues: [selectedItems.text],
      });
    }
  }

  function onChangeDateValues(event, type) {
    const value = moment(new Date(event.target.valueAsNumber)).format(
      "YYYY-MM-DD"
    );

    if (type === "start") {
      setFilterValues({
        ...filterValues,
        fieldValue: value,
      });
    } else if (type === "end") {
      setFilterValues({
        ...filterValues,
        fieldSecondaryValue: value,
      });
    }
  }

  function onApplyFilter() {
    if (isFilterValid()) {
      onFilterApplied(filterValues);
      setFilterValues(getIntitalFilterValues());
    }
  }

  function isFilterValid() {
    if (option?.criteria && !selectedCriterion) {
      toast.error("Please select the filter criteria.");
      return false;
    } else if (
      (selectedCriterion?.showField && !filterValues?.fieldValue) ||
      (option.type === FieldType.MultiSelect &&
        !filterValues?.fieldValue?.length)
    ) {
      toast.error("Please provide the values.");
      return false;
    } else if (
      selectedCriterion?.value === "between" &&
      !filterValues?.fieldSecondaryValue
    ) {
      toast.error("Please provide the other value.");
      return false;
    } else if (
      option?.type === FieldType.Date &&
      selectedCriterion?.value === "between" &&
      moment(filterValues?.fieldSecondaryValue).isBefore(
        moment(filterValues?.fieldValue)
      )
    ) {
      toast.error("Start date cannot be greater than end date.");
      return false;
    }

    return true;
  }

  return (
    <>
      <div
        className="font-14 border-bottom-1 mb-2 p-2 text-black cursor-pointer filter-option__selected"
        onClick={onBackClick}
      >
        <i className="fas fa-chevron-left mr-1"></i>
        <span className="ms-2">{option.text}</span>
      </div>
      <div className="d-flex flex-column py-2 px-3">
        {option?.criteria ? (
          <div className="filter-criteria">
            {option.criteria.map((d) => (
              <Form.Check
                type="radio"
                id={d.value}
                label={d.name}
                checked={selectedCriterion?.value === d.value}
                onChange={() => onChangeCriteria(d)}
              />
            ))}
          </div>
        ) : undefined}
        <div className="w-100">
          {option?.criteria && !selectedCriterion?.value
            ? undefined
            : (() => {
                switch (option.type) {
                  case FieldType.MultiSelect:
                    return (
                      <SelectInput
                        className="w-100"
                        multiple={option?.selectOptions?.multiple ?? false}
                        allowSearch={
                          option?.selectOptions?.allowSearch ?? false
                        }
                        data={option?.selectOptions?.data ?? []}
                        onSelectionChanges={onChangeSelectValues}
                      />
                    );
                  case FieldType.Date:
                    return option?.criteria &&
                      !selectedCriterion?.showField ? undefined : (
                      <div
                        className="d-flex align-items-center"
                        style={{ gap: "2%" }}
                      >
                        <input
                          type="date"
                          placeholder={
                            selectedCriterion?.value === "between"
                              ? "Start date"
                              : "Select date"
                          }
                          className="form-control"
                          style={{
                            width:
                              selectedCriterion?.value === "between"
                                ? "49%"
                                : "100%",
                          }}
                          onChange={(event) =>
                            onChangeDateValues(event, "start")
                          }
                        />
                        {selectedCriterion?.value === "between" ? (
                          <input
                            type="date"
                            placeholder="End date"
                            className="form-control"
                            style={{ width: "49%" }}
                            onChange={(event) =>
                              onChangeDateValues(event, "end")
                            }
                          />
                        ) : undefined}
                      </div>
                    );
                  default:
                    return undefined;
                }
              })()}
        </div>
      </div>
      <div
        className="px-3 py-3 d-flex justify-content-end "
        onClick={onApplyFilter}
      >
        <Button className="apply-filter-btn rounded px-4 py-2"> Apply </Button>
      </div>
    </>
  );
}
