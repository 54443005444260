export const RolesEnum = {
    Member: 1,
    Admin: 2,
    Owner: 3,
};

// Optionally, you can add a reverse mapping if needed
export const RoleNames = {
    1: 'Member',
    2: 'Admin',
    3: 'Owner',
};

export const UserStatusNamesEnum = {
    0: "Invited",
    1: "Active",
    2: "expired",
    3: "Removed", 
    4: "Disabled"
};

export const UserStatusEnum = {
    Invited  : 0,
    Active   : 1,
    Expired  : 2,
    Removed  : 3,
    Disabled : 4
};