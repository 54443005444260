// export const msalConfig = {
//     auth: {
//       clientId: '40f90e27-4fcc-4b1f-b9a4-7e597409c8cd',
//       authority: 'https://login.microsoftonline.com/f8cdef31-a31e-4b4a-93e4-5f571e91255a',
//       redirectUri: 'http://localhost:55182',
//     },
//     cache: {
//       cacheLocation: 'localStorage', // or 'localStorage'
//       storeAuthStateInCookie: false,
//     },
//   };
  
//   export const loginRequest = {
//     scopes: ['User.Read'], // Add the required scopes for your application
//   };

import { PublicClientApplication } from "@azure/msal-browser";
const Office365ClientID = process.env.REACT_APP_OFFICE365_CLIENT_ID;
const Office365RedirectURI = process.env.REACT_APP_REDIRECT_URI;
//This component registers the microsoft azure for office365 login.
//We require clientId, redirectUri for this.
//Go to microsoft azure to create a login page. Contact @Vijaya or Admin for the credentials.
const config = {
    auth: {
        clientId: Office365ClientID,
        authority: "https://login.microsoftonline.com/common",
        redirectUri: Office365RedirectURI
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false
    }
};

export const msalInstance = new PublicClientApplication(config);