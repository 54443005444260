export default function SpinnerLoader() {
  return (
    <section class="content-loader">
      <section>
        <div class="loader">
          <div class="loader-outer"></div>
          <div class="loader-inner"></div>
        </div>
      </section>
    </section>
  );
}
