import React, { useEffect } from "react";
import { useState } from "react";
import './balancefilter.component.scss';
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';
import { Tooltip } from "react-tooltip";
import 'react-tooltip/dist/react-tooltip.css'

const BalanceFilters = ({onLowerChange,lower,onUpperChange,upper,onStatusChange,applyFilter,
    status,onTotalClear,onStatusClear, transactionType,hidePaymentStyle,
    type,onTypeChange,onTypeClear}) => {
    const [totalCreditsLowerLimit, setTotalCreditsLowerLimit] = useState(lower);
    const [totalCreditsUpperLimit, setTotalCreditsUpperLimit] = useState(upper);
    const [statusSelection, setStatusSelection] = useState(status);
    const [typeSelection, setTypeSelection] = useState(type)
    const [selectedFilter, setSelectedFilter] = useState(null);
    const [invalidCreditsInput, setInvalidCreditsInput] = useState(null);
    let transactionTypeText = 'Credits Used';
    if(transactionType === 0){
        transactionTypeText = 'Credits Purchased'
    }

    const handleLowerLimitChange = (event) => {
        const value = event.target.value;
        onLowerChange(value);
        setSelectedFilter("transactionCredits");
        setTotalCreditsLowerLimit(value);
        if((parseInt(value, 10)>parseInt(totalCreditsUpperLimit, 10))){
            setInvalidCreditsInput("Min credits cannot be more than Max credits.");
        }
        else{
            setInvalidCreditsInput(null);
        }
      };
    
      const handleUpperLimitChange = (event) => {
        const value = event.target.value;
        setTotalCreditsUpperLimit(value);
        onUpperChange(value);
        if((parseInt(value, 10)<parseInt(totalCreditsLowerLimit, 10))){
            setInvalidCreditsInput("Min credits cannot be more than Max credits.");
        }
        else{
            setInvalidCreditsInput(null);
        }
      };

      const handleCheckboxChange = (event) => {
        const { value, checked } = event.target;
        let status = []
        if (checked) {
            status = [...statusSelection, value];
        //   setStatusSelection([...statusSelection, value]);
        } else {
            status = statusSelection.filter((status) => status !== value);
        //   setStatusSelection(statusSelection.filter((status) => status !== value));
        }
        onStatusChange(status);
        setStatusSelection(status);
      };

      const handleRadioChange = (event) => {
        const newType = [event.target.value]
        onTypeChange(newType);
        setTypeSelection(newType);
      };
      const handleClearTransactionType = () => {
        onTypeClear();
    };

      const handleClearProjectStatus = () => {
        onStatusClear();
      };
      const handleClearTotalRecords = () => {
        onTotalClear();
      }

      useEffect(() => {
        if(statusSelection){
            // console.log("STATUSSELECTION");
        applyFilter("transactionStatus");
        }
        
      }, [statusSelection]);

      useEffect(() => {
        if(typeSelection){
        applyFilter("transactionReason");
        }
      }, [typeSelection]);

      useEffect(() => {
        applyFilter("transactionCredits"); 
        // console.log(totalCreditsLowerLimit);
      }, [totalCreditsLowerLimit,totalCreditsUpperLimit]);

    return (
        <Accordion>
            <Accordion.Item eventKey="0">
                <Accordion.Header className={hidePaymentStyle} data-tooltip-content="Filter by transaction status" data-tooltip-id="Filters">Payment Status</Accordion.Header>
                <Accordion.Body className="p-3">
                    <label className="CheckboxContainer mb-1 mt-1 d-block">
                        <input
                            type="checkbox"
                            name="Status"
                            value="0"
                            checked={statusSelection.includes("0")}
                            data-tooltip-content="Search by transaction reason" 
                            data-tooltip-id="listtips"
                            onChange={handleCheckboxChange}
                            className=""
                        />
                        <span className="CheckedMark"></span>
                        <span className="ms-4 ps-1 dark-text lato-medium">Completed <span className="light-grey-text font-12 ms-1 d-none"></span></span>
                    </label>
                    <label className="CheckboxContainer mb-1 mt-1 d-block">
                        <input
                            type="checkbox"
                            name="Status"
                            value="1"
                            checked={statusSelection.includes("1")}
                            onChange={handleCheckboxChange}
                            className=""
                        />
                        <span className="CheckedMark"></span>
                        <span className="ms-4 ps-1 dark-text lato-medium">Failed<span className="light-grey-text font-12 ms-1 d-none"></span></span>
                    </label>
                    <label className="CheckboxContainer mb-1 mt-1 d-block">
                        <input
                            type="checkbox"
                            name="Status"
                            value="2"
                            checked={statusSelection.includes("2")}
                            onChange={handleCheckboxChange}
                            className=""
                        />
                        <span className="CheckedMark"></span>
                        <span className="ms-4 ps-1 dark-text lato-medium">Processing <span className="light-grey-text font-12 ms-1 d-none"></span></span>
                    </label>
                    <div className="text-end font-12 poppins-medium primary-blue-text cursor-pointer" onClick={handleClearProjectStatus}>
                        Clear
                    </div>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1" className="d-none">
                <Accordion.Header>Added by</Accordion.Header>
                <Accordion.Body>
                    <input type="text" className="form-control" />
                    <div className="text-end font-12 poppins-medium primary-blue-text cursor-pointer mt-2">
                        Clear
                    </div>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2" className="d-none">
                <Accordion.Header>Added Date</Accordion.Header>
                <Accordion.Body>
                    <div className="d-flex align-items-center justify-content-between mb-1">
                        <Form.Check
                            type="radio"
                            id="Today"
                            label="Today"
                        />
                        <div className="light-grey-text font-12">14 Jun</div>
                    </div>

                    <div className="d-flex align-items-center justify-content-between mb-1">
                        <Form.Check
                            type="radio"
                            id="Yesterday"
                            label="Yesterday"
                        />
                        <div className="light-grey-text font-12">13 Jun</div>
                    </div>

                    <div className="d-flex align-items-center justify-content-between mb-1">
                        <Form.Check
                            type="radio"
                            id="Lastweek"
                            label="Last week"
                        />
                        <div className="light-grey-text font-12">4 - 10 Jun</div>
                    </div>

                    <div className="text-end font-12 poppins-medium primary-blue-text cursor-pointer mt-2">
                        Clear
                    </div>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
                <Accordion.Header data-tooltip-content="Filter by number of credits in transaction" data-tooltip-id="Filters">{transactionTypeText}</Accordion.Header>
                <Accordion.Body>
                    <div className="d-flex align-items-center justify-content-between">
                        <div>
                            <input 
                            type="number"
                            className={`form-control ${invalidCreditsInput ? 'is-invalid' : ''}`}
                            placeholder="Min" 
                            value={totalCreditsLowerLimit} 
                            data-tooltip-content="Min credits amount" data-tooltip-id="Filters"
                            onChange={handleLowerLimitChange}
                            />
                        </div>
                        <div className="px-2 light-grey-text">to</div>
                        <div>
                            <input 
                            type="number" 
                            className={`form-control ${invalidCreditsInput ? 'is-invalid' : ''}`}
                            placeholder="Max" 
                            data-tooltip-content="Max credits amount" data-tooltip-id="Filters"
                            value={totalCreditsUpperLimit}
                            onChange={handleUpperLimitChange}/>
                        </div>
                    </div>
                    {invalidCreditsInput && (
                        <div className=" font-14  lato-regular text_error_message">
                            {invalidCreditsInput}
                        </div>
                    )}
                    <div className="text-end font-12 poppins-medium primary-blue-text cursor-pointer mt-2"onClick={handleClearTotalRecords}>
                        Clear
                    </div>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
                <Accordion.Header className={hidePaymentStyle} data-tooltip-content="Filter by transaction type" data-tooltip-id="Filters">Transaction Type</Accordion.Header>
                <Accordion.Body className="p-3">
                    <label className="CheckboxContainer mb-1 mt-1 d-block">
                        <input
                            type="radio"
                            name="transactionType"
                            value="Purchase"
                            checked={typeSelection.includes("Purchase")}
                            onChange={handleRadioChange}
                            className=""
                        />
                        <span className="CheckedMark"></span>
                        <span className="ms-4 ps-1 dark-text lato-medium">Purchase<span className="light-grey-text font-12 ms-1 d-none"></span></span>
                    </label>
                    <label className="CheckboxContainer mb-1 mt-1 d-block">
                        <input
                            type="radio"
                            name="transactionType"
                            value="Promotion"
                            checked={typeSelection.includes("Promotion")}
                            onChange={handleRadioChange}
                            className=""
                        />
                        <span className="CheckedMark"></span>
                        <span className="ms-4 ps-1 dark-text lato-medium">Promotion<span className="light-grey-text font-12 ms-1 d-none"></span></span>
                    </label>
                    <div className="text-end font-12 poppins-medium primary-blue-text cursor-pointer" onClick={handleClearTransactionType}>
                        Clear
                    </div>
                </Accordion.Body>
            </Accordion.Item>
            <Tooltip id = "Filters"/>
        </Accordion>
    );
}

export default BalanceFilters;