import { Routes, Route, Navigate } from "react-router-dom";
import IntegrationLayout from "./integration-layout.component";
import Integrations from "./integrations.component";
import ConfigureObjects from "./integration-details/configurations/configure-objects.component";
import IntegrationConfiguration from "./integration-details/configurations/integration-configuration.component";
import IntegrationDetails from "./integration-details/integration-details.context";
import IntegrationSummary from "./integration-details/views/integration-summary.component";
import ConfigureActivity from "./integration-details/configurations/configure-activity.component";
import ConfigureSettings from "./integration-details/configurations/configure-settings.component";
import ConfigureUserMapping from "./integration-details/configurations/configure-user-mapping.component";
import IntegrationIndex from "./integration-details/views/integration-index.component";
import IntegrationLogs from "./integration-details/views/integration-logs.component";
import IntegrationSyncHistory from "./integration-details/views/integration-sync-history.component";
import IntegrationApiUsage from "./integration-details/views/integration-api-usage.component";
import IntegrationErrorLogs from "./integration-details/views/integration-logs-errors.component";

const IntegrationRoutes = () => (
  <Routes>
    <Route path="/" element={<IntegrationLayout />}>
      <Route index element={<Integrations />} />
      <Route
        path="/:integrationName/*"
        element={<IntegrationDetails />}
      ></Route>
    </Route>
  </Routes>
);

const IntegrationDetailsRoutes = ({ state }) => {
  return (
    <Routes>
      <Route path="" element={<IntegrationIndex />}>
        <Route
          path=""
          element={<Navigate to="summary" state={state} replace={true} />}
        />
        <Route path="summary" element={<IntegrationSummary />} />
        <Route path="logs" element={<IntegrationLogs />} />
        <Route path="errors" element={<IntegrationErrorLogs />} />
        <Route path="synchistory" element={<IntegrationSyncHistory />} />
        <Route path="apiusage" element={<IntegrationApiUsage />} />
      </Route>
      <Route path="configure" element={<IntegrationConfiguration />}>
        <Route
          path=""
          element={<Navigate to="objects" state={state} replace={true} />}
        />
        <Route path="objects" element={<ConfigureObjects />} />
        <Route path="activity" element={<ConfigureActivity />} />
        <Route path="usermapping" element={<ConfigureUserMapping />} />
        <Route path="settings" element={<ConfigureSettings />} />
      </Route>
    </Routes>
  );
};

export { IntegrationRoutes, IntegrationDetailsRoutes };
