import { createContext } from "react";

export const IntegrationContext = createContext({
  integration: {},
  configuration: {},
});

export function transformFieldMappings(fMappings) {
  const getSeparateFieldValuesIfComma = (value) => {
    if (value) {
      const vs = value.split(",");
      return vs;
    } else {
      return value;
    }
  };

  const fieldsMap = new Map();

  fMappings.forEach((f) => {
    f.fieldMappings.forEach((m) => {
      if (fieldsMap.has(m.pvlFieldId)) {
        const obj = fieldsMap.get(m.pvlFieldId);
        obj.crmFields.push({
          fieldMappingId: m.fieldMappingId,
          mappingStatus: m.mappingStatus,
          crmFieldName: getSeparateFieldValuesIfComma(m.crmFieldName),
          crmFieldId: getSeparateFieldValuesIfComma(m.crmFieldId),
          crmFieldType: getSeparateFieldValuesIfComma(m.crmFieldType),
          objectTypeId: f.typeId,
          objectTypeName: f.typeName,
        });

        fieldsMap.set(m.pvlFieldId, obj);
      } else {
        const obj = {
          pvlFieldId: m.pvlFieldId,
          pvlFieldName: m.pvlFieldName,
          pvlFieldType: m.pvlFieldType,
          syncDirection: m.syncDirection,
          crmFields: [
            {
              fieldMappingId: m.fieldMappingId,
              mappingStatus: m.mappingStatus,
              crmFieldName: getSeparateFieldValuesIfComma(m.crmFieldName),
              crmFieldId: getSeparateFieldValuesIfComma(m.crmFieldId),
              crmFieldType: getSeparateFieldValuesIfComma(m.crmFieldType),
              objectTypeId: f.typeId,
              objectTypeName: f.typeName,
            },
          ],
        };

        fieldsMap.set(m.pvlFieldId, obj);
      }
    });
  });

  return Array.from(fieldsMap.values());
}

export const FieldType = Object.freeze({
  Text: 1,
  Number: 2,
  Date: 3,
  Float: 4,
  DateTime: 5,
  PickList: 6,
  TimeZone: 7,
  Lookup: 8,
  Url: 9,
  MultiSelect: 10,
  Boolean: 11,
});

export const FieldTypeById = Object.freeze({
  1: "string",
  2: "number",
  3: "date",
  4: "float",
  5: "datetime",
  10: "multiselect",
  11: "boolean",
});

export const pollIntervalTypes = [
  {
    id: "1",
    text: "Day",
  },
  {
    id: "2",
    text: "Hour",
  },
  {
    id: "3",
    text: "Minute",
  },
];

export const SyncStatus = Object.freeze({
  Idle: 0,
  InProgress: 1,
});

export const FilterOperatorType = Object.freeze({
  Equals: 1,
  NotEquals: 2,
  GreaterThan: 3,
  LessThan: 4,
  GreaterThanOrEqual: 5,
  LessThanOrEqual: 6,
  Contains: 7,
  StartsWith: 8,
  EndsWith: 9,
  In: 10,
  NotIn: 11,
});

export function getInboundFilterOperators(types) {
  const operators = {
    1: "Equal to",
    2: "Does not equal to",
    3: "Greater than",
    4: "Less than",
    5: "Greater than or equal to",
    6: "Less than or equal to",
    7: "Contains",
    8: "Starts with",
    9: "Ends With",
    10: "In",
    11: "Not in",
  };

  return types.map((type) => ({ id: type, text: operators[type] }));
}

export function getOperatorTextSeparator(operator) {
  switch (operator) {
    case 2:
      return "does not equal to";
    case 3:
      return "greater than";
    case 4:
      return "less than";
    case 5:
      return "greater than or equal to";
    case 6:
      return "less than or equal to";
    case 7:
      return "contains";
    case 8:
      return "starts with";
    case 9:
      return "ends with";
    case 10:
      return "in";
    case 11:
      return "not in";
    default:
      return "is";
  }
}

export const APIUsageGraphType = Object.freeze({
  weekly: 1,
  hourly: 2,
});

export const MappingStatus = Object.freeze({
  Active: 1,
  Deleted: 2,
  InActive: 3,
  Disabled: 4
});
