import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import authFailedImg from "../../assets/img/ErrorLampRobotSC.svg";

const OAuthResultComponent = () => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);

  const success = urlParams.get("success");
  const statusCode = parseInt(urlParams.get("statuscode"), 10);
  const message = urlParams.get("message");
  const name = urlParams.get("integrationname");

  useEffect(() => {
    if (success === "True") {
      window.close();
    }
  }, [success]);

  const handleClose = () => {
    window.close();
  };

  if (success === "False") {
    if (statusCode === 2) {
      return (
        <div
          className="auth-fail-block"
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            height: "100%",
            flexDirection: "column",
          }}
        >
          <div>
            <img
              src={authFailedImg}
              style={{ maxWidth: "250px", margin: "30px 0" }}
              alt="Auth Failed"
            />
          </div>
          <div
            style={{
              padding: "0 0 20px",
              borderRadius: "0.25rem",
              fontSize: "16px",
              lineHeight: "24px",
              textAlign: "center",
              fontFamily: "sans-serif",
              color: "#9b9b9b",
            }}
          >
            Looks like {name} account is already connected with another
            SureConnect account.
          </div>
          <div
            id="closeWindow-1"
            style={{
              padding: "1px 1.5rem",
              height: "32px",
              maxWidth: "4rem",
              borderRadius: "24px",
              backgroundColor: "#ffffff",
              fontSize: "12px",
              display: "flex",
              border: "1px solid #c6b9b3",
              color: "#9b9b9b",
              fontFamily: "sans-serif",
              cursor: "pointer",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={handleClose}
          >
            Close
          </div>
        </div>
      );
    } else {
      return (
        <div
          className="auth-fail-block"
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            height: "100%",
            flexDirection: "column",
          }}
        >
          <div>
            <img
              src={authFailedImg}
              style={{ maxWidth: "250px", margin: "30px 0" }}
              alt="Auth Failed"
            />
          </div>
          <div
            style={{
              padding: "0 0 20px",
              borderRadius: "0.25rem",
              fontSize: "16px",
              lineHeight: "24px",
              textAlign: "center",
              fontFamily: "sans-serif",
              color: "#9b9b9b",
            }}
          >
            {statusCode === 3 && <span>API rate limit exceeded</span>}
            {statusCode === 4 && <span>{message}</span>}
            {statusCode === 400 ? (
              <span>
                Looks like there is an issue while connecting with your {name}{" "}
                account.
                <span style={{ display: "block" }}>
                  {message && <span>Error: {message}</span>}
                </span>
              </span>
            ) : (
              <span>
                Looks like there is an issue while connecting with your {name}{" "}
                account
              </span>
            )}
          </div>
          <div
            id="closeWindow-2"
            style={{
              padding: "1px 1.5rem",
              height: "32px",
              maxWidth: "4rem",
              borderRadius: "24px",
              backgroundColor: "#ffffff",
              fontSize: "12px",
              display: "flex",
              border: "1px solid #c6b9b3",
              color: "#9b9b9b",
              fontFamily: "sans-serif",
              cursor: "pointer",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={handleClose}
          >
            Close
          </div>
        </div>
      );
    }
  }

  return <></>;
};

export default OAuthResultComponent;
