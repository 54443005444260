import React from "react";
import './app.component.scss'
import SidebarMenu from "./sidebar-menu/sidebar-menu.component";
import HeaderComponent from "./header/header.component";
import ProfileComponent from "./base-content/profile/profile.component";

const ProfileApp = () => {
    return (
<div className="container-fluid">
  <div className="row">
      <div className="col-sm-auto sidebar-menu sticky-top">
        <SidebarMenu />
      </div>
      <div className="col-sm min-vh-100 p-0">
        <div>
            <HeaderComponent />
            <ProfileComponent />
        </div>
      </div>
  </div>
</div>
    );
}

export default ProfileApp;