import { useEffect, useState } from "react";
import {
  useParams,
  useLocation,
  useNavigate,
  useOutletContext,
} from "react-router-dom";
import IntegrationService from "../../../../services/integrationservice";
import { toast } from "react-toastify";
import { formatDistanceToNow } from "date-fns";
import moment from "moment";
import Pagination from "../../../../shared/pagination/pagination.component";
import { toTitleCase } from "../../../../utils/helper.utils";

export default function IntegrationErrorLogs() {
  const [logsByCategory, setLogsByCategory] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [totalRows, setTotalRows] = useState(0);
  const { createBreadcrumb, integration, configuration } = useOutletContext();

  const { log, appliedFilters } = location.state || {};

  const [payload, setPayload] = useState({
    integrationid: integration.integrationId,
    filters: "",
    category: log.category,
    fromdate: "",
    todate: "",
    pageindex: 1,
    pagesize: 50,
  });

  useEffect(() => {
    processFilters(appliedFilters);
    setIsLoading(true);
    fetchCRMLogsByCategory();
  }, [params, location, payload.pageindex]);

  useEffect(() => {
    createBreadcrumb({
      handleClick: ({ item }) => {
        if (!item.route) return;
        navigate(`/integrations/${configuration.name}/${item.route}`, {
          state: location.state,
        });
        createBreadcrumb(null);
      },
      items: [
        { name: "Errors & Warnings", route: "logs" },
        { name: "Error Details", route: "" },
      ],
    });
  }, []);

  function processFilters(appliedFilters) {
    const _filters = {};
    appliedFilters?.forEach((filter) => {
      switch (filter.id) {
        case "date":
          _filters["fromdate"] = filter.list[0].text;
          _filters["todate"] = filter.list[0].secondaryText;
          break;
        default:
          _filters[filter.id] = filter.list[0].id;
      }
    });

    _filters.objecttype = log.sureconnectobjecttype;
    _filters.synctype = log.direction;

    payload["filters"] =
      JSON.stringify(_filters) === "{}" ? "" : JSON.stringify(_filters);
    setPayload({ ...payload });
  }

  const fetchCRMLogsByCategory = async () => {
    try {
      const logs = await IntegrationService.GetSyncLogsByCategory(payload);
      setLogsByCategory(logs?.crmlogslist);
      setTotalRows(logs.totalrows);
      setIsLoading(false);
    } catch (error) {
      toast.error("Failed to fetch logs");
      setIsLoading(false);
    }
  };

  function onPageChange(page) {
    setPayload({ ...payload, pageindex: page });
  }

  if (!logsByCategory) {
    return <div>No logs found</div>;
  }

  const formatTime = (date, suffix) => {
    const formatted = formatDistanceToNow(new Date(date + "Z"), {
      addSuffix: true,
    });
    if (suffix === "old") {
      return formatted.replace(/ago$/, "old").replace(/^about\s/, "");
    }
    return formatted.replace(/^about\s/, "");
  };

  return (
    <div id="errors">
      <div className="table-responsive mb-3 colored-table td-transparent flat-table minus-m-25 table-radius mt-3">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th className="w-30rem">Error & Description</th>
              <th className="text-center">Occurrences</th>
              <th className="text-center w-10rem">Sync Type</th>
              <th>Object</th>
              <th className="w-12rem">Error last occurred on</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="w-30rem">
                <div className="blue-link cursor-pointer mb-1">
                  {toTitleCase(log.formattedcategory)}
                </div>
                <div
                  className="font-12 formatted-message default-text mb-2 line-break"
                  dangerouslySetInnerHTML={{ __html: log.formattedmessage }}
                ></div>
                <div className="font-12 light-grey-text d-flex align-items-center gap-10">
                  <span>
                    <i className="fa-regular fa-clock"></i>{" "}
                    {formatTime(log.lasterrordate, "ago")}
                  </span>
                  <span className="mb-5px">
                    <i className="fa-solid fa-circle light-grey-text small-dot"></i>
                  </span>
                  <span>{formatTime(log.createddate, "old")}</span>
                </div>
              </td>
              <td className="text-center default-text">{log.errorscount}</td>
              <td className="text-center">
                <span className="sync-icon">
                  {log.direction === 2 ? (
                    <i className="fa-solid fa-arrow-left-long"></i>
                  ) : (
                    <i className="fa-solid fa-arrow-right-long"></i>
                  )}
                </span>
              </td>
              <td className="default-text">{log.objectname}</td>
              <td className="w-15rem grey-text">
                {moment(log.lasterrordate).format("MMM D, h:mm:ss a")}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="d-flex align-items-center justify-content-between mb-3">
        <div className="d-flex gap-2 align-items-center">
          {/* <span class="filterIcon square-bordered-btn">
            <i class="fa-regular fa-filter"></i>
          </span> */}
        </div>
        <div className="d-flex gap-3 align-items-center">
          <span
            class="refreshIcon widget-icon"
            onClick={fetchCRMLogsByCategory}
          >
            <i class="fa-regular fa-redo-alt"></i>
          </span>
          <Pagination
            totalItems={totalRows}
            pageSize={50}
            pageChanged={onPageChange}
          />
        </div>
      </div>

      <div className="table-responsive mb-5 colored-table td-transparent flat-table minus-m-25">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th className="w-12rem">Error Occurred on</th>
              <th>Object</th>
              <th>CRM Exception</th>
              <th>Error Description</th>
            </tr>
          </thead>
          <tbody>
            {logsByCategory.map((log, index) => (
              <tr key={index}>
                <td className="light-grey-text w-15rem">
                  {moment(log.timeStamp).format("MMM D, h:mm:ss a")}
                </td>
                <td className="w-10rem">
                  {log.objectName != null ? (
                    log.objectName
                  ) : (
                    <i className="light-grey-text">No Name</i>
                  )}
                </td>
                <td
                  className="formatted-message line-break"
                  dangerouslySetInnerHTML={{ __html: log.message }}
                ></td>
                <td
                  className="formatted-message line-break"
                  dangerouslySetInnerHTML={{ __html: log.formattedMessage }}
                ></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
