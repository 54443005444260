import { useEffect, useState } from "react";
import IntegrationService from "../../../../services/integrationservice";
import { toast } from "react-toastify";
import FieldMappingRow from "./field-mapping-row.component";
import { toTitleCase } from "../../../../utils/helper.utils";
import { transformFieldMappings } from "../../integrations-constants";
import { useOutletContext } from "react-router-dom";
import LoadingAnimation from "../../../lottie-files/loading.lotte";

export default function ConfigureObjects() {
  const {
    integration,
    configuration,
    goToNextStep,
    goBack,
    currentStepIndex,
    setValidationFn,
  } = useOutletContext();
  const [isLoading, setIsLoading] = useState(false);
  const [crmFieldOptions, setCrmFieldOptions] = useState({}); // It will be a object with key = "ObjectTypeId" and value = "Field[]"
  const [fieldMappings, setFieldMappings] = useState([]);

  const [objectTypes, setObjectTypes] = useState(
    configuration.defaultConfig.types
  );

  const [selectedObjectIds, setSelectedObjectIds] = useState([]);

  const [fieldValidationObj, setFieldValidationObj] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    getFieldMappings();
    getSyncSettings();
  }, []);

  useEffect(() => {
    setValidationFn(onNavigationValidateFn);
  }, [fieldMappings, selectedObjectIds]);

  function onNavigationValidateFn() {
    let isValid = true;

    const mandatoryPvlFieldIds = [7, 8, 9];
    const unmappedFieldNames = [];

    for (const mapping of fieldMappings) {
      for (const field of mapping.crmFields) {
        if (
          selectedObjectIds.includes(field.objectTypeId) &&
          mandatoryPvlFieldIds.includes(mapping.pvlFieldId) &&
          !field.crmFieldId
        ) {
          unmappedFieldNames.push(mapping.pvlFieldName);
          break;
        }
      }
    }

    if (unmappedFieldNames.length) {
      toast.error(
        `${unmappedFieldNames.join(", ")} field${unmappedFieldNames.length > 1 ? "s" : ""} should be mapped.`
      );
      return false;
    }

    return true;
  }

  function handleFieldMappingResponse(fMappings) {
    const transformedMappings = transformFieldMappings(fMappings);

    if (!fieldValidationObj) {
      prepareFieldValidationObj([...transformedMappings]);
    }
    setFieldMappings([...transformedMappings]);
  }

  function handleSyncSettingsResponse(syncSettings) {
    const objectTypeMappings =
      configuration.defaultConfig.typeMappings.fieldmappingsapi;

    const objectTypeIds = [];

    objectTypes.forEach((o) => {
      const foundSyncSetting = syncSettings.find(
        (s) => s.objectType === o.typeId
      );

      o.syncSettingId = foundSyncSetting?.syncSettingId;
      o.mappedCrmObjectName = objectTypeMappings.find(
        (ot) => ot.pvlName === o.name
      ).crmName;

      if (foundSyncSetting?.inboundSyncEnabled === 1) {
        objectTypeIds.push(o.typeId);
      }
    });

    setSelectedObjectIds([...objectTypeIds]);
    getCrmFieldsByObjectType(objectTypeIds);
    setObjectTypes(objectTypes);
  }

  async function getSyncSettings() {
    try {
      const syncSettings = await IntegrationService.SyncSettings(
        integration.integrationId
      );

      handleSyncSettingsResponse(syncSettings);
    } catch (error) {
      toast.error("Failed to fetch integrations");
    }
  }

  async function getFieldMappings() {
    try {
      const fMappings = await IntegrationService.FieldMappings(
        integration.integrationId,
        configuration.crmConfigurationId,
        objectTypes.map((type) => type.typeId)
      );

      handleFieldMappingResponse(fMappings);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error("Failed to fetch integrations");
    }
  }

  async function getCrmFieldsByObjectType(objectTypeIds) {
    const options = { ...crmFieldOptions };

    for (const id of objectTypeIds) {
      if (options[id]) return;

      const crmObject = objectTypes.find((o) => o.typeId === id);

      const configObj =
        configuration.uiConfig.uiproperties.configureobjects.find(
          (d) => d.name?.toLowerCase() === crmObject?.pvlName?.toLowerCase()
        );

      await IntegrationService.CrmFields(
        crmObject?.mappedCrmObjectName,
        integration.integrationId,
        configuration.name
      ).then((result) => {
        options[id] = (result || [])
          ?.filter(
            (item) =>
              !configObj.fieldmappings?.typestobeexcluded?.includes(
                item.type
              ) ?? true
          )
          .map((item) => ({
            id: item.id,
            text: item.name,
            additional: item,
          }));
        setCrmFieldOptions(options);
      });
    }
  }

  function selectDeselectObject(index) {
    const syncSettingId = objectTypes[index].syncSettingId;
    const inboundSyncEnabled = !selectedObjectIds.includes(
      objectTypes[index].typeId
    );

    if (!inboundSyncEnabled && selectedObjectIds.length === 1) {
      toast.error("One of the object should be selected.");
      setSelectedObjectIds([...selectedObjectIds]);
      return;
    }

    IntegrationService.UpdateObjectConfiguration(
      syncSettingId,
      inboundSyncEnabled
    )
      .then(() => {
        objectTypes[index] = {
          ...objectTypes[index],
        };
        inboundSyncEnabled
          ? selectedObjectIds.push(objectTypes[index].typeId)
          : selectedObjectIds.splice(
              selectedObjectIds.findIndex(
                (d) => d === objectTypes[index].typeId
              ),
              1
            );

        setSelectedObjectIds([...selectedObjectIds]);

        if (inboundSyncEnabled) {
          getCrmFieldsByObjectType([objectTypes[index].typeId]);
        }
      })
      .catch(() => {
        objectTypes[index] = {
          ...objectTypes[index],
        };
        toast.error("Failed to update status, please try again.");
      })
      .finally(() => {
        setObjectTypes([...objectTypes]);
      });
  }

  function handleFieldMappingUpdate() {
    getFieldMappings();
  }

  function prepareFieldValidationObj(fMappings) {
    const _validationObj = {};

    for (const obj of objectTypes) {
      const configObj =
        configuration.uiConfig.uiproperties.configureobjects.find(
          (d) => d.name?.toLowerCase() === obj.pvlName?.toLowerCase()
        )?.fieldmappings;

      if (configObj) {
        fMappings.forEach((f) => {
          const multipleSelectionObj =
            configObj?.pvlfieldswithmultipletrue?.find(
              (m) => +m.fieldid === +f.pvlFieldId
            );

          _validationObj[f.pvlFieldId] = {
            canBeDeleted:
              !configObj?.pvlidscannotbedeleted?.includes(f.pvlFieldId) ?? true,
            canBeEdited:
              !configObj?.pvlidscannotbeedited?.includes(f.pvlFieldId) ?? true,
            validation:
              configObj?.validations.find(
                (v) => v.pvlfieldtypeid === f.pvlFieldId
              ) ?? null,
            multipleSelection: !!multipleSelectionObj,
            maxSelection: multipleSelectionObj?.maxselect ?? Infinity,
            minSelection: multipleSelectionObj?.minselect ?? null,
          };
        });

        break;
      }
    }

    setFieldValidationObj(_validationObj);
  }

  return isLoading ? (
    <div className="loading-animation">
      <LoadingAnimation />
      <span>Loading data, please wait...</span>
    </div>
  ) : (
    <div id="ConfigureObjects">
      <div className="lato-medium my-3">
        Select objects to be fetched from {toTitleCase(configuration.name)}
      </div>
      <div className="d-flex gap-20">
        {objectTypes.map((object, objectIndex) => (
          <label key={object.typeId} className="CheckboxContainer mb-0 d-block" style={{lineHeight: "18px"}}>
            <input
              type="checkbox"
              name="Status"
              checked={selectedObjectIds.includes(object.typeId)}
              onChange={() => selectDeselectObject(objectIndex)}
            />
            <span className="CheckedMark"></span>
            <span className="ms-4 ps-1 dark-text lato-medium">
              {object.displayName}
            </span>
          </label>
        ))}
      </div>
      <div className="mt-4 mb-3">
        <span>
          <i className="fa-regular fa-left-right six-c-7"></i>
        </span>
        <span className="lato-bold dark-text ms-2">
          Map CRM Fields with SureConnect
        </span>
      </div>
      <div className="table-responsive mb-5 colored-table">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th style={{ minWidth: "215px" }}>SureConnect Field</th>
              <th className="text-center">
                Sync
              </th>
              {objectTypes
                .filter((object) => selectedObjectIds.includes(object.typeId))
                .map((object) => (
                  <th
                    key={object.typeId}
                    style={{
                      minWidth:
                        objectTypes.filter((object) =>
                          selectedObjectIds.includes(object.typeId)
                        ).length > 1
                          ? "250px"
                          : "380px",
                      width: "100%",
                    }}
                  >
                    {toTitleCase(configuration.name)} {object.displayName} Field
                  </th>
                ))}
              <th
                style={{ minWidth: "100px", width: "100%", maxWidth: "100px" }}
              ></th>
            </tr>
          </thead>
          <tbody>
            {fieldMappings.map((mapping, index) => (
              <FieldMappingRow
                key={mapping.pvlFieldId}
                fieldMapping={mapping}
                crmFieldOptions={crmFieldOptions}
                handleFieldMappingUpdate={handleFieldMappingUpdate}
                fieldValidationObj={fieldValidationObj[mapping.pvlFieldId]}
                selectedObjectIds={selectedObjectIds}
              />
            ))}
          </tbody>
        </table>
      </div>
      <div className="d-flex align-items-center gap-2">
        {currentStepIndex > 0 ? (
          <button
            type="button"
            className="btn blue-btn-primary font-12 small-btn go-back-btn"
            onClick={goBack}
          >
            <i class="fa-solid fa-arrow-left me-2"></i>Back
          </button>
        ) : undefined}
        <button
          type="button"
          className="btn blue-btn-primary font-12 small-btn"
          onClick={goToNextStep}
        >
          Next
        </button>
      </div>
    </div>
  );
}
