import {React, useEffect, useState} from 'react';
import './emailverification.component.scss';

const VerificationFailed = ({errorHead,errorDescription,onResent}) => {
  const handleResend = async() => {
    onResent();
  }
    return(
<div className="verify-sucess">

<div className="text-center mb-3">
  <img src={require("../../../assets/img/alert.svg").default} alt="Success" />
  </div>
  
  <div className="text-center font-18 dark-text poppins-semibold mb-2">
  {errorHead}
  </div>
  <p className="text-center font-14 grey-text mw-388 pt-1 poppins-regular">{errorDescription}</p>
  <div className="text-center mt-3">
  <button className="btn blue-btn-primary w-100 mb-3" onClick={handleResend}>Resend Verification Link</button>
  </div>
</div>
    );
};

export {VerificationFailed};