import React from 'react';
import Cookies from 'universal-cookie';
import axios from 'axios';

const cookies = new Cookies();

const setAccessTokenCookie = (accessToken) => {
  cookies.set('accessToken', accessToken, { secure: true, sameSite: 'strict' , path: '/'});
}

const setUserDetails = (userDetails) => {
  localStorage.setItem('PVLUserDetails', userDetails);
}

const getAccessTokenCookie = () => {
  return cookies.get('accessToken');
}

// const setRefreshToken = (refreshToken) => {
//     localStorage.setItem('refreshToken', refreshToken);
// }
const setRefreshToken = (refreshToken) => {
  cookies.set('refreshToken', refreshToken, { secure: true, sameSite: 'strict', path: '/' });
}
const getRefreshToken = () => {
    return cookies.get('refreshToken');
    // return localStorage.getItem('refreshToken');
}

const getUserDetails = () => {
  return localStorage.getItem('PVLUserDetails');
}

const removeAccessTokenCookie = () => {
  cookies.remove('accessToken',{ path: '/' });
}
const removeRefreshToken = () => {
    // localStorage.removeItem('refreshToken');
    cookies.remove('refreshToken',{ path: '/' });
}

const removeUserDetails = () => {
  localStorage.removeItem('PVLUserDetails');
  // cookies.remove('userDetails');
}

const setCurrentCredits = (credits) => {
  localStorage.setItem('PVLCreditsAmount', credits);
}


export {
    setAccessTokenCookie,
    getAccessTokenCookie,
    removeAccessTokenCookie,
    setRefreshToken,
    getRefreshToken,
    removeRefreshToken,
    setUserDetails,
    getUserDetails,
    removeUserDetails,
    setCurrentCredits
  };