import React, { useState } from "react";

const UploadComponent = ({ onFileSelect }) => {
  const [dragOver, setDragOver] = useState(false);

  const handleFileChange = (file) => {
    onFileSelect(file);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    setDragOver(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setDragOver(false);
    const file = event.dataTransfer.files[0];
    if (file) {
      handleFileChange(file);
    }
  };

  return (
    <div className={`upload-boundry-area text-center ${ dragOver ? "drag-over" : ""}`}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <div>
        <div>
          <img src={require("../../../assets/img/upload-icon.svg").default} alt="uploadlogo" />
        </div>
        <div className="font-14 dark-text mt-2 mb-2 lato-medium">
          Drag & Drop a file or click to select
        </div>
        <div className="font-12 light-grey-text">
          .csv file size less than 2MB
        </div>
        <button className="rounded-border-btn mt-3" onClick={() => {
            document.querySelector("input[type=file]").click();
          }}>
          Select file
        </button>
        <input
          type="file"
          accept=".csv"
          style={{ display: "none" }}
          onChange={(event) => handleFileChange(event.target.files[0])}
        />
      </div>
    </div>
  );
};

export default UploadComponent;