import React from "react";

const UploadedComponent = ({file, onDelete}) => {
  let fileName = null;
  let fileSize = null;
  if(file){
    fileName = file.name;
    if(fileName.length > 30){
      fileName = fileName.slice(0,30) + "...csv"
    }
  if (file.size > 1024*1024){
    const number = (file.size/(1024*1024)).toFixed(2)
    fileSize = `${number} MB`;
  }
  else if (file.size > 1024){
    const number = (file.size/1024).toFixed(2)
    fileSize = `${number} KB`;
  }
  else{
    fileSize = `${file.size} Bytes`;
  }
  }
  const handleFileDelete = () => {
    onDelete();
  };
  
    return(
          <div className='uploaded-wrap'>
            <div className='header d-flex justify-content-between align-items-center'>
              <div className='d-flex align-items-center'>
                <i className="fa-solid fa-file-csv green-text font-18"></i>
                <span className='ms-2 font-14 dark-text lato-medium'>{fileName}</span>
                <i className="fa-solid fa-circle font-4 mx-2 light-grey-text"></i>
                <span className='font-14 light-grey-text'>CSV File</span>
                <span className='ms-3 default-text font-10'>{fileSize}</span>
              </div>
              <div onClick={handleFileDelete}>
                <i className='fa-regular fa-trash-alt font-16 pink-text cursor-pointer' ></i>
                {/* <i className='fa-regular fa-trash-alt font-16 pink-text cursor-pointer' data-bs-toggle="modal" data-bs-target="#uploadModal"></i> */}
              </div>
              
            </div>
            
          </div>
    );
}

export default UploadedComponent;