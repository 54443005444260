import React, { useState } from "react";
import ResultsComponent from "../../uploads/analysis.results.component";

const ResultsModal = ({ selectedFile, results,  onFileDelete, analyzeErrorMessage}) => {
    const handleDeleteFile = () => {
        onFileDelete();
    }
  return (
    <>
        <div className="form-steps mb-3">
            <div>Upload CSV</div>
            <div><i className='fa-solid fa-chevron-right'></i></div>
            <div>Map Fields</div>
            <div><i className='fa-solid fa-chevron-right'></i></div>
            <div className='active'>Review</div>
        </div>
        <ResultsComponent file={selectedFile} onDelete={handleDeleteFile} results={results} />
        {analyzeErrorMessage && (
            <p className="font-14 mt-2" style={{ color: 'red' }}>{analyzeErrorMessage}</p>
        )}
    </>
  );
};

export default ResultsModal;