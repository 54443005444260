import { Navigate, useLocation, useOutletContext } from "react-router-dom";
import { IntegrationDetailsRoutes } from "../integration.routes";
import { IntegrationContext } from "../integrations-constants";
import { useState } from "react";

export default function IntegrationDetails() {
  const { integrations, configurations } = useOutletContext();
  const location = useLocation();

  const { integrationType } = location.state || {};
  const [integration, setIntegration] = useState(
    integrations?.[integrationType]
  );
  const [configuration, setConfiguration] = useState(
    configurations?.[integrationType]
  );

  if (!integrationType) {
    return <Navigate to="/integrations" />;
  }

  const routes = IntegrationDetailsRoutes({
    state: location.state,
  });

  return (
    <>
      <IntegrationContext.Provider
        value={{ configuration, integration, setIntegration, setConfiguration }}
      >
        {routes}
      </IntegrationContext.Provider>
    </>
  );
}
