import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import './users.component.scss'
import { NavLink } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import TeamService from "../../../apis/teamservice";
import Sidebar from "../side-bar.component";
import BalanceFilters from "../credits-history/filters/balancefilter.component";
import AddEditUsers from "./add-edit-users.component";
import { RoleNames, UserStatusEnum } from "../../Modals/enum-modal";
import LoadingAnimation from "../lottie-files/loading.lotte";
import { ChangeUserRole, GetAccountMembersDetails, GetPermission, GetUserConnectedAccountDetails, SendJoinAccountInvitation } from "../../../apis/pvlapis";
import PermissionDenied from "../../shared/permission-denied.component";
import UsersComponentFilter from "./filters/user-filter.component";
const UsersComponent = () => {
  
  const roles = ['Admin', 'User', 'Manager', 'Guest'];
  const statuses = ['Active', 'Inactive', 'Pending'];

  const [selectedRoles, setSelectedRoles] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [sortByValue, setSortByValue] = useState(0);


  //State variables for List page settings
  const [users, setUsers] = useState([]);
  const [userFirstName, setUserFirstName] = useState('');
  const [userLastName, setUserLastName] = useState('');
  const [userRoleType, setUserRoleType] = useState(1);
  const [userEmailId, setUserEmailId] = useState('');
  const [userToDelete, setUserToDelete] = useState(null);
  const [userToEdit, setUserToEdit] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isPermissionLoading, setIsPermissionLoading] = useState(false);


  const [nextPage, setNextPage] = useState(false);
  const [previousPage, setPreviousPage] = useState(false);
  const [pageDataCount, setpageDataCount] = useState(10);
  const [searchChange, setSearchChange] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [pageIndex, setPageIndex] = useState(1);
  const [sortByDirection, setSortByDirection] = useState(2);
  const [pageFilters, setPageFilters] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [lowerIndex, setLowerIndex] = useState(0);
  const [upperIndex, setUpperIndex] = useState(0);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showUsersTab, setShowUsersTab] = useState(true);

  //Filters
  const [showDropdown, setShowDropdown] = useState(false);
  const [showEllipseDropdown, setShowEllipseDropdown] = useState(false);
  const [activeDropdownIndex, setActiveDropdownIndex] = useState(null);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [totalCreditsLowerLimit, setTotalCreditsLowerLimit] = useState(null);
  const [totalCreditsUpperLimit, setTotalCreditsUpperLimit] = useState(null);
  const [rolesSelection, setRolesSelection] = useState([]);
  const [statusSelection, setStatusSelection] = useState([]);
  const [typeSelction, setTypeSelection] = useState([]);
  const [modalType, setModalType] = useState('invite');
  const [showUserModal, setShowUserModal] = useState(false);
  const dropdownRef = useRef(null);

  const toggleEllipseDropdown = (index) => {
    if (activeDropdownIndex === index) {
      setActiveDropdownIndex(null);
      setShowEllipseDropdown(false);
    } else {
      setActiveDropdownIndex(index);
      setShowEllipseDropdown(true);
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowEllipseDropdown(false);
      setActiveDropdownIndex(null);
      setShowDropdown(false);
    }
  };

 
  const handleCloseUserModal =() =>{
    setShowUserModal(false);
    setUserEmailId('');
    setUserFirstName('');
    setUserRoleType(1);
    setUserLastName('');
  }

  const editItem = (user) => {
    setShowUserModal(true);
    setUserEmailId(user.useremail);
    setUserFirstName(user.userfirstname);
    setUserLastName(user.userfamilyname);
    setUserRoleType(user.roleid);
    setUserToEdit(user.userid);
    setModalType('Edit');
  };

  const deleteItem = (user) => {
    setShowDeleteModal(true);
    setUserToDelete(user);
  };

  const resendInvitation = async(user) => {
    try {
      const res = await SendJoinAccountInvitation({inviteeEmail: user.useremail, roleType: user.roleid, 
        firstName:user.userfirstname, lastName:user.userfamilyname});
        if(res.statusCode === 200){
          toast.success("Invitation sent successfully.", { position: toast.POSITION.BOTTOM_LEFT });
          await GetAccountMemberDetails();
        }
        else if(res.statusCode === 403){
            toast.error("Invitation already sent. Please ask user to join the account.",{position:toast.POSITION.BOTTOM_LEFT})
        }
        else if(res.statusCode === 431){
            toast.error("Buy additional licenses to invite more users.",{position:toast.POSITION.BOTTOM_LEFT})
        }
        else if(res.statusCode === 432){
            toast.error("Subscribe to invite users.",{position:toast.POSITION.BOTTOM_LEFT})
        }
        else{
            toast.error("Something went wrong. Please try again after some time.",{position:toast.POSITION.BOTTOM_LEFT})
        }
    } catch (error) {
      toast.error("Something went wrong. Please try again after some time.",{position:toast.POSITION.BOTTOM_LEFT})
    }
  };

  const handleClearRoles = (statusId) =>{
    setPageIndex(1);
    let updatedList = []
    //close the dropdown
    setShowDropdown(false);
    //Get the index of the filter
    const index = pageFilters.findIndex((f) => f.fieldName === "roleid");
    if(index !== -1){
      if(statusId){
        //Modify the status selection
        const modifiedStatusSelection = rolesSelection.filter(id => id !== statusId);
        setRolesSelection(modifiedStatusSelection);
          //If the modified status list is length zero remove the index from page filters
          if(modifiedStatusSelection.length ===0){
            updatedList = [...pageFilters];
              updatedList.splice(index, 1);
          }
          //Else modify the filter at the index
          else{
            updatedList = pageFilters.map(filter => {
              if (filter.fieldName === "roleid") {
                  return { ...filter, fieldValue: modifiedStatusSelection };
              }
              else{
                return filter;
              }
            });
          }
        }
      else{
        setRolesSelection([]);
        updatedList = [...pageFilters];
        updatedList.splice(index, 1);
      }
      setPageFilters(updatedList);
    }
  }

  const handleClearStatus = (statusId) =>{
    setPageIndex(1);
    let updatedList = []
    //close the dropdown
    setShowDropdown(false);
    //Get the index of the filter
    const index = pageFilters.findIndex((f) => f.fieldName === "status");
    if(index !== -1){
      if(statusId){
        //Modify the status selection
        const modifiedStatusSelection = statusSelection.filter(id => id !== statusId);
        setStatusSelection(modifiedStatusSelection);
          //If the modified status list is length zero remove the index from page filters
          if(modifiedStatusSelection.length ===0){
            updatedList = [...pageFilters];
              updatedList.splice(index, 1);
          }
          //Else modify the filter at the index
          else{
            updatedList = pageFilters.map(filter => {
              if (filter.fieldName === "status") {
                  return { ...filter, fieldValue: modifiedStatusSelection };
              }
              else{
                return filter;
              }
            });
          }
        }
      else{
        setStatusSelection([]);
        updatedList = [...pageFilters];
        updatedList.splice(index, 1);
      }
      setPageFilters(updatedList);
    }
  }

  const handleCloseDeleteModal = ()=>{
    setShowDeleteModal(false);
    setUserToDelete(null);
  }

  const handleDeleteUser = async()=>{
    try {
      const changeType = 1;
      const response  = await ChangeUserRole(changeType, userToDelete.userid,null);
      if(response.statusCode === 200){
        toast.success("User deleted successfully.", { position: toast.POSITION.BOTTOM_LEFT });
        await GetAccountMemberDetails();
      }
      else if(response.statusCode === 403){
        toast.error("User doesn't have permission to delete.", { position: toast.POSITION.BOTTOM_LEFT });
      }
      else if(response.statusCode === 431){
        toast.error("Buy additional licenses to invite more users.",{position:toast.POSITION.BOTTOM_LEFT});
        await GetAccountMemberDetails();
      }
      else if(response.statusCode === 432){
          toast.error("Subscribe to invite users.",{position:toast.POSITION.BOTTOM_LEFT});
          await GetAccountMemberDetails();
      }
      else if(response.statusCode === 472){
        toast.error("User doesn't have permission to delete.", { position: toast.POSITION.BOTTOM_LEFT });
        await GetAccountMemberDetails();
      }
      else{
        toast.error("Something went wrong. Please try again after some time.",{position:toast.POSITION.BOTTOM_LEFT});
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again after some time.",{position:toast.POSITION.BOTTOM_LEFT});
    }
    finally{
      setUserToDelete(null);
      setShowDeleteModal(false);
    }
  }
  
  const [show, setShow] = useState(false);

    // Handler 
   //All filtering, sorting, searching handlers
    const handleNextPage = () => {

        if(nextPage){
          setPageIndex(pageIndex+1);
          
      }
    }

    const handlePreviousPage = () => {
        if(previousPage){
          setPageIndex(pageIndex-1);
      }
    }

    const handleSorting = (field) => {

      if(sortByDirection === 1){
        setSortByDirection(2);
      }
        else{
        setSortByDirection(1);
      }
        if(field!==-1){

        setSortByValue(field);
      }
      setPageIndex(1);
    }

    const handleSearchKeyDown = (event) => {
      if (event.key === 'Enter') {
        setSearchTerm(searchChange);
        setSearchChange("");
        setPageIndex(1);
      }
    };

    const handleSearchChange = (event) => {
      setSearchChange(event.target.value);
      setSearchTerm(event.target.value);
      setPageIndex(1);
      // api call
    };


    const handleTotalCreditsLowerLimitChange = (lower) => {
      setTotalCreditsLowerLimit(lower);
    };

    const handleTotalCreditsUpperLimitChange = (upper) => {
      setTotalCreditsUpperLimit(upper);
    };

    const handleRolesSelectionChange = (selectedOptions) => {
      setRolesSelection(selectedOptions);
    
      setPageFilters((filter) => {
        // Find the index of the role filter
        const rolesFilterIdx = filter.findIndex((item) => item.fieldName === "roleid");
    
        // If the role filter exists
        if (rolesFilterIdx > -1) {
          // If no roles are selected, remove the role filter
          if (selectedOptions.length === 0) {
            return filter.filter((_, idx) => idx !== rolesFilterIdx);
          } else {
            // Otherwise, update the existing role filter's value
            return filter.map((item, idx) => 
              idx === rolesFilterIdx ? { ...item, fieldValue: selectedOptions } : item
            );
          }
        } else {
          // If the role filter doesn't exist, add it to the filters array
          return [...filter, { fieldName: "roleid", fieldValue: selectedOptions }];
        }
      });
    };
    

    const handleStatusSelectionChange = (selectedOptions) => {
      setStatusSelection(selectedOptions);
    
      setPageFilters((filter) => {
        const statusFilterIdx = filter.findIndex((item) => item.fieldName === "status");
    
        if (statusFilterIdx > -1) {
          if (selectedOptions.length === 0) {
            // Remove the status filter if no statuses are selected
            return filter.filter((_, idx) => idx !== statusFilterIdx);
          } else {
            // Update the existing status filter's value
            return filter.map((item, idx) =>
              idx === statusFilterIdx ? { ...item, fieldValue: selectedOptions } : item
            );
          }
        } else {
          // Add a new status filter if it doesn't exist
          return [...filter, { fieldName: "status", fieldValue: selectedOptions }];
        }
      });
    };
    

    const handleTypeSelectionChange = (selectedOption) => {
      setTypeSelection(selectedOption);
    };

    const handleFilteredUsers = (users) => {
      setFilteredUsers(users);
    }

    const handleRefresh = async() => {
      await GetAccountMemberDetails();
    }

    const handleApplyFilter = (selectedFilter) => {
      setPageIndex(1);
      // Call the handleFilters function with the selected filter option and its corresponding value
      let fieldType = 1
      let fieldValue = []
      if(selectedFilter === 'transactionCredits'){
      fieldType = 0;
        if(totalCreditsLowerLimit === null || totalCreditsUpperLimit === null){
          return;
        }
        if(parseInt(totalCreditsLowerLimit, 10)>parseInt(totalCreditsUpperLimit, 10)){
          return;
        }
      }
      else if(selectedFilter === 'userName'){
        fieldValue = filteredUsers;
      }
      else if(selectedFilter === "roleid"){
        fieldValue = rolesSelection;
      }
      const newFilter = {
        "fieldName": selectedFilter,
        "fieldType": fieldType,
        "fieldMinValue": totalCreditsLowerLimit,
        "fieldMaxValue": totalCreditsUpperLimit,
        "fieldValue": fieldValue
      };
      const index = pageFilters.findIndex((f) => f.fieldName === newFilter.fieldName);
      if (index !== -1) {
        // If filter with same fieldname already exists, replace its fieldvalue
        const updatedFilters = [...pageFilters];
          if (selectedFilter === "transactionStatus" && fieldValue.length ===0){
          updatedFilters.splice(index, 1);
        }
          else if (selectedFilter === "transactionReason" && fieldValue.length ===0){
          updatedFilters.splice(index, 1);
        }
          else if(selectedFilter === "transactionCredits" && (totalCreditsLowerLimit ===null || totalCreditsLowerLimit.length ===0 || totalCreditsUpperLimit === null || totalCreditsUpperLimit.length === 0)){
          updatedFilters.splice(index, 1);
        }
          else{
          updatedFilters[index] = newFilter;
        }
        setPageFilters(updatedFilters);
      }

      else {
        // Add new filter to the pageFilters array
          if(newFilter.fieldName === "transactionStatus" && newFilter.fieldValue.length === 0){
          return;
        }
          if(newFilter.fieldName === "transactionReason" && newFilter.fieldValue.length === 0){
          return;
        }
          else if(selectedFilter === "transactionCredits" && (totalCreditsLowerLimit ===null || totalCreditsLowerLimit.length ===0 || totalCreditsUpperLimit === null || totalCreditsUpperLimit.length === 0)){
          return;
        }
          else{
          setPageFilters([...pageFilters, newFilter]);
        }
      }
      // setSelectedFilter(null);
      // Close the dropdown after applying the filter
      // setShowDropdown(false);
    };

    const handleClearFilters = () => {
      setShowDropdown(false);
      setPageFilters([]);
      setSearchTerm("");
      setSearchChange("");
      setTotalCreditsLowerLimit(null);
      setTotalCreditsUpperLimit(null);
      setRolesSelection([]);
      setTypeSelection([]);
      setPageIndex(1);
    }
    const handleClearTotalRecords = () => {
      setPageIndex(1);
      setShowDropdown(false);
      setTotalCreditsLowerLimit(null);
      setTotalCreditsUpperLimit(null);
      const index = pageFilters.findIndex((f) => f.fieldName === "transactionCredits");
        if(index !== -1){
        let updatedList = [...pageFilters];
        updatedList.splice(index, 1);
        setPageFilters(updatedList);
      }
    }
    const handleClearTransactionType = () => {
      setPageIndex(1);
      setShowDropdown(false);
      //Get the index of the filter
      const index = pageFilters.findIndex((f) => f.fieldName === "transactionReason");
      if(index !== -1){
          setTypeSelection([]);
          let updatedList = [...pageFilters];
          updatedList.splice(index, 1);
        setPageFilters(updatedList);
      }
    }
    const handleUserStatusChange = async(index, user) =>{
      // 0 - invited
      // 1 - active
      // 2 - expired :question_mark:
      // 3 - removed 
      // 4 - disabled
      // user.status = !user.status;
      const changeToStatusEnum = user.status === UserStatusEnum.Disabled ? UserStatusEnum.Active : user.status === UserStatusEnum.Active? UserStatusEnum.Disabled:null;
      if(typeof changeToStatusEnum === 'undefined' || changeToStatusEnum === null){
        if(user.status === 2){
          toast.warn("Invitation expired. Please send the invitation again.",{position:toast.POSITION.BOTTOM_LEFT});
        }
        else if(user.status === 0){
          toast.warn("Invitation sent. Please ask to join the account.",{position:toast.POSITION.BOTTOM_LEFT});
        }
        
        return;
      }
      users[index].status = changeToStatusEnum; 
      setUsers([...users]);
      try {
        const changeType = 2;
        
        const response = await ChangeUserRole(changeType,user.userid, null,changeToStatusEnum);
        if(response.statusCode === 403){
          toast.error("User doesn't have permission to update the status.", { position: toast.POSITION.BOTTOM_LEFT });
          await GetAccountMemberDetails();
        }
        else if(response.statusCode === 200){
          const message = `User ${changeToStatusEnum === UserStatusEnum.Disabled ?'disabled':'enabled'} successfully`
          toast.success(message, { position: toast.POSITION.BOTTOM_LEFT });
          
        }
        else if(response.statusCode === 431){
          toast.error("Buy additional licenses to invite more users.",{position:toast.POSITION.BOTTOM_LEFT});
          await GetAccountMemberDetails();
        }
        else if(response.statusCode === 432){
            toast.error("Subscribe to invite users.",{position:toast.POSITION.BOTTOM_LEFT});
            await GetAccountMemberDetails();
        }
        else  if(response.statusCode === 459){
          toast.error("Invalid operation.", { position: toast.POSITION.BOTTOM_LEFT });
          await GetAccountMemberDetails();
        }
        else if(response.statusCode === 472){
          toast.error("You do not have the Permissions. Please contact the Admin.",{position:toast.POSITION.BOTTOM_LEFT});
          await GetAccountMemberDetails();
        }
        else{
          toast.error("Something went wrong. Please try again after some time.",{position:toast.POSITION.BOTTOM_LEFT});
          await GetAccountMemberDetails();
        }
      } catch (error) {
        await GetAccountMemberDetails();
      }
      finally{

      }
    }
    const handleClearSearchTerm = () => {
      setPageIndex(1);
      setShowDropdown(false);
      setSearchTerm("");
      setSearchChange("");
    }
    const handleClose = () => setShow(false);
    const handleShow = async() => {
      setModalType('Invite');
      setShowUserModal(true);
      await handleGetPermission();
    };
    
    const handleGetPermission = async() =>{
      setIsPermissionLoading(true);

      try {
          const response = await GetPermission();
          if(response.statusCode !==200 || response?.result === null){
              return;
          }
          const inviteuserstoaccount = response?.result?.find(obj => obj?.component === "inviteuserstoaccount");

          setShowUsersTab(inviteuserstoaccount?.permission);
      } catch (error) {
          toast.error("Something went wrong. Please try again after some time.",{position:toast.POSITION.BOTTOM_LEFT})
      }
      finally{
        setIsPermissionLoading(false);
      }
      
  }

    const GetAccountMemberDetails =async()=>{
      setIsLoading(true);
      setNextPage(false);
      setPreviousPage(false);
      try {
          const response = await GetAccountMembersDetails({ pageIndex : pageIndex, pageDataCount: pageDataCount, search:searchTerm,
            sortByValue: 0, sortByDirection: sortByDirection, filters:{pageFilters}});
          if(response === -1){
            return;
          }
          if(response.statusCode===200){
            response.result.data.map(x=>x.editPopup=false);
            setUsers(response.result.data);
            setTotalRecords(response.result.pageDataCount ?? 0);
            const pageIdx = response.result.pageIndex ?? 1;
            setPageIndex(pageIdx);

            setPreviousPage(response.result.pagination["previous"]);
            setNextPage(response.result.pagination["next"]);
            setTotalRecords(response.result.totalRecords);
           
            setUpperIndex((pageIdx-1)*pageDataCount+response.result.data.length);
            if(response.result.data.length === 0){
              setLowerIndex((pageIdx-1)*pageDataCount);
            }
            else{
              setLowerIndex((pageIdx-1)*pageDataCount+1);

            }
          }

      } catch (error) {
          toast.error("Something went wrong. Please try again after some time.",{position:toast.POSITION.BOTTOM_LEFT})
      }
      finally{
        setIsLoading(false);
      }
    }

    
    const rolesMapping = {
      "0": "Admin",
      "1": "Member",
      "2": "Owner",
    }
    const dropEllipsedownRef = useRef(null);
    const toggleButtonRef = useRef(null);

    useEffect(()=>{
      GetAccountMemberDetails();
      // call api 
    },[pageFilters,searchTerm,sortByDirection,pageIndex]);

    useEffect(() => {
      handleGetPermission();
      GetAccountMemberDetails();

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);
    

    return (
        <>
        {showUserModal && <AddEditUsers handleClose={handleCloseUserModal} GetAccountMemberDetails={GetAccountMemberDetails}
                            clicktype={modalType} userFirstName={userFirstName} setUserFirstName={setUserFirstName} 
                            userLastName ={userLastName} setUserLastName={setUserLastName} emailId={userEmailId} 
                            setEmailId={setUserEmailId} selectedRole={userRoleType} setSelectedRole={setUserRoleType} 
                            userId={userToEdit}/>}
           <Container fluid className="main-page-wrap">
                <Row>
                    <Col>
                        <div className="d-flex align-items-center justify-content-between page-header py-3">
                            <span class="font-18 dark-text poppins-semibold">Settings</span>
                        </div>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col sm={3}>
                      <Sidebar />
                    </Col>
                    {!showUsersTab &&!isPermissionLoading &&
                      <Col sm={9}>
                      <div className="profile-card mb-4">
                      <PermissionDenied/>
                      </div>
                      </Col>
                    }
                    {showUsersTab  && !isPermissionLoading &&
                      <Col sm={9}>
                      
                          <div className="profile-card mb-4">
                              <div className="card-title">Users</div>
                              <div className="d-flex align-items-center justify-content-between">
                              <div
                                className="d-flex align-items-center justify-content-between action-items-bar my-3 w-100"
                              >
                                <div className="d-flex">
                                  <div className="form">
                                    <i className="fa-regular fa-search"></i>
                                    <input
                                      type="text"
                                      className="form-control form-input"
                                      placeholder="Search"
                                      value={searchChange}
                                      onChange={handleSearchChange}
                                      onKeyDown={handleSearchKeyDown}
                                      data-tooltip-content="Search by user name" 
                                      data-tooltip-id="listtips"
                                    />
                                  </div>
                                  <div className="list-widget-relative"ref={dropdownRef}>
                                    <div className="widget-icon ms-2 cursor-pointer" data-tooltip-content="Add filters" data-tooltip-id="listtips" onClick={() => setShowDropdown(!showDropdown)}>
                                      <i className={`${pageFilters.length > 0 ? "fa-solid" : "fa-regular"} fa-filter`} style={pageFilters.length > 0 ? {color: "#5C3CE4"} : {}}></i>
                                    </div>
                                    {showDropdown && (
                                      <div className="dropdown">
                                        <ul style={{ listStyleType: 'none', padding: 0, textAlign: 'left' }}>
                                          <li><UsersComponentFilter onLowerChange={handleTotalCreditsLowerLimitChange}
                                            onUpperChange={handleTotalCreditsUpperLimitChange} onRolesChange={handleRolesSelectionChange} onStatusChange={handleStatusSelectionChange} applyFilter={handleApplyFilter}
                                            lower={totalCreditsLowerLimit} upper={totalCreditsUpperLimit} status={statusSelection} roles = {rolesSelection}
                                            onTotalClear={handleClearTotalRecords}  transactionType={0} 
                                            type={typeSelction} onTypeClear={handleClearTransactionType} onTypeChange={handleTypeSelectionChange}/></li>
                                        </ul>
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className="d-flex align-items-center">
                                  <div className="widget-icon cursor-pointer" onClick={() => handleSorting(-1)}>
                                {sortByDirection === 2? (<i className="fa-regular fa-arrow-down-wide-short" data-tooltip-content="Sort by Ascending" data-tooltip-id="listtips"></i>)
                                :(<i className="fa-regular fa-arrow-up-wide-short" data-tooltip-content="Sort by Descending" data-tooltip-id="listtips"></i>)}
                                  </div>
                                  <div className="widget-icon ms-2 cursor-pointer" data-tooltip-content="Refresh" data-tooltip-id="listtips"
                                  onClick={handleRefresh} >
                                    <i className="fa-regular fa-arrow-rotate-right"></i>
                                  </div>
                                  <div className="mx-2 grey-text font-14 d-flex align-items-center pagination">
                                    <span>{lowerIndex} - {upperIndex} of {totalRecords}</span>
                                    <div className={`widget-icon ms-2 w-auto text-center ${!previousPage ? 'disabled' : 'cursor-pointer'}`} onClick={!previousPage ? null : handlePreviousPage}>
                                      <div className="prev-btn" data-tooltip-content="Previous Page" data-tooltip-id="listtips">
                                        <i className="far fa-chevron-left" ></i>
                                      </div>
                                    </div>
                                    <div className={`widget-icon ms-2 w-auto text-center ${!nextPage ? 'disabled' : 'cursor-pointer'}`} onClick={!nextPage ? null : handleNextPage}>
                                      <div className="next-btn" data-tooltip-content="Next Page" data-tooltip-id="listtips">
                                        <i className="far fa-chevron-right"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                                  <div><button className="btn blue-btn-primary small-btn font-12 min-w-135" onClick={handleShow}><i className="fa-solid fa-plus me-2"></i>Invite User</button></div>
                                  {/* <div><button className="btn blue-btn-primary small-btn font-12" onClick={sendInvite}><i className="fa-solid fa-plus me-2"></i>Send Invite User</button></div>
                                  <span>{invitationLink}</span> */}
                              </div>
                              {(pageFilters.length>0 || searchTerm) && (
                              <p className="search-response">
                                {searchTerm && (<span className="filter-tag"> Search By <i>{searchTerm}</i><i className="fa-regular fa-times ms-1 cursor-pointer" onClick={handleClearSearchTerm}></i></span>)}
                              {pageFilters.map((filter, index) => (
                                <span key={index}>
                                  {filter.fieldName === "userName" && (
                                    <span className="filter-tag"> Uploaded By&nbsp; {" "}
                                    {filter.fieldValue.map((value, valueIndex) => (
                                            <span key={valueIndex}>
                                              {value}
                                            </span>
                                          ))}
                                    </span>)
                                  }

                                  {filter.fieldName === "roleid" && (
                                    <span className="filter-tag">Roles is{" "}
                                      {filter.fieldValue.map((value, valueIndex) => (
                                        <span key={valueIndex}>
                                          <span className="dark-text lato-bold applied-filter-clr">{value}&nbsp;</span>
                                          <span><i className="fa-regular fa-times ms-1 cursor-pointer" onClick={() => handleClearRoles(value)}></i></span>
                                          &nbsp;
                                        </span>
                                      ))}
                                    </span>)}
                                    {filter.fieldName === "status" && (
                                    <span className="filter-tag">Status is{" "}
                                      {filter.fieldValue.map((value, valueIndex) => (
                                        <span key={valueIndex}>
                                          <span className="dark-text lato-bold applied-filter-clr">{value}&nbsp;</span>
                                          <span><i className="fa-regular fa-times ms-1 cursor-pointer" onClick={() => handleClearStatus(value)}></i></span>
                                          &nbsp;
                                        </span>
                                      ))}
                                    </span>)}
                                  {filter.fieldName === "transactionCredits" && (<span className="filter-tag"> Contacts between <i>{filter.fieldMinValue}</i> To <i>{filter.fieldMaxValue}</i><i className="fa-regular fa-times ms-1 cursor-pointer" onClick={handleClearTotalRecords}></i></span>)}
                                      </span>
                                  ))}
                                    <span className="clear-all primary-blue-text poppins-medium" onClick={handleClearFilters}> Clear All</span>
                                  </p>
                                )}
                              {!isLoading &&<div className="table-responsive users-table">
                                  <table className="table pb-0 mb-0 default-text">
                                      <thead>
                                          <tr>
                                              <th>Users</th>
                                              <th>Email ID</th>
                                              <th>Role</th>
                                              <th>User Status</th>
                                              <th></th>
                                          </tr>
                                      </thead>
                                      <tbody>
                                        {users.map((user, index) => (
                                          <tr key={user.userid}>
                                            <td>
                                              <div className="d-flex align-items-center">
                                                <img
                                                  src={user.displaypicture || 'https://dev-app.phoneverify.ai/static/media/user-dummy.86a907ed9f0eae93171ab1e826ecfef5.svg'}
                                                  width="32"
                                                  height="32"
                                                  className="rounded-circle"
                                                  alt=""
                                                />
                                                <span className="lato-medium font-14 dark-text ms-2">{user.userfullname || 'N/A'}</span>
                                              </div>
                                            </td>
                                            <td>{user.useremail}</td>
                                            <td>{RoleNames[user.roleid] || 'Unknown'}</td>
                                            <td>
                                              {
                                              user.status !== 0 && user.status !== 2 && <div className="custom-toggle">
                                              <div 
                                                className="tooltip-wrapper" 
                                                title={user.roleid === 3 ? 'Disabled for this role' : ''}
                                                style={user.roleid === 3 ? { cursor: 'default' } : {}}
                                              >
                                                <input
                                                  type="checkbox"
                                                  role="switch"
                                                  id={`flexSwitchCheckChecked-${user.userid}`}
                                                  disabled={user.roleid === 3}
                                                  checked={user.status === 1}
                                                  onChange={() => handleUserStatusChange(index, user)}
                                                  style={user.roleid === 3 ? { pointerEvents: 'none', opacity: 0.6, cursor: 'default' } : {}}
                                                />
                                                <label 
                                                  htmlFor={`flexSwitchCheckChecked-${user.userid}`} 
                                                  style={user.roleid === 3 ? { cursor: 'default' } : {}}
                                                ></label>
                                              </div>         
                                              
                                              </div>
                                              
                                              }
                                              {
                                                user.status === 0 &&
                                                <div>
                                                  Invite Pending
                                                </div>
                                              }
                                              {
                                                user.status === 2 &&
                                                <div>
                                                  Invite Expired
                                                </div>
                                              }
                                            </td>
                                          
                                            <td className="text-end">
                                               {user.roleid !== 3 && 
                                              <>
                                             
                                              <div className="threedot"
                                                onClick={() => toggleEllipseDropdown(index)}
                                                style={{ cursor: 'pointer' }}
                                              >
                                                <i className="fa fa-ellipsis-v"></i>
                                              </div>
                                              {showEllipseDropdown && activeDropdownIndex === index && (
                                                <ul
                                                  ref={dropdownRef}
                                                  className="dropdown-menu dropdown-menu-end show"
                                                  style={{
                                                    display: 'block',
                                                    position: 'absolute',
                                                    right: '40px',
                                                    marginTop: '0.5rem',
                                                    width: '100px',
                                                    padding: '0',
                                                    borderColor:'#F0F1F3',
                                                    boxShadow:'0px 4px 20px 0px #00000014'
                                                  }}
                                                >
                                                  <li>
                                                    <button
                                                      className="dropdown-item font-14"
                                                      onClick={() => editItem(user)}
                                                    >
                                                      Edit User
                                                    </button>
                                                  </li>
                                                  <li>
                                                    <button
                                                      className="dropdown-item font-14"
                                                      style={{color:'#D83D32'}}
                                                      onClick={() => deleteItem(user)}
                                                    >
                                                      Delete User
                                                    </button>
                                                  </li>
                                                  {user.status === 2 && <li>
                                                    <button
                                                      className="dropdown-item font-14"
                                                      style={{color:'#008000'}}
                                                      onClick={() => resendInvitation(user)}
                                                    >
                                                      Resend Invitation
                                                    </button>
                                                  </li>}
                                                </ul>
                                              )}
                                              </>
                                              }
                                              
                                            </td>
                                            
                                          </tr>
                                        ))}
                                      </tbody>
                                  </table>
                              </div>
                            }
                            {
                              isLoading && (
                              <div className="loading-animation">
                                  <LoadingAnimation/>
                                  <span>
                                  Loading data, please wait...
                                  </span>
                              </div>
                              )
                          }
                          </div>
                      </Col>
                    }
                    {
                      isPermissionLoading && (
                      <div className="loading-animation">
                          <LoadingAnimation/>
                          <span>
                          Loading data, please wait...
                          </span>
                      </div>
                      )
                    }
                </Row>
            </Container>

            {/* Delete user cancellation  */}
            <Modal show={showDeleteModal} onHide={handleCloseDeleteModal} className='modal-no-border'>
                <Modal.Header closeButton>
                    <Modal.Title><span className="round-modal-icon orange">
                        <i className="fa-regular fa-circle-exclamation"></i>
                    </span>{" "}
                    Delete {userToDelete?.userfirstname || 'Unknow'} {userToDelete?.userfamilyname || 'Unknown'}?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>Are you sure you want to remove this user? This action is irreversible and will immediately revoke the user's access to the account.</div>
                </Modal.Body>

                <Modal.Footer className="no-shadow pt-0">
                    <div className="text-end w-100">
                    <Button  className="btn btn-cancel mr-15px" variant="secondary"  onClick={handleCloseDeleteModal}>
                            Do not Delete
                        </Button>
                        <Button className="btn red-btn small-btn font-12 position-relative" variant="primary" onClick={handleDeleteUser} >
                            Yes, Proceed
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default UsersComponent;