import { useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import { Routes, Route } from "react-router-dom";

//Import all the pages components here
import HomeApp from "./components/app/homeapp.component";
import ListApp from "./components/app/listapp.component";
import Login from "./components/app/login/login.component";
import Signup from "./components/app/login/signup.component";
import NoPage from "./components/nopage/NoPage";
// import Help from './components/app/sidebar-menu/Help';
import AuthGuard from "./components/privateAuth/privateRoute";
import ProfileApp from "./components/app/profileapp.component";
import CreditsHistoryApp from "./components/app/credits-historyapp.component";
import HelpApp from "./components/app/helpapp.component";
// import EmailVerificationPage from './components/app/emailvalidate/emailverification.component';
import ResetPassword from './components/app/forgot-password/resetpassword.component';
import HeaderComponent from './components/app/header/header.component';
import BillingApp from './components/app/billingapp.component';
import InvoiceApp from './components/app/invoiceapp.component';
import PublicAPIApp from './components/app/publicapiapp.component';
import SubscriptionApp from './components/app/subscriptionapp.component'
import { Crisp } from 'crisp-sdk-web';
import UsersApp from "./components/app/usersapp.component";
import Invitation from "./components/app/invitation.component";
import { IntegrationRoutes } from "./components/app/base-content/Integrations/integration.routes";
import OAuthResultComponent from "./components/app/oauth-result.component";

function App() {
  useEffect(() => {
    const userDetails= localStorage.getItem('PVLUserDetails');
    window.addEventListener("storage",(event)=>{
      if(event.key==='PVLUserDetails'){
        const oldUserDetails = JSON.parse(event.oldValue);
        const newUserDetails = JSON.parse(event.newValue);
        if(oldUserDetails?.accountid !== newUserDetails?.accountid){
          window.location.reload();
        }
      }
    })
    Crisp.configure("121c4278-3882-4e35-8553-1696b43729ab", {
      autoload: false,
    });
  }, []);

  return (
    <Router>
      <div className="App">
        <Routes>
          {/* Create a path for each component.
          The path is the URL that will be used to access the page.
          We use AuthGuard which checks for availability of refresh token before accessing the page.AuthGuard is used for internal pages of the portal(Once users signs in) */}
          <Route path='/verify-email/:token' element={<Signup />} />
          <Route path='/success/:token' element={<AuthGuard><HomeApp /></AuthGuard>} />
          <Route path='/cancel/:token' element={<AuthGuard><HomeApp /></AuthGuard>} />
          <Route path='/reset-password/:token' element={<ResetPassword />} />
          <Route path='/forgot-password' element={<ResetPassword />} />
          <Route path='/invitation/:token' element={<Login />} />
          <Route path='/' element={<Login />} />
          <Route path='/signup' element={<Signup />} />
          <Route path='/home' element={<AuthGuard><HomeApp /></AuthGuard>} />
          <Route path='/list' element={<AuthGuard><ListApp /></AuthGuard>} />
          <Route path='/help' element={<AuthGuard><HelpApp /></AuthGuard>} />
          <Route path='/profile' element={<AuthGuard><ProfileApp /></AuthGuard>} />
          <Route path='/credits-history' element={<AuthGuard><CreditsHistoryApp /></AuthGuard>} />
          <Route path='/billing' element={<AuthGuard><BillingApp /></AuthGuard>} />
          <Route path='/invoice' element={<AuthGuard><InvoiceApp /></AuthGuard>} />
          <Route path='/api' element={<AuthGuard><PublicAPIApp /></AuthGuard>} />
          <Route
            path="/integrations/*"
            element={
              <AuthGuard>
                <IntegrationRoutes />
              </AuthGuard>
            }
          />
          <Route path="/integrationsconnect/result" element={<AuthGuard><OAuthResultComponent /></AuthGuard>} />
          <Route path='/subscription' element={<AuthGuard><SubscriptionApp /></AuthGuard>} />
          <Route path='/users' element={<AuthGuard><UsersApp /></AuthGuard>} />
          <Route path='/joinaccount/:token' element={<Invitation />} />
          <Route path="*" element={<NoPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
