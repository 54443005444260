import React, { useState } from 'react';
import { Link } from 'react-router-dom';
// import './paymentsuccess.scss'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const AddressForm = ({address,onSave,onClose}) => {
    if(!address){
        address = {
            'line1': null,
            'line2': null,
            'city': null,
            'country': null,
            'state': null,
            'postal_code': null
        }
    }
    const [companyName, setCompanyName] = useState(address['line1']);
    const [addressLine, setAddressLine] = useState(address['line2']);
    const [cityName, setCityName] = useState(address['city']);
    const [stateName, setStateName] = useState(address['state']);
    const [countryName, setCountryName] = useState(address['country']);
    const [pinCode, setPinCode] = useState(address['postal_code']);
    const handleCloseModal = () => {
        onClose();
    }
    const handleCompanyNameChange = (event) => {
        setCompanyName(event.target.value);
    }
    const handleStateNameChange = (event) => {
        setStateName(event.target.value);
    }
    const handleCountryNameChange = (event) => {
        setCountryName(event.target.value);
    }
    const handlePincodeChange = (event) => {
        setPinCode(event.target.value);
    }
    const handleAddressLineChange = (event) => {
        setAddressLine(event.target.value);
    }
    const handleCityNameChange = (event) => {
        setCityName(event.target.value);
    }
    
    const handleSaveAddress = () => {
        const newAddress = {
            'line1': companyName,
            'line2': addressLine,
            'city': cityName,
            'country': countryName,
            'state': stateName,
            'postal_code': pinCode
        }
        onSave(newAddress);
    }

  return(
    <>
      <Modal show={true} onHide={handleCloseModal}className='modal-no-border'>
        <Modal.Header className="modal-header">
          <Modal.Title><span className="round-modal-icon grey">
            <i className="fa-regular fa-edit"></i>
          </span>{" "}
            Edit Billing Address</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group mb-3">
            <label className='mb-1' htmlFor="Full Name">Company Name</label>
            <input
             type="text" 
             className="form-control"
             placeholder="Enter Name" 
             value={companyName}
             onChange={handleCompanyNameChange}
             required/>
             {/* {invalidInviteeUserName && (
             <div className=" font-14  lato-regular text_error_message">
                    {invalidInviteeUserName}
              </div>
              )} */}
          </div>
          <div className="form-group mb-3">
            <label className='mb-1' htmlFor="Full Name">Address</label>
            <input 
            type="text" 
            className="form-control"
            placeholder="Enter Address" 
            value={addressLine}
            onChange={handleAddressLineChange}
            required/>
            {/* {invalidInviteeEmail && (
             <div className=" font-14  lato-regular text_error_message">
                    {invalidInviteeEmail}
              </div>
              )} */}
          </div>
          <div className="form-group mb-3 d-flex justify-content-between inline">
            <div>
            <label className='mb-1' htmlFor="Full Name">City</label>
            <input 
            type="text" 
            className="form-control"
            placeholder="Enter City" 
            value={cityName}
            onChange={handleCityNameChange}
            required/>
            {/* {invalidInviteeEmail && (
             <div className=" font-14  lato-regular text_error_message">
                    {invalidInviteeEmail}
              </div>
              )} */}
            </div>
          <div className="">
            <label className='mb-1' htmlFor="Full Name">State</label>
            <input 
            type="text" 
            className="form-control"
            placeholder="Enter State" 
            value={stateName}
            onChange={handleStateNameChange}
            required/>
            {/* {invalidInviteeEmail && (
             <div className=" font-14  lato-regular text_error_message">
                    {invalidInviteeEmail}
              </div>
              )} */}
          </div>
          </div>
          <div className="form-group d-flex justify-content-between inline">
            <div>
            <label className='mb-1' htmlFor="Full Name">Country</label>
            <input 
            type="text" 
            className="form-control"
            placeholder="Enter Country" 
            value={countryName}
            onChange={handleCountryNameChange}
            required/>
            {/* {invalidInviteeEmail && (
             <div className=" font-14  lato-regular text_error_message">
                    {invalidInviteeEmail}
              </div>
              )} */}
          </div>
          <div className="form-group">
            <label className='mb-1' htmlFor="Full Name">Pincode</label>
            <input 
            type="text" 
            className="form-control"
            placeholder="Enter Pincode" 
            value={pinCode}
            onChange={handlePincodeChange}
            required/>
            {/* {invalidInviteeEmail && (
             <div className=" font-14  lato-regular text_error_message">
                    {invalidInviteeEmail}
              </div>
              )} */}
          </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="text-end w-100">
            <Button className="btn btn-cancel mr-15px" variant="secondary" onClick={handleCloseModal}>
              Cancel
            </Button>
            <Button className="btn blue-btn-primary font-12" variant="primary" onClick={handleSaveAddress}>
              Save
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

            
    </>

  );
};

export { AddressForm };