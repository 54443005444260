import React, { useState, useEffect, useReducer } from 'react';
import './home.component.scss';
import axios from "axios";
import { Button, Modal } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
// import { Modal } from "bootstrap";
import Papa from 'papaparse';
// import { bootstrap } from "bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { saveAs } from 'file-saver';
import { useCookies } from 'react-cookie';
import { Tooltip } from "react-tooltip";
import 'react-tooltip/dist/react-tooltip.css'

import csvFile from "../../../../assets/csv/Sample.csv";
import excelFile from "../../../../assets/csv/guide.xlsx";

import CardComponent from "./card/card.component";
import UploadComponent from '../../uploads/upload.component';
import UploadedComponent from '../../uploads/uploaded.component';
import AnalyzingComponent from '../../uploads/analyzing.component';
import ResultsComponent from '../../uploads/analysis.results.component';
import UploadModal from '../modals/uploadmodal.component';
import UploadedModal from '../modals/uploadedmodal.component';
import MappingModal from '../modals/mappingmodal.component';
import AnalyzingModal from '../modals/analyzingmodal.component';
import ResultsModal from '../modals/resultsmodal.component';
import BeforeAnalyzeMessageModal from '../modals/beforeanalyzemodal.component';
import { async } from 'q';
//Import the required helper functions from pvlapis
import { regenerateTokens, userLogout, getPaymentStatus, getCurrentCredits, downloadValidatedCSV, getTierDetails } from '../../../apis/pvlapis';
import { getAccessTokenCookie } from '../../../apis/tokenhandler';
import LoadingAnimation from '../lottie-files/loading.lotte';
import { useParams } from 'react-router-dom';
import { PaymentSuccess } from '../billing/modals/paymentsuccess.component';
import { PaymentFailed } from '../billing/modals/paymentfailed.component';
import { PaymentChecking } from '../billing/modals/paymentchecking.component';
import BuySubscriptionOrCredits from '../billing/buycredits.component';
import { Mixpanel } from '../../../configs/mixPanel';
import { Crisp } from 'crisp-sdk-web';
//For Mapping
import { automapColumns, getAppFields, addMappedColumns, getColumnMappingforBackend } from '../../../apis/mappingHandler';
import TeamService from '../../../apis/teamservice';
//API Endpoints
const getProjectUrl = process.env.REACT_APP_PVL_APP_URL+"/pvl/get_user_account_projects_details";
const validateDataUrl = process.env.REACT_APP_PVL_APP_URL+"/pvl/upload_and_validate_prospect_data";
const executeProjectUrl = process.env.REACT_APP_PVL_APP_URL+"/pvl/request_project_execution";
const apiKey = process.env.REACT_APP_PVL_API_KEY;


const HomeComponent = () => {
  //Get user details from the local storage
  const userDetails = JSON.parse(localStorage.getItem('PVLUserDetails'));
  let userId = null;
  let userAccountName = null;
  if(userDetails){
    userId = userDetails.userid;
    userAccountName = userDetails.accountname || 'Unknown';
  }
  //App fields to be shown in the UI
  const appFields = getAppFields();
  //tier details
  const tiers = getTierDetails();
  const tierDetails = tiers["tierArray"];
  //to get the projects
  const [projects, setProjects] = useState([]);
  //to store the selected file
  const [selectedFile, setSelectedFile] = useState(null);
  //to store the mapping of the selected file
  const [columnMapping, setColumnMapping] = useState({});
  const [csvColumns, setCsvColumns] = useState([]);
  //to store the selected file data
  const [selectedFileData, setSelectedFileData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  //to store if the csv file is valid or not
  const [validCsvData, setValidCsvData] = useState(false);
  //to disable the validate button if there are any problems with the file
  const [disableValidateButton,setDisableValidateButton] = useState(true);
  const [disableMappingButton, setDisableMappingButton] = useState(true);
  //to store the error message to be shown if we have any errors in the data
  const [dataErrorMessage,setDataErrorMessage] = useState(null);
  const [mappingErrorMessage, setMappingErrorMessage] = useState(null);
  const [mappingWarningMessage, setMappingWarningMessage] = useState(null);
  const [dataWarningMessage, setDataWarningMessage] = useState(null);
  const [projectNameErrorMessage, setProjectNameErrorMessage] = useState(null);
  const [disableAnalyzeButton, setDisableAnalyzeButton] = useState(false);
  const [analyzeErrorMessage, setAnalyzeErrorMessage] = useState(null);
  //to store the project name
  const [projectName,setProjectName] = useState(null);
  //to store the validation results
  const [validationResults,setValidationResults] = useState(null);
  //to store the project id
  const [projectId, setProjectId] = useState(null);

  const [openUploadModal,setOpenUploadModal] = useState(false);
  const [openUploadedModal,setOpenUploadedModal] = useState(false);
  const [openMappingModal, setOpenMappingModal] = useState(false);
  const [openResultsModal,setOpenResultsModal] = useState(false);
  const [openAnlaysisModal,setOpenAnlaysisModal] = useState(false);
  const [stopProject, setStopProject] = useState(false);

  //to store the file uploaded or not
  const [isFileUploadOpen,setisFileUploadOpen] = useState(false);
  const [isUploadedOpen,setisUploadedOpen] = useState(false);
  const [isResultsOpen,setisResultsOpen] = useState(false);
  const [isAnalyzeOpen,setisAnalyzeOpen] = useState(false);
  const [isMappingOpen,setisMappingOpen] = useState(false);
  const [isBeforeAnalyzeMessageOpen,setisBeforeAnalyzeMessageOpen] = useState(false);

  //State variables for List page settings
  const [pageDataCount, setpageDataCount] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [pageIndex, setPageIndex] = useState(1);
  const [sortByValue, setSortByValue] = useState(3);
  const [sortByDirection, setSortByDirection] = useState(2);
  const [pageFilters, setPageFilters] = useState([]);
  //Success and cancel pages
  const [openSuccessPage, setOpenSuccessPage] = useState(false);
  const [openCancelPage, setOpenCancelPage] = useState(false);
  const [creditsAmount, setCreditsAmount] = useState(null);
  // const [currentCreditsAmount, setCurrentCreditsAmount] = useState(0);
  const [paymentMessage, setPaymentMessage] = useState(null);
  const [paymentDescription, setPaymentDescription] = useState(null);
  const [openBuyCredits, setOpenBuyCredits] = useState(null);
  const [checkoutType,setCheckoutType] = useState('');
  const [openPaymentChecking, setPaymentChecking] = useState(false);
  const [paymentType,setPaymentType] = useState(null);
  const [subscriptionStatus,setSubscriptionStatus] =useState(null);
  const [subscriptionFlag,setSubscriptionFlag] =useState(null);

  //set the token from the url
  const { token } = useParams();
  const stripeKey = process.env.REACT_APP_PVL_STRIPE_SECRET_KEY;
  const navigate = useNavigate();
  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  const downloadSampleCsv = async() => {
  Papa.parse(csvFile, {
    download: true,
    complete: function (results) {
      // Access the parsed CSV data
      const records = results.data;
      // Convert the CSV data to a Blob
      const csvData = Papa.unparse(records);
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });

      // Save the Blob as a file
      saveAs(blob, 'Sample.csv');
    },
  });
  };
  const downloadSampleExcel = async () => {
    //This function is used to download the Guide excel file that contains CSV instructions
    const response = await fetch(excelFile);
    const data = await response.blob();
    //Download the file with a name
    saveAs(data, 'Guide_To_Sample_Csv.xlsx');
  };

  const handleDownloadValidatedCSV = async(id) => {
    //Tract the download validated csv event.
    Mixpanel.track("Download Validated CSV button clicked");
    //Call the download validated CSV function with projectid
    await downloadValidatedCSV(id);
  }

  const handleGetCredits = async() => {
    return new Promise(async(resolve,reject)=>
    {    try{
      //Get the active credits of the user 
      const response = await getCurrentCredits();
      if(response.data.statusCode === 200){
        // setCurrentCreditsAmount(response.data.result['current_credits']);
        setSubscriptionFlag(response.data.result.subscription_details.flag_subscription_present);
        if(response.data.result.subscription_details.flag_subscription_present ){
          setSubscriptionStatus(response.data.result.subscription_details.subscription_status);
        }
        //   setPaymentType(response.data.result.subscription_details.flag_subscription_present);
        // resolve(response.data.result['current_credits']);
        resolve(response.data.result['credit_details']['credit_balance']);
      }
      else{
        toast.error("Could not load your credits. Please try again.",{position: toast.POSITION.BOTTOM_LEFT});
        reject (null);
      }
    }
    catch{
      //Show an error toast
      toast.error("Something went wrong. Please try again after some time.",{position: toast.POSITION.BOTTOM_LEFT});
      reject (null);
    }})

  }
  const location = useLocation();
  const[showJoinedModel,setShowJoinedModel] = useState(!!location.state?.showJoinedModel);
  const [showAlreadyJoinedModel, setShowAlreadyJoinedModel] = useState(!!location.state?.showAlreadyJoinedModel);
  const [showUserNotAllowedToJoinModal, setShowUserNotAllowedToJoinModal] = useState(!!location.state?.showUserNotAllowedToJoinModal);
  
  if(!!location.state?.showJoinedModel){
    navigate('/home');
  }
  const handleCloseModal = () => {
    setShowJoinedModel(false);
    setShowAlreadyJoinedModel(false);
    setShowUserNotAllowedToJoinModal(false);
}

  const resetProjectDetails = () => {
    //Reset all the project related state variables. This is done when a upload is cancelled or project has been executed.
    setProjectName(null);
    setSelectedFile(null);
    setSelectedFileData(null);
    setDataErrorMessage(null);
    setDataWarningMessage(null);
    setValidCsvData(false);
    setDisableValidateButton(true);
    setDisableMappingButton(true);
    setValidationResults(null);
    setProjectId(null);
    setDisableAnalyzeButton(false);
    setAnalyzeErrorMessage(null);
    setProjectNameErrorMessage(null);
    setOpenUploadModal(false);
    setOpenUploadedModal(false);
    setOpenResultsModal(false);
    setOpenMappingModal(false);
    setOpenAnlaysisModal(false);
    setisUploadedOpen(false);
    setisAnalyzeOpen(false);
    setisResultsOpen(false);
    setisBeforeAnalyzeMessageOpen(false);
  }
  const sendUploadClickedDatatoCrisp = async() => {
    Crisp.session.setSegments(['UploadClicked']);
  }
  const handleOpenUpload = () => {
    if(!openUploadedModal){
      Mixpanel.track("Add list button clicked");
    }
    //Open the Upload modal
    resetProjectDetails();
    setOpenUploadModal(true);
    sendUploadClickedDatatoCrisp();
  }
  const handleOpenUploaded = () => {
    //Open the Uploaded modal
    setOpenMappingModal(false);
    setisMappingOpen(false);
    setisUploadedOpen(true);
    setOpenUploadedModal(true);
  }
  const handleOpenMapping = () => {
    setisResultsOpen(false);
    setOpenResultsModal(false);
    setisAnalyzeOpen(false);
    setOpenAnlaysisModal(false);
    setisUploadedOpen(false);
    setOpenUploadedModal(false);
    setOpenMappingModal(true);
    setisMappingOpen(true);
  }
  const handleOpenBeforeAnalyze = () => {
    // When user clicks on start verification button
    setisResultsOpen(false);
    setOpenResultsModal(false);
    setisAnalyzeOpen(false);
    setOpenAnlaysisModal(false);
    setisUploadedOpen(false);
    setOpenUploadedModal(false);
    setOpenMappingModal(false);
    setisMappingOpen(false);
    setisBeforeAnalyzeMessageOpen(true);
  }

  const handleBackToOpenResult = () => {
    setisResultsOpen(true);
    setOpenResultsModal(true);
    setisBeforeAnalyzeMessageOpen(false);
    setisAnalyzeOpen(false);
    setOpenAnlaysisModal(false);
    setisUploadedOpen(false);
    setOpenUploadedModal(false);
    setOpenMappingModal(false);
    setisMappingOpen(false);
  }


  const sendAnalyzeClickedDatatoCrisp = async() => {
    Crisp.session.setSegments(['AnalyzeClicked']);
  }
  //function to handle the analyze button click
  const HandleAnalyze = async () => {
    setDisableAnalyzeButton(true);
    Mixpanel.track("Analyze list button clicked");
    try {
      const accessToken = getAccessTokenCookie();
      const config = {
        
        headers: { 
          'Accept': 'application/json',
          Authorization: `Bearer ${accessToken}`,
          'X-API-Key': apiKey
        }
      };
      const payload = {
        projectId: projectId, 
      };
      //Call the execute project endpoint
      const response = await axios.post(executeProjectUrl, payload,config);
      //Execution successful
      if (response.data.statusCode === 200){
        toast.success("Your list is added to the queue. Waiting for Admin approval.",{position: toast.POSITION.BOTTOM_LEFT});
        sendAnalyzeClickedDatatoCrisp();
        await sleep(4000);
        //Reload the page
        window.location.reload();

        // window.location.href = "/list"
        // getProjects();
      }
      //Failed because of insufficient credits
      else if (response.data.statusCode === 423){
        toast.error("Insufficient Credits! Please add more credits to execute the list.",{position: toast.POSITION.BOTTOM_LEFT});
        await sleep(4000);
        // window.location.href = "/list"
      }
      //Failed because of expired token
      else if(response.data.statusCode === 457){
        //Regenerate access token
        const status =  await regenerateTokens();
        if(status === 200){
          await HandleAnalyze();
        }
        else{
          //Logout if regeneration failed
          await userLogout();
          navigate("/");
        }
      }
      //Failed because of incorrect token. Log out
      else if(response.data.statusCode === 455){
        await userLogout();
        navigate("/");
      }
      //Failed because of some third party errors. Retry
      else if (response.data.statusCode === 453){
        sleep(4000);
        await HandleAnalyze();
      }
      //Unknown failed reason. Log the error
      else{
        toast.error("Something went wrong. Please try again.",{position: toast.POSITION.BOTTOM_LEFT})
        sleep(4000);
        resetProjectDetails();
      }
      // navigate('/list');
      
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong. Please try again after some time.",{position: toast.POSITION.BOTTOM_LEFT})
      sleep(4000);
      resetProjectDetails();
  }
  finally{
    //Reset the project details
    resetProjectDetails();
  };

  }
  //Whenever a user enter the project name we come here
  const handleProjectName = (name) =>{
    const projectName = name.trimStart();
    // const projectName = event.target.value;
      // console.log(projectName);
      //Check the projectName
      if (projectName.length > 150){
        //Set the error message
        setProjectNameErrorMessage("List Name is too long");
        setProjectName(projectName);
      }
      else{
        setProjectNameErrorMessage(null);
        //We will disable the validate button if no projectname is entered or the data is not valid
        setProjectName(projectName);
      }
  }
  //Whenever a user selects a file we come here
  const handleFileSelect = (file) => {
    //We close the upload modal
    setOpenUploadModal(false);
    setSelectedFile(file);

  };

  const recreateCsvFile = () => {
    //Add the new mapped columns to the CSV
    const newCsvData = addMappedColumns(selectedFileData, columnMapping);
    //Unparse the CSV data
    const newCsvUnparsed = Papa.unparse(newCsvData);
    //Recreate the csv string from the unparsed data
    const blob = new Blob([newCsvUnparsed], { type: 'text/csv' });
    // Create a new File object from the Blob(csv string)
    const file = new File([blob], projectName, { type: 'text/csv' });
    return file
  }
  const handleValidate = async () => {
    Mixpanel.track("Validate list button clicked");
    setOpenMappingModal(false);
    setisMappingOpen(false);
    setisAnalyzeOpen(true);
    setOpenAnlaysisModal(true);
    //Recreate the new file after handling the mapping
    const file = recreateCsvFile();
    // Make API request
    try {
      const accessToken = getAccessTokenCookie();
      const config = {
        
        headers: { 
          'Accept': 'application/json',
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'multipart/form-data', 
          'X-API-Key': apiKey
        }
      };
      //store the payload to be sent
      const data = new FormData();
      data.append('userId',userId);
      // data.append('accountId',accountId);
      data.append('projectName',projectName.trim());
      data.append('csvFile', file);
      const finalColumnMapping = getColumnMappingforBackend(columnMapping);
      data.append('columnMapping',JSON.stringify(finalColumnMapping));
  
      const response = await axios.post(validateDataUrl, data,config);
      // console.log(response.data);
      if (response.data.statusCode === 200) {
        if(response.data.result.report.passCount <1){ 
          setDisableAnalyzeButton(true);
          setAnalyzeErrorMessage("Passed contacts not found for analysis.");
        }
        if(response.data.result.report.passCount <20){ 
          setDisableAnalyzeButton(true);
          setAnalyzeErrorMessage("Please have atleast 20 passed contacts for verification.");
        }
        const currentCreditsAmount = await handleGetCredits();
        if(!currentCreditsAmount){
          setDisableAnalyzeButton(true);
          setAnalyzeErrorMessage("Could not load your credits. Please try again.")
        }
        if(response.data.result.report.passCount > currentCreditsAmount){
          setDisableAnalyzeButton(true);
          setAnalyzeErrorMessage("Insufficient Funds! Purchase more credits to execute the list.");
        }
      setValidationResults(response.data);
      //set the project id
      setProjectId(response.data.result.projectId);
      setOpenAnlaysisModal(false);
      setisAnalyzeOpen(false);
      setOpenResultsModal(true);
      setisResultsOpen(true);
    }
    else if(response.data.message === 'SureConnect.ai - upload_and_validate_prospect_data endpoint failed! --> List name already in use.'){
      setOpenAnlaysisModal(false);
      setisAnalyzeOpen(false);
      setOpenUploadedModal(true);
      setisUploadedOpen(true);
      setProjectNameErrorMessage("List Name already exists");
    }
    else if(response.data.statusCode === 457){
      const status = await regenerateTokens();
      if(status === 200){
        await handleValidate();
      }
      else{
        await userLogout();
        navigate("/");
      }
    }
    else if(response.data.statusCode === 455){
      await userLogout();
      navigate("/");
    }
    else if (response.data.statusCode === 453){
      sleep(4000);
      await handleValidate();
    }
    //Unknown error
    else{
      toast.error("Couldn't process your request. Please try again!",{position:toast.POSITION.BOTTOM_LEFT});
      sleep(4000);
      resetProjectDetails();
    }
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong. Please try again after some time.",{position:toast.POSITION.BOTTOM_LEFT})
      sleep(4000);
      resetProjectDetails();
  };
}
const handleMappingChange = (appField, csvColumn) => {
  setColumnMapping((prevMapping) => {
    //Create a new mapping object
    const newMapping ={ ...prevMapping };
    //Check if the appField is empty string. If yes, then delete the column mapping
    if(csvColumn === ""){
      delete newMapping[appField];
    }
    else{
    //Add the new mapping
    newMapping[appField] = csvColumn;
    }
    return newMapping;
  });
};

  //Whenever a user uploads a file, we check the properties of the selected file here
  const filePropertiesCheck = () => {
    if (selectedFile) {
      //Set the projectname as file name if the project name is not created
      if (!projectName) {
        handleProjectName(selectedFile.name.replace(".csv", ""));
      }
      const fileSize = selectedFile.size;
      //Maximum size of the file we are going to allow
      const maxSize = 1024*1024*2;
      //Check if it is a valid CSV file
      if(!selectedFile.type.toLowerCase().includes("csv")){
        setDataErrorMessage("Please upload a valid CSV file.");
        setValidCsvData(false);
      }
      //Check the file size
      else if (fileSize> maxSize){
        setDataErrorMessage(`File Size is more than 2 MB`);
        setValidCsvData(false);
      }
      else{
      Papa.parse(selectedFile, {
        skipEmptyLines: true,
        header: true,
        complete: function (results) {
          // Here, 'results' will contain the parsed CSV data
          setCsvColumns(results.meta.fields);
          // Accessing column headers
          const csvColumnNames = results.meta.fields;
          //Do automapping of the CSV columns
          setColumnMapping(automapColumns(csvColumnNames));
          //Get the number of columns in the CSV
          const numberOfColumns = csvColumnNames.length;
          //Iterate through the CSV rows and check if all the rows contain the same number of columns.
          for(let i=0; i<results.data.length; i++){
            //Check if all fields contains all csv columns
            if(Object.keys(results.data[i]).length !== numberOfColumns){
              setDataErrorMessage("Invalid CSV. Please make sure that all the rows contain the same number of columns.");
              setValidCsvData(false);
            }
          }
          //Check if the number of records are not exceeding 10000
          if(results.data.length>10000){
            setValidCsvData(false);
            setDataErrorMessage(`Number of contacts should not be more than 10000`);
          }
          //If there are no records, mark as invalid csv
          else if (results.data.length < 1){
            setValidCsvData(false);
            setDataErrorMessage(`No contacts found`);
          }
          //If count of records is less than 10, then raise error
          else if (results.data.length < 20){
            setValidCsvData(false);
            setDataErrorMessage(`Please upload a file with atleast 20 records!`);
          }
          else{
          setSelectedFileData(results.data);
          setValidCsvData(true);
          }
        },
      });
    }
  }
  };

 
useEffect(() => {
  if(validCsvData && projectNameErrorMessage === null){
    setDisableMappingButton(false);
  }
  else{
    setDisableMappingButton(true);
  }
}, [validCsvData, projectNameErrorMessage]);
//Use Effect for field mapping
useEffect(() => {
  getCurrentCredits();
  if(columnMapping){
    let multipleFiledsMapped = false;
    //Mandatory fields to be mapped
    const mandatoryFields = ["first name", "phone1"];
    const warningFields = ["company name", "email", "linkedin url"];
    const mappedFields = Object.keys(columnMapping);
    //Check if all items are present in the mapping
    const containsAllItems = mandatoryFields.every(item => mappedFields.includes(item));
    for(const [field,col] of Object.entries(columnMapping)){
      for(const [field1,col1] of Object.entries(columnMapping)){
          if(field !== field1 && col === col1){
            multipleFiledsMapped = true;
          }
      }
    };
    if(multipleFiledsMapped){
      toast.warning("Cannot map multiple fields with same CSV header.",{position:toast.POSITION.BOTTOM_LEFT})
      setMappingErrorMessage("Cannot map multiple fields with same CSV header.");
      setMappingWarningMessage(null);
      setDisableValidateButton(true);
    }
    else if(!containsAllItems){
      setMappingErrorMessage("All mandatory fields are not mapped.");
      setMappingWarningMessage(null);
      setDisableValidateButton(true);
    }
    else{
      setMappingErrorMessage(null);
      //Check if all the warning fields are present in the mapping
      const containsAllWarningItems = warningFields.every(item => mappedFields.includes(item));
      if(!containsAllWarningItems){
        setMappingWarningMessage("Consider mapping all fields for better accuracy.")
      }
      else{
        setMappingWarningMessage(null);
      }
      setDisableValidateButton(false);
    }
  }
}, [columnMapping]);
 const handleDeleteFile = () => {
  //We come here when a file is deleted by the user.
  setOpenUploadedModal(false);
  setOpenResultsModal(false);
  setOpenAnlaysisModal(false);
  setOpenUploadModal(true);
  resetProjectDetails();
 }


 useEffect(() => {
  if (selectedFile) {
    // Perform any actions with the selected file here
    filePropertiesCheck();
    setisResultsOpen(false);
    setOpenResultsModal(false);
    setOpenUploadedModal(true);
    setisUploadedOpen(true);
  }
}, [selectedFile]);

  //We comehere whenever a person we want to get the project details of the user
  const getProjects = async () => {
    const accessToken = getAccessTokenCookie();

    //Prepare the headers
    const config = {
      headers: { 
        Authorization: `Bearer ${accessToken}`,
        'X-API-Key': apiKey
      },
    };
    //Prepare the payload to hit the get user acccount project details endpoint
    const payload = {
      // 'accountId':accountId, 
      'userId':userId, 
      "pageIndex": pageIndex,
      "pageDataCount": pageDataCount,
      "search": searchTerm,
      "sortByValue": sortByValue,
      "sortByDirection": sortByDirection,
      "filters": pageFilters
    };
    try {
      const response = await axios.post(getProjectUrl, payload, config);
      if(response.data.statusCode === 200){
        setProjects(response.data.result.data);
      }
      else if(response.data.statusCode === 457){
        const status = await regenerateTokens();
        if(status === 200){
          await getProjects();
        }
        else{
          await userLogout();
          navigate("/");
        }
      }
      else if(response.data.statusCode === 455){
        // console.log("LOGGING OUT");
        await userLogout();
        navigate("/");
      }
      else if (response.data.statusCode === 453){
        sleep(4000);
        await getProjects();
      }
      else{
        toast.error("Couldn't process your request. Please try again!",{position:toast.POSITION.BOTTOM_LEFT})
      }

    } catch (error) {
      toast.error("Something went wrong. Please try again after some time.",{position: toast.POSITION.BOTTOM_LEFT})
    }
    finally{
      setIsLoading(false);
    }
    
  };
  
  useEffect(() => {
    getProjects();
    sleep(4000);
  }, []);

  const getPaymentDetails = async() => {
    //We come here when the user has redirected from the stripe page to our website.
    try{
      //Create the payload to check payment status
      const payload = {
        "sessionid":token
      }
      const response = await getPaymentStatus(payload);
      //Mark the payment checking as false once we get the response
      setPaymentChecking(false);
      await handleGetCredits();

      // console.log(response);
      if(response.data.statusCode === 200){
        //Payment credits
        setCreditsAmount(response.data.result['payment_credits'])
        //Check the payment status
        if(response.data.result["payment_status"] === "paid" || response.data.result["payment_status"] === "succeeded"){
          //Message when payment is successfull
          if(response.data.result["mode"]==='payasyougo'){
            setPaymentMessage(`${response.data.result['payment_credits']} Credits added successfully!`);
            setPaymentDescription("Your payment is completed and additional lead credits have been added to your account.");

          }
          else{
            setPaymentMessage(`Congratulations!! Your SureConnect subscription is Active.`);
            setPaymentDescription(`${response.data.result['payment_credits']} credits have been added your account. You can now start using SureConnect.`)
          }
          //Open successpage          
          setOpenSuccessPage(true);
        }
        else if(response.data.result["payment_status"] === "canceled" || response.data.result["payment_status"] === "unpaid"){
          //When payment is failed. Open failed modal
          let paymentAmount = null;
          //calculate the price based on the credits tier
          for(let i=0; i< tierDetails.length; i++){
            if(tierDetails[i][1].from <= response.data.result['payment_credits'] && response.data.result['payment_credits'] <= tierDetails[i][1].upto){
              paymentAmount = Math.floor(tierDetails[i][1].cost * response.data.result['payment_credits']);
              break;
            }
          }
          if(response.data.result["mode"]==='payasyougo'){
            setPaymentType(true);
            setPaymentMessage("Your payment has failed!");
            if(paymentAmount === null){
              setPaymentDescription(`An error occured while processing your payment of ${response.data.result['payment_credits']} credits. Please try again.`)
            }
            else{
              setPaymentDescription(`An error occured while processing your payment of $${paymentAmount}. Please try again.`)
            }
          }
          else{
            setPaymentType(false);
            setPaymentMessage("Your Subscription Payment has failed!");
            setPaymentDescription("An error occurred while processing your subscription. Please try again.");
          }
          
          
          setOpenCancelPage(true);
        }
        else if(response.data.result["payment_status"] === "processing"){
          //open processing page
        }
      }
    }
    catch{
      toast.error("Something went wrong. Please try again after some time.",{position: toast.POSITION.BOTTOM_LEFT})
    }
  }

const handleClosePaymentSuccess = () => {
  // When user closes the payment modal, redirect to home page
    setOpenSuccessPage(false);
    window.location.href = "/home"
  }

const handleClosePaymentCancel = () => {
  //When user cancels the payment, redirect to home page.
  setOpenCancelPage(false);
  window.location.href = "/home"
}
const handleCloseBuyCredits = () => {
  setOpenBuyCredits(false);
}

const handlePaymentTryAgain = async() => {
  await handleGetCredits()
  setOpenCancelPage(false);
  setOpenBuyCredits(true);
  
  if(paymentType){
    setCheckoutType("payasyougo");
  }
  else{
    setCheckoutType("subscription");
  }
}
  
  useEffect(() => {
    // console.log("Use Effect called");
    if(token){
      setPaymentChecking(true);
      getPaymentDetails();
    }
    // console.log(projects.length);
  }, [token]);

  return (<>
    <div className={"main-page-wrap gradient-bg " + ((!subscriptionFlag || [1, 2, 3, 4, 5, 6, 12].includes(subscriptionStatus)) ? "main-wrap-banner" : "")}>
    {
      openBuyCredits && (
        <BuySubscriptionOrCredits onClose={handleCloseBuyCredits} checkoutType={checkoutType} />
      )
      }
      {
        openSuccessPage && (
          <PaymentSuccess messHead={paymentMessage} messDescription={paymentDescription} onDone={handleClosePaymentSuccess} />
        )
      }
      {
        openCancelPage && (
          <PaymentFailed messHead={paymentMessage} messDescription={paymentDescription} onClose={handleClosePaymentCancel} onTryAgain={handlePaymentTryAgain} />
        )
      }
      {
        openPaymentChecking && (
          <PaymentChecking />
        )
      }
      <div className="validate-phone-wrap">
        <div className="font-36 poppins-bold text-center">
          <span className="dark-text">Validate Phone Numbers in</span>
          <span className="primary-blue-text"> Real Time</span>
        </div>
        <div className="font-16 text-center default-text poppins-regular mt-1">
          Boost the Sales team's productivity by the accuracy of the <br />phone
          numbers provided by prospects on your sign-up forms
        </div>
      </div>
      {/* <!-- Upload New List Section --> */}
      {isLoading &&(
        <div className='loading-animation'>
          <LoadingAnimation/>
          <span>
              Loading data, please wait...
          </span>
        </div>
      )}
      {!isLoading && projects && projects.length > 0 ? (
        <div>
          <div className="container">
            <div className="row my-4">
              <div className="col-12 text-center">
                <button className="btn blue-btn-primary btn-lg" onClick={handleOpenUpload}>
                  <i className="fa-solid fa-arrow-up-from-bracket me-3"></i>Upload New List
                </button>
                <div className="upload-wrap mt-5">

                  <Modal
                    show={openUploadModal}
                    onHide={resetProjectDetails}
                    backdrop="static"
                    keyboard={false}
                    aria-labelledby="uploadModalLabel"
                    className='modal-no-border modal-lg'
                  >
                    <Modal.Header className="modal-header" closeButton>
                      <Modal.Title className="modal-title" id="uploadModalLabel">
                        <span className="round-modal-icon grey">
                          <i className="fa-solid fa-arrow-up-from-bracket"></i>
                        </span>{" "}
                        Upload New List
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <UploadModal onFileChange={handleFileSelect} />
                    </Modal.Body>
                    <Modal.Footer className="modal-footer mt-3">
                      <div className="primary-blue-text font-12 poppins-medium cursor-pointer" onClick={downloadSampleExcel}>
                      </div>
                      <div>
                        <Button className="btn btn-cancel mr-15px" variant="secondary" onClick={resetProjectDetails}>
                          Cancel
                        </Button>
                        <Button className="btn blue-btn-primary font-12" variant="primary" disabled={true}>
                        Next<i className="fa-regular fa-arrow-right ms-2"></i>
                        </Button>
                      </div>
                    </Modal.Footer>
                  </Modal>
                  {/* Uploaded Modal */}
                  <Modal
                    show={openUploadedModal}
                    onHide={resetProjectDetails}
                    backdrop="static"
                    keyboard={false}
                    aria-labelledby="uploadedModalLabel"
                    className='modal-no-border modal-lg'
                  >
                    <Modal.Header className="modal-header" closeButton>
                      <Modal.Title className="modal-title" id="uploadedModalLabel">
                        <span className="round-modal-icon grey">
                          <i className="fa-solid fa-arrow-up-from-bracket"></i>
                        </span>{" "}
                        Upload New List
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      {/* <div className="form-steps mb-3">
                        <div className='active'>Upload CSV</div>
                        <div><i className='fa-solid fa-chevron-right'></i></div>
                        <div>Map Fields</div>
                        <div><i className='fa-solid fa-chevron-right'></i></div>
                        <div>Review</div>
                      </div>
                          <div className="input-form my-3">
                            <label>
                              Add a Title to your List
                            </label>
                            <input className={`form-control ${projectNameErrorMessage ? 'is-invalid' : ''}`} type="text" value={projectName} onChange={(e) => handleProjectName(e.target.value)} />
                          </div>
                      <UploadedComponent file={selectedFile} onDelete={handleDeleteFile}/>
                      {dataErrorMessage && (
                        <p className="font-14" style={{ color: 'red' }}>{dataErrorMessage}</p>
                      )}
                      {!dataErrorMessage && (
                        <>
                          {dataWarningMessage && (
                            <p className="text_error_message">{dataWarningMessage}</p>
                          )}
                          {projectNameErrorMessage && (
                            <p className="font-14" style={{ color: 'red' }}>{projectNameErrorMessage}</p>
                          )}
                        </>
                      )} */}
                    <UploadedModal 
                      file={selectedFile} 
                      onFileDelete={resetProjectDetails} 
                      dataErrorMessage={dataErrorMessage} 
                      dataWarningMessage={dataWarningMessage} 
                      projectNameErrorMessage={projectNameErrorMessage} 
                      name={projectName} 
                      onNameChange={handleProjectName} 
                    />
                    </Modal.Body>
                    <Modal.Footer className="modal-footer mt-3">
                      {/* <div className="primary-blue-text font-12 poppins-medium cursor-pointer" onClick={downloadSampleExcel}>
                        <i className="fa-regular fa-arrow-down-to-bracket me-1" ></i>
                        CSV Upload Instructions
                      </div> */}
                      <div className='font-12 poppins-medium cursor-pointer'>
                        <Button variant="secondary" className="btn back-btn mr-15px" onClick={handleOpenUpload}>
                         <i className='fa-solid fa-arrow-left me-1'></i> Back
                        </Button>
                      </div>
                      <div>
                      <Button variant="secondary" className="btn btn-cancel mr-15px" onClick={resetProjectDetails}>
                          Cancel
                        </Button>
                        <Button className='btn blue-btn-primary' variant="primary" disabled={disableMappingButton} onClick={handleOpenMapping}>
                        Next
                          <i className="fa-regular fa-arrow-right ms-2"></i>
                        </Button>
                      </div>
                    </Modal.Footer>
                  </Modal>
                  {/* Mapping Modal */}
                  <Modal
                    show={openMappingModal}
                    onHide={resetProjectDetails}
                    backdrop="static"
                    keyboard={false}
                    aria-labelledby="mappingModalLabel"
                    className='modal-no-border modal-lg'
                  >
                    <Modal.Header className="modal-header" closeButton>
                      <Modal.Title className="modal-title" id="mappingModalLabel">
                        <span className="round-modal-icon grey">
                          <i className="fa-solid fa-arrow-up-from-bracket"></i>
                        </span>{" "}
                        Upload New List
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <MappingModal 
                        selectedFile={selectedFile} 
                        onFileDelete={resetProjectDetails} 
                        columns={csvColumns} 
                        mapping={columnMapping} 
                        onMappingChange={handleMappingChange} 
                        mappingErrorMessage={mappingErrorMessage} 
                        mappingWarningMessage={mappingWarningMessage}  
                      />
                      {/* <div className="form-steps mb-3">
                        <div>Upload CSV</div>
                        <div><i className='fa-solid fa-chevron-right'></i></div>
                        <div className='active'>Map Fields</div>
                        <div><i className='fa-solid fa-chevron-right'></i></div>
                        <div>Review</div>
                      </div>
                      <UploadedComponent file={selectedFile} onDelete={handleDeleteFile}/>
                      <div className='columnWrap my-3'>
                        <div className="header lato-medium">
                          <div className='min-w-200'>SureConnect Fields</div>
                          <div>CSV Header</div>
                        </div>
                        {appFields.map((field) => (
                          <div className='custom-row' key={field}>
                            <label className='mb-0 dark-text text-capitalize'>{field}</label>
                            <select 
                            value={columnMapping[field] || ''}
                            onChange={(e) => handleMappingChange(field,e.target.value)}
                            >
                              <option value="">Select</option>
                              {csvColumns.map((column) => (
                                <option key={column} value={column}>
                                  {column}
                                </option>
                              ))}
                            </select>
                          </div>
                        ))}
                      </div>
                      {mappingErrorMessage && (
                        <p className="text_error_message">{mappingErrorMessage}</p>
                      )}
                      {mappingWarningMessage && (
                          <p className="font-14" style={{ color: 'red' }}>{mappingWarningMessage}</p>
                      )} */}
                    </Modal.Body>
                    <Modal.Footer className="modal-footer mt-3">
                      {/* <div className="primary-blue-text font-12 poppins-medium cursor-pointer" onClick={downloadSampleExcel}>
                        <i className="fa-regular fa-arrow-down-to-bracket me-1" ></i>
                        CSV Upload Instructions
                      </div> */}
                      <div className='font-12 poppins-medium cursor-pointer'>
                      <Button variant="secondary" className="btn mr-15px back-btn" onClick={handleOpenUploaded}>
                      <i className='fa-solid fa-arrow-left me-1'></i>Back
                        </Button>
                      </div>
                      <div>
                        <Button variant="secondary" className="btn btn-cancel mr-15px" onClick={resetProjectDetails}>
                          Cancel
                        </Button>
                        <Button className='btn blue-btn-primary' variant="primary" disabled={disableValidateButton} onClick={handleValidate}>
                        Next
                          <i className="fa-regular fa-arrow-right ms-2"></i>
                        </Button>
                      </div>
                    </Modal.Footer>
                  </Modal>
                  {/* Analyzing Modal */}
                  <Modal
                    show={openAnlaysisModal}
                    onHide={resetProjectDetails}
                    backdrop="static"
                    keyboard={false}
                    aria-labelledby="analyzingModalLabel"
                    className='modal-no-border modal-lg'
                  >
                    <Modal.Header className="modal-header" closeButton>
                      <Modal.Title className="modal-title" id="analyzingModalLabel">
                        <span className="round-modal-icon grey">
                          <i className="fa-solid fa-arrow-up-from-bracket"></i>
                        </span>{" "}
                        Upload New List
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <AnalyzingModal selectedFile={selectedFile} onFileDelete={resetProjectDetails} />
                      {/* <div className="form-steps mb-3">
                          <div>Upload CSV</div>
                          <div><i className='fa-solid fa-chevron-right'></i></div>
                          <div className='active'>Map Fields</div>
                          <div><i className='fa-solid fa-chevron-right'></i></div>
                          <div>Review</div>
                      </div>
                      <AnalyzingComponent file={selectedFile} onDelete={handleDeleteFile} /> */}
                    </Modal.Body>
                    <Modal.Footer className="modal-footer mt-3">
                    <div className='font-12 poppins-medium cursor-pointer'>
                        <Button className="btn back-btn mr-15px" variant="secondary" disabled={true}>
                        <i className='fa-solid fa-arrow-left me-1'></i>Back
                        </Button>
                      </div>
                      <div>
                      <Button variant="secondary" className="btn btn-cancel mr-15px" onClick={resetProjectDetails}>
                          Cancel
                        </Button>
                        <Button className='btn blue-btn-primary' variant="primary" disabled={true}>
                          Next
                          <i className="fa-regular fa-arrow-right ms-2"></i>
                        </Button>
                      </div>
                    </Modal.Footer>
                  </Modal>
                  {/* result Modal */}
                  <Modal
                    show={openResultsModal && selectedFile}
                    onHide={resetProjectDetails}
                    backdrop="static"
                    keyboard={false}
                    aria-labelledby="resultsModalLabel"
                    className='modal-no-border modal-lg'
                  >
                    <Modal.Header className="modal-header" closeButton>
                      <Modal.Title className="modal-title" id="resultsModalLabel">
                        <span className="round-modal-icon grey">
                          <i className="fa-solid fa-arrow-up-from-bracket"></i>
                        </span>{" "}
                        Upload New List
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <ResultsModal 
                      selectedFile={selectedFile} 
                      onFileDelete={resetProjectDetails} 
                      analyzeErrorMessage={analyzeErrorMessage} 
                      results={validationResults} />
                    {/* <div className="form-steps mb-3">
                      <div className='active'>Upload CSV</div>
                      <div><i className='fa-solid fa-chevron-right'></i></div>
                      <div>Map Fields</div>
                      <div><i className='fa-solid fa-chevron-right'></i></div>
                      <div>Review</div>
                  </div>
                      <ResultsComponent file={selectedFile} results={validationResults} onDelete={handleDeleteFile} />
                      {analyzeErrorMessage && (
                                <p className="font-14" style={{ color: 'red' }}>{analyzeErrorMessage}</p>
                              )} */}
                    </Modal.Body>
                    <Modal.Footer>
                    <div className='d-flex align-items-center'>
                    <Button className="btn back-btn mr-15px" variant="secondary" onClick={handleOpenMapping}>
                    <i className='fa-solid fa-arrow-left me-1'></i> Back
                        </Button>
                      <div className="primary-blue-text font-12 poppins-medium cursor-pointer" onClick={() => handleDownloadValidatedCSV(projectId)}>
                        <i className="fa-regular fa-arrow-down-to-bracket me-1" ></i>
                        Validated CSV
                      </div>
                    </div>
                      <div>                        
                        <Button className="btn btn-cancel mr-15px" variant="secondary" onClick={resetProjectDetails}>
                          Cancel
                        </Button>
                        <Button className='btn blue-btn-primary' variant="primary" disabled={disableAnalyzeButton} onClick={handleOpenBeforeAnalyze}>
                          Next
                          <i className="fa-regular fa-arrow-right ms-2"></i>
                        </Button>
                      </div>
                    </Modal.Footer>
                  </Modal>
                  {/* Before Analyze Submit, Message Model */}
                  <Modal
                    show={isBeforeAnalyzeMessageOpen}
                    onHide={resetProjectDetails}
                    backdrop="static"
                    keyboard={false}
                    aria-labelledby="beforeAnalyzeModalLabel"
                    className='modal-no-border modal-lg'
                  >
                    <Modal.Header className="modal-header" closeButton>
                      <Modal.Title className="modal-title" id="beforeAnalyzeModalLabel">
                        <span className="round-modal-icon grey">
                          <i className="fa-solid fa-arrow-up-from-bracket"></i>
                        </span>{" "}
                        Upload New List
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <BeforeAnalyzeMessageModal />
                    </Modal.Body>
                    <Modal.Footer>
                      <div className='d-flex align-items-center'>
                        <Button className="btn back-btn mr-15px" variant="secondary" onClick={handleBackToOpenResult}>
                          <i className='fa-solid fa-arrow-left me-1'></i> Back
                        </Button>
                        <div className="primary-blue-text font-12 poppins-medium cursor-pointer" onClick={() => handleDownloadValidatedCSV(projectId)}>
                          <i className="fa-regular fa-arrow-down-to-bracket me-1" ></i>
                          Validated CSV
                        </div>
                      </div>
                      <div>                        
                        <Button className="btn btn-cancel mr-15px" variant="secondary" onClick={resetProjectDetails}>
                          Cancel
                        </Button>
                        <Button className='btn blue-btn-primary' variant="primary" disabled={disableAnalyzeButton} onClick={HandleAnalyze}>
                          Start Verifying
                          <i className="fa-regular fa-arrow-right ms-2"></i>
                        </Button>
                      </div>
                    </Modal.Footer>
                  </Modal>
                  
                </div>
              </div>
            </div>
            {/* Show Home Page Cards */}
            <div className="row">
            {projects.map((project) => (
              // project.projectStats[0].totalProspects > 0 && (
                <div className="col-lg-6" key={project.projectId}>
                  <CardComponent project={project} />
                </div>
              // )
            ))}
            </div>
          </div>
        </div>
      ) : ( !isLoading && (
        //First time screens
        <div className="upload-wrap mt-5">
          {/* Show the First time home page */}
          <div className="header poppins-medium">
            Upload your first list to start validating
          </div>
          {/* { !selectedFile && <UploadComponent onFileSelect={handleFileSelect} /> } */}
          { !selectedFile && 
            <UploadModal onFileChange={handleFileSelect} /> 
          }
          {isUploadedOpen && (
            <div>
              <UploadedModal file={selectedFile} onFileDelete={resetProjectDetails} onNameChange={handleProjectName} name={projectName} projectNameErrorMessage={projectNameErrorMessage} dataErrorMessage={dataErrorMessage} dataWarningMessage={dataWarningMessage} />
              <div className='d-flex align-items-center justify-content-between mx-24'>
                <button className='btn back-btn mb-3' onClick={resetProjectDetails}><i className='fa-regular fa-arrow-left me-2'></i>Back</button>
                <button className='btn blue-btn-primary mb-3' disabled={disableMappingButton} onClick={handleOpenMapping}>Next <i className='fa-regular fa-arrow-right ms-2'></i></button>
              </div>              
            </div>
          )
          }
          {isMappingOpen && (
            <div>
              <MappingModal 
                selectedFile={selectedFile} 
                onFileDelete={resetProjectDetails} 
                columns={csvColumns} 
                mapping={columnMapping} 
                onMappingChange={handleMappingChange} 
                mappingErrorMessage={mappingErrorMessage} 
                mappingWarningMessage={mappingWarningMessage}  
              />
              <div className='d-flex align-items-center justify-content-between mx-24'>
              <button className='btn back-btn mb-3' onClick={handleOpenUploaded}><i className='fa-regular fa-arrow-left me-2'></i>Back</button>
              <button className='btn blue-btn-primary mb-3' disabled={disableValidateButton} onClick={handleValidate}>Next <i className='fa-regular fa-arrow-right ms-2'></i></button>
              </div>              
            </div>
          )
          }
          {/* { isUploadedOpen && (
            <div>
            <UploadedComponent file={selectedFile} onDelete={handleDeleteFileHome}/>
            {dataErrorMessage && (
              <div className="plr-60px">
                <p className="font-14" style={{ color: 'red' }}>{dataErrorMessage}</p>
              </div>
            )}
            {!dataErrorMessage && (
              <>
              <div className="input-form d-flex flex-column plr-60px">
                <label style={{ textAlign: 'left' }}>
                  Add a Title to your List
                </label>
                <input className={`form-control ${projectNameErrorMessage ? 'is-invalid' : ''}`} type="text" value={projectName} onChange={(e) => handleProjectName(e.target.value)} />
              </div>
              <div>
              <h3>Map CSV Columns to Fields</h3>
              {appFields.map((field) => (
                <div key={field}>
                  <label>{field}</label>
                  <select 
                  value={columnMapping[field] || ''}
                  onChange={(e) => handleMappingChange(field,e.target.value)}
                  >
                    <option value="">Select field</option>
                    {csvColumns.map((column) => (
                      <option key={column} value={column}>
                        {column}
                      </option>
                    ))}
                  </select>
                </div>
              ))}
            </div>
            {dataWarningMessage && (
                  <p className="text_error_message">{dataWarningMessage}</p>
            )}
            {projectNameErrorMessage && (
              <div className="plr-60px">
                                <p className="font-14" style={{ color: 'red' }}>{projectNameErrorMessage}</p>
              </div>
            )}
            {mappingErrorMessage && (
                              <p className="text_error_message">{mappingErrorMessage}</p>
            )}
            {mappingWarningMessage && (
                <p className="font-14" style={{ color: 'red' }}>{mappingWarningMessage}</p>
            )}
            </>
            )}
            <div class="text-center">
            <button className='btn blue-btn-primary my-4' disabled={disableValidateButton} onClick={handleValidateHome}>Validate <i className='fa-regular fa-arrow-right ms-2'></i></button>
              </div>
            </div>
          )
          } */}
          {/* {isAnalyzeOpen && (
            <AnalyzingComponent file={selectedFile} onDelete={handleDeleteFileHome}/>
          )} */}
          {isAnalyzeOpen && (
            <AnalyzingModal selectedFile={selectedFile} onFileDelete={resetProjectDetails} />
          )}
          {/* {selectedFile && isResultsOpen && (
            <div>
              <ResultsComponent file={selectedFile} results = {validationResults} onDelete={handleDeleteFileHome}/>
              {analyzeErrorMessage && (
                <div className="plr-60px">
                  <p className="font-14 text_error_message">{analyzeErrorMessage}</p>
                </div>
              )}
              <div class="text-center">
              <button className='btn blue-btn-primary my-4' disabled={disableAnalyzeButton} onClick={handleAnalyzeHome}>Start Verifying <i className='fa-regular fa-arrow-right ms-2'></i></button>
              </div>
            </div>
          )} */}
          {selectedFile && isResultsOpen && (
            <>
            <ResultsModal 
              selectedFile={selectedFile} 
              onFileDelete={resetProjectDetails} 
              analyzeErrorMessage={analyzeErrorMessage} 
              results={validationResults}
            />
            {/* footer div */}
            <div className='d-flex align-items-center justify-content-between flex-direction-row mx-24'>
              {/* left component */}
              <div className="d-flex align-items-center mb-3 gap-10">
                {/* back button */}
                <button className='btn back-btn' onClick={handleOpenMapping}><i className='fa-regular fa-arrow-left me-2'></i>Back</button>
                {/* validated csv  */}
                <div className="primary-blue-text poppins-medium font-12 text-center">
                    <span className="cursor-pointer" onClick={() => handleDownloadValidatedCSV(projectId)}
                    ><i className="fa-regular fa-arrow-down-to-bracket"></i> Validated CSV</span>
                </div>
              </div>
              {/* right component */}
              <div className="d-flex align-items-center mb-3 gap-10">
                {/* cancel button */}
                <Button className="btn btn-cancel" variant="secondary" onClick={resetProjectDetails}>
                  Cancel
                </Button>
                {/* next button */}
                <button className='btn blue-btn-primary' disabled={disableAnalyzeButton} onClick={handleOpenBeforeAnalyze}>Next <i className='fa-regular fa-arrow-right ms-2'></i></button>
              </div>
              
            </div>            
            </>
          )}
          { isBeforeAnalyzeMessageOpen && (
              <>
              <BeforeAnalyzeMessageModal />
              {/* footer div */}
              <div className='d-flex align-items-center justify-content-between flex-direction-row mx-24 mt-5'>
                {/* left component */}
                <div className='d-flex align-items-center mb-3 gap-10'>
                  <Button className="btn back-btn" variant="secondary" onClick={handleBackToOpenResult}>
                    <i className='fa-solid fa-arrow-left me-1'></i> Back
                  </Button>
                  <div className="primary-blue-text font-12 poppins-medium cursor-pointer" onClick={() => handleDownloadValidatedCSV(projectId)}>
                    <i className="fa-regular fa-arrow-down-to-bracket me-1" ></i>
                    Validated CSV
                  </div>
                </div>
                {/* right component */}
                <div className="d-flex align-items-center mb-3 gap-10">                        
                  <Button className="btn btn-cancel" variant="secondary" onClick={resetProjectDetails}>
                    Cancel
                  </Button>
                  <Button className='btn blue-btn-primary' variant="primary" disabled={disableAnalyzeButton} onClick={HandleAnalyze}>
                    Start Verifying
                    <i className="fa-regular fa-arrow-right ms-2"></i>
                  </Button>
                </div>
              </div>
              </>
          )}
          {!selectedFile && (
          <div className="primary-blue-text poppins-medium font-12 text-center mb-30px">
            <span className="cursor-pointer" onClick={downloadSampleExcel}
            ><i className="fa-regular fa-arrow-down-to-bracket"></i> CSV Upload Instructions</span>
          </div>
          )
          }
          {/* {projectId && (
          <div className="primary-blue-text poppins-medium font-12 text-center mb-30px">
            <span className="cursor-pointer" onClick={() => handleDownloadValidatedCSV(projectId)}
            ><i className="fa-regular fa-arrow-down-to-bracket"></i> Validated CSV</span>
          </div>
            )
          } */}
        </div>
      ))}
      < ToastContainer / >
      <Tooltip id = "Home12"/>
    </div>

    <Modal
            show={showJoinedModel}
            size="sg"
            aria-labelledby="contained-modal-title-vcenter"
            // centered
            onHide={handleCloseModal}
        >
            <Modal.Body>
            <div className="verify-sucess">

                <div className="text-center mb-3">
                    <img src={require("../../../../assets/img/Success.svg").default} alt="Success" />
                </div> 
                <div className="text-center font-18 dark-text poppins-semibold mb-2">
                You joined a new account!
                </div>
                <p className="text-center font-14 grey-text mw-388 pt-1 poppins-regular"> 
                  You are now part of {userAccountName}. You can now switch to accounts from Switch account option.
                </p>
                <div className="text-center mt-3">
                    <button className="btn blue-btn-primary w-20 mb-3" onClick={handleCloseModal}>Okay</button>
                </div>
            </div>
            </Modal.Body>
    </Modal>

    <Modal
            show={showAlreadyJoinedModel}
            size="sg"
            aria-labelledby="contained-modal-title-vcenter"
            // centered
            onHide={handleCloseModal}
        >
            <Modal.Body>
            <div className="verify-sucess">

                <div className="text-center mb-3">
                    <img src={require("../../../../assets/img/Success.svg").default} alt="Success" />
                </div> 
                <div className="text-center font-18 dark-text poppins-semibold mb-2">
                You have already joined the account!
                </div>
                <p className="text-center font-14 grey-text mw-388 pt-1 poppins-regular"> 
                  You are already part of {userAccountName}. You can now switch to accounts from Switch account option.
                </p>
                <div className="text-center mt-3">
                    <button className="btn blue-btn-primary w-20 mb-3" onClick={handleCloseModal}>Okay</button>
                </div>
            </div>
            </Modal.Body>
    </Modal>
    
    <Modal
            show={showUserNotAllowedToJoinModal}
            size="sg"
            aria-labelledby="contained-modal-title-vcenter"
            // centered
            onHide={handleCloseModal}
        >
            <Modal.Body>
            <div className="verify-sucess">

                <div className="text-center mb-3">
                    <img src={require("../../../../assets/img/alert.svg").default} alt="Failed" />
                </div> 
                <div className="text-center font-18 dark-text poppins-semibold mb-2">
                  Invalid Invitation Link
                </div>
                <p className="text-center font-14 grey-text mw-388 pt-1 poppins-regular"> 
                It seems you have someone else’s Invitation link. Please retry with the correct link or contact account admin.
                </p>
                <div className="text-center mt-3">
                    <button className="btn blue-btn-primary w-20 mb-3" onClick={handleCloseModal}>Okay</button>
                </div>
            </div>
            </Modal.Body>
    </Modal>
    </>

  );
  } ;

export default HomeComponent;