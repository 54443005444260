import React from "react";
import './app.component.scss'
import SidebarMenu from "./sidebar-menu/sidebar-menu.component";
import HeaderComponent from "./header/header.component";
import UsersComponent from "./base-content/users/users.component";

const UsersApp = () => {
    return (
        <div className="container-fluid">
          <div className="row">
              <div className="col-sm-auto sidebar-menu sticky-top">
                <SidebarMenu />
              </div>
              <div className="col-sm min-vh-100 p-0">
                <div>
                    <HeaderComponent />
                    <UsersComponent />
                </div>
              </div>
          </div>
        </div>
  )
}

export default UsersApp;