import { forwardRef, useImperativeHandle, useState } from "react";
import { Button, Modal } from "react-bootstrap";

const ConfirmationModal = forwardRef(
  (
    {
      title = "",
      message = "",
      iconClass = "",
      iconBackgroundClass = "orange",
      onConfirm = () => {},
      onCancel = () => {},
      confirmButtonText = "Confirm",
      CancelButtonText = "Cancel",
      disabled = false,
    },
    ref
  ) => {
    const [show, setShow] = useState();

    useImperativeHandle(ref, () => {
      return {
        show() {
          setShow(true);
        },
        hide() {
          setShow(false);
        },
      };
    });

    function handleClose() {
      setShow(false);
      onCancel();
    }

    return (
      <Modal show={show} onHide={handleClose} className="modal-no-border">
        <Modal.Header closeButton>
          <Modal.Title>
            <span className={`round-modal-icon ${iconBackgroundClass}`}>
              <i className={`${iconClass} font-14`}></i>
            </span>{" "}
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>{message}</div>
        </Modal.Body>

        <Modal.Footer className="no-shadow pt-0">
          <div className="text-end w-100">
            <Button
              className="btn btn-cancel mr-15px"
              variant="secondary"
              onClick={handleClose}
            >
              {CancelButtonText}
            </Button>
            <Button
              className="btn blue-btn-primary font-12 small-btn"
              variant="primary"
              onClick={onConfirm}
              disabled={disabled}
            >
              {confirmButtonText}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
);

export default ConfirmationModal;
