import { useEffect, useState } from 'react';
import TeamService from '../../apis/teamservice';
import './base-content.component.scss'
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoadingAnimation from './lottie-files/loading.lotte';
import { GetPermission } from '../../apis/pvlapis';

const Sidebar = () => {
    return (
        <div className="profile-sidebar-menu">
            <ul>
                { <>
                    <li><NavLink to="/profile" activeClassName="active" aria-current="page">My Account</NavLink></li>
                     <li><NavLink to="/users" activeClassName="active" aria-current="page">Users</NavLink></li>
                    <li><NavLink to="/credits-history" activeClassName="active" aria-current="page">Credits History</NavLink></li>
                    <li><NavLink to="/invoice" activeClassName="active" aria-current="page">Invoice</NavLink></li>
                    <li><NavLink to="/api" activeClassName="active" aria-current="page">API</NavLink></li>
                    <li><NavLink to="/integrations" activeClassName="active" aria-current="page">Integrations</NavLink></li>
                    <li><NavLink to="/subscription" activeClassName="active" aria-current="page">Subscription</NavLink></li>
                </>
                }
            </ul>
        </div>
    )
}

export default Sidebar;