import { useEffect, useRef, useState } from "react";
import {
  useParams,
  useLocation,
  useNavigate,
  useOutletContext,
} from "react-router-dom";
import IntegrationService from "../../../../services/integrationservice";
import { toast } from "react-toastify";
import {
  getOperatorTextSeparator,
  SyncStatus,
} from "../../integrations-constants";
import moment from "moment";
import { getDurationInReadableFormat } from "../../../../utils/helper.utils";
import LoadingAnimation from "../../../lottie-files/loading.lotte";

export default function IntegrationSummary() {
  const [configurationSummary, setconfigurationSummary] = useState(null);
  const [validationDetails, setValidationDetails] = useState(null);
  const [syncSettings, setSyncSettings] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [integrationSyncStatus, setIntegrationSyncStatus] = useState(0);
  const [syncDetails, setSyncDetails] = useState({});
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { integration, configuration } = useOutletContext();
  const [syncButtonDisable, setSyncButtonDisable] = useState(false);
  const [syncTime, setSyncTime] = useState("");
  const intervalIdRef = useRef(null);

  const typeDetails = configuration.defaultConfig.types;

  async function fetchSyncSettings() {
    try {
      const settings = await IntegrationService.SyncSettings(
        integration.integrationId
      );

      setSyncSettings(settings);
    } catch (error) {}
  }

  const nextSyncDate = (nextSyncDate) => {
    const syncTime = getDurationInReadableFormat(
      moment.utc().valueOf(),
      new Date(nextSyncDate + "z").getTime()
    )?.split(",")[0];
    setSyncTime(syncTime);
  };

  const syncStatus = async () => {
    try {
      const response = await IntegrationService.SyncStatus(
        integration.integrationId,
        typeDetails.map((x) => x.typeId).join(",")
      );
      setIntegrationSyncStatus(response.syncStatus);
      setSyncDetails(response);
      setSyncButtonDisable(false);
    } catch (error) {
      toast.error("Failed to fetch sync status");
    }
  };

  const fetchConfigurationSummary = async () => {
    setIsLoading(true);

    try {
      const configSummary = await IntegrationService.ConfigurationSummary(
        integration.integrationId
      );

      configSummary.configurationObjectsDetails.forEach((object) => {
        const typeDetail = typeDetails.find(
          (detail) => detail.typeId === object.typeid
        );

        object.typeName = typeDetail?.displayName ?? "Unknown Type";
      });

      setconfigurationSummary(configSummary);
      setIsLoading(false);
      syncStatus();
      nextSyncDate(configSummary.nextSyncDate);
    } catch (error) {
      setIsLoading(false);
      toast.error("Failed to fetch integrations");
    }
  };

  const fetchValidationDetails = async () => {
    try {
      const validationDetails = await IntegrationService.ValidationDetails(
        integration.integrationId
      );
      setValidationDetails(validationDetails);
    } catch (error) {}
  };

  useEffect(() => {
    fetchConfigurationSummary();
    fetchSyncSettings();
    fetchValidationDetails();
  }, [params, location]);

  async function enableDisablePolling(value) {
    try {
      const payload = syncSettings.map((setting) => ({
        ...setting,
        enablePolling: value,
        inboundFilter: "",
        integrationId: integration.integrationId,
      }));

      await IntegrationService.UpdateSyncSetting(payload);

      setconfigurationSummary({
        ...configurationSummary,
        pollingEnabled: value,
      });
    } catch (error) {
      setconfigurationSummary({
        ...configurationSummary,
        pollingEnabled: value === 2 ? 1 : 2,
      });
      toast.error(
        error?.message ?? "Failed to update status, please try again."
      );
    }
  }

  function configure() {
    navigate(`/integrations/${params.integrationName}/configure/objects`, {
      state: location.state,
    });
  }

  function goToSyncSettings() {
    navigate(`/integrations/${params.integrationName}/configure/settings`, {
      state: location.state,
    });
  }

  const invokeSync = async () => {
    setSyncButtonDisable(true);
    try {
      const response = await IntegrationService.InvokeSync({
        integrationId: integration.integrationId,
        integrationName: configuration.name,
      });

      if (response.success) {
        toast.success(response.message);
      } else {
        toast.warning(response.message);
      }

      syncStatus();
    } catch (error) {
      toast.error("Failed to invoke sync");
    }
  };

  const cancelSync = async () => {
    setSyncButtonDisable(true);
    try {
      const response = await IntegrationService.CancelSync(
        integration.integrationId
      );

      if (response.success) {
        toast.success(response.message);
      } else {
        toast.warning(response.message);
      }

      syncStatus();
    } catch (error) {
      toast.error("Failed to fetch integrations");
    }
  };

  const checkStatusEveryMinute = async () => {
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
      fetchConfigurationSummary();
    }

    if (integrationSyncStatus === SyncStatus.InProgress) {
      intervalIdRef.current = setInterval(() => {
        syncStatus();
      }, 1000 * 60);
    }
  };

  useEffect(() => {
    checkStatusEveryMinute();

    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
      }
    };
  }, [integrationSyncStatus]);

  return isLoading || !configurationSummary ? (
    <div className="loading-animation">
      <LoadingAnimation />
      <span>Loading data, please wait...</span>
    </div>
  ) : (
    <div id="Summary">
      <IntegrationValidations
        setSyncButtonDisable={setSyncButtonDisable}
        validationDetails={validationDetails}
      />
      <div className="mt-4 mb-3">
        <span>
          <i className="fa-regular fa-gear six-c-7"></i>
        </span>
        <span className="lato-bold dark-text ms-2">Configuration Summary</span>
        <div className="lato-medium d-flex align-items-center gap-10 my-3">
          <div className="custom-toggle">
            <input
              type="checkbox"
              id="darkModeToggle3"
              checked={configurationSummary.pollingEnabled === 1}
              onChange={() =>
                enableDisablePolling(
                  configurationSummary.pollingEnabled === 1 ? 2 : 1
                )
              }
            />
            <label htmlFor="darkModeToggle3"></label>
          </div>{" "}
          <span>
            Periodically poll{" "}
            {configuration.integrationConfig.details.displayName} to fetch new
            and changed data
          </span>
        </div>
        <div className="d-flex align-items-center gap-10 mb-5px">
          <button
            className={`btn small-btn ${
              syncButtonDisable
                ? "outline-btn-disabled cursor-not-allowed"
                : "outline-btn cursor-pointer"
            }`}
            onClick={() => {
              if (integrationSyncStatus === SyncStatus.InProgress) {
                cancelSync();
              } else {
                invokeSync();
              }
            }}
            disabled={syncButtonDisable}
          >
            {integrationSyncStatus === SyncStatus.InProgress
              ? "Cancel Sync"
              : "Sync Now"}
          </button>
          {integrationSyncStatus === SyncStatus.InProgress && (
            <div className="font-12 grey-text">
              <span className="lato-bold">Sync in progress</span>
            </div>
          )}
          {configurationSummary.pollingEnabled === 1 &&
            integrationSyncStatus !== SyncStatus.InProgress &&
            configurationSummary.nextSyncDate != null &&
            !syncButtonDisable &&
            (syncTime !== null && syncTime.length > 0 ? (
              <div className="font-12 grey-text">
                Next Sync due in <span className="lato-bold">{syncTime}</span>
              </div>
            ) : (
              <div className="font-12 grey-text">Next Sync due now</div>
            ))}
        </div>
        {syncDetails.lastSyncDate != null && (
          <div className="mb-15px">
            <span>
              <span className="grey-text font-12">
                Last synced on{" "}
                <span className="lato-bold">
                  {moment(syncDetails.lastSyncDate).format(
                    "D MMM, yyyy hh:mm A"
                  )}{" "}
                  UTC
                </span>
              </span>
            </span>
          </div>
        )}
      </div>

      <div className="table-responsive lato-regular mb-4">
        <table className="table table-bordered">
          {configuration.uiConfig.uiproperties.configuresummary
            .connecteduser ? (
            <tr>
              <td className="px-3 py-2 default-text br-1" width={260}>
                Integration Connected User:
              </td>
              <td className="px-3 py-2">
                <span className="lato-medium dark-text">
                  {configurationSummary.integrationConnectedUser}
                </span>
                <span className="font-12 light-grey-text">
                  {configurationSummary.integrationConnectedUserId}
                </span>
              </td>
            </tr>
          ) : undefined}
          {configuration.uiConfig.uiproperties.configuresummary
            .pollingfrequency ? (
            <tr className="grey-bg">
              <td className="px-3 py-2 default-text br-1" width={260}>
                Polling Frequency:
              </td>
              <td className="px-3 py-2">
                <span className="lato-medium dark-text">
                  Every {configurationSummary.pollInterval}{" "}
                  {GetIntervalTypeLabel(configurationSummary.pollIntervalType)}
                </span>
                <span
                  className="font-12 light-grey-text cursor-pointer"
                  onClick={goToSyncSettings}
                >
                  <i className="fa-regular fa-pen"></i>
                </span>
              </td>
            </tr>
          ) : undefined}
          {configuration.uiConfig.uiproperties.configuresummary
            .objectdetails ? (
            <tr>
              <td
                className="px-3 py-2 default-text br-1 v-align-top"
                width={260}
              >
                Data Fetched from{" "}
                {configuration.integrationConfig?.details?.displayName}:
              </td>
              <td className="px-3 py-2">
                <div>
                  {configurationSummary.configurationObjectsDetails.map(
                    (object, index) => (
                      <span key={index} className="ph-loc me-1">
                        {object.typeName}
                      </span>
                    )
                  )}
                </div>
                {configurationSummary.configurationObjectsDetails.map(
                  (object) => (
                    <div>
                      <div className="font-12 grey-text">
                        Fetch {object.typeName.toLowerCase()}s matching the
                        following criteria:
                      </div>
                      {object.inboundfilters &&
                      object.inboundfilters !== "{}" ? (
                        <div
                          className="tag-like-wrap font-12"
                          style={{ letterSpacing: "0.5px" }}
                        >
                          {object.inboundfilters
                            ? RenderFilterCriteria(
                                JSON.parse(object.inboundfilters)
                              )
                            : ""}
                        </div>
                      ) : (
                        <i
                          className="lato-medium font-12"
                          style={{ color: "#6A7686" }}
                        >
                          No criteria added
                        </i>
                      )}
                    </div>
                  )
                )}
              </td>
            </tr>
          ) : undefined}
          {configuration.uiConfig.uiproperties.configuresummary.activity ? (
            <tr className="grey-bg">
              <td className="px-3 py-2 default-text br-1" width={260}>
                Record Activity to{" "}
                {configuration.integrationConfig?.details?.displayName}:
              </td>
              <td className="px-3 py-2 default-text">
                <span className="on">
                  {JSON.parse(integration.activityConfig).activityenabled
                    ? "ON"
                    : "OFF"}
                </span>
              </td>
            </tr>
          ) : undefined}
          {configuration.uiConfig.uiproperties.configuresummary.users ? (
            <tr>
              <td className="px-3 py-2 default-text br-1" width={260}>
                Users with Active Mapping:
              </td>
              <td className="px-3 py-2">
                <div>
                  {configurationSummary.mappedUsers ? (
                    configurationSummary.mappedUsers?.map((user, index) => (
                      <span className="ph-loc me-1" key={index}>
                        {user}
                      </span>
                    ))
                  ) : (
                    <i className="font-12">No Users Mapped</i>
                  )}
                </div>
              </td>
            </tr>
          ) : undefined}
        </table>
      </div>
      <button
        type="button"
        className="btn outline-btn font-12 small-btn"
        onClick={configure}
      >
        <i className="fa-solid fa-pen me-2"></i>Edit Configuration
      </button>
    </div>
  );
}

function RenderFilterCriteria(filter) {
  return (
    <>
      {filter.rules.map((r, index) =>
        r?.condition ? (
          <> &#123; {RenderFilterCriteria(r)}&#125;</>
        ) : (
          <span className="lato-medium" style={{ color: "#6A7686" }}>
            {r.crmfieldname}{" "}
            <i className="lato-regular">
              {getOperatorTextSeparator(r?.operator)}
            </i>{" "}
            <span className="lato-bold text-dark">
              {r.value
                ? r.crmfieldtype === "datetime"
                  ? moment(r.value).utc().format("YYYY-MM-DD HH:mm")
                  : r.value
                : r.values?.map((v) => v.text).join(", ") ?? ""}
            </span>{" "}
            <span className="lato-bold">
              {" "}
              {index === filter.rules.length - 1 ? "" : filter.condition + " "}
            </span>
          </span>
        )
      )}
    </>
  );
}

const GetIntervalTypeLabel = (intervalType) => {
  switch (intervalType) {
    case 1:
      return "day(s)";
    case 2:
      return "hour(s)";
    case 3:
      return "minute(s)";
    default:
      return "minute(s)";
  }
};

const IntegrationValidations = ({
  setSyncButtonDisable,
  validationDetails,
}) => {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  if (!validationDetails) {
    return null;
  }

  const configure = () => {
    navigate(`/integrations/${params.integrationName}/configure/objects`, {
      state: location.state,
    });
  };

  if (
    !validationDetails.hasEnoughCredits ||
    !validationDetails.hasActiveSubscription
  ) {
    setSyncButtonDisable(true);
  }

  const issues = [];

  if (
    validationDetails.fieldMappings &&
    Object.keys(validationDetails.fieldMappings).length > 0
  ) {
    Object.entries(validationDetails.fieldMappings).forEach(([key, value]) => {
      issues.push(
        <li key={`fieldMapping-${key}`}>
          Update the field mapping for <strong>{value.disabledCount}</strong>{" "}
          field, as SureConnect is unable to identify them in the current
          mapping.{" "}
          <a
            onClick={configure}
            className="blue-link lato-medium cursor-pointer"
          >
            Fix now
          </a>
        </li>
      );
    });
  }

  if (validationDetails.userMappings && validationDetails.userMappings > 0) {
    issues.push(
      <li key="userMappings">
        Update the user mapping for{" "}
        <strong>{validationDetails.userMappings}</strong> deleted users to
        ensure synchronization with active accounts.{" "}
        <a onClick={configure} className="blue-link lato-medium">
          Fix now
        </a>
      </li>
    );
  }

  if (
    validationDetails.hasEnoughCredits &&
    validationDetails.hasActiveSubscription &&
    issues.length === 0
  ) {
    return null;
  }

  return (
    <div className="warning-box">
      <i className="fa-solid fa-warning orange-color"></i>
      <span className="lato-bold dark-text ms-2">Issues found!</span>
      <div className="font-12 mt-2 default-text">
        {!validationDetails.hasActiveSubscription && (
          <li>Your account does not have an active subscription.</li>
        )}
        {!validationDetails.hasEnoughCredits && (
          <li>Your account is running low on credits.</li>
        )}
        {issues.length > 0 && (
          <>
            <ul>
              <li>
                {issues.length} issues identified in your CRM sync settings.
                Please fix them to enable smooth synchronization.
              </li>
            </ul>

            <ul>{issues}</ul>
          </>
        )}
      </div>
    </div>
  );
};
