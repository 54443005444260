import moment from "moment";

const dateFormat = "YYYY-MM-DD";

export function getDatesByCriterionValue(value) {
  switch (value) {
    case "today":
      return {
        startDate: moment().format(dateFormat),
        endDate: moment().format(dateFormat),
      };
    case "yesterday":
      return {
        startDate: moment().subtract(1, "day").format(dateFormat),
        endDate: moment().subtract(1, "day").format(dateFormat),
      };
    case "last7Days":
      return {
        startDate: moment().subtract(7, "days").format(dateFormat),
        endDate: moment().format(dateFormat),
      };
    case "last15Days":
      return {
        startDate: moment().subtract(15, "days").format(dateFormat),
        endDate: moment().format(dateFormat),
      };
    case "last30Days":
      return {
        startDate: moment().subtract(30, "days").format(dateFormat),
        endDate: moment().format(dateFormat),
      };
    default:
      return null;
  }
}

export function getSeparatorTextByCriterionValue(value) {
  switch (value) {
    case "today":
    case "yesterday":
      return "equal to";
    case "last7Days":
    case "last15Days":
    case "last30Days":
      return "between";
    default:
      return value;
  }
}
