import { useEffect, useRef, useState } from "react";
import LoadingAnimation from "../../../lottie-files/loading.lotte";
import IntegrationService from "../../../../services/integrationservice";
import { toast } from "react-toastify";
import { useOutletContext } from "react-router-dom";
import {
  formatNumberInShort,
  toTitleCase,
} from "../../../../utils/helper.utils";
import moment from "moment";
import {
  Chart as ChartJS,
  Filler,
  LineController,
  LineElement,
  PointElement,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import { APIUsageGraphType } from "../../integrations-constants";
import { Button, ButtonGroup } from "react-bootstrap";
import userEvent from "@testing-library/user-event";
import { Tooltip } from "react-tooltip";

ChartJS.register(LineController, LineElement, PointElement, Filler);

const graphTypeButtons = [
  { name: "Past 24 hours", value: APIUsageGraphType.hourly },
  { name: "Last 7 Days", value: APIUsageGraphType.weekly },
];

export default function IntegrationApiUsage() {
  const [isLoading, setIsLoading] = useState(false);

  const [usageDetails, setUsageDetails] = useState(null);
  const [crmApiUsageDetails, setCrmApiUsageDetails] = useState(null);

  const chartRef = useRef();
  const crmApiThresholdRef = useRef();
  const pvlApiThresholdRef = useRef();

  const { configuration, integration } = useOutletContext();
  const integrationName = toTitleCase(configuration.name);

  const [currentView, setCurrentView] = useState(null);
  const [chartOptions, setChartOptions] = useState(getDefaultChartOptions());
  const [chartData, setChartData] = useState({
    datasets: [],
    labels: [],
  });
  const [gradient, setGradient] = useState("#fa9891");

  useEffect(() => {
    setIsLoading(true);
    fetchApiUsage();
  }, []);

  useEffect(() => {
    if (currentView) {
      prepareGraphData();
    }
  }, [currentView]);

  async function fetchApiUsage() {
    try {
      const response = await IntegrationService.GetApiLimitUsage(
        integration.integrationId
      );

      if (response) {
        response.crmapiLimit = response?.crmapiLimit ?? 0;
        response.crmapiUsage = response?.crmapiUsage ?? 0;
        response.totalAPILimit = response?.totalAPILimit ?? 0;
        response.sureConnectAPILimit = response?.sureConnectAPILimit ?? 0;
        response.sureConnectAPIUsage = response?.sureConnectAPIUsage ?? 0;

        const sureConnectAPIUsage = !configuration.uiConfig.uiproperties
          .apiusage.showcrmapilimitusage
          ? response.sureConnectAPIUsage
          : response.crmapiUsage < response.sureConnectAPIUsage
          ? response.crmapiUsage
          : response.sureConnectAPIUsage;

        const sureConnectApiLimit =
          response.sureConnectAPILimit === 0
            ? response.totalAPILimit === 0
              ? response.crmapiLimit === 0
                ? response.sureConnectAPILimit
                : response.crmapiLimit
              : response.totalAPILimit
            : response.sureConnectAPILimit;

        const crmUsagePercentage =
          (response.crmapiUsage / response.crmapiLimit) * 100;
        const crmUsageLimitPercentage =
          (response.totalAPILimit / response.crmapiLimit) * 100 -
          crmUsagePercentage;

        setUsageDetails({
          ...response,
          crmApiUsageLimit:
            response.totalAPILimit === 0
              ? response.crmapiLimit
              : response.totalAPILimit,
          sureConnectAPIUsage: sureConnectAPIUsage,
          sureConnectAPILimit: sureConnectApiLimit,
          crmUsagePercentage: crmUsagePercentage,
          crmUsageLimitPercentage: crmUsageLimitPercentage,
          crmLimitPercentage:
            100 - crmUsagePercentage - crmUsageLimitPercentage,
          sureConnectUsagePercentage:
            (sureConnectAPIUsage / sureConnectApiLimit) * 100,
          sureConnectUsageLimitPercentage:
            100 - (sureConnectAPIUsage / sureConnectApiLimit) * 100,
        });
        fetchCrmApiUsage();
      }
    } catch (error) {
      toast.error(
        error?.message ?? "Something went wrong, please reload the page."
      );
    } finally {
      setIsLoading(false);
    }
  }

  async function fetchCrmApiUsage() {
    try {
      const response = await IntegrationService.GetCrmApiUsage(
        integration.integrationId
      );

      setCrmApiUsageDetails(response);
    } catch (error) {
      toast.error(
        error?.message ?? "Something went wrong, please reload the page."
      );
    }
  }

  useEffect(() => {
    if (!currentView) {
      setInitialChartData();
    } else {
      prepareGraphData();
    }
  }, [crmApiUsageDetails]);

  function setInitialChartData() {
    if (!chartRef.current) return;

    const chart = chartRef.current?.getContext("2d").chart;
    chart.canvas.height = "250px";

    const _gradient = chart.ctx.createLinearGradient(0, 0, 0, 100);
    _gradient.addColorStop(0, "#f7dedc");
    _gradient.addColorStop(1, "white");

    const _data = {
      labels: [],
      datasets: [
        {
          data: [],
          label: "bar",
          type: "bar",
          fill: false,
          tension: 0.1,
          borderColor: "#CCE2F6",
          backgroundColor: "#CCE2F6",
          pointBackgroundColor: "#fa9891",
          pointRadius: 1,
        },
        {
          data: [],
          label: "Line",
          type: "line",
          fill: true,
          borderColor: "#fa9891",
          borderRadius: "6px",
          backgroundColor: _gradient,
          pointRadius: 0,
        },
      ],
    };

    setGradient(_gradient);
    setChartData(_data);

    setCurrentView(APIUsageGraphType.weekly);
  }

  function prepareGraphData() {
    if (currentView === APIUsageGraphType.weekly) {
      const _graphData = crmApiUsageDetails?.weeklyGraphData?.dayGraphData;

      let barData = [];
      let lineData = [];
      let labels = [];

      if (_graphData) {
        lineData = Array(3).fill(usageDetails.sureConnectAPILimit);
        labels = [""];
        barData = [0];

        _graphData.forEach((x) => {
          labels.push(moment(new Date(x.usageDay)).format("ddd, D MMM"));
          barData.push(x.usageApiCount);
          lineData.push(usageDetails.sureConnectAPILimit);
        });
      }

      setChartOptions({
        ...chartOptions,
        scales: {
          ...chartOptions.scales,
          x: {
            ...chartOptions.scales.x,
            min: moment(
              new Date(new Date(Date.now() - 6 * 24 * 60 * 60 * 1000))
            )
              .format("ddd, D MMM")
              .toString(),
          },
        },
      });

      setChartData({
        ...chartData,
        labels: labels,
        datasets: [
          {
            data: lineData,
            label: "Limit",
            type: "line",
            fill: true,
            borderColor: "#fa9891",
            borderRadius: "6px",
            backgroundColor: gradient,
            pointRadius: 0,
            order: 2,
          },
          {
            data: barData,
            label: "API Usage",
            type: "bar",
            fill: true,
            tension: 0.1,
            borderColor: "#CCE2F6",
            backgroundColor: "#CCE2F6",
            pointBackgroundColor: "#fa9891",
            pointRadius: 1,
            order: 1,
          },
        ],
      });
    } else if (currentView === APIUsageGraphType.hourly) {
      const _graphData = crmApiUsageDetails?.weeklyGraphData?.hourGraphData;

      let lineData1 = [];
      let lineData2 = [];
      let labels = [];

      if (_graphData) {
        lineData2 = Array(3).fill(usageDetails.sureConnectAPILimit);
        labels = [""];
        lineData1 = [0];

        _graphData.forEach((x) => {
          if (x.usageHour === 0) {
            labels.push(moment(new Date()).format("ddd, D MMM")).toString();
            lineData1.push(x.usageApiCount);
            return 0;
          }
          labels.push(moment(x.usageHour).format("hh A").toString());
          lineData1.push(x.usageApiCount);
          lineData2.push(usageDetails.sureConnectAPILimit);
        });
      }

      setChartOptions({
        ...chartOptions,
        scales: {
          ...chartOptions.scales,
          x: {
            ...chartOptions.scales.x,
            min: moment(
              new Date(new Date(Date.now() - 24 * 60 * 60 * 1000)).getHours()
            )
              .format("hh A")
              .toString(),
          },
        },
      });

      setChartData({
        ...chartData,
        labels: labels,
        datasets: [
          {
            data: lineData1,
            label: "API Usage",
            type: "line",
            fill: true,
            tension: 0.1,
            borderColor: "#CCE2F6",
            backgroundColor: "#CCE2F6",
            pointBackgroundColor: "#CCE2F6",
            pointRadius: 2,
          },
          {
            data: lineData2,
            label: "Limit",
            type: "line",
            fill: true,
            borderColor: "#fa9891",
            borderRadius: "6px",
            backgroundColor: gradient,
            pointRadius: 0,
          },
        ],
      });
    }
  }

  async function handleThresholdChange(type) {
    const payload = {
      integrationId: integration.integrationId,
      totalAPILimit: 0,
      sureConnectAPILimit: 0,
    };

    if (type === 1) {
      if (
        crmApiThresholdRef.current.value === null ||
        crmApiThresholdRef.current.value === undefined ||
        crmApiThresholdRef.current.value < 0
      ) {
        toast.error("Enter a valid input limit.");
        return;
      } else if (crmApiThresholdRef.current.value > 1500000) {
        toast.error("Total API usage cannot exceed 1500000.");
        return;
      } else if (
        crmApiThresholdRef.current.value > 0 &&
        usageDetails.sureConnectAPILimit > crmApiThresholdRef.current.value
      ) {
        toast.error(
          "SureConnect API usage limit should be less than Total API usage limit."
        );
        return;
      }

      payload.sureConnectAPILimit = usageDetails.sureConnectAPILimit;
      payload.totalAPILimit = crmApiThresholdRef.current.value;
    } else if (type === 2) {
      if (
        pvlApiThresholdRef.current.value === null ||
        pvlApiThresholdRef.current.value === undefined ||
        pvlApiThresholdRef.current.value < 0
      ) {
        toast.error("Enter a valid input limit.");
        return;
      } else if (pvlApiThresholdRef.current.value > 1500000) {
        toast.error("SureConnect API usage cannot exceed 1500000.");
        return;
      } else if (
        pvlApiThresholdRef.current.value > 0 &&
        pvlApiThresholdRef.current.value > usageDetails.totalAPILimit &&
        usageDetails.totalAPILimit > 0 &&
        configuration.uiConfig.uiproperties.apiusage.showcrmapilimitusage
      ) {
        toast.error(
          "SureConnect API usage limit should be less than Total API usage limit."
        );
        return;
      }

      payload.sureConnectAPILimit = pvlApiThresholdRef.current.value;
      payload.totalAPILimit = usageDetails.totalAPILimit;
    }

    try {
      const response = await IntegrationService.UpdateApiLimit(payload);

      fetchApiUsage();
      toast.success(response?.message ?? "Threshold updated successfully");
    } catch (error) {
      toast.error("Failed to update, please try to update again.");
    }
  }

  return (
    <div id="APIUsage">
      <div className="p-5 position-relative">
        <div className="position-absolute" style={{ right: "0", top: "0" }}>
          <ButtonGroup>
            {graphTypeButtons.map((btn) => (
              <Button
                key={btn.value}
                className={`primary-btn-group ${
                  btn.value === currentView ? "active" : ""
                }`}
                onClick={() => setCurrentView(btn.value)}
              >
                {btn.name}
              </Button>
            ))}
          </ButtonGroup>
        </div>
        <Chart
          ref={chartRef}
          charttype="bar"
          data={chartData}
          options={chartOptions}
          redraw={true}
        />
      </div>
      {configuration.uiConfig.uiproperties.apiusage.showcrmapilimitusage ? (
        <div>
          <div className="api-progress-bar">
            <div className="lato-bold dark-text mb-3">
              Total API Usage on {integrationName}
            </div>
            <div className="progress" style={{ width: "500px" }}>
              <div
                role="progressbar"
                className="progress-bar APIUsage"
                style={{
                  width:
                    usageDetails?.crmUsagePercentage > 0
                      ? usageDetails?.crmUsagePercentage + "%"
                      : "0%",
                }}
              ></div>
              <div
                role="progressbar"
                className="progress-bar UsageLimit"
                style={{
                  width:
                    usageDetails?.crmUsageLimitPercentage > 0
                      ? usageDetails?.crmUsageLimitPercentage + "%"
                      : "0%",
                }}
              ></div>
              <div
                role="progressbar"
                className="progress-bar SalesforceLimit"
                style={{
                  width:
                    usageDetails?.crmLimitPercentage > 0
                      ? usageDetails?.crmLimitPercentage + "%"
                      : "0%",
                }}
              ></div>
            </div>
            <div className="d-flex align-items-center stats mt-5px">
              <div className="box APIUsage"></div>
              <div className="me-3 box-text">
                {" "}
                API Usage:{" "}
                <span placement="auto" data-tooltip-id="apiusage" data-tooltip-content={usageDetails?.crmapiUsage}>
                  {" "}
                  {formatNumberInShort(usageDetails?.crmapiUsage)}
                </span>
              </div>
              <div className="box UsageLimit"></div>
              <div className="me-3 box-text">
                {" "}
                Usage Limit:{" "}
                <span placement="auto" data-tooltip-id="apiusage" data-tooltip-content={usageDetails?.crmApiUsageLimit}>
                  {" "}
                  {formatNumberInShort(usageDetails?.crmApiUsageLimit)}
                </span>
              </div>
              <div className="box SalesforceLimit"></div>
              <div className="me-3 box-text">
                {" "}
                {integrationName} Limit:{" "}
                <span placement="auto" data-tooltip-id="apiusage" data-tooltip-content={usageDetails?.crmapiLimit}>
                  {" "}
                  {formatNumberInShort(usageDetails?.crmapiLimit)}
                </span>
              </div>
            </div>
          </div>
          <div className="my-4">
            <div>Set threshold for Total API usage on {integrationName}</div>
            <div className="d-flex align-items-center gap-10 my-2">
              <div>
                <input
                  ref={crmApiThresholdRef}
                  className="form-control"
                  type="number"
                  placeholder="200"
                  defaultValue={usageDetails?.totalAPILimit}
                />
              </div>
              <button
                className="btn outline-btn"
                onClick={() => handleThresholdChange(1)}
              >
                Update
              </button>
            </div>
            <div className="font-12">
              API calls to {integrationName} will be blocked once the Total API
              usage on
              {integrationName} has crossed the set threshold
            </div>
          </div>
        </div>
      ) : undefined}
      <div className="mt-5">
        <div className="api-progress-bar">
          <div className="lato-bold dark-text mb-3">
            API Usage on SureConnect
          </div>
          <div className="progress" style={{ width: "500px" }}>
            <div
              role="progressbar"
              className="progress-bar APIUsage"
              style={{
                width:
                  usageDetails?.sureConnectUsagePercentage > 0
                    ? usageDetails?.sureConnectUsagePercentage + "%"
                    : "0%",
              }}
            ></div>
            <div
              role="progressbar"
              className="progress-bar UsageLimit"
              style={{
                width:
                  usageDetails?.sureConnectUsageLimitPercentage > 0
                    ? usageDetails?.sureConnectUsageLimitPercentage + "%"
                    : "0%",
              }}
            ></div>
          </div>
          <div className="d-flex align-items-center stats mt-5px">
            <div className="box APIUsage"></div>
            <div className="me-3 box-text">
              {" "}
              API Usage:{" "}
              <span placement="auto">
                {" "}
                {formatNumberInShort(usageDetails?.sureConnectAPIUsage)}
              </span>
            </div>
            <div className="box UsageLimit"></div>
            <div className="me-3 box-text">
              {" "}
              Usage Limit:{" "}
              <span placement="auto">
                {" "}
                {formatNumberInShort(usageDetails?.sureConnectAPILimit)}
              </span>
            </div>
          </div>
        </div>
        <div className="my-4">
          <div>Set threshold on API usage for SureConnect</div>
          <div className="d-flex align-items-center gap-10 my-2">
            <div>
              <input
                ref={pvlApiThresholdRef}
                className="form-control"
                type="number"
                placeholder="200"
                defaultValue={usageDetails?.sureConnectAPILimit}
              />
            </div>
            <button
              className="btn outline-btn"
              onClick={() => handleThresholdChange(2)}
            >
              Update
            </button>
          </div>
          <div className="font-12">
            API calls to {integrationName} will be blocked once the API usage by
            SureConnect has crossed the set threshold
          </div>
        </div>
      </div>
      <div className="mt-2">
        <p className="mb-1 font-14 lato-regular">
          Max recorded API usage/hour:{" "}
          <strong>
            {crmApiUsageDetails?.maxHourlyUsage?.usageApiCount || 0}
          </strong>
        </p>
        <p className="font-14 lato-regular">
          Max recorded API usage/min:{" "}
          <strong>
            {crmApiUsageDetails?.maxMinuteUsage?.usageApiCount || 0}
          </strong>
        </p>
      </div>
      <Tooltip id="apiusage" />
    </div>
  );
}

function getDefaultChartOptions() {
  return {
    responsive: true,
    maintainAspectRatio: false,
    animations: {
      resize: {
        duration: 5,
      },
    },

    interaction: {
      mode: "index",
      intersect: false,
    },
    elements: {
      point: {
        radius: 0,
        hitRadius: 0,
        hoverRadius: 0,
      },
      line: {
        borderWidth: 1,
      },
    },
    scales: {
      y: {
        border: {
          display: false,
        },
        grid: {
          color: "#e9ebec",
          lineWidth: 0,
          display: false,
        },
        ticks: {
          maxTicksLimit: 5,
        },
        beginAtZero: true,
      },
      x: {
        border: {
          display: false,
        },
        grid: {
          display: false,
        },
        min: moment().format("ddd, D MMM").toString(),
        beginAtZero: true,
      },
    },
  };
}
