import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Login from './login/login.component';
import { inhouseLogin, regenerateTokens, sendLoginDataToCrisp, userLogin, verifyAndAcceptInvitation } from '../apis/pvlapis';
import { toast } from 'react-toastify';
import Signup from './login/signup.component';
import { Modal } from 'react-bootstrap';
import Cookies from 'universal-cookie';
import CreateUserAccountModal from './base-content/modals/create-account-modal.component';
import { setAccessTokenCookie, setRefreshToken, setUserDetails } from '../apis/tokenhandler';
import { Mixpanel } from '../configs/mixPanel';

const Invitation = () => {
    const navigate = useNavigate();
    const cookies = new Cookies();
    const params = useParams();
    var token = params.token;
    const location = useLocation();
    const [invitationErrorCode, setInvitationErrorCode] = useState(null);
    const [openLoginPage, setOpenLoginPage] = useState(false);
    const [openSignupPage, setOpenSignupPage] = useState(false);
    const [showJoinedModel,setShowJoinedModel] = useState(false);
    const [showLinkExpired, setShowLinkExpired] = useState(false);
    const [errorMessage,setErrorMessage] = useState(null);
    const [errorDescription,seterrorDescription] = useState("Something went wrong. Please sign up again.");
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [openCreateUserAccount,setOpenCreateUserAccount] = useState(false);
    const [userIdAccountCreate ,setUserIdAccountCreate] = useState(location.state?.userIdToCreateAccount);
    const [loginUserName ,setLoginUserName] = useState(location.state?.loginUserName);
    const [loginPwd ,setLoginPwd] = useState(location.state?.loginPwd);
    const[signUpSuccessfull,setSignUpSuccessfull] = useState(!!location.state?.signUpSuccessfull);
    const[tokenTologinAccount,setTokenTologinAccount] = useState(location.state?.tokenTologinAccount);

    const verifyInvitiation = async() =>{
        token = params.token;
        const refreshToken = cookies.get("refreshToken");
        const accessToken = cookies.get("accessToken");
        const response =  await verifyAndAcceptInvitation(token, location.state?.tokenTologinAccount);

        if(typeof response === 'undefined'){
            return;
        }

        if(tokenTologinAccount?.length > 0 && (response.statusCode !== 203 || response.statusCode !== 204)){
            setOpenCreateUserAccount(true);
        }
        if(response.statusCode===422){
            // user not logged in
            setOpenLoginPage(true);
        }
        else if(response.statusCode===202){
            // user not logged in
            setOpenLoginPage(true);
        }
        else if(response.statusCode === 408){
            const status = await regenerateTokens();
            if(status===200){
                await Invitation();
            }
        }
        else if(response.statusCode === 462){
            // open login page
            toast.error("Invitation Expired",{position:toast.POSITION.BOTTOM_LEFT});
            setOpenLoginPage(true);
            setOpenSignupPage(false); 
            setShowErrorMessage(true);   
            setErrorMessage("Invitation Expired");
            seterrorDescription("Your invitation has expired. Ask account owner for a new invitation.");
        }
        else if(response.statusCode ===201){
            // open signup page
            setOpenSignupPage(true);
            setOpenLoginPage(false);
        }
        else if(response.statusCode===402){
            // open login page
            if(refreshToken.length>0 && accessToken.length>0){
                // toast.error("User Nosst allowed to join.",{position:toast.POSITION.BOTTOM_LEFT});
                navigate('/home',{state:{showUserNotAllowedToJoinModal:true}});
            }
            else{
                setOpenLoginPage(true);
                toast.error("User Not allowed to join.",{position:toast.POSITION.BOTTOM_LEFT});
            }
            
        }
        else if(response.statusCode===203){
            // toast.success("",{position:toast.POSITION.BOTTOM_LEFT});
            setOpenLoginPage(false);
            setOpenSignupPage(false);
            if(location.state?.tokenTologinAccount){
                if(location.state?.source === 'inhouse'){
                    // get the access token
                    const requestForm = {
                        "userEmail": location.state?.loginUserName,
                        "password": location.state?.loginPwd
                    }
                    //Hit the inhouse login endpoint
                    const response = await inhouseLogin(requestForm);
                    if (response.data.statusCode === 200){
                        //Set the access and refresh tokens
                        setAccessTokenCookie(response.data.result.tokens.accessToken);
                        setRefreshToken(response.data.result.tokens.refreshToken);
                        const userDetails = response.data.result.userDetails;
                        const userDetailsString = JSON.stringify(userDetails);
                        setUserDetails(userDetailsString);
                        // console.log(response.data.result.userDetails.userid);
                        Mixpanel.identify(userDetails.userid);
                        Mixpanel.people.set({
                        "$name": userDetails.userfullname,
                        "$email": userDetails.useremail,
                        "Account": userDetails.accountid
                        })
                        sendLoginDataToCrisp(userDetails);
                        navigate('/home',{state:{showJoinedModel:true}});
                    }
                    else{
                        toast.error("Something went wrong. Please try again.",{position: toast.POSITION.BOTTOM_LEFT});
                    }
                }
                else{
                    await userLogin(location.state?.accessToken, location.state?.source, navigate,'',true);
                }
            }
            else{
                navigate("/home",{state:{showJoinedModel:true}});
            }
            
        }
        else if(response.statusCode === 204){
            // toast.success("",{position:toast.POSITION.BOTTOM_LEFT});

            if(tokenTologinAccount?.length > 0){
                // get the access token
                const requestForm = {
                    "userEmail": location.state?.loginUserName,
                    "password": location.state?.loginPwd
                  }
                  //Hit the inhouse login endpoint
                  const response = await inhouseLogin(requestForm);
                  if (response.data.statusCode === 200){
                    //Set the access and refresh tokens
                    setAccessTokenCookie(response.data.result.tokens.accessToken);
                    setRefreshToken(response.data.result.tokens.refreshToken);
                    const userDetails = response.data.result.userDetails;
                    const userDetailsString = JSON.stringify(userDetails);
                    setUserDetails(userDetailsString);
                    // console.log(response.data.result.userDetails.userid);
                    Mixpanel.identify(userDetails.userid);
                    Mixpanel.people.set({
                      "$name": userDetails.userfullname,
                      "$email": userDetails.useremail,
                      "Account": userDetails.accountid
                    })
                    sendLoginDataToCrisp(userDetails);
                    navigate('/home',{state:{showJoinedModel:true}});
                }
                else{
                    toast.error("Something went wrong. Please try again.",{position: toast.POSITION.BOTTOM_LEFT});
                }
            }
            else{
            
                if(refreshToken?.length>0 && accessToken?.length>0){
                    navigate("/home",{state:{showAlreadyJoinedModel:true}});
                    setOpenLoginPage(false);
                    setOpenSignupPage(false);
                }
                else{
                    setOpenLoginPage(true);
                    setOpenSignupPage(false);
                }
        }
        }
        else if(response.statusCode===457){
             // open login page
             setOpenLoginPage(true);
             setOpenSignupPage(false); 
             setShowErrorMessage(true);   
             setErrorMessage("Invitation Expired");
             seterrorDescription("Your invitation has expired. Ask account owner for a new invitation.");
        }
        else if(response.statusCode=== 455){
            setOpenLoginPage(true);
            setShowErrorMessage(true);
            setShowErrorMessage(true);
            setErrorMessage("Invalid Invitation Link");
            seterrorDescription("Your invitation link is invalid. Please get in touch with support team.");
        }
        return response;
    }
    useEffect(() => {
        
        setSignUpSuccessfull(!!location.state?.signUpSuccessfull);
        setTokenTologinAccount(location.state?.tokenTologinAccount);
        const res = verifyInvitiation();
      }, [location,signUpSuccessfull]);
      useEffect(() => {
        
        if(location.state?.tokenTologinAccount){
            const res = verifyInvitiation();
        }
      }, [location]);

  return (<>
    {openCreateUserAccount &&(<CreateUserAccountModal showModal={openCreateUserAccount} setShowModal={setOpenCreateUserAccount} userId={location.state?.userIdAccountCreate}/>)}
    {openLoginPage && <Login  redirectUrl={location.pathname} showErrorMessage={showErrorMessage} 
        errorMessage={errorMessage} errorDescription={errorDescription} signUpSuccessfull={signUpSuccessfull}/>}
    {openSignupPage && <Signup isFromInvitation={true} invitationToken={params.token}  redirectUrl={location.pathname}/>}
  </>
    

  )
}

export default Invitation