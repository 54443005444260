import React, { useState } from "react";
import './credits-history.component.scss';
import { Tooltip } from "react-tooltip";
import 'react-tooltip/dist/react-tooltip.css'

const UsageCard = ({usage}) => {
  // console.log(usage)

    const dateformatter = (date) => {
        const formatedDate = new Date(date).toLocaleDateString(undefined, {
          day: "numeric",
          month: "short",
          year: "numeric",
        });
        const formattedTime = new Date(date).toLocaleTimeString(undefined, {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        });
        const [month, day, year] = formatedDate.split(" ");
        const formattedDay = day.includes(",") ? day.replace(",", "") : day; 
        const outputDate = `${formattedDay} ${month}, ${year}`;
        return `${outputDate} ${formattedTime} UTC`;
      } 
    // const invoiceId = usage.id;
    let transactionReason = usage.transactionReason;
    let transactionReasonTooltip = "";
    if(usage.userId === -1){
      transactionReasonTooltip = `Credits removed by admin for ${transactionReason}`
    }
    else{
      transactionReasonTooltip = `Credits consumed for analyzing ${transactionReason} list`
    }
    if(transactionReason.length > 30){
      transactionReason = transactionReason.slice(0,30) + "..."
    }
    const transactionCredits = usage.transactionCredits;
    const transactionStatus = usage.transactionStatus;
    let userCompleteName = usage.userFullName;
    // console.log(userCompleteName)
    if(userCompleteName===null){
      userCompleteName = "";
    }
    let userFullName = userCompleteName;
    if(userFullName.length > 30){
      userFullName = userFullName.slice(0,30) + "..."
    }
    const transactionDate = dateformatter(usage.transactionDate);
    return (
        <>
        <tr className="text-start main-tr-row">
          <td className="text-center">
            <span data-tooltip-id="invoicecardcomponent" data-tooltip-content={`Transaction initiated by ${userCompleteName}`}>{userFullName}</span>
          </td>
          <td className="text-center">
            <span data-tooltip-id="invoicecardcomponent" data-tooltip-content={transactionReasonTooltip}>{transactionReason}</span>
          </td>
          <td className="text-center">
            <span data-tooltip-id="invoicecardcomponent" data-tooltip-content={`${transactionCredits} credits used for analyzing list`}>{transactionCredits}</span>
          </td>
          <td className="text-center">
            <span data-tooltip-id="invoicecardcomponent" data-tooltip-content={`Credits utilized on ${transactionDate}`}>{transactionDate}</span>
          </td>
        </tr>
        <tr className="inside-tr">
        </tr>
      <Tooltip id = "invoicecardcomponent"/>
      </>
    );
}

export default UsageCard;