import IntegrationCard from "./integration-card.component";
import "react-toastify/dist/ReactToastify.css";
import "react-tooltip/dist/react-tooltip.css";
import { useOutletContext } from "react-router-dom";

const Integrations = () => {
  const { integrations, configurations, setIntegrations } = useOutletContext();

  return (
    <div className="mb-4">
      <div className="card-title mb-4">Integrations</div>
      {Object.values(configurations).map((configuration) => (
        <IntegrationCard
          key={configuration.crmConfigurationId}
          integration={integrations[configuration.crmConfigurationId]}
          configuration={configuration}
          setIntegrationInfo={setIntegrations}
        />
      ))}
    </div>
  );
};

export default Integrations;
