import { useCombobox, useMultipleSelection } from "downshift";
import "./select-input.component.css";
import { Dropdown } from "react-bootstrap";
import { useEffect, useMemo, useState } from "react";
import { Tooltip } from "react-tooltip";

export default function SelectInput({
  data = [],
  onSelectionChanges = (value) => {},
  onValueChanges = (value) => {},
  multiple = false,
  allowSearch = false,
  placeholder = "Select",
  defaultValue = null,
  value = null,
  disabled = false,
  inputStyles = {},
  ...props
}) {
  let [selectedItems, setSelectedItems] = useState([]);
  const [inputValue, setInputValue] = useState("");

  const items = useMemo(
    () => getFilteredItems(data, inputValue),
    [data, inputValue]
  );

  useEffect(() => {
    if (defaultValue) {
      setDefaultValues();
    }
  }, [defaultValue]);

  useEffect(() => {
    if (data.length) {
      setDefaultValues();
      setInputValue("");
    }
  }, [data]);

  function setDefaultValues() {
    if (!defaultValue) {
      setSelectedItems([]);
      return;
    }

    const findItem = (v) => {
      return data.find((item) => {
        const hasItem = typeof v === "object" ? v?.id == item.id : v == item.id;
        return hasItem;
      });
    };

    if (Array.isArray(defaultValue)) {
      const _selectedItems = [];
      defaultValue.forEach((v) => {
        const item = findItem(v);
        if (item) {
          _selectedItems.push(item);
        }
      });

      selectedItems = _selectedItems;
      setSelectedItems(
        selectedItems.length
          ? multiple
            ? [...selectedItems]
            : [{ ...selectedItems[0] }]
          : []
      );
    } else {
      const item = findItem(defaultValue);

      if (item) {
        selectedItems = [item];
        setSelectedItems(
          selectedItems.length
            ? multiple
              ? [...selectedItems]
              : [{ ...selectedItems[0] }]
            : []
        );
      }
    }

    if (
      !selectedItems.length ||
      (Array.isArray(defaultValue)
        ? defaultValue.length !== selectedItems.length
        : false)
    ) {
      if (typeof defaultValue === "object") {
        setSelectedItems(
          Array.isArray(defaultValue)
            ? multiple
              ? defaultValue
              : defaultValue.length
              ? [{ ...defaultValue[0] }]
              : []
            : [{ ...defaultValue }]
        );
      }
    }
  }

  function getFilteredItems(newItems, searchText) {
    const text = searchText?.toLowerCase();

    return newItems.filter((d) => d.text.toLowerCase().includes(text));
  }

  function onSelectItems(_items) {
    setSelectedItems(_items);

    if (multiple) {
      onSelectionChanges(_items);
      onValueChanges(_items.map((d) => d.id));
    } else {
      onSelectionChanges(_items[0]);
      onValueChanges(_items[0].id);
    }
  }

  const { getSelectedItemProps, removeSelectedItem, getDropdownProps } =
    useMultipleSelection({
      selectedItems,
      onStateChange({ selectedItems: newSelectedItems, type }) {
        switch (type) {
          case useMultipleSelection.stateChangeTypes
            .SelectedItemKeyDownBackspace:
          case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownDelete:
          case useMultipleSelection.stateChangeTypes.DropdownKeyDownBackspace:
          case useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem:
            onSelectItems(newSelectedItems);
            break;
          default:
            break;
        }
      },
    });

  const {
    isOpen,
    getToggleButtonProps,
    getItemProps,
    getInputProps,
    getMenuProps,
  } = useCombobox({
    items,
    itemToString(item) {
      return item ? item.text : "";
    },
    defaultHighlightedIndex: 0, // after selection, highlight the first item.
    selectedItem: null,
    inputValue,
    stateReducer(state, actionAndChanges) {
      const { changes, type } = actionAndChanges;

      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          return {
            ...changes,
            isOpen: false,
            highlightedIndex: 0, // with the first option highlighted.
          };
        case useCombobox.stateChangeTypes.InputClick:
          return {
            ...changes,
            isOpen: true,
            highlightedIndex: 0,
          };
        case useCombobox.stateChangeTypes.ToggleButtonClick:
          return {
            ...changes,
            isOpen: multiple ? true : !state.isOpen,
            highlightedIndex: 0,
          };
        default:
          return changes;
      }
    },
    onStateChange({
      inputValue: newInputValue,
      type,
      selectedItem: newSelectedItem,
    }) {
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputBlur:
          if (
            newSelectedItem &&
            !selectedItems.find((item) => item.id === newSelectedItem.id)
          ) {
            onSelectItems(
              multiple ? [...selectedItems, newSelectedItem] : [newSelectedItem]
            );
            setInputValue("");
          }
          break;

        case useCombobox.stateChangeTypes.InputChange:
          setInputValue(newInputValue);

          break;
        default:
          break;
      }
    },
  });

  return (
    <div id="selectInput" {...props}>
      <Dropdown show={disabled || !data.length ? false : isOpen}>
        <Dropdown.Toggle disabled={disabled}>
          <div
            className={`w-72 d-flex align-items-center gap-1 cursor-pointer`}
            {...getToggleButtonProps(
              getDropdownProps(
                { preventKeyAction: isOpen },
                { suppressRefError: true }
              )
            )}
          >
            {multiple ? (
              <div
                className="d-flex align-items-center flex-wrap overflow-x-hidden w-100 form-control"
                style={inputStyles}
              >
                <div className="d-flex align-items-center flex-wrap gap-2 text-truncate w-100">
                  {selectedItems.map((item, index) => (
                    <div key={item.id} className="select-value">
                      <span
                        className="text-ellipsis lato-medium font-12 text-truncate"
                        style={{ whiteSpace: "nowrap" }}
                        {...getSelectedItemProps({
                          selectedItem: item,
                          index,
                        })}
                      >
                        {item.text}
                      </span>
                      <button
                        style={{
                          background: "transparent",
                          border: "none",
                          padding: 0,
                          color: "#566182",
                        }}
                        onClick={(event) => {
                          event.stopPropagation();
                          removeSelectedItem(item);
                        }}
                      >
                        <i className="fa-solid fa-times font-10"></i>
                      </button>
                    </div>
                  ))}
                  <div className="select-autocomplete ps-1">
                    <input
                      type="text"
                      placeholder={selectedItems.length ? "" : placeholder}
                      {...getInputProps({}, { suppressRefError: true })}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className="form-control d-flex align-items-center">
                  <div
                    className="flex-grow-1 text-start text-truncate"
                    data-tooltip-id="selectLabel"
                    data-tooltip-content={
                      selectedItems.length ? selectedItems[0].text : ""
                    }
                  >
                    {selectedItems.length ? (
                      <span
                        {...getSelectedItemProps({
                          selectedItem: selectedItems.length
                            ? selectedItems[0]
                            : null,
                          index: 0,
                        })}
                      >
                        {selectedItems[0].text}
                      </span>
                    ) : (
                      <span className="select-placeholder">{placeholder}</span>
                    )}
                  </div>
                  <button style={{ background: "transparent", border: "none" }}>
                    <i
                      className={`fa-solid pt-1 light-grey-text ${
                        isOpen ? "fa-caret-up" : "fa-caret-down"
                      }`}
                    ></i>
                  </button>
                </div>
              </>
            )}
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {allowSearch && !multiple ? (
            <div className="px-1 py-1">
              <input
                placeholder="Search"
                className="custom-search-input"
                {...getInputProps({}, { suppressRefError: true })}
              />
            </div>
          ) : undefined}
          <ul
            className="custom-dropdown"
            style={{ maxHeight: "200px", overflowY: "auto" }}
            {...getMenuProps(
              getDropdownProps(
                { preventKeyAction: isOpen },
                { suppressRefError: true }
              ),
              {
                suppressRefError: true,
              }
            )}
          >
            {items.map((item, index) => (
              <li
                key={item.id}
                {...getItemProps({
                  key: item.id,
                  index,
                  item,
                })}
                className={`custom-dropdown-item text-truncate ${
                  selectedItems.find((d) => d.id === item.id)
                    ? "highlighted"
                    : undefined
                }`}
                style={{ minHeight: "35px" }}
                data-tooltip-id="selectItem"
                data-tooltip-content={item.text}
              >
                {item.text}
              </li>
            ))}
            {!items.length ? (
              <li className="custom-dropdown-item">No Data Found!</li>
            ) : undefined}
            <Tooltip id="selectItem" />
          </ul>
        </Dropdown.Menu>
      </Dropdown>
      <Tooltip id="selectLabel" />
    </div>
  );
}
