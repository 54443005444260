import "react-toastify/dist/ReactToastify.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "react-tooltip/dist/react-tooltip.css";
import Sidebar from "../side-bar.component";
import { Outlet } from "react-router-dom";
import HeaderComponent from "../../header/header.component";
import SidebarMenu from "../../sidebar-menu/sidebar-menu.component";
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import IntegrationService from "../../services/integrationservice";
import LoadingAnimation from "../lottie-files/loading.lotte";
import { GetPermission } from "../../../apis/pvlapis";
import PermissionDenied from "../../shared/permission-denied.component";

const IntegrationLayout = () => {
  const [integrations, setIntegrations] = useState(null);
  const [configurations, setConfigurations] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isPermissionLoading, setIsPermissionLoading] = useState(false);
  const [showIETab, setShowIETab] = useState(false);



  const stableIntegrationInfo = useCallback(getIntegrationDetails, []);

  const handleGetPermission = async() =>{
    setIsPermissionLoading(true);

    try {
        const response = await GetPermission();
        if(response.statusCode !==200 || response?.result === null){
            return;
        }
        const ieSettingObject = response?.result?.find(obj => obj?.component === "settingsviewintegration");

        setShowIETab(ieSettingObject?.permission);
    } catch (error) {
        toast.error("Something went wrong. Please try again after some time.",{position:toast.POSITION.BOTTOM_LEFT})
    }
    finally{
      setIsPermissionLoading(false);
    }
    
}

  async function getIntegrationDetails() {
    setIsLoading(true);

    try {
      const integrations = await IntegrationService.Integrations();
      const configurations = await IntegrationService.CRMConfigurations();

      const integrationsObj = {};
      integrations.forEach((int) => {
        if (!integrationsObj[int.integrationType]) {
          integrationsObj[int.integrationType] = int;
        }
      });

      const configurationObj = {};
      configurations.forEach((config) => {
        if (!configurationObj[config.crmConfigurationId]) {
          config.uiConfig = JSON.parse(config.uiConfig);
          configurationObj[config.crmConfigurationId] = config;
        }
      });

      setIntegrations(integrationsObj);
      setConfigurations(configurationObj);
      setIsLoading(false);
    } catch (error) {
      toast.error(error?.message ?? "Failed to fetch integrations details.");
    }
  }

  useEffect(() => {
    if (!configurations) {
      handleGetPermission();
      stableIntegrationInfo();

    }
  }, [configurations, stableIntegrationInfo]);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-auto sidebar-menu sticky-top">
          <SidebarMenu />
        </div>
        <div className="col-sm min-vh-100 p-0">
          <div>
            <HeaderComponent />
            <Container fluid className="main-page-wrap">
              <Row>
                <Col>
                  <div className="d-flex align-items-center justify-content-between page-header py-3">
                    <span className="font-18 dark-text poppins-semibold">
                      Settings
                    </span>
                  </div>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col sm={3}>
                  <Sidebar />
                </Col>
                {showIETab && 
                  <Col sm={9}>
                  <div className="profile-card">
                  {isLoading || !configurations ? (
                    <div className="loading-animation">
                      <LoadingAnimation />
                      <span>Loading data, please wait...</span>
                    </div>
                  ) : (
                    <Outlet
                      context={{
                        integrations,
                        configurations,
                        setIntegrations,
                        setConfigurations,
                      }}
                    />
                  )}
                  </div>
                </Col>
                }
                {
                  isPermissionLoading && (
                  <div className="loading-animation">
                      <LoadingAnimation/>
                      <span>
                      Loading data, please wait...
                      </span>
                  </div>
                  )
                }
                {!showIETab &&!isPermissionLoading &&
                      <Col sm={9}>
                      <div className="profile-card mb-4">
                      <PermissionDenied/>
                      </div>
                      </Col>
                  }
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntegrationLayout;
