import React from 'react';
import { Link } from 'react-router-dom';
import './paymentsuccess.scss'
import Modal from 'react-bootstrap/Modal';


const PaymentFailed = ({messHead,messDescription,onTryAgain, onClose}) => {
    const handleTryAgain = () => {
        onTryAgain();
    }
    const handleCloseModal = () => {
        onClose();
    }

  return(
    <>
        <Modal
                show={true}
                size="sg"
                aria-labelledby="contained-modal-title-vcenter"
                // centered
                onHide={handleCloseModal}
                className='modal-no-border'
        >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div className="verify-fail">
                        <div className="text-center mb-3">
                        <img src={require("../../../../../assets/img/alert.svg").default} alt="Failed" />
                        </div>
                        
                        <div className="text-center font-18 dark-text poppins-semibold mb-2">
                        {messHead}
                        </div>
                        <p className="text-center font-14 grey-text mw-388 pt-1 poppins-regular">{messDescription}</p>
                        <div className="text-center mt-3">
                            <button className="btn blue-btn-primary font-12 position-relative btn btn-primary" onClick={handleTryAgain}>
                                 <i class="fa-regular fa-arrow-rotate-left me-1"> </i>Try Again</button>
                        </div>
                    </div>
                </Modal.Body>
        </Modal>         
    </>

  );
};

export { PaymentFailed };