import React from "react";
import './app.component.scss'
import SidebarMenu from "./sidebar-menu/sidebar-menu.component";
import HomeComponent from "./base-content/home/home.component";
import HeaderComponent from "./header/header.component";
//This is the complete home page component. It imports homecomponent, header component, sidebar component and creates the final page.
//A component like this should be created for all the pages and then imported in App.js to be shown in the website.
const HomeApp = () => {
    return (
<div className="container-fluid">
  <div className="row">
      <div className="col-sm-auto sidebar-menu sticky-top">
        <SidebarMenu />
      </div>
      <div className="col-sm min-vh-100 p-0">
        <div>
            <HeaderComponent />
            <HomeComponent />
        </div>
      </div>
  </div>
</div>
    );
}

export default HomeApp;