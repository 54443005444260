import React, { useState } from "react";
import UploadedComponent from "../../uploads/uploaded.component";

const UploadedModal = ({ file, onFileDelete, dataErrorMessage, dataWarningMessage, projectNameErrorMessage, name, onNameChange }) => {
    const handleNameChange = (name) => {
        onNameChange(name);
    }
    const handleDeleteFile = () => {
        onFileDelete();
    }
  return (
    <>
        <div className="form-steps mb-3">
            <div className='active'>Upload CSV</div>
            <div><i className='fa-solid fa-chevron-right'></i></div>
            <div>Map Fields</div>
            <div><i className='fa-solid fa-chevron-right'></i></div>
            <div>Review</div>
        </div>
            <div className="input-form my-3">
            <label>
                    Add a Title to your List
            </label>
            <input className={`form-control ${projectNameErrorMessage ? 'is-invalid' : ''}`} placeholder="Enter List Title" type="text" value={name} onChange={(e) => handleNameChange(e.target.value)} />
        </div>
        <UploadedComponent file={file} onDelete={handleDeleteFile}/>
            {dataErrorMessage && (
            <p className="font-14" style={{ color: 'red' }}>{dataErrorMessage}</p>
            )}
            {!dataErrorMessage && (
            <>
                {dataWarningMessage && (
                <p className="text_error_message">{dataWarningMessage}</p>
                )}
                {projectNameErrorMessage && (
                <p className="font-14" style={{ color: 'red' }}>{projectNameErrorMessage}</p>
                )}
            </>
    )}
    </>
  );
};

export default UploadedModal;